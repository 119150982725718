import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { toast } from 'react-hot-toast';
import { Trophy } from 'lucide-react';
import { auth, db } from '@/lib/firebase';
import { Button } from '@/components/ui/Button';
import { motion } from 'framer-motion';
import { doc, setDoc, writeBatch, increment, arrayUnion, getDoc } from 'firebase/firestore';

const ALLOWED_EMAIL_DOMAINS = [
  'yahoo.com',
  'yahoo.co.uk',
  'hotmail.com',
  'hotmail.co.uk',
  'gmail.com',
  'live.com',
  'outlook.com'
];

const registerSchema = z.object({
  email: z.string()
    .email('Invalid email address')
    .refine((email) => {
      const domain = email.split('@')[1];
      return ALLOWED_EMAIL_DOMAINS.includes(domain);
    }, 'Only email addresses from major providers (Gmail, Yahoo, Hotmail, Live) are allowed'),
  password: z.string().min(6, 'Password must be at least 6 characters'),
  confirmPassword: z.string(),
}).refine(data => data.password === data.confirmPassword, {
  message: "Passwords don't match",
  path: ["confirmPassword"],
});

type RegisterFormData = z.infer<typeof registerSchema>;

export function Register() {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm<RegisterFormData>({
    resolver: zodResolver(registerSchema),
  });

  const onSubmit = async (data: RegisterFormData) => {
    try {
      setIsLoading(true);
      const { user } = await createUserWithEmailAndPassword(auth, data.email, data.password);

      // Create user document with basic data
      const userRef = doc(db, 'users', user.uid);
      await setDoc(userRef, {
        email: data.email,
        createdAt: new Date(),
        totalEarned: 0,
        points: 0,
        tasksCompleted: 0,
        streak: 0,
        isActive: false,
        emailVerified: false
      });

      // Send verification email
      await sendEmailVerification(user, {
        url: `${window.location.origin}/login`,
      });
      
      toast.success('Account created! Please check your email to verify your account.');
      navigate('/verification-pending');

    } catch (error: any) {
      console.error('Registration error:', error);
      toast.error(error.message || 'Failed to create account');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-r from-gray-800 to-gray-900 flex items-center justify-center p-4">
      <motion.div 
        className="w-full max-w-md space-y-8 bg-gray-800 p-8 rounded-lg shadow-lg"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
      >
        <div className="text-center">
          <Link to="/" className="inline-flex items-center space-x-2">
            <Trophy className="h-8 w-8 text-white" />
            <span className="text-2xl font-bold text-white">TaskRewards</span>
          </Link>
          <h2 className="mt-6 text-3xl font-bold text-white">Create your account</h2>
          <p className="mt-2 text-gray-300">Start earning rewards today</p>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="mt-8 space-y-6">
          <div className="space-y-4">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-white">
                Email address
              </label>
              <input
                {...register('email')}
                type="email"
                className="mt-1 block w-full rounded-md border border-gray-600 bg-gray-700 px-3 py-2 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-300"
                placeholder="you@example.com"
              />
              {errors.email && (
                <p className="mt-1 text-sm text-red-400">{errors.email.message}</p>
              )}
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-white">
                Password
              </label>
              <input
                {...register('password')}
                type="password"
                className="mt-1 block w-full rounded-md border border-gray-600 bg-gray-700 px-3 py-2 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-300"
              />
              {errors.password && (
                <p className="mt-1 text-sm text-red-400">{errors.password.message}</p>
              )}
            </div>

            <div>
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-white">
                Confirm Password
              </label>
              <input
                {...register('confirmPassword')}
                type="password"
                className="mt-1 block w-full rounded-md border border-gray-600 bg-gray-700 px-3 py-2 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-300"
              />
              {errors.confirmPassword && (
                <p className="mt-1 text-sm text-red-400">{errors.confirmPassword.message}</p>
              )}
            </div>

            <div className="bg-gray-700/50 rounded-lg p-4 mt-4">
              <p className="text-sm text-gray-300 mb-2">Allowed email providers:</p>
              <div className="grid grid-cols-2 gap-2">
                {ALLOWED_EMAIL_DOMAINS.map((domain) => (
                  <div key={domain} className="text-sm text-gray-400 flex items-center">
                    <span className="text-green-400 mr-2">✓</span>
                    @{domain}
                  </div>
                ))}
              </div>
            </div>
          </div>

          <Button type="submit" className="w-full bg-gradient-to-r from-purple-600 to-pink-600" disabled={isLoading}>
            {isLoading ? 'Creating account...' : 'Create account'}
          </Button>

          <p className="text-center text-sm text-gray-300">
            Already have an account?{' '}
            <Link to="/login" className="text-white hover:underline">
              Sign in
            </Link>
          </p>
        </form>
      </motion.div>
    </div>
  );
}