import React, { useState, useEffect } from 'react';
import { collection, addDoc, onSnapshot, deleteDoc, doc } from 'firebase/firestore';
import { db } from '@/lib/firebase';
import { Button } from '@/components/ui/Button';
import { toast } from 'react-toastify';

const TAGS = ['Update', 'Event', 'Maintenance', 'Promotion', 'General'];

export function CreateNewsArticle() {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [link, setLink] = useState('');
  const [image, setImage] = useState('');
  const [author, setAuthor] = useState('');
  const [tag, setTag] = useState(TAGS[0]);
  const [date, setDate] = useState(new Date().toISOString().split('T')[0]);
  const [newsArticles, setNewsArticles] = useState<any[]>([]);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'news'), (snapshot) => {
      const articlesData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNewsArticles(articlesData);
    });

    return () => unsubscribe();
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      const newArticle = {
        title,
        content,
        link,
        image,
        author,
        tag,
        date: new Date(date),
      };

      const articlesRef = collection(db, 'news');
      await addDoc(articlesRef, newArticle);
      toast.success('News article created successfully!');
      // Reset form fields
      setTitle('');
      setContent('');
      setLink('');
      setImage('');
      setAuthor('');
      setTag(TAGS[0]);
      setDate(new Date().toISOString().split('T')[0]);
    } catch (error) {
      console.error('Error creating news article:', error);
      toast.error('Failed to create news article. Please try again.');
    }
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteDoc(doc(db, 'news', id));
      toast.success('News article deleted successfully!');
    } catch (error) {
      console.error('Error deleting news article:', error);
      toast.error('Failed to delete news article. Please try again.');
    }
  };

  return (
    <div className="bg-gray-900 p-6 rounded-lg shadow-lg grid grid-cols-1 md:grid-cols-2 gap-6">
      {/* Create News Article Form */}
      <div className="p-4 bg-gray-800 rounded-lg">
        <h2 className="text-2xl font-bold mb-4">Create News Article</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Title</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Content</label>
            <textarea
              value={content}
              onChange={(e) => setContent(e.target.value)}
              className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Link</label>
            <input
              type="url"
              value={link}
              onChange={(e) => setLink(e.target.value)}
              className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Image URL</label>
            <input
              type="text"
              value={image}
              onChange={(e) => setImage(e.target.value)}
              className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Author</label>
            <input
              type="text"
              value={author}
              onChange={(e) => setAuthor(e.target.value)}
              className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Tag</label>
            <select
              value={tag}
              onChange={(e) => setTag(e.target.value)}
              className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg"
            >
              {TAGS.map((tag) => (
                <option key={tag} value={tag}>{tag}</option>
              ))}
            </select>
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Date</label>
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg"
            />
          </div>
          <Button type="submit" className="mt-2">Create Article</Button>
        </form>
      </div>

      {/* List of News Articles */}
      <div className="p-4 bg-gray-800 rounded-lg">
        <h2 className="text-2xl font-bold mt-6 mb-4">Existing News Articles</h2>
        <div className="space-y-4">
          {newsArticles.map(article => (
            <div key={article.id} className="p-4 bg-gray-700 rounded-lg">
              <h4 className="font-medium">{article.title}</h4>
              <p className="text-sm text-gray-300">{article.content}</p>
              <p className="text-xs text-gray-500">Posted by: {article.author} on {new Date(article.date).toLocaleString()}</p>
              <div className="flex justify-between mt-2">
                <Button onClick={() => handleDelete(article.id)} className="text-red-500">Delete</Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}