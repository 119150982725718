// src/lib/firebase.ts
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import {
  getFirestore,
  doc,
  setDoc,
  collection,
  getDocs,
  serverTimestamp,
  addDoc,
  query,
  where,
  onSnapshot,
  limit,
} from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getAnalytics } from 'firebase/analytics';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDQGNubArIKrPP-0pgxn0pkC6jf5OzlteA",
  authDomain: "dailysolly-f9552.firebaseapp.com",
  projectId: "dailysolly-f9552",
  storageBucket: "dailysolly-f9552.appspot.com", // Corrected storage bucket format
  messagingSenderId: "814399554063",
  appId: "1:814399554063:web:b37552a783c956a5c4597a",
  measurementId: "G-E883MTW9KE"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);

// Type definition for Notification
export interface Notification {
  title: string;
  message: string;
  type: 'reward' | 'system' | 'social' | 'achievement';
  read: boolean;
  createdAt: string;
}

// Type definition for Task
export interface Task {
  id: string;
  title: string;
  description: string;
  balance: number;
  type: 'daily' | 'weekly' | 'special'; // Added task types
  maxStreak: number;
}

// Type definition for User
export interface User {
  email: string;
  referralCode: string;
  referralLink: string;
  createdAt: string;
  points: number;
  streak: number;
  balance: number;
  tasksCompleted: number;
  isAdmin: boolean;
  lastLoginAt: string;
  status: 'active' | 'inactive';
  proofs?: {
    [taskId: string]: string; // Stores proof URLs or text
  };
}

// Helper function to initialize user data
export const initializeUserData = async (userId: string, email: string): Promise<void> => {
  try {
    const userRef = doc(db, 'users', userId);
    const referralCode = `${Math.random().toString(36).substring(2, 8).toUpperCase()}-${userId.substring(0, 6)}`;
    
    await setDoc(userRef, {
      email,
      referralCode,
      referralLink: `dailysolly.eu/${referralCode}`,
      createdAt: new Date().toISOString(),
      points: 0,
      streak: 0,
      balance: 0,
      tasksCompleted: 0,
      isAdmin: false,
      lastLoginAt: new Date().toISOString(),
      status: 'active',
      proofs: {},
    }, { merge: true });
  } catch (error) {
    console.error('Error initializing user data:', error);
  }
};

// Function to create a notification
export const createNotification = async (notification: Notification): Promise<void> => {
  try {
    const notificationsRef = collection(db, 'notifications');
    await addDoc(notificationsRef, {
      ...notification,
      createdAt: new Date().toISOString(), // Ensure createdAt is set to the current date
    });
    console.log('Notification created successfully!');
  } catch (error) {
    console.error('Error creating notification:', error);
  }
};

// Function to update system stats
export const updateSystemStats = async (): Promise<void> => {
  try {
    const statsRef = doc(db, 'systemStats', 'global');
    const usersRef = collection(db, 'users');
    const tasksRef = collection(db, 'tasks');
    const withdrawalsRef = collection(db, 'withdrawals');

    // Get total users
    const usersSnapshot = await getDocs(usersRef);
    const totalUsers = usersSnapshot.size;

    // Get active users (last 24h)
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    const activeUsers = usersSnapshot.docs.filter(doc => 
      new Date(doc.data().lastLoginAt) > yesterday
    ).length;

    // Get total tasks completed
    const tasksSnapshot = await getDocs(tasksRef);
    const totalTasks = tasksSnapshot.docs.reduce((sum, doc) => 
      sum + (doc.data().completionCount || 0), 0
    );

    // Get total rewards distributed
    const withdrawalsSnapshot = await getDocs(withdrawalsRef);
    const totalRewards = withdrawalsSnapshot.docs.reduce((sum, doc) => 
      sum + (doc.data().amount || 0), 0
    );

    // Update stats
    await setDoc(statsRef, {
      totalUsers,
      activeUsers,
      totalTasks,
      totalRewards,
      lastUpdated: serverTimestamp()
    }, { merge: true });
  } catch (error) {
    console.error('Error updating system stats:', error);
  }
};

// Function to fetch all tasks (optimized for lazy loading)
export const fetchAllTasks = async (limitCount: number = 10): Promise<Task[]> => {
  try {
    const tasksRef = collection(db, 'tasks');
    const tasksSnapshot = await getDocs(query(tasksRef, limit(limitCount))); // Renamed variable
    const tasks: Task[] = tasksSnapshot.docs.map(doc => ({
      id: doc.id,
      title: doc.data().title,
      description: doc.data().description,
      balance: doc.data().balance,
      type: doc.data().type,
      maxStreak: doc.data().maxStreak,
    }));
    return tasks;
  } catch (error) {
    console.error('Error fetching tasks:', error);
    return [];
  }
};

// Function to fetch a random task based on type (optimized)
export const fetchRandomTask = async (type: Task['type']): Promise<Task | null> => {
  try {
    const tasksRef = collection(db, 'tasks');
    const q = query(tasksRef, where('type', '==', type), limit(1)); // Limit to one document
    const tasksSnapshot = await getDocs(q);
    const tasks: Task[] = tasksSnapshot.docs.map(doc => ({
      id: doc.id,
      title: doc.data().title,
      description: doc.data().description,
      balance: doc.data().balance,
      type: doc.data().type,
      maxStreak: doc.data().maxStreak,
    }));

    if (tasks.length === 0) {
      console.warn(`No tasks found for type: ${type}`);
      return null;
    }

    // Select a random task from the fetched tasks
    const randomIndex = Math.floor(Math.random() * tasks.length);
    return tasks[randomIndex];
  } catch (error) {
    console.error('Error fetching random task:', error);
    return null;
  }
};

// Helper function to upload proof file to Firebase Storage
export const uploadProofFile = async (file: File, userId: string, taskId: string): Promise<string | null> => {
  try {
    const storageRef = ref(storage, `proofs/${userId}/${taskId}/${file.name}`);
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    return downloadURL;
  } catch (error) {
    console.error('Error uploading proof file:', error);
    return null;
  }
};

// Function to listen for user data changes (optimized with detachment)
export const listenForUserData = (userId: string, callback: (data: User | null) => void) => {
  const userRef = doc(db, 'users', userId);
  const unsubscribe = onSnapshot(userRef, (doc) => {
    if (doc.exists()) {
      callback(doc.data() as User);
    } else {
      callback(null);
    }
  });

  // Return the unsubscribe function to detach the listener when not needed
  return unsubscribe;
};

// Add this to your Firebase config file
export const actionCodeSettings = {
  url: 'https://dailysolly.eu/verify-email',
  handleCodeInApp: true,
  dynamicLinkDomain: 'dailysolly.eu'
};
