// src/components/dashboard/TaskManagement.tsx

import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import {
  collection,
  getDocs,
  doc,
  updateDoc,
  addDoc,
  deleteDoc,
  getDoc,
  increment,
} from 'firebase/firestore';
import { db } from '@/lib/firebase';
import { Button } from '@/components/ui/Button';
import {
  Plus,
  Edit2,
  Trash2,
  Save,
  X,
  CheckCircle,
  Clock,
  Target,
} from 'lucide-react';
import { toast } from 'react-hot-toast';
import { auth } from '@/lib/firebase';

interface Task {
  id: string;
  title: string;
  description: string;
  reward: number;
  type: 'football' | 'bible' | 'quiz';
  difficulty: 'easy' | 'medium' | 'hard';
  createdAt: string;
  status: 'active' | 'inactive';
  completionCount: number;
  day: number;
  question: string;
  answer: string;
  points: number;
}

// Define a separate interface for the form state
interface FormTask {
  title: string;
  description: string;
  reward: number;
  type: 'football' | 'bible' | 'quiz';
  difficulty: 'easy' | 'medium' | 'hard';
  status: 'active' | 'inactive';
  day: number;
  question: string;
  answer: string;
  points: number;
}

interface TaskManagementProps {
  onTaskCreated: (task: Task) => void;
}

export const TaskManagement: React.FC<TaskManagementProps> = ({ onTaskCreated }) => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [editingTask, setEditingTask] = useState<Task | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [filter, setFilter] = useState<'all' | 'active' | 'inactive'>('all');

  const initialFormState: FormTask = {
    title: '',
    description: '',
    reward: 0,
    type: 'football',
    difficulty: 'easy',
    status: 'active',
    day: 1,
    question: '',
    answer: '',
    points: 0,
  };

  const [formTask, setFormTask] = useState<FormTask>(initialFormState);

  useEffect(() => {
    fetchTasks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function fetchTasks() {
    try {
      const tasksSnapshot = await getDocs(collection(db, 'tasks'));
      const tasksData = tasksSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as Task[];

      // Sort tasks by day
      const sortedTasks = tasksData.sort((a, b) => a.day - b.day);
      setTasks(sortedTasks);
    } catch (error) {
      console.error('Failed to fetch tasks:', error);
      toast.error('Failed to load tasks');
    } finally {
      setLoading(false);
    }
  }

  const resetForm = () => {
    setFormTask({ ...initialFormState });
    setEditingTask(null);
    setShowModal(false);
  };

  async function handleAddTask() {
    try {
      // Check if a task already exists for the selected day
      const existingTask = tasks.find((task) => task.day === formTask.day);
      if (existingTask) {
        toast.error(`A task for Day ${formTask.day} already exists.`);
        return;
      }

      const taskRef = await addDoc(collection(db, 'tasks'), {
        ...formTask,
        createdAt: new Date().toISOString(),
        completionCount: 0,
        points: formTask.points,
      });

      setTasks([
        ...tasks,
        {
          id: taskRef.id,
          ...formTask,
          createdAt: new Date().toISOString(),
          completionCount: 0,
          points: formTask.points,
        },
      ]);
      resetForm();
      toast.success('Task created successfully');
    } catch (error) {
      console.error('Failed to add task:', error);
      toast.error('Failed to create task');
    }
  }

  async function handleUpdateTask() {
    if (!editingTask) return;

    try {
      // If the day is being changed, ensure no duplicate
      if (formTask.day !== editingTask.day) {
        const existingTask = tasks.find((task) => task.day === formTask.day);
        if (existingTask) {
          toast.error(`A task for Day ${formTask.day} already exists.`);
          return;
        }
      }

      await updateDoc(doc(db, 'tasks', editingTask.id), {
        ...formTask,
        points: formTask.points,
      });
      setTasks(
        tasks.map((task) =>
          task.id === editingTask.id ? { ...task, ...formTask } : task
        )
      );
      resetForm();
      toast.success('Task updated successfully');
    } catch (error) {
      console.error('Failed to update task:', error);
      toast.error('Failed to update task');
    }
  }

  async function handleDeleteTask(taskId: string) {
    if (!window.confirm('Are you sure you want to delete this task?')) return;

    try {
      await deleteDoc(doc(db, 'tasks', taskId));
      setTasks(tasks.filter((task) => task.id !== taskId));
      toast.success('Task deleted successfully');
    } catch (error) {
      console.error('Failed to delete task:', error);
      toast.error('Failed to delete task');
    }
  }

  const toggleTaskStatus = async (task: Task) => {
    try {
      const updatedStatus: 'active' | 'inactive' =
        task.status === 'active' ? 'inactive' : 'active';
      await updateDoc(doc(db, 'tasks', task.id), { status: updatedStatus });
      setTasks(
        tasks.map((t) =>
          t.id === task.id ? { ...t, status: updatedStatus } : t
        )
      );
      toast.success(
        `Task ${updatedStatus === 'active' ? 'activated' : 'deactivated'} successfully`
      );
    } catch (error) {
      console.error('Failed to update task status:', error);
      toast.error('Failed to update task status');
    }
  };

  const getDifficultyColor = (difficulty: Task['difficulty']) => {
    switch (difficulty) {
      case 'easy':
        return 'text-green-400 bg-green-500/20';
      case 'medium':
        return 'text-yellow-400 bg-yellow-500/20';
      case 'hard':
        return 'text-red-400 bg-red-500/20';
      default:
        return 'text-gray-400 bg-gray-500/20';
    }
  };

  const getTypeIcon = (type: Task['type']) => {
    switch (type) {
      case 'football':
        return <CheckCircle className="h-4 w-4 inline" />;
      case 'bible':
        return <CheckCircle className="h-4 w-4 inline" />;
      case 'quiz':
        return <CheckCircle className="h-4 w-4 inline" />;
      default:
        return null;
    }
  };

  const filteredTasks = tasks.filter((task) =>
    filter === 'all' ? true : task.status === filter
  );

  const getDayName = (day: number): string => {
    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    return days[day - 1];
  };

  async function handleCompleteTask(task: Task) {
    const user = auth.currentUser;
    if (!user) return;

    const userRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userRef);
    const userData = userDoc.data();

    // Update user's points
    if (userData) {
        const newPoints = (userData.points || 0) + formTask.reward; // Use formTask.reward for points
        await updateDoc(userRef, { points: newPoints });
    } else {
        toast.error('User data not found');
    }

    // Update task's completion count
    const taskRef = doc(db, 'tasks', task.id);
    await updateDoc(taskRef, {
        completionCount: increment(1),
    });

    // Notify parent component about task completion
    onTaskCreated({
      ...task,
      reward: formTask.reward,
    });
  }

  if (loading) {
    return (
      <div className="space-y-4">
        <div className="h-8 bg-gray-800 rounded w-1/4 animate-pulse"></div>
        <div className="h-64 bg-gray-800 rounded animate-pulse"></div>
      </div>
    );
  }

  return (
    <div className="space-y-6 p-4 md:p-6 lg:p-8">
      {/* Header */}
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between space-y-4 md:space-y-0">
        <div>
          <h2 className="text-2xl font-bold">Task Management</h2>
          <p className="text-gray-400">Create and manage daily tasks</p>
        </div>
        <Button
          onClick={() => {
            setShowModal(true);
            setFormTask(initialFormState);
          }}
        >
          <Plus className="h-4 w-4 mr-2" />
          Add New Task
        </Button>
      </div>

      {/* Filters */}
      <div className="flex items-center space-x-4 mb-6">
        <div className="flex space-x-2 overflow-x-auto">
          {(['all', 'active', 'inactive'] as const).map((status) => (
            <Button
              key={status}
              variant={filter === status ? 'default' : 'ghost'}
              onClick={() => setFilter(status)}
            >
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </Button>
          ))}
        </div>
      </div>

      {/* Task List */}
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
        {filteredTasks.map((task) => (
          <div
            key={task.id}
            className="bg-gray-800/50 rounded-lg p-6 border border-gray-700/50 flex flex-col justify-between h-full"
          >
            <div>
              {/* Task Day Banner */}
              <div className="mb-4 -mt-6 -mx-6 p-2 bg-blue-500/20 text-blue-400 text-center font-semibold">
                {getDayName(task.day)} (Day {task.day})
              </div>

              {/* Task Header */}
              <div className="flex flex-col space-y-2">
                <div className="flex items-center justify-between flex-wrap gap-2">
                  <h3 className="text-lg font-semibold">{task.title}</h3>
                  <div className="flex items-center space-x-2">
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => toggleTaskStatus(task)}
                      className={`text-xs ${
                        task.status === 'active' 
                          ? 'text-green-400 hover:text-green-500' 
                          : 'text-red-400 hover:text-red-500'
                      }`}
                    >
                      {task.status === 'active' ? 'Active' : 'Inactive'}
                    </Button>
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => {
                        setEditingTask(task);
                        setFormTask({
                          title: task.title,
                          description: task.description,
                          reward: task.reward,
                          type: task.type,
                          difficulty: task.difficulty,
                          status: task.status,
                          day: task.day,
                          question: task.question,
                          answer: task.answer,
                          points: task.points,
                        });
                        setShowModal(true);
                      }}
                      className="text-blue-400 hover:text-blue-500"
                    >
                      <Edit2 className="h-4 w-4" />
                    </Button>
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => handleDeleteTask(task.id)}
                      className="text-red-400 hover:text-red-500"
                    >
                      <Trash2 className="h-4 w-4" />
                    </Button>
                  </div>
                </div>
                <div className="flex flex-wrap gap-2">
                  <span
                    className={`px-2 py-1 rounded-full text-xs ${getDifficultyColor(
                      task.difficulty
                    )}`}
                  >
                    {task.difficulty.charAt(0).toUpperCase() +
                      task.difficulty.slice(1)}
                  </span>
                  <span className="px-2 py-1 rounded-full text-xs bg-purple-500/20 text-purple-400 flex items-center">
                    {getTypeIcon(task.type)}
                    <span className="ml-1 capitalize">{task.type}</span>
                  </span>
                </div>
              </div>

              {/* Task Description */}
              <p className="text-gray-400 mt-2">{task.description}</p>
            </div>

            {/* Task Footer */}
            <div className="flex items-center justify-between mt-4 text-sm text-gray-400">
              <span>{task.reward} SOL</span>
              <span>{task.completionCount ?? 0} completions</span>
              <span>
                Created {new Date(task.createdAt).toLocaleDateString()}
              </span>
            </div>
          </div>
        ))}
      </div>

      {/* Add/Edit Task Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
          <div className="bg-gray-900 p-6 rounded-xl max-w-md w-full overflow-y-auto max-h-full">
            <div className="flex items-center justify-between mb-6">
              <h3 className="text-xl font-bold">
                {editingTask ? 'Edit Task' : 'Add New Task'}
              </h3>
              <Button
                variant="ghost"
                size="sm"
                onClick={() => {
                  setShowModal(false);
                  setFormTask(initialFormState);
                }}
                aria-label="Close Modal"
              >
                <X className="h-5 w-5" />
              </Button>
            </div>

            <form
              onSubmit={(e: FormEvent<HTMLFormElement>) => {
                e.preventDefault();
                editingTask ? handleUpdateTask() : handleAddTask();
              }}
              className="space-y-4"
            >
              {/* Title */}
              <div>
                <label className="block text-sm font-medium mb-1">
                  Title
                </label>
                <input
                  type="text"
                  value={formTask.title}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFormTask({ ...formTask, title: e.target.value })
                  }
                  required
                  className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                />
              </div>

              {/* Description */}
              <div>
                <label className="block text-sm font-medium mb-1">
                  Description
                </label>
                <textarea
                  value={formTask.description}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                    setFormTask({
                      ...formTask,
                      description: e.target.value,
                    })
                  }
                  required
                  className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 h-24 resize-none"
                />
              </div>

              {/* Reward and Type */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {/* Reward */}
                <div>
                  <label className="block text-sm font-medium mb-1">
                    Reward (SOL)
                  </label>
                  <input
                    type="number"
                    min="0"
                    step="0.0001"
                    value={formTask.reward}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setFormTask({
                        ...formTask,
                        reward: parseFloat(e.target.value) || 0,
                      })
                    }
                    required
                    className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                  />
                </div>

                {/* Type */}
                <div>
                  <label className="block text-sm font-medium mb-1">
                    Type
                  </label>
                  <select
                    value={formTask.type}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                      setFormTask({
                        ...formTask,
                        type: e.target.value as FormTask['type'],
                      })
                    }
                    required
                    className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                  >
                    <option value="football">Football</option>
                    <option value="bible">Bible</option>
                    <option value="quiz">Quiz</option>
                  </select>
                </div>
              </div>

              {/* Difficulty and Day */}
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                {/* Difficulty */}
                <div>
                  <label className="block text-sm font-medium mb-1">
                    Difficulty
                  </label>
                  <select
                    value={formTask.difficulty}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                      setFormTask({
                        ...formTask,
                        difficulty: e.target.value as FormTask['difficulty'],
                      })
                    }
                    required
                    className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                  >
                    <option value="easy">Easy</option>
                    <option value="medium">Medium</option>
                    <option value="hard">Hard</option>
                  </select>
                </div>

                {/* Day */}
                <div>
                  <label className="block text-sm font-medium mb-1">
                    Day of Week
                  </label>
                  <select
                    value={formTask.day}
                    onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                      setFormTask({
                        ...formTask,
                        day: parseInt(e.target.value),
                      })
                    }
                    required
                    className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                  >
                    <option value={1}>Monday (Day 1)</option>
                    <option value={2}>Tuesday (Day 2)</option>
                    <option value={3}>Wednesday (Day 3)</option>
                    <option value={4}>Thursday (Day 4)</option>
                    <option value={5}>Friday (Day 5)</option>
                    <option value={6}>Saturday (Day 6)</option>
                    <option value={7}>Sunday (Day 7)</option>
                  </select>
                </div>
              </div>

              {/* Question */}
              <div>
                <label className="block text-sm font-medium mb-1">
                  Question
                </label>
                <input
                  type="text"
                  value={formTask.question}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFormTask({ ...formTask, question: e.target.value })
                  }
                  required
                  className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                />
              </div>

              {/* Answer */}
              <div>
                <label className="block text-sm font-medium mb-1">
                  Correct Answer
                </label>
                <input
                  type="text"
                  value={formTask.answer}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFormTask({ ...formTask, answer: e.target.value })
                  }
                  required
                  className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                />
              </div>

              {/* Points Field */}
              <div>
                <label className="block text-sm font-medium mb-1">
                  Points
                </label>
                <input
                  type="number"
                  min="0"
                  step="0.01"
                  value={formTask.points}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setFormTask({
                      ...formTask,
                      points: parseFloat(e.target.value) || 0,
                    })
                  }
                  required
                  className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                />
              </div>

              {/* Buttons */}
              <div className="flex justify-end space-x-3">
                <Button variant="ghost" type="button" onClick={resetForm}>
                  Cancel
                </Button>
                <Button type="submit">
                  <Save className="h-4 w-4 mr-2" />
                  {editingTask ? 'Update Task' : 'Create Task'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
