import React, { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot, Timestamp, DocumentData, updateDoc, doc } from 'firebase/firestore';
import { db, auth } from '@/lib/firebase';
import { Button } from '@/components/ui/Button';
import { MessageSquare, X, Send } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { toast } from 'react-hot-toast';

interface Ticket extends DocumentData {
  id: string;
  hasNewReply: boolean;
  status: string;
  title: string;
  description: string;
  replies: any[];
  createdAt: Timestamp;
}

interface Reply {
  id: string;
  content: string;
  userId: string;
  userEmail: string;
  isStaff: boolean;
  createdAt: Timestamp;
}

export function TicketIcon() {
  const [showTickets, setShowTickets] = useState(false);
  const [newTickets, setNewTickets] = useState(0);
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null);
  const [replyContent, setReplyContent] = useState('');
  const user = auth.currentUser;
  const [newReplies, setNewReplies] = useState(0);

  useEffect(() => {
    if (!user) return;

    const ticketsRef = collection(db, 'tickets');
    const q = query(
      ticketsRef,
      where('userId', '==', user.uid),
      where('hasNewReply', '==', true)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const tickets = snapshot.docs.map(doc => doc.data());
      const staffReplies = tickets.filter(ticket => 
        ticket.replies?.some((reply: Reply) => reply.isStaff)
      ).length;
      
      setNewReplies(staffReplies);
      setNewTickets(snapshot.docs.length - staffReplies);
    });

    return () => unsubscribe();
  }, [user]);

  // Fetch all tickets when modal is opened
  useEffect(() => {
    if (!user || !showTickets) return;

    const ticketsRef = collection(db, 'tickets');
    const q = query(
      ticketsRef,
      where('userId', '==', user.uid)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const ticketsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Ticket[];
      setTickets(ticketsData);

      // Clear notifications when opening tickets
      if (showTickets) {
        ticketsData.forEach(async (ticket) => {
          if (ticket.hasNewReply) {
            await updateDoc(doc(db, 'tickets', ticket.id), {
              hasNewReply: false
            });
          }
        });
      }
    });

    return () => unsubscribe();
  }, [user, showTickets]);

  const handleReply = async (ticket: Ticket) => {
    if (!replyContent.trim()) {
      toast.error('Reply cannot be empty');
      return;
    }

    try {
      const ticketRef = doc(db, 'tickets', ticket.id);
      const newReply = {
        id: Math.random().toString(36).substr(2, 9),
        content: replyContent.trim(),
        userId: user?.uid,
        userEmail: user?.email,
        isStaff: false,
        createdAt: Timestamp.now()
      };

      await updateDoc(ticketRef, {
        replies: [...(ticket.replies || []), newReply],
        hasNewReply: false
      });

      setReplyContent('');
      toast.success('Reply sent successfully');
    } catch (error) {
      console.error('Error sending reply:', error);
      toast.error('Failed to send reply');
    }
  };

  if (!user) return null;

  return (
    <>
      <div className="relative">
        <Button 
          variant="ghost" 
          size="sm" 
          onClick={() => setShowTickets(true)}
          className="relative"
        >
          <MessageSquare className="h-5 w-5 text-gray-400" />
          {newTickets > 0 && (
            <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
              {newTickets}
            </span>
          )}
          {newReplies > 0 && (
            <span className="absolute -top-1 left-4 bg-blue-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
              {newReplies}
            </span>
          )}
        </Button>
      </div>

      {showTickets && (
        <div className="fixed inset-0 bg-black/80 flex items-center justify-center z-50 backdrop-blur-sm">
          <div className="bg-gray-900 rounded-xl p-6 w-full max-w-xl max-h-[80vh] overflow-y-auto border border-gray-800">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-bold text-white flex items-center gap-2">
                <MessageSquare className="h-6 w-6 text-purple-400" />
                Your Tickets
              </h2>
              <Button 
                variant="ghost" 
                size="sm" 
                onClick={() => setShowTickets(false)}
                className="hover:bg-gray-800 rounded-full p-2"
              >
                <X className="h-5 w-5 text-gray-400 hover:text-white transition-colors" />
              </Button>
            </div>

            <div className="space-y-4">
              {tickets.map((ticket) => (
                <div key={ticket.id} 
                  className="bg-gray-800/50 p-4 rounded-xl border border-gray-700/50 cursor-pointer"
                  onClick={() => setSelectedTicket(ticket)}
                >
                  <div className="flex justify-between items-start mb-2">
                    <h4 className="font-medium text-white">{ticket.title}</h4>
                    <span className={`text-xs px-2 py-0.5 rounded-full border ${
                      ticket.status === 'open' ? 'text-green-400 border-green-400' :
                      ticket.status === 'in-progress' ? 'text-yellow-400 border-yellow-400' :
                      'text-gray-400 border-gray-400'
                    }`}>
                      {ticket.status}
                    </span>
                  </div>
                  
                  <p className="text-sm text-gray-300 mb-2">{ticket.description}</p>
                  
                  <div className="text-xs text-gray-400">
                    <span className="flex items-center gap-1">
                      <MessageSquare className="h-3 w-3" />
                      {ticket.replies?.length || 0} replies
                    </span>
                    <span className="text-xs text-gray-500">
                      {ticket.createdAt.toDate().toLocaleDateString()}
                    </span>
                  </div>

                  {selectedTicket?.id === ticket.id && (
                    <>
                      {ticket.replies?.length > 0 && (
                        <div className="mt-4 space-y-3">
                          <h5 className="text-sm font-medium text-gray-300">Replies:</h5>
                          {ticket.replies.map((reply) => (
                            <div key={reply.id} className="bg-gray-700/50 p-3 rounded-lg">
                              <div className="flex items-center justify-between mb-1">
                                <span className="text-sm font-medium text-gray-300">
                                  {reply.isStaff ? 'Support Staff' : 'You'}
                                </span>
                                <span className="text-xs text-gray-400">
                                  {reply.createdAt.toDate().toLocaleString()}
                                </span>
                              </div>
                              <p className="text-sm text-gray-300">{reply.content}</p>
                            </div>
                          ))}
                        </div>
                      )}

                      {ticket.status !== 'resolved' && (
                        <div className="mt-4">
                          <div className="flex gap-2">
                            <input
                              type="text"
                              value={replyContent}
                              onChange={(e) => setReplyContent(e.target.value)}
                              placeholder="Write a reply..."
                              className="flex-1 px-3 py-2 bg-gray-700 rounded-lg text-white border border-gray-600 focus:border-purple-500 focus:ring-1 focus:ring-purple-500 text-sm"
                              onClick={(e) => e.stopPropagation()}
                            />
                            <Button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleReply(ticket);
                              }}
                              className="flex items-center gap-2 bg-purple-600 hover:bg-purple-700"
                              size="sm"
                            >
                              <Send className="h-4 w-4" />
                              Reply
                            </Button>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}