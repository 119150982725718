import { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '@/lib/firebase';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
} from 'recharts';
import { Users, Award, Target, DollarSign } from 'lucide-react';

export function Analytics() {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    totalUsers: 0,
    activeUsers: 0,
    totalTasks: 0,
    totalRewards: 0,
  });

  const [taskCompletionData, setTaskCompletionData] = useState<{ date: string; completed: number }[]>([]);

  useEffect(() => {
    fetchAnalytics();
  }, []);

  async function fetchAnalytics() {
    try {
      const usersSnapshot = await getDocs(collection(db, 'users'));
      const tasksSnapshot = await getDocs(collection(db, 'tasks'));
      const withdrawalsSnapshot = await getDocs(collection(db, 'withdrawals'));

      // Calculate total users
      const totalUsers = usersSnapshot.size;

      // Calculate active users (assuming there's an isActive field)
      const activeUsers = usersSnapshot.docs.filter(doc => doc.data().isActive === true).length;

      // Calculate total tasks
      const totalTasks = tasksSnapshot.size;

      // Calculate total rewards from withdrawals
      const totalRewards = withdrawalsSnapshot.docs.reduce((acc, doc) => {
        return acc + (doc.data().amount || 0); // Ensure amount is defined
      }, 0);

      setStats({
        totalUsers,
        activeUsers,
        totalTasks,
        totalRewards,
      });

      // Fetch task completion data for the chart
      const taskData = tasksSnapshot.docs.map(doc => ({
        date: doc.data().date.toDate().toLocaleDateString(), // Assuming there's a date field
        completed: doc.data().completed ? 1 : 0, // Assuming there's a completed field
      }));

      // Aggregate task completion data by date
      const aggregatedData = taskData.reduce<{ date: string; completed: number }[]>((acc, curr) => {
        const existing = acc.find(item => item.date === curr.date);
        if (existing) {
          existing.completed += curr.completed;
        } else {
          acc.push({ date: curr.date, completed: curr.completed });
        }
        return acc;
      }, []);

      setTaskCompletionData(aggregatedData);
    } catch (error) {
      console.error('Failed to fetch analytics:', error);
    } finally {
      setLoading(false);
    }
  }

  const userActivityData = [
    { date: 'Mon', users: 120, tasks: 350 },
    { date: 'Tue', users: 150, tasks: 420 },
    { date: 'Wed', users: 180, tasks: 380 },
    { date: 'Thu', users: 190, tasks: 450 },
    { date: 'Fri', users: 160, tasks: 400 },
    { date: 'Sat', users: 140, tasks: 320 },
    { date: 'Sun', users: 130, tasks: 300 },
  ];

  const rewardDistributionData = [
    { name: 'Gift Cards', value: 400 },
    { name: 'Cash Rewards', value: 300 },
    { name: 'Premium Features', value: 200 },
    { name: 'Other', value: 100 },
  ];

  const COLORS = ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'];

  if (loading) {
    return <div className="text-center text-lg text-white">Loading...</div>;
  }

  return (
    <div className="space-y-8 p-6 bg-gray-900 rounded-lg shadow-lg">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <div className="bg-gray-800 p-6 rounded-lg shadow-md">
          <div className="flex items-center space-x-4">
            <div className="bg-gray-700 p-3 rounded-full">
              <Users className="h-6 w-6 text-blue-400" />
            </div>
            <div>
              <p className="text-sm text-gray-400">Total Users</p>
              <p className="text-2xl font-semibold text-white">{stats.totalUsers}</p>
            </div>
          </div>
        </div>

        <div className="bg-gray-800 p-6 rounded-lg shadow-md">
          <div className="flex items-center space-x-4">
            <div className="bg-gray-700 p-3 rounded-full">
              <Award className="h-6 w-6 text-blue-400" />
            </div>
            <div>
              <p className="text-sm text-gray-400">Active Users</p>
              <p className="text-2xl font-semibold text-white">{stats.activeUsers}</p>
            </div>
          </div>
        </div>

        <div className="bg-gray-800 p-6 rounded-lg shadow-md">
          <div className="flex items-center space-x-4">
            <div className="bg-gray-700 p-3 rounded-full">
              <Target className="h-6 w-6 text-blue-400" />
            </div>
            <div>
              <p className="text-sm text-gray-400">Total Tasks</p>
              <p className="text-2xl font-semibold text-white">{stats.totalTasks}</p>
            </div>
          </div>
        </div>

        <div className="bg-gray-800 p-6 rounded-lg shadow-md">
          <div className="flex items-center space-x-4">
            <div className="bg-gray-700 p-3 rounded-full">
              <DollarSign className="h-6 w-6 text-blue-400" />
            </div>
            <div>
              <p className="text-sm text-gray-400">Total Rewards</p>
              <p className="text-2xl font-semibold text-white">${stats.totalRewards}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="bg-gray-800 p-6 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold mb-4 text-white">User Activity</h3>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={userActivityData}>
                <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                <XAxis dataKey="date" stroke="#fff" />
                <YAxis stroke="#fff" />
                <Tooltip />
                <Line
                  type="monotone"
                  dataKey="users"
                  stroke="#36A2EB"
                  strokeWidth={2}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>

        <div className="bg-gray-800 p-6 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold mb-4 text-white">Task Completion</h3>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={taskCompletionData}>
                <CartesianGrid strokeDasharray="3 3" stroke="#444" />
                <XAxis dataKey="date" stroke="#fff" />
                <YAxis stroke="#fff" />
                <Tooltip />
                <Bar dataKey="completed" fill="#FF6384" />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <div className="bg-gray-800 p-6 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold mb-4 text-white">Reward Distribution</h3>
          <div className="h-[300px]">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart>
                <Pie
                  data={rewardDistributionData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={100}
                  fill="#8884d8"
                  dataKey="value"
                >
                  {rewardDistributionData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
          </div>
          <div className="mt-4 grid grid-cols-2 gap-4">
            {rewardDistributionData.map((entry, index) => (
              <div key={entry.name} className="flex items-center space-x-2">
                <div
                  className="w-3 h-3 rounded-full"
                  style={{ backgroundColor: COLORS[index % COLORS.length] }}
                />
                <span className="text-sm text-white">{entry.name}</span>
              </div>
            ))}
          </div>
        </div>

        <div className="bg-gray-800 p-6 rounded-lg shadow-md">
          <h3 className="text-lg font-semibold mb-4 text-white">Key Metrics</h3>
          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <span className="text-sm text-gray-400">User Retention</span>
              <span className="font-semibold text-white">68.5%</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-sm text-gray-400">Task Completion Rate</span>
              <span className="font-semibold text-white">82.3%</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-sm text-gray-400">Average Rewards/User</span>
              <span className="font-semibold text-white">$45.20</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="text-sm text-gray-400">Active Streaks</span>
              <span className="font-semibold text-white">456</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}