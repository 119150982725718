import { X, Mail, Wallet, Calendar, CheckCircle, Clock, ThumbsUp, ThumbsDown, AlertTriangle } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { db } from '@/lib/firebase';
import { collection, getDocs, updateDoc, doc, Timestamp, onSnapshot, orderBy, query } from 'firebase/firestore';

interface Claim {
    id: string;
    email: string;
    solanaAddress: string;
    timestamp: Timestamp | number;
    status: 'pending' | 'approved' | 'declined';
    transactionLink: string | null;
    declineReason?: string;
}

const FaucetManager: React.FC = () => {
    const [claims, setClaims] = useState<Claim[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [isVisible, setIsVisible] = useState(true);
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
    const [filterStatus, setFilterStatus] = useState<string>('all');
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [currentPage, setCurrentPage] = useState<number>(1);
    const claimsPerPage = 16; // Updated to show 16 claims per page
    const [showDeclineModal, setShowDeclineModal] = useState(false);
    const [selectedClaimId, setSelectedClaimId] = useState<string | null>(null);
    const [declineReason, setDeclineReason] = useState<string>('');
    const [newClaimId, setNewClaimId] = useState<string | null>(null);

    useEffect(() => {
        const fetchClaims = async () => {
            setLoading(true);
            try {
                // Create a query with ordering
                const claimsRef = collection(db, 'faucetClaims');
                const claimsQuery = query(claimsRef, orderBy('timestamp', 'desc'));

                // Set up real-time listener
                const unsubscribe = onSnapshot(claimsQuery, (snapshot) => {
                    const claimsData = snapshot.docs.map(doc => ({
                        id: doc.id,
                        ...doc.data(),
                        timestamp: doc.data().timestamp?.toMillis() || Date.now()
                    })) as Claim[];

                    if (claims.length < claimsData.length) {
                        const newClaim = claimsData.find(claim => 
                            !claims.some(c => c.id === claim.id)
                        );
                        if (newClaim) {
                            setNewClaimId(newClaim.id);
                            setTimeout(() => setNewClaimId(null), 45000);
                        }
                    }

                    setClaims(claimsData);
                    setLoading(false);
                }, (error) => {
                    console.error('Error fetching claims:', error);
                    setError('Failed to load claims');
                    setLoading(false);
                });

                return () => unsubscribe();
            } catch (err) {
                console.error('Error setting up claims listener:', err);
                setError('Failed to load claims');
                setLoading(false);
            }
        };

        fetchClaims();
    }, []);

    const approveClaim = async (claimId: string, transactionLink: string) => {
        try {
            const claimDoc = doc(db, 'faucetClaims', claimId);
            await updateDoc(claimDoc, {
                status: 'approved',
                transactionLink: transactionLink
            });
            setClaims(claims.map(claim => 
                claim.id === claimId ? { ...claim, status: 'approved', transactionLink: transactionLink } : claim
            ));
        } catch (error) {
            console.error('Error approving claim:', error);
        }
    };

    const declineClaim = async (claimId: string, reason: string) => {
        try {
            const claimDoc = doc(db, 'faucetClaims', claimId);
            await updateDoc(claimDoc, {
                status: 'declined',
                declineReason: reason
            });
            setClaims(claims.map(claim => 
                claim.id === claimId ? { ...claim, status: 'declined', declineReason: reason } : claim
            ));
        } catch (error) {
            console.error('Error declining claim:', error);
        }
    };

    const handleClose = () => {
        setIsVisible(false);
    };

    const handleSort = () => {
        const sortedClaims = [...claims].sort((a, b) => {
            const timeA = typeof a.timestamp === 'number' ? a.timestamp : a.timestamp.toMillis();
            const timeB = typeof b.timestamp === 'number' ? b.timestamp : b.timestamp.toMillis();
            return sortOrder === 'asc' ? timeA - timeB : timeB - timeA;
        });
        setClaims(sortedClaims);
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    };

    const handleFilterChange = (status: string) => {
        setFilterStatus(status);
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handleDecline = (claimId: string) => {
        setSelectedClaimId(claimId);
        setShowDeclineModal(true);
    };

    const submitDeclineReason = () => {
        if (selectedClaimId && declineReason) {
            declineClaim(selectedClaimId, declineReason);
            setShowDeclineModal(false);
            setDeclineReason('');
        }
    };

    const filteredClaims = claims
        .filter(claim => filterStatus === 'all' || claim.status === filterStatus)
        .filter(claim => claim.email.includes(searchTerm) || claim.solanaAddress.includes(searchTerm));

    const indexOfLastClaim = currentPage * claimsPerPage;
    const indexOfFirstClaim = indexOfLastClaim - claimsPerPage;
    const currentClaims = filteredClaims.slice(indexOfFirstClaim, indexOfLastClaim);

    const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

    // Identify duplicate emails
    const emailCounts = claims.reduce((acc, claim) => {
        acc[claim.email] = (acc[claim.email] || 0) + 1;
        return acc;
    }, {} as Record<string, number>);

    if (!isVisible) return null;

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div className="text-red-500">{error}</div>;
    }

    return (
        <div className="p-2 bg-gray-900 text-white rounded-lg shadow-lg relative">
            <button onClick={handleClose} className="absolute top-2 right-2 text-white">
                <X className="w-6 h-6" />
            </button>
            <h2 className="text-lg font-bold mb-2">Faucet Claims History</h2>

            {loading ? (
                <div className="flex justify-center items-center h-40">
                    <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-purple-500"></div>
                </div>
            ) : error ? (
                <div className="text-red-500 p-4 text-center bg-red-500/10 rounded">
                    {error}
                </div>
            ) : claims.length === 0 ? (
                <div className="text-gray-400 p-4 text-center bg-gray-800/50 rounded">
                    No faucet claims found. Claims will appear here when users request SOL.
                </div>
            ) : (
                <>
                    <div className="mb-2">
                        <button onClick={handleSort} className="bg-blue-500 text-white p-1 rounded mr-2">
                            Sort by Date ({sortOrder === 'asc' ? 'Ascending' : 'Descending'})
                        </button>
                        <select onChange={(e) => handleFilterChange(e.target.value)} className="bg-gray-800 text-white p-1 rounded mr-2">
                            <option value="all">All</option>
                            <option value="pending">Pending</option>
                            <option value="approved">Approved</option>
                        </select>
                        <input
                            type="text"
                            placeholder="Search by email or address"
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="bg-gray-800 text-white p-1 rounded"
                        />
                    </div>
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-2">
                        {currentClaims.map((claim, index) => (
                            <div key={index} className="bg-gray-800 p-2 rounded-lg shadow-md relative">
                                {newClaimId === claim.id && (
                                    <div className="absolute top-2 right-2 w-3 h-3 bg-green-500 rounded-full animate-blink"></div>
                                )}
                                <div className="flex items-center mb-1">
                                    {emailCounts[claim.email] > 1 && (
                                        <AlertTriangle className="w-4 h-4 mr-1 text-orange-500" />
                                    )}
                                    <Mail className="w-4 h-4 mr-1 text-blue-400" />
                                    <span className="text-sm">{claim.email}</span>
                                </div>
                                <div className="flex items-center mb-1">
                                    <Wallet className="w-4 h-4 mr-1 text-green-400" />
                                    <span className="text-sm">{claim.solanaAddress}</span>
                                </div>
                                <div className="flex items-center mb-1">
                                    <Calendar className="w-4 h-4 mr-1 text-yellow-400" />
                                    <span className="text-sm">
                                        {new Date(typeof claim.timestamp === 'number' ? claim.timestamp : claim.timestamp.toMillis()).toLocaleString()}
                                    </span>
                                </div>
                                <div className="flex items-center mb-1">
                                    {claim.status === 'approved' ? (
                                        <CheckCircle className="w-4 h-4 mr-1 text-green-500" />
                                    ) : (
                                        <Clock className="w-4 h-4 mr-1 text-yellow-500" />
                                    )}
                                    <span className="text-sm">{claim.status}</span>
                                </div>
                                <div className="flex justify-between">
                                    {claim.status === 'pending' && (
                                        <>
                                            <ThumbsUp
                                                onClick={() => {
                                                    const transactionLink = prompt('Enter Solscan transaction link:');
                                                    if (transactionLink) approveClaim(claim.id, transactionLink);
                                                }}
                                                className="w-5 h-5 text-green-500 cursor-pointer"
                                            />
                                            <ThumbsDown
                                                onClick={() => handleDecline(claim.id)}
                                                className="w-5 h-5 text-red-500 cursor-pointer"
                                            />
                                        </>
                                    )}
                                    {claim.status === 'approved' && claim.transactionLink && (
                                        <a href={claim.transactionLink} target="_blank" rel="noopener noreferrer" className="text-blue-500">
                                            View Transaction
                                        </a>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="flex justify-center mt-2">
                        {Array.from({ length: Math.ceil(filteredClaims.length / claimsPerPage) }, (_, i) => (
                            <button
                                key={i}
                                onClick={() => paginate(i + 1)}
                                className={`mx-1 px-2 py-1 rounded ${currentPage === i + 1 ? 'bg-blue-500' : 'bg-gray-700'}`}
                            >
                                {i + 1}
                            </button>
                        ))}
                    </div>

                    {showDeclineModal && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                            <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-sm w-full">
                                <div className="flex items-center mb-4">
                                    <AlertTriangle className="w-6 h-6 text-red-500 mr-2" />
                                    <h3 className="text-lg font-bold text-white">Decline Claim</h3>
                                </div>
                                <p className="text-sm text-gray-300 mb-4">
                                    Please provide a reason for declining this claim. This action cannot be undone.
                                </p>
                                <textarea
                                    value={declineReason}
                                    onChange={(e) => setDeclineReason(e.target.value)}
                                    className="w-full p-2 border border-gray-600 rounded mb-4 focus:outline-none focus:ring-2 focus:ring-red-500 bg-gray-700 text-white"
                                    placeholder="Enter reason for declining"
                                />
                                <div className="flex justify-end">
                                    <button
                                        onClick={() => setShowDeclineModal(false)}
                                        className="bg-gray-600 text-white px-4 py-2 rounded mr-2 hover:bg-gray-500"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={submitDeclineReason}
                                        className="bg-red-600 text-white px-4 py-2 rounded hover:bg-red-500"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

const styles = `
@keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
}

.animate-blink {
    animation: blink 1s infinite;
}
`;

const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default FaucetManager;