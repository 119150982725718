import React from 'react';
import { Button } from '@/components/ui/Button';
import { Star } from 'lucide-react';

interface StreakPopupProps {
  isOpen: boolean;
  onClose: () => void;
  streak: number; // Pass the user's streak as a prop
}

export const StreakPopup: React.FC<StreakPopupProps> = ({ isOpen, onClose, streak }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-gray-900 p-6 rounded-xl shadow-xl max-w-md w-full border border-gray-800">
        <div className="flex items-center mb-4">
          <Star className="h-8 w-8 text-yellow-400 mr-2" />
          <h3 className="text-xl font-bold">Your Streak</h3>
        </div>
        <p className="text-lg text-white mb-2">Current Streak: <span className="text-purple-400">{streak} days</span></p>
        <p className="text-sm text-gray-400 mb-2">
          Keep up the great work! Maintain your streak to earn rewards and bonuses.
        </p>
        <p className="text-sm text-gray-400">
          Aim for a higher streak to unlock extra Solana rewards!
        </p>
        <Button onClick={onClose} className="mt-4 text-white">Close</Button>
      </div>
    </div>
  );
};