import React, { useState, useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import {
  doc,
  onSnapshot,
  collection,
  query,
  updateDoc,
  Timestamp,
  increment,
  arrayUnion,
  setDoc,
  deleteDoc,
  getDocs,
  where,
  orderBy,
  limit
} from 'firebase/firestore';
import {
  ChevronRight,
  Users as UsersIcon,
  Clock,
  Trophy,
  Users,
  Zap,
  Ticket,
  Bell,
  Wallet,
  UserCircle,
  Shield,
  BookOpen,
  Heart,
  LogOut,
  TrendingUp,
  AlertCircle,
  DollarSign,
  X,
  ArrowRight,
  BarChart3,
  Newspaper,
  Package,
  MessageSquare,
  ThumbsUp,
  MessageCircle,
  Gift,
  Star,
} from 'lucide-react';
import { auth, db } from '@/lib/firebase';
import { Button } from '@/components/ui/Button';
import { TaskPopup } from '@/components/dashboard/TaskPopup';
import { NotificationsPopover } from '@/components/dashboard/NotificationsPopover';
import { WalletPopover } from '@/components/dashboard/WalletPopover';
import { SpinWheelPopup } from '@/components/dashboard/SpinWheelPopup';
import { ActivityChart } from '@/components/dashboard/ActivityChart';
import { NewsSection } from '@/components/dashboard/NewsSection';
import { NewsModal } from '@/components/dashboard/NewsModal';
import { StatsModal } from '@/components/dashboard/StatsModal';
import { AccountSettings } from '@/components/dashboard/AccountSettings';
import { UserProfile } from '@/components/dashboard/UserProfile';
import { TicketIcon } from '@/components/dashboard/ticketIcon';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RewardsPopup } from '@/components/dashboard/RewardsPopup';
import { motion, AnimatePresence } from 'framer-motion';
import { RedeemShopModal } from '@/components/dashboard/RedeemShopModal';
import { Shoutbox } from '@/components/dashboard/shoutbox';
import { Topics } from '@/components/dashboard/Topics';
import { TicketSupport } from '@/components/dashboard/TicketSupport';
import { RecentDiscussions } from '@/components/dashboard/RecentDiscussions';
import { ReferralDashboard } from '@/components/dashboard/ReferralDashboard';
import { StreakPopup } from '@/components/ui/StreakPopup';


// Define the Transaction interface
interface Transaction {
  id: string;
  amount: number;
  createdAt: string;
  status: 'pending' | 'approved' | 'rejected';
  transactionLink?: string;
}

// Add this interface at the top with other interfaces
interface PaidPromotion {
  id: string;
  userId: string;
  userEmail: string;
  title: string;
  description: string;
  link?: string;
  expiresAt: Date;
  imageUrl?: string;
}

// Add this interface near the top with other interfaces
interface TopicNotifications {
  newTopics: number;
  newReplies: number;
}

// Add this interface near the top with other interfaces
interface User {
  uid: string;
  email: string;
  // Add other required properties
}

// Add to your interfaces at the top
interface NewsArticle {
  id: string;
  title: string;
  content: string;
  views: number;
  viewedBy: string[];  // Array of user IDs who have viewed
  // ... other existing properties
}

export function Dashboard() {
  const [user] = useAuthState(auth);
  const [userData, setUserData] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [showLogoutPopup, setShowLogoutPopup] = useState(false);
  const [showTaskPopup, setShowTaskPopup] = useState(false);
  const [showRewardsPopup, setShowRewardsPopup] = useState(false);
  const [showReferralDashboard, setShowReferralDashboard] = useState(false); // State for ReferralDashboard
  const [showSpinWheel, setShowSpinWheel] = useState(false);
  const [showAccountSettings, setShowAccountSettings] = useState(false);
  const [selectedNews, setSelectedNews] = useState<any>(null);
  const [showStatsModal, setShowStatsModal] = useState(false);
  const [statsType, setStatsType] = useState<'streak' | 'points' | 'tasks' | 'referrals'>('streak');
  const [showTicketSupport, setShowTicketSupport] = useState(false);
  const [serverTime, setServerTime] = useState(new Date());
  const [onlineUsers, setOnlineUsers] = useState(0);
  const [announcements, setAnnouncements] = useState<any[]>([]);
  const [newsArticles, setNewsArticles] = useState<any[]>([]);
  const [notifications, setNotifications] = useState<any[]>([]);
  const [solToUsdRate, setSolToUsdRate] = useState<number>(0);
  const [usdBalance, setUsdBalance] = useState<number>(0);
  const [showRedeemShop, setShowRedeemShop] = useState(false);
  const navigate = useNavigate();
  const [paidPromotions, setPaidPromotions] = useState<PaidPromotion[]>([]);
  // Removed AdvertiseModal related states
  const [showTopicsModal, setShowTopicsModal] = useState(false);
  const [ticketNotifications, setTicketNotifications] = useState(0);
  const [topicNotifications, setTopicNotifications] = useState<TopicNotifications>({
    newTopics: 0,
    newReplies: 0,
  });
  const [showForumModal, setShowForumModal] = useState(false);
  const [showMessages, setShowMessages] = useState(false);
  const [newMessagesCount, setNewMessagesCount] = useState(0);
  const [showStreakPopup, setShowStreakPopup] = useState(false); // State for Streak Popup

  // Fetch user data and manage presence
  useEffect(() => {
    if (!user) return;

    const onlineUserRef = doc(db, 'onlineUsers', user.uid);

    // Add user to onlineUsers collection
    setDoc(onlineUserRef, {
      uid: user.uid,
      email: user.email,
      lastActive: Timestamp.now(),
    }).catch((error) => {
      console.error('Error setting online user:', error);
    });

    // Listen for user data changes
    const userDoc = doc(db, 'users', user.uid);
    const unsubscribeUser = onSnapshot(userDoc, (docSnapshot) => {
      if (docSnapshot.exists()) {
        setUserData(docSnapshot.data());
      }
      setLoading(false);
    });

    // Update lastActive timestamp every 10 seconds
    const timeInterval = setInterval(() => {
      setServerTime(new Date());
      updateDoc(onlineUserRef, { lastActive: Timestamp.now() }).catch((error) => {
        console.error('Error updating lastActive:', error);
      });
    }, 10000); // Every 10 seconds

    // Listen for online users count
    const onlineUsersRef = collection(db, 'onlineUsers');
    const onlineUnsubscribe = onSnapshot(onlineUsersRef, (snapshot) => {
      setOnlineUsers(snapshot.size);
    });

    // Fetch announcements with limit
    const announcementsRef = collection(db, 'announcements');
    const announcementsQueryInstance = query(announcementsRef, orderBy('createdAt', 'desc'), limit(10)); // Limit to 10
    const announcementsUnsubscribe = onSnapshot(announcementsQueryInstance, (snapshot) => {
      const announcementsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setAnnouncements(announcementsData);
    });

    // Fetch news articles with limit
    const newsRef = collection(db, 'news');
    const newsQueryInstance = query(newsRef, orderBy('date', 'desc'), limit(10)); // Limit to 10
    const newsUnsubscribe = onSnapshot(newsQueryInstance, (snapshot) => {
      const newsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        date: doc.data().date instanceof Timestamp ? doc.data().date.toDate() : new Date(doc.data().date),
      }));
      setNewsArticles(newsData);
    });

    // Fetch notifications with limit
    const notificationsRef = collection(db, 'notifications');
    const notificationsQueryInstance = query(notificationsRef, limit(10)); // Limit to 10
    const notificationsUnsubscribe = onSnapshot(notificationsQueryInstance, (snapshot) => {
      const notificationsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNotifications(notificationsData);
    });

    // Handle user disconnect
    const handleDisconnect = () => {
      deleteDoc(onlineUserRef).catch((error) => {
        console.error('Error deleting online user:', error);
      });
    };

    window.addEventListener('beforeunload', handleDisconnect);

    return () => {
      unsubscribeUser();
      clearInterval(timeInterval);
      onlineUnsubscribe();
      announcementsUnsubscribe();
      newsUnsubscribe();
      notificationsUnsubscribe();
      handleDisconnect();
      window.removeEventListener('beforeunload', handleDisconnect);
    };
  }, [user]);

  // Fetch SOL to USD exchange rate
  useEffect(() => {
    const fetchSolToUsdRate = async () => {
      try {
        // Use a CORS proxy or set mode to 'no-cors'
        const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd', {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*'
          }
        });

        if (!response.ok) {
          // Fallback to a static value or alternative API
          setSolToUsdRate(20); // Example fallback value
          return;
        }

        const data = await response.json();
        if (data.solana && data.solana.usd) {
          setSolToUsdRate(data.solana.usd);
        }
      } catch (error) {
        console.error('Error fetching exchange rate:', error);
        setSolToUsdRate(20); // Fallback value
      }
    };

    fetchSolToUsdRate();
    const interval = setInterval(fetchSolToUsdRate, 300000); // 5 minutes
    return () => clearInterval(interval);
  }, []);

  // Calculate USD balance whenever userData or solToUsdRate changes
  useEffect(() => {
    if (userData && solToUsdRate) {
      setUsdBalance(userData.balance * solToUsdRate);
    }
  }, [userData, solToUsdRate]);

  // Handle Logout
  const handleLogout = async () => {
    try {
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        await updateDoc(userRef, { isActive: false });

        const onlineUserRef = doc(db, 'onlineUsers', user.uid);
        await deleteDoc(onlineUserRef);
      }

      await auth.signOut();
      toast.success('Logged out successfully!');
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
      toast.error('Failed to log out. Please try again.');
    }
  };

  // Handle Read More in News
  const handleReadMore = async (news: NewsArticle) => {
    if (!user?.uid) return;

    // First set the selected news to show modal
    setSelectedNews(news);

    // Then update view count if user hasn't viewed
    if (!news.viewedBy?.includes(user.uid)) {
      try {
        const newsRef = doc(db, 'news', news.id);
        
        // Update Firestore
        await updateDoc(newsRef, {
          views: increment(1),
          viewedBy: arrayUnion(user.uid)
        });

        // Update local state
        setNewsArticles(currentNews => 
          currentNews.map(article => 
            article.id === news.id 
              ? { 
                  ...article, 
                  views: (article.views || 0) + 1,
                  viewedBy: [...(article.viewedBy || []), user.uid]
                }
              : article
          )
        );
      } catch (error) {
        console.error('Error updating view count:', error);
      }
    }
  };

  // Define Menu Items
  const menuItems = [
    { icon: Trophy, label: 'Tasks', onClick: () => { setShowReferralDashboard(false); setShowTaskPopup(true); } },
    { icon: Ticket, label: 'Daily Raffles', onClick: () => { setShowReferralDashboard(false); setShowRewardsPopup(true); } },
    { icon: MessageSquare, label: 'Topics', onClick: () => { setShowReferralDashboard(false); setShowTopicsModal(true); } },
    { icon: UsersIcon, label: 'Referrals', onClick: () => { setShowReferralDashboard(true); } }, // Open ReferralDashboard
    { icon: Zap, label: 'Spin Wheel', onClick: () => setShowSpinWheel(true) },
    { icon: Gift, label: 'Daily Shop', onClick: () => setShowRedeemShop(true) },
    { icon: Ticket, label: 'Support', onClick: () => setShowTicketSupport(true) },
    { icon: UserCircle, label: 'Account', onClick: () => setShowAccountSettings(true) },
  ];

  // Handle Like Announcement
  const handleLikeAnnouncement = async (announcementId: string) => {
    try {
      const announcementRef = doc(db, 'announcements', announcementId);
      await updateDoc(announcementRef, {
        likes: increment(1),
        likedBy: arrayUnion(user?.uid),
      });
      toast.success('Liked the announcement!');
    } catch (error) {
      console.error('Error liking announcement:', error);
      toast.error('Failed to like the announcement. Please try again.');
    }
  };

  // Add this effect to fetch paid promotions
  useEffect(() => {
    const promotionsRef = collection(db, 'promotions');
    const promotionsQuery = query(
      promotionsRef,
      where('expiresAt', '>', new Date())
    );

    const unsubscribe = onSnapshot(promotionsQuery, (snapshot) => {
      const promotionsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        expiresAt: doc.data().expiresAt.toDate(),
      })) as PaidPromotion[];
      setPaidPromotions(promotionsData);
    });

    return () => unsubscribe();
  }, []);

  // Modify this useEffect to only count brand new tickets
  useEffect(() => {
    if (!user?.uid) return;

    const ticketsRef = collection(db, 'tickets');
    const q = query(
      ticketsRef,
      where('userId', '==', user.uid),
      where('hasNewReply', '==', true)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      // Only count new tickets (not admin replies)
      const count = snapshot.docs.reduce((acc, doc) => {
        const ticket = doc.data();
        const replies = ticket.replies || [];
        // Only count if it's a new ticket without any replies
        if (replies.length === 0) {
          return acc + 1;
        }
        return acc;
      }, 0);
      
      setTicketNotifications(count);
    });

    return () => unsubscribe();
  }, [user]);

  // Modify the useEffect for topic notifications to only show for replies to user's topics
  useEffect(() => {
    if (!user?.uid) return;

    const topicsRef = collection(db, 'topics');
    
    // Only query for replies to user's topics
    const repliesQuery = query(
      topicsRef,
      where('userId', '==', user.uid),
      where('hasNewReplies', '==', true)
    );

    const unsubscribeReplies = onSnapshot(repliesQuery, (snapshot) => {
      setTopicNotifications(prev => ({
        ...prev,
        newReplies: snapshot.docs.length
      }));
    });

    return () => {
      unsubscribeReplies();
    };
  }, [user]);

  // Loading State
  if (loading) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-900 via-purple-900/20 to-gray-900 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500"></div>
        <ToastContainer />
      </div>
    );
  }

  // Function to handle new messages
  const handleNewMessage = (messageId: string) => {
    setNewMessagesCount(prevCount => prevCount + 1);
    // Additional logic for handling the new message can go here
  };

  // Define a function to handle the spin result
  const handleSpin = (prizeAmount: number) => {
    // Update user data or perform any actions based on the prize won
    // For example, update the user's balance
    if (userData && user) {
      const userRef = doc(db, 'users', user.uid);
      updateDoc(userRef, {
        balance: increment(prizeAmount),
        // You can add more fields to update as needed
      }).then(() => {
        toast.success(`You won ${prizeAmount.toFixed(4)} SOL!`);
      }).catch((error) => {
        console.error('Error updating balance:', error);
        toast.error('Failed to update balance. Please try again.');
      });
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-purple-900/20 to-gray-900">
      {/* Top Menu */}
      <div className="bg-gray-800/50 border-b border-gray-700/50">
        <div className="container mx-auto px-4 py-2">
          <div className="flex items-center justify-between text-sm text-gray-400">
            {/* Left Side: Server Time and Active Users */}
            <div className="flex items-center space-x-4">
              <div className="flex items-center">
                <Clock className="h-4 w-4 mr-2 text-gray-400" />
                <span>{serverTime.toLocaleTimeString()}</span>
              </div>
              <div className="flex items-center">
                <UsersIcon className="h-4 w-4 mr-2 text-gray-400" />
                <span>{onlineUsers} Active Users</span>
              </div>
            </div>

            {/* Right Side: Popovers and Logout */}
            <div className="flex items-center space-x-4">
              <div className="relative">
                <MessageCircle 
                  className="h-5 w-5 text-gray-400 cursor-pointer" 
                  onClick={() => setShowForumModal(true)}
                />
                {(topicNotifications.newTopics > 0 || topicNotifications.newReplies > 0) && (
                  <div className="absolute -top-2 -right-2 flex">
                    {topicNotifications.newTopics > 0 && (
                      <span className="flex h-4 w-4 items-center justify-center rounded-full bg-green-500 text-xs text-white">
                        {topicNotifications.newTopics}
                      </span>
                    )}
                    {topicNotifications.newReplies > 0 && (
                      <span className="flex h-4 w-4 items-center justify-center rounded-full bg-blue-500 text-xs text-white -ml-1">
                        {topicNotifications.newReplies}
                      </span>
                    )}
                  </div>
                )}
              </div>
              <TicketIcon />
              <WalletPopover balance={usdBalance} streak={userData?.streak || 0} />
              <NotificationsPopover notifications={notifications} />
              {userData?.isAdmin && (
                <Link to="/admin">
                  <Button variant="ghost" size="sm">
                    <Shield className="h-5 w-5 text-gray-400" />
                  </Button>
                </Link>
              )}
              {/* Logout Icon */}
              <Button onClick={() => setShowLogoutPopup(true)} className="flex items-center">
                <LogOut className="h-5 w-5 text-gray-400 sm:h-6 sm:w-6" />
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* Recent Discussions Modal */}
      {showForumModal && (
        <RecentDiscussions 
          isOpen={showForumModal} 
          onClose={() => setShowForumModal(false)} 
          user={user ? { uid: user.uid, email: user.email || '' } : null} 
        />
      )}

      {/* Logout Confirmation Popup */}
      {showLogoutPopup && (
        <AnimatePresence>
          <motion.div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <motion.div
              className="bg-gray-900 p-6 rounded-lg shadow-lg max-w-md w-full"
              initial={{ scale: 0.95, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              exit={{ scale: 0.95, opacity: 0 }}
              onClick={(e) => e.stopPropagation()}
            >
              <h3 className="text-xl font-bold mb-4">Confirm Logout</h3>
              <p className="text-gray-400">Are you sure you want to log out?</p>
              <div className="flex justify-end space-x-3 mt-6">
                <Button variant="ghost" onClick={() => setShowLogoutPopup(false)}>
                  No
                </Button>
                <Button variant="destructive" onClick={handleLogout}>
                  Yes, Logout
                </Button>
              </div>
            </motion.div>
          </motion.div>
        </AnimatePresence>
      )}

      <div className="container mx-auto px-4 py-8">
        {showReferralDashboard && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
            <ReferralDashboard userId={user?.uid || ''} onClose={() => setShowReferralDashboard(false)} />
          </div>
        )}
        {/* Announcements Section */}
        <div className="bg-gray-900/50 p-6 rounded-xl border border-gray-800 mb-6">
          <h3 className="text-lg font-semibold mb-4">Announcements</h3>
          {announcements.length > 0 ? (
            <div className="space-y-4">
              {announcements.map((announcement) => (
                <div key={announcement.id} className="p-4 bg-gray-800 rounded-lg">
                  <h4 className="font-medium">{announcement.title}</h4>
                  <p className="text-sm text-gray-400">{announcement.content}</p>
                  <p className="text-xs text-gray-500">Posted on: {new Date(announcement.createdAt).toLocaleString()}</p>
                  <div className="flex items-center mt-2">
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => handleLikeAnnouncement(announcement.id)}
                      disabled={announcement.likedBy && announcement.likedBy.includes(user?.uid || '')}
                      className="flex items-center space-x-1"
                    >
                      <Heart className="h-4 w-4 text-red-500" />
                      <span>Like</span>
                    </Button>
                    <span className="ml-2">{announcement.likes || 0} ❤️</span>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-gray-400">No announcements available.</p>
          )}
        </div>

        {/* Content Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
          {/* Left Sidebar */}
          <div className="lg:col-span-3">
            <UserProfile user={user} userData={userData} />
            <nav className="mt-6 space-y-2">
              {menuItems.map((item) => (
                <button
                  key={item.label}
                  onClick={item.onClick}
                  className="menu-item w-full flex items-center justify-between px-4 py-2 bg-gray-800 rounded-lg hover:bg-gray-700 transition"
                >
                  <div className="flex items-center">
                    <item.icon className="h-5 w-5 mr-3 text-gray-400" />
                    <span>{item.label}</span>
                  </div>
                  <div className="flex items-center">
                    <ChevronRight className="h-4 w-4 text-gray-400" />
                  </div>
                </button>
              ))}
              {/* Streaks Button */}
              <button
                onClick={() => setShowStreakPopup(true)}
                className="menu-item w-full flex items-center justify-between px-4 py-2 bg-gray-800 rounded-lg hover:bg-gray-700 transition"
              >
                <div className="flex items-center">
                  <Star className="h-5 w-5 mr-3 text-gray-400" />
                  <span>Streaks</span>
                </div>
                <div className="flex items-center">
                  <ChevronRight className="h-4 w-4 text-gray-400" />
                </div>
              </button>
              {/* Logout Button */}
              <button
                onClick={() => setShowLogoutPopup(true)}
                className="menu-item w-full flex items-center justify-between px-4 py-2 bg-gray-800 rounded-lg hover:bg-gray-700 transition"
              >
                <div className="flex items-center">
                  <LogOut className="h-5 w-5 mr-3 text-gray-400" />
                  <span>Logout</span>
                </div>
                <div className="flex items-center">
                  <ChevronRight className="h-4 w-4 text-gray-400" />
                </div>
              </button>
            </nav>

            {/* Paid Promotions Square */}
            <div className="mt-6 p-4 bg-gray-800 rounded-lg">
              <div className="flex items-center justify-between mb-4">
                <div className="flex items-center gap-2">
                  <Package className="h-5 w-5 text-purple-400" />
                  <h3 className="font-semibold">Paid Promotions</h3>
                </div>
              </div>
              <div className="space-y-3">
                {paidPromotions.slice(0, 3).map((promo) => (
                  <div
                    key={promo.id}
                    className="p-3 bg-gray-700/50 rounded-lg hover:bg-gray-700 transition-colors cursor-pointer"
                    onClick={() => window.open(promo.link, '_blank')}
                  >
                    <div className="flex items-center gap-2">
                      {promo.imageUrl && (
                        <img 
                          src={promo.imageUrl} 
                          alt="" 
                          className="w-10 h-10 rounded object-cover"
                        />
                      )}
                      <div className="flex-1 min-w-0">
                        <p className="text-sm font-medium truncate">{promo.title}</p>
                        <p className="text-xs text-gray-400 truncate">{promo.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
                {paidPromotions.length === 0 && (
                  <p className="text-sm text-gray-400 text-center py-2">
                    No active promotions
                  </p>
                )}
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="lg:col-span-9 space-y-8">
            {/* Stats Cards */}
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
              {/* Tasks Completed */}
              <div className="p-4 bg-gray-800 rounded-lg flex items-center">
                <Trophy className="h-6 w-6 text-yellow-500 mr-3" />
                <div>
                  <h4 className="text-lg font-semibold">{userData?.tasksCompleted || 0}</h4>
                  <p className="text-sm text-gray-400">Tasks Completed</p>
                </div>
              </div>

              {/* Points Earned */}
              <div className="p-4 bg-gray-800 rounded-lg flex items-center">
                <Heart className="h-6 w-6 text-red-500 mr-3" />
                <div>
                  <h4 className="text-lg font-semibold">{userData?.points || 0}</h4>
                  <p className="text-sm text-gray-400">Points Earned</p>
                </div>
              </div>

              {/* Referrals Made */}
              <div className="p-4 bg-gray-800 rounded-lg flex items-center">
                <Users className="h-6 w-6 text-green-500 mr-3" />
                <div>
                  <h4 className="text-lg font-semibold">{userData?.referrals || 0}</h4>
                  <p className="text-sm text-gray-400">Referrals Made</p>
                </div>
              </div>

              {/* Balance (USD) */}
              <div className="p-4 bg-gray-800 rounded-lg flex items-center">
                <Wallet className="h-6 w-6 text-blue-500 mr-3" />
                <div>
                  <h4 className="text-lg font-semibold">
                    {solToUsdRate ? `$${usdBalance.toFixed(2)} USD` : 'Loading...'}
                  </h4>
                  <p className="text-sm text-gray-400">Balance</p>
                </div>
              </div>
            </div>

            {/* Content Grid */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
              <div className="space-y-6">
                {/* Activity Overview - Moved up */}
                <div className="content-card p-6 bg-gray-800 rounded-lg">
                  <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center gap-2">
                      <BarChart3 className="w-5 h-5 text-purple-400" />
                      <h3 className="text-lg font-semibold">Activity Overview</h3>
                    </div>
                    <div className="flex gap-6 text-sm text-gray-400">
                      <div className="flex items-center gap-2">
                        <div className="w-3 h-3 rounded-full bg-purple-500"></div>
                        <span>Points</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <div className="w-3 h-3 rounded-full bg-pink-500"></div>
                        <span>Tasks</span>
                      </div>
                    </div>
                  </div>
                  <ActivityChart data={userData?.activity || []} />
                </div>

                {/* Latest News - Moved down */}
                <div className="content-card p-6 bg-gray-800 rounded-lg">
                  <div className="flex items-center justify-between mb-4">
                    <div className="flex items-center gap-2">
                      <Newspaper className="w-5 h-5 text-purple-400" />
                      <h3 className="text-lg font-semibold">Latest News</h3>
                    </div>
                    <div className="text-sm text-gray-400">
                      Stay updated with our latest announcements
                    </div>
                  </div>
                  <NewsSection
                    news={newsArticles}
                    onReadMore={handleReadMore}
                  />
                </div>
              </div>

              <div className="space-y-6">
                {/* Shoutbox */}
                <div className="content-card p-6 bg-gray-800 rounded-lg">
                  <Shoutbox user={user} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Popups */}
      <AnimatePresence>
        {showTaskPopup && (
          <TaskPopup
            isOpen={showTaskPopup}
            onClose={() => setShowTaskPopup(false)}
            onComplete={() => {}}
            nextTask={userData?.nextTask || null}
          />
        )}
        {showRewardsPopup && (
          <RewardsPopup
            isOpen={showRewardsPopup}
            onClose={() => setShowRewardsPopup(false)}
            userId={user?.uid ?? ''}
            ticketPrice={10}
          />
        )}
        {showSpinWheel && (
          <SpinWheelPopup
            isOpen={showSpinWheel}
            onClose={() => setShowSpinWheel(false)}
            onSpin={handleSpin} // Pass the handleSpin function
            lastSpinDate={userData?.lastSpinDate}
          />
        )}
      </AnimatePresence>

      {/* Other Modals */}
      <NewsModal
        isOpen={!!selectedNews}
        onClose={() => setSelectedNews(null)}
        news={selectedNews}
      />
      <StatsModal
        isOpen={showStatsModal}
        onClose={() => setShowStatsModal(false)}
        type={statsType}
        data={{
          currentStreak: userData?.streak || 0,
          bestStreak: (userData?.streak || 0) + 2,
          totalPoints: userData?.points || 0,
          weeklyPoints: Math.floor((userData?.points || 0) * 0.3),
          totalTasks: userData?.tasksCompleted || 0,
          completionRate: 85,
          totalReferrals: userData?.referrals || 0,
          activeReferrals: Math.floor((userData?.referrals || 0) * 0.8),
        }}
      />
      <AccountSettings
        isOpen={showAccountSettings}
        onClose={() => setShowAccountSettings(false)}
        userData={userData}
        user={user}
      />
      <RedeemShopModal
        isOpen={showRedeemShop}
        onClose={() => setShowRedeemShop(false)}
        userId={user?.uid || ''}
        userPoints={userData?.points || 0}
      />
      <Topics
        isOpen={showTopicsModal}
        onClose={() => setShowTopicsModal(false)}
        user={user ? { uid: user.uid, email: user.email || '' } : null}
      />
      <TicketSupport
        isOpen={showTicketSupport}
        onClose={() => setShowTicketSupport(false)}
        user={user ? { uid: user.uid, email: user.email || '' } : null}
      />
      <ToastContainer />
      {/* Streak Popup */}
      {showStreakPopup && (
        <StreakPopup 
          isOpen={showStreakPopup} 
          onClose={() => setShowStreakPopup(false)} 
          streak={userData?.streak || 0} // Pass the user's streak
        />
      )}
    </div>
  );
}

export default Dashboard;
