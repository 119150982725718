import { useState, useEffect, useRef } from 'react';
import { collection, addDoc, query, orderBy, limit, onSnapshot, Timestamp, getDoc, doc, updateDoc, serverTimestamp, deleteDoc, setDoc, getDocs, writeBatch, where, arrayRemove, arrayUnion, increment } from 'firebase/firestore';
import { db, auth } from '@/lib/firebase';
import { Send, Trash2, MessageSquare, Smile, Info, AlertOctagon, Terminal, Megaphone, Clock, Ban, Unlock, X, Command, Globe, Edit2, Shield, Users, LifeBuoy, UserCircle, Crown, Pin } from 'lucide-react';
import { Button } from '@/components/ui/Button';
import { toast } from 'react-hot-toast';
import { User } from 'firebase/auth';
import { EmojiPicker } from '@/components/ui/EmojiPicker';
import * as Tooltip from '@radix-ui/react-tooltip';
import * as Popover from '@radix-ui/react-popover';

interface ShoutboxMessage {
  id: string;
  content: string;
  userEmail: string;
  timestamp: Date;
  userId: string;
  userRole?: string | null;
  replyTo?: {
    id: string;
    content: string;
    userEmail: string;
  };
  isMuted?: boolean;
  reactions?: {
    [key: string]: string[]; // emoji: userId[]
  };
  editHistory?: {
    content: string;
    editedAt: Date;
  }[];
  lastEdited?: Date;
  threadId?: string;
  threadCount?: number;
  isPinned?: boolean;
  pinnedBy?: string;
}

interface ShoutboxProps {
  user: User | null | undefined;
}

interface MutedUser {
  userId: string;
  mutedUntil: Date;
  mutedBy: string;
}

interface AdminCommand {
  command: string;
  description: string;
  icon: React.ReactNode;
  example?: string;
  execute: (args: string[]) => Promise<void>;
}

const BLOCKED_WORDS = [
  // Hate speech & slurs (not listing them explicitly)
  'slur1', 'slur2', 'slur3',
  // Profanity
  'fuck', 'shit', 'ass',
  // Discriminatory terms
  'retard', 'faggot', 'nazi',
  // Add more as needed
].map(word => word.toLowerCase());

const filterBadWords = (text: string): string => {
  let filteredText = text.toLowerCase();
  BLOCKED_WORDS.forEach(word => {
    const regex = new RegExp(`\\b${word}\\b`, 'gi');
    filteredText = filteredText.replace(regex, '*'.repeat(word.length));
  });
  return filteredText;
};

const getUserColor = (userId: string): string => {
  // Generate a consistent color based on userId
  let hash = 0;
  for (let i = 0; i < userId.length; i++) {
    hash = userId.charCodeAt(i) + ((hash << 5) - hash);
  }
  
  // List of pleasant, accessible colors
  const colors = [
    'bg-blue-600/50',
    'bg-purple-600/50',
    'bg-green-600/50',
    'bg-red-600/50',
    'bg-yellow-600/50',
    'bg-pink-600/50',
    'bg-indigo-600/50',
    'bg-teal-600/50'
  ];
  
  return colors[Math.abs(hash) % colors.length];
};

const getRoleColor = (role: string) => {
  switch (role) {
    case 'Admin':
      return 'text-red-500';
    case 'Moderator':
      return 'text-blue-500';
    case 'Support':
      return 'text-green-500';
    case 'User':
      return 'text-gray-500';
    default:
      return 'text-gray-400'; // Default color for unknown roles
  }
};

const getRoleIcon = (role: string) => {
  switch (role) {
    case 'Admin':
      return <Crown className="h-4 w-4 text-red-500" />;
    case 'Moderator':
      return <Users className="h-4 w-4 text-blue-500" />;
    case 'Support':
      return <LifeBuoy className="h-4 w-4 text-green-500" />;
    case 'User':
      return <UserCircle className="h-4 w-4 text-gray-500" />;
    default:
      return null; // Return null if no role matches
  }
};

export const Shoutbox: React.FC<ShoutboxProps> = ({ user }) => {
  const [messages, setMessages] = useState<ShoutboxMessage[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const messagesContainerRef = useRef<HTMLDivElement>(null);
  const [replyingTo, setReplyingTo] = useState<ShoutboxMessage | null>(null);
  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState(false);
  const [mutedUsers, setMutedUsers] = useState<MutedUser[]>([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isSupport, setIsSupport] = useState(false);
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true);
  const [showCommands, setShowCommands] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState<ShoutboxMessage[]>([]);
  const [typingUsers, setTypingUsers] = useState<{[key: string]: boolean}>({});
  const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout>();
  const [editingMessage, setEditingMessage] = useState<string | null>(null);
  const [editText, setEditText] = useState('');
  const [pinnedMessage, setPinnedMessage] = useState<ShoutboxMessage | null>(null);

  const scrollToBottom = () => {
    if (shouldAutoScroll && messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleScroll = () => {
    if (!messagesContainerRef.current) return;
    
    const { scrollTop, scrollHeight, clientHeight } = messagesContainerRef.current;
    const isNearBottom = scrollHeight - scrollTop - clientHeight < 100;
    
    setShouldAutoScroll(isNearBottom);
  };

  const getUserRole = async (userId: string) => {
    try {
      const userDoc = await getDoc(doc(db, 'users', userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (userData.isAdmin) return 'Admin';
        if (userData.isModerator) return 'Moderator';
        if (userData.isSupport) return 'Support';
        if (userData.isEarlyAccess) return 'Early Access';
      }
      return null;
    } catch (error) {
      console.error('Error fetching user role:', error);
      return null;
    }
  };

  const isUserMuted = (userId: string): boolean => {
    const mutedUser = mutedUsers.find(mu => mu.userId === userId);
    if (!mutedUser) return false;
    return new Date() < mutedUser.mutedUntil;
  };

  useEffect(() => {
    if (!user) return;

    const messagesRef = collection(db, 'shoutbox');
    const q = query(messagesRef, orderBy('timestamp', 'desc'), limit(50));

    const unsubscribe = onSnapshot(q, async (snapshot) => {
      const messagesWithRoles = await Promise.all(
        snapshot.docs.map(async (doc) => {
          const messageData = doc.data();
          const userRole = await getUserRole(messageData.userId);
          return {
            id: doc.id,
            content: messageData.content,
            userEmail: messageData.userEmail,
            timestamp: messageData.timestamp.toDate(),
            userId: messageData.userId,
            userRole,
            ...(messageData.replyTo && {
              replyTo: {
                id: messageData.replyTo.id,
                content: messageData.replyTo.content,
                userEmail: messageData.replyTo.userEmail,
              }
            })
          };
        })
      );

      setMessages(messagesWithRoles.reverse());
      scrollToBottom();
    });

    return () => unsubscribe();
  }, [user]);

  useEffect(() => {
    if (!user) return;

    const checkUserRoles = async () => {
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      const userData = userDoc.data();
      setIsAdmin(userData?.isAdmin === true);
      setIsSupport(userData?.isSupport === true);
    };

    checkUserRoles();
  }, [user]);

  useEffect(() => {
    const cleanup = setInterval(async () => {
      const mutedUsersRef = collection(db, 'mutedUsers');
      const snapshot = await getDocs(mutedUsersRef);
      
      snapshot.docs.forEach(async (doc) => {
        const mutedUntil = doc.data().mutedUntil.toDate();
        if (new Date() > mutedUntil) {
          await deleteDoc(doc.ref);
        }
      });
    }, 60000); // Check every minute

    return () => clearInterval(cleanup);
  }, []);

  useEffect(() => {
    if (!user) return;
    
    const typingRef = doc(db, 'typing', 'shoutbox');
    let typingTimeout: NodeJS.Timeout;
    
    const updateTypingStatus = async (isTyping: boolean) => {
      try {
        await setDoc(typingRef, {
          [user.uid]: isTyping
        }, { merge: true });
      } catch (error) {
        console.error('Error updating typing status:', error);
      }
    };
    
    const unsubscribe = onSnapshot(typingRef, (doc) => {
      setTypingUsers(doc.data() || {});
    });
    
    return () => {
      unsubscribe();
      updateTypingStatus(false);
      if (typingTimeout) clearTimeout(typingTimeout);
    };
  }, [user]);

  const adminCommands: AdminCommand[] = [
    {
      command: '/clear',
      description: 'Clear all chat messages',
      icon: <Trash2 className="w-4 h-4 text-red-400" />,
      execute: async () => {
        try {
          const messagesRef = collection(db, 'shoutbox');
          const snapshot = await getDocs(messagesRef);
          const batchSize = 500;
          let batch = writeBatch(db);
          let count = 0;

          snapshot.docs.forEach((doc) => {
            batch.delete(doc.ref);
            count++;

            if (count === batchSize) {
              batch.commit();
              batch = writeBatch(db);
              count = 0;
            }
          });

          if (count > 0) {
            await batch.commit();
          }
          
          toast.success('Chat cleared successfully');
        } catch (error) {
          console.error('Error clearing chat:', error);
          toast.error('Failed to clear chat');
        }
      }
    },
    {
      command: '/announce',
      description: 'Send an announcement message',
      example: '/announce Server maintenance in 10 minutes',
      icon: <Megaphone className="w-4 h-4 text-yellow-400" />,
      execute: async (args: string[]) => {
        try {
          const message = args.join(' ');
          await addDoc(collection(db, 'shoutbox'), {
            content: `📢 ANNOUNCEMENT: ${message}`,
            userEmail: user?.email,
            userId: user?.uid,
            timestamp: Timestamp.now(),
            isAnnouncement: true
          });
          toast.success('Announcement sent');
        } catch (error) {
          console.error('Error sending announcement:', error);
          toast.error('Failed to send announcement');
        }
      }
    },
    {
      command: '/mute',
      description: 'Mute a user for specified minutes',
      example: '/mute username 10',
      icon: <Ban className="w-4 h-4 text-red-400" />,
      execute: async (args: string[]) => {
        try {
          const [username, minutes] = args;
          const duration = parseInt(minutes) || 10;
          
          // Find user by username
          const usersRef = collection(db, 'users');
          const q = query(usersRef, where('username', '==', username));
          const snapshot = await getDocs(q);
          
          if (snapshot.empty) {
            toast.error('User not found');
            return;
          }

          const targetUser = snapshot.docs[0];
          await setDoc(doc(db, 'mutedUsers', targetUser.id), {
            userId: targetUser.id,
            userEmail: targetUser.data().email,
            mutedUntil: new Date(Date.now() + duration * 60 * 1000),
            mutedBy: user?.email,
            mutedAt: Timestamp.now()
          });

          toast.success(`Muted ${username} for ${duration} minutes`);
        } catch (error) {
          console.error('Error muting user:', error);
          toast.error('Failed to mute user');
        }
      }
    },
    {
      command: '/unmute',
      description: 'Unmute a user',
      example: '/unmute username',
      icon: <Unlock className="w-4 h-4 text-green-400" />,
      execute: async (args: string[]) => {
        try {
          const [username] = args;
          const usersRef = collection(db, 'users');
          const q = query(usersRef, where('username', '==', username));
          const snapshot = await getDocs(q);
          
          if (snapshot.empty) {
            toast.error('User not found');
            return;
          }

          const targetUser = snapshot.docs[0];
          await deleteDoc(doc(db, 'mutedUsers', targetUser.id));
          toast.success(`Unmuted ${username}`);
        } catch (error) {
          console.error('Error unmuting user:', error);
          toast.error('Failed to unmute user');
        }
      }
    },
    {
      command: '/slow',
      description: 'Enable slow mode (seconds between messages)',
      example: '/slow 5',
      icon: <Clock className="w-4 h-4 text-blue-400" />,
      execute: async (args: string[]) => {
        try {
          const seconds = parseInt(args[0]) || 5;
          await setDoc(doc(db, 'systemStats', 'chatSettings'), {
            slowMode: seconds,
            updatedAt: Timestamp.now(),
            updatedBy: user?.email
          }, { merge: true });
          toast.success(`Slow mode enabled: ${seconds} seconds`);
        } catch (error) {
          console.error('Error enabling slow mode:', error);
          toast.error('Failed to enable slow mode');
        }
      }
    },
    {
      command: '/slowoff',
      description: 'Disable slow mode',
      icon: <Clock className="w-4 h-4 text-gray-400" />,
      execute: async () => {
        try {
          await updateDoc(doc(db, 'systemStats', 'chatSettings'), {
            slowMode: 0,
            updatedAt: Timestamp.now(),
            updatedBy: user?.email
          });
          toast.success('Slow mode disabled');
        } catch (error) {
          console.error('Error disabling slow mode:', error);
          toast.error('Failed to disable slow mode');
        }
      }
    }
  ];

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!user || !newMessage.trim()) return;

    try {
      // Check if message is a command
      if (isAdmin && newMessage.startsWith('/')) {
        const [command, ...args] = newMessage.trim().split(' ');
        const adminCommand = adminCommands.find(cmd => cmd.command === command);
        
        if (adminCommand) {
          await adminCommand.execute(args);
          setNewMessage('');
          return;
        } else {
          toast.error('Unknown command');
          return;
        }
      }

      // Regular message handling...
      const filteredMessage = filterBadWords(newMessage.trim());
      
      if (filteredMessage.split('*').length > 10) {
        toast.error('Message contains too many blocked words');
        return;
      }

      const messageData = {
        content: filteredMessage,
        userEmail: user.email,
        userId: user.uid,
        timestamp: Timestamp.now(),
        ...(replyingTo && {
          replyTo: {
            id: replyingTo.id,
            content: replyingTo.content,
            userEmail: replyingTo.userEmail,
          }
        })
      };

      await addDoc(collection(db, 'shoutbox'), messageData);
      setNewMessage('');
      setReplyingTo(null);
      setShouldAutoScroll(true);
      scrollToBottom();
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error('Failed to send message');
    }
  };

  const handleEmojiSelect = (emoji: string) => {
    setNewMessage(prev => prev + emoji);
    setIsEmojiPickerOpen(false);
  };

  const handleMuteUser = async (userId: string, userEmail: string) => {
    if (!isAdmin) return;

    try {
      const mutedUntil = new Date(Date.now() + 10 * 60 * 1000); // 10 minutes
      const mutedUserRef = doc(db, 'mutedUsers', userId);
      
      await setDoc(mutedUserRef, {
        userId,
        userEmail,
        mutedUntil,
        mutedBy: user?.email,
        mutedAt: serverTimestamp(),
      });

      toast.success(`User ${userEmail} has been muted for 10 minutes`);
    } catch (error) {
      console.error('Error muting user:', error);
      toast.error('Failed to mute user');
    }
  };

  const handleDeleteMessage = async (messageId: string) => {
    if (!isAdmin && !isSupport) return;
    
    try {
      await deleteDoc(doc(db, 'shoutbox', messageId));
      toast.success('Message deleted');
    } catch (error) {
      console.error('Error deleting message:', error);
      toast.error('Failed to delete message');
    }
  };

  const handleReaction = async (messageId: string, emoji: string) => {
    if (!user) return;
    
    try {
      const messageRef = doc(db, 'shoutbox', messageId);
      const messageDoc = await getDoc(messageRef);
      const reactions = messageDoc.data()?.reactions || {};
      
      if (reactions[emoji]?.includes(user.uid)) {
        // Remove reaction
        await updateDoc(messageRef, {
          [`reactions.${emoji}`]: arrayRemove(user.uid)
        });
      } else {
        // Add reaction
        await updateDoc(messageRef, {
          [`reactions.${emoji}`]: arrayUnion(user.uid)
        });
      }
    } catch (error) {
      console.error('Error updating reaction:', error);
      toast.error('Failed to update reaction');
    }
  };

  const handleEditMessage = async (messageId: string, newContent: string) => {
    if (!user) return;
    
    try {
      const messageRef = doc(db, 'shoutbox', messageId);
      const messageDoc = await getDoc(messageRef);
      const currentContent = messageDoc.data()?.content;
      const editHistory = messageDoc.data()?.editHistory || [];

      await updateDoc(messageRef, {
        content: newContent,
        lastEdited: Timestamp.now(),
        editHistory: [...editHistory, {
          content: currentContent,
          editedAt: Timestamp.now()
        }]
      });
      
      toast.success('Message updated');
    } catch (error) {
      console.error('Error editing message:', error);
      toast.error('Failed to edit message');
    }
  };

  const handleCreateThread = async (parentMessageId: string) => {
    if (!user) return;
    
    try {
      const threadRef = collection(db, 'shoutbox', parentMessageId, 'thread');
      await addDoc(threadRef, {
        content: newMessage,
        userEmail: user.email,
        userId: user.uid,
        timestamp: Timestamp.now()
      });
      
      // Update parent message thread count
      const parentRef = doc(db, 'shoutbox', parentMessageId);
      await updateDoc(parentRef, {
        threadCount: increment(1)
      });
      
      setNewMessage('');
    } catch (error) {
      console.error('Error creating thread:', error);
      toast.error('Failed to create thread');
    }
  };

  const handleSearch = async (searchQuery: string) => {
    if (!searchQuery.trim()) {
      setSearchResults([]);
      return;
    }
    
    try {
      const messagesRef = collection(db, 'shoutbox');
      const q = query(messagesRef, 
        where('content', '>=', searchQuery.toLowerCase()),
        where('content', '<=', searchQuery.toLowerCase() + '\uf8ff'),
        limit(10)
      );
      
      const snapshot = await getDocs(q);
      const results = snapshot.docs.map(doc => ({
        id: doc.id,
        ...(doc.data() as Omit<ShoutboxMessage, 'id'>)
      })) as ShoutboxMessage[];
      
      setSearchResults(results);
    } catch (error) {
      console.error('Error searching messages:', error);
      toast.error('Failed to search messages');
    }
  };

  const updateTypingStatus = async (isTyping: boolean) => {
    if (!user) return;
    try {
      const typingRef = doc(db, 'typing', 'shoutbox');
      await setDoc(typingRef, {
        [user.uid]: isTyping
      }, { merge: true });
    } catch (error) {
      console.error('Error updating typing status:', error);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewMessage(e.target.value);
    if (!user) return;
    
    updateTypingStatus(true);
    if (typingTimeout) clearTimeout(typingTimeout);
    const timeout = setTimeout(() => updateTypingStatus(false), 3000);
    setTypingTimeout(timeout);
  };

  const handlePinMessage = async (message: ShoutboxMessage) => {
    if (!isAdmin || !user) return;

    try {
      const messageRef = doc(db, 'shoutbox', message.id);

      if (message.isPinned) {
        await updateDoc(messageRef, {
          isPinned: false,
          pinnedBy: null
        });
        setPinnedMessage(null);
        toast.success('Message unpinned');
      } else {
        // Unpin current pinned message if exists
        if (pinnedMessage) {
          await updateDoc(doc(db, 'shoutbox', pinnedMessage.id), {
            isPinned: false,
            pinnedBy: null
          });
        }

        await updateDoc(messageRef, {
          isPinned: true,
          pinnedBy: user.email
        });
        setPinnedMessage(message);
        toast.success('Message pinned');
      }
    } catch (error) {
      console.error('Error toggling pin:', error);
      toast.error('Failed to toggle pin');
    }
  };

  const CommandsPopover = () => (
    <Popover.Root open={showCommands} onOpenChange={setShowCommands}>
      <Popover.Trigger asChild>
        <button
          className={`p-2 rounded-full transition-colors ${
            showCommands 
              ? 'bg-purple-500 text-white' 
              : 'text-gray-400 hover:text-purple-400'
          }`}
          aria-label="Show admin commands"
        >
          <Command className="w-5 h-5" />
        </button>
      </Popover.Trigger>

      <Popover.Portal>
        <Popover.Content
          className="z-50 w-80 rounded-lg shadow-lg bg-gray-900 border border-gray-800 animate-in fade-in-0 zoom-in-95"
          sideOffset={5}
          align="end"
        >
          <div className="p-4">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-2">
                <Terminal className="w-5 h-5 text-purple-400" />
                <h3 className="font-semibold text-white">Admin Commands</h3>
              </div>
              <Popover.Close className="text-gray-400 hover:text-gray-300">
                <X className="w-5 h-5" />
              </Popover.Close>
            </div>

            <div className="space-y-3">
              {adminCommands.map((cmd) => (
                <div
                  key={cmd.command}
                  className="p-3 rounded-lg bg-gray-800/50 hover:bg-gray-800 transition-colors"
                >
                  <div className="flex items-start gap-3">
                    <div className="mt-1">{cmd.icon}</div>
                    <div className="flex-1">
                      <div className="flex items-center gap-2">
                        <code className="text-sm font-mono text-purple-400">
                          {cmd.command}
                        </code>
                      </div>
                      <p className="text-sm text-gray-300 mt-1">
                        {cmd.description}
                      </p>
                      {cmd.example && (
                        <p className="text-xs text-gray-400 mt-1">
                          Example: <code>{cmd.example}</code>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="mt-4 pt-3 border-t border-gray-800">
              <p className="text-xs text-gray-400">
                Press <kbd className="px-1.5 py-0.5 bg-gray-800 rounded text-xs">Tab</kbd> to 
                autocomplete commands
              </p>
            </div>
          </div>
          <Popover.Arrow className="fill-gray-900" />
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );

  return (
    <div className="h-[500px] md:h-[600px] lg:h-[700px] flex flex-col">
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-2 sm:gap-0 mb-1">
        <div className="flex items-center gap-2">
          <MessageSquare className="w-5 h-5 text-purple-400" />
          <h3 className="text-lg font-semibold">Dailybox</h3>
        </div>
        <div className="flex items-center gap-3">
          {/* Rules Tooltip */}
          <Tooltip.Provider delayDuration={0}>
            <Tooltip.Root>
              <Tooltip.Trigger asChild>
                <button 
                  className="text-gray-400 hover:text-gray-300 transition-colors"
                  aria-label="Chat Rules"
                >
                  <Info className="w-5 h-5" />
                </button>
              </Tooltip.Trigger>
              <Tooltip.Portal>
                <Tooltip.Content
                  className="bg-gray-800 p-4 rounded-lg shadow-lg max-w-xs z-50 border border-gray-700 animate-in fade-in-0 zoom-in-95"
                  sideOffset={5}
                  collisionPadding={20}
                >
                  <div className="space-y-3">
                    <h4 className="font-semibold text-purple-400">Chat Rules:</h4>
                    <ul className="text-sm space-y-1.5 text-gray-300">
                      <li className="flex items-center gap-2">
                        <span className="w-1.5 h-1.5 rounded-full bg-purple-400"></span>
                        Be respectful to others
                      </li>
                      <li className="flex items-center gap-2">
                        <span className="w-1.5 h-1.5 rounded-full bg-purple-400"></span>
                        No spam or excessive caps
                      </li>
                      <li className="flex items-center gap-2">
                        <span className="w-1.5 h-1.5 rounded-full bg-purple-400"></span>
                        No hate speech or harassment
                      </li>
                      <li className="flex items-center gap-2">
                        <span className="w-1.5 h-1.5 rounded-full bg-purple-400"></span>
                        No advertising or self-promotion
                      </li>
                      <li className="flex items-center gap-2">
                        <span className="w-1.5 h-1.5 rounded-full bg-purple-400"></span>
                        Keep it family-friendly
                      </li>
                      <li className="flex items-center gap-2">
                        <span className="w-1.5 h-1.5 rounded-full bg-purple-400"></span>
                        Max 200 characters per message
                      </li>
                    </ul>
                  </div>
                  <Tooltip.Arrow className="fill-gray-800" />
                </Tooltip.Content>
              </Tooltip.Portal>
            </Tooltip.Root>
          </Tooltip.Provider>

          {/* Mute Information Tooltip */}
          <Tooltip.Provider delayDuration={0}>
            <Tooltip.Root>
              <Tooltip.Trigger asChild>
                <button 
                  className="text-gray-400 hover:text-red-400 transition-colors"
                  aria-label="Mute Information"
                >
                  <AlertOctagon className="w-5 h-5" />
                </button>
              </Tooltip.Trigger>
              <Tooltip.Portal>
                <Tooltip.Content
                  className="bg-gray-800 p-4 rounded-lg shadow-lg max-w-xs z-50 border border-gray-700 animate-in fade-in-0 zoom-in-95"
                  sideOffset={5}
                  collisionPadding={20}
                >
                  <div className="space-y-4">
                    <div>
                      <h4 className="font-semibold text-red-400 mb-2">Mute System:</h4>
                      <p className="text-sm text-gray-300">
                        Users who violate chat rules may be muted by administrators.
                      </p>
                    </div>

                    <div className="space-y-2">
                      <h5 className="font-medium text-gray-200">What happens when muted:</h5>
                      <ul className="text-sm space-y-1.5 text-gray-300">
                        <li className="flex items-center gap-2">
                          <span className="w-1.5 h-1.5 rounded-full bg-red-400"></span>
                          Cannot send messages
                        </li>
                        <li className="flex items-center gap-2">
                          <span className="w-1.5 h-1.5 rounded-full bg-red-400"></span>
                          Messages are hidden from chat
                        </li>
                        <li className="flex items-center gap-2">
                          <span className="w-1.5 h-1.5 rounded-full bg-red-400"></span>
                          Mute lasts for 10 minutes
                        </li>
                      </ul>
                    </div>

                    <div className="space-y-2">
                      <h5 className="font-medium text-gray-200">Mute Triggers:</h5>
                      <ul className="text-sm space-y-1.5 text-gray-300">
                        <li className="flex items-center gap-2">
                          <span className="w-1.5 h-1.5 rounded-full bg-red-400"></span>
                          Harassment or bullying
                        </li>
                        <li className="flex items-center gap-2">
                          <span className="w-1.5 h-1.5 rounded-full bg-red-400"></span>
                          Excessive spam
                        </li>
                        <li className="flex items-center gap-2">
                          <span className="w-1.5 h-1.5 rounded-full bg-red-400"></span>
                          Inappropriate content
                        </li>
                        <li className="flex items-center gap-2">
                          <span className="w-1.5 h-1.5 rounded-full bg-red-400"></span>
                          Rule violations
                        </li>
                      </ul>
                    </div>

                    <div className="text-xs text-gray-400 pt-2 border-t border-gray-700">
                      Repeated violations may result in longer mute durations or permanent ban.
                    </div>
                  </div>
                  <Tooltip.Arrow className="fill-gray-800" />
                </Tooltip.Content>
              </Tooltip.Portal>
            </Tooltip.Root>
          </Tooltip.Provider>
        </div>
      </div>
      <p className="text-sm text-gray-400 mb-4">Chat with other members in real-time</p>
      
      {/* Messages Container */}
      <div 
        ref={messagesContainerRef}
        onScroll={handleScroll}
        className="bg-[#1a1b26] rounded-xl p-3 sm:p-6 flex-1 overflow-y-auto mb-4 scroll-smooth" 
        style={{ 
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
          WebkitOverflowScrolling: 'touch'
        }}
      >
        <style>{`
          div::-webkit-scrollbar {
            display: none;
            width: 0;
            background: transparent;
          }
        `}</style>
        {/* Pinned Message */}
        {pinnedMessage && (
          <div className="mb-4 bg-yellow-500/10 border border-yellow-500/20 rounded-lg p-3">
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center gap-2">
                <Pin className="w-4 h-4 text-yellow-400" />
                <span className="text-sm text-yellow-400">
                  Pinned by {pinnedMessage.pinnedBy?.split('@')[0]}
                </span>
              </div>
              {isAdmin && (
                <button
                  onClick={() => handlePinMessage(pinnedMessage)}
                  className="text-yellow-400 hover:text-yellow-500"
                >
                  <X className="w-4 h-4" />
                </button>
              )}
            </div>
            <p className="text-white">{pinnedMessage.content}</p>
            <span className="text-sm text-gray-400">
              {pinnedMessage.userEmail.split('@')[0]}
            </span>
          </div>
        )}
        {messages.map((message, index) => {
          const isMuted = isUserMuted(message.userId);
          if (isMuted) return null;

          const userColor = getUserColor(message.userId);
          // Alternate between left and right based on index
          const isRight = index % 2 === 0;

          return (
            <div 
              key={message.id} 
              className={`relative mb-2 p-1.5 rounded-lg ${userColor} ${
                isRight ? 'ml-auto' : 'mr-auto'
              } max-w-[85%] sm:max-w-[70%] group`}
            >
              {(isAdmin || isSupport) && (
                <div className="absolute -top-2 -right-2 opacity-0 group-hover:opacity-100 transition-opacity flex gap-1">
                  {isAdmin && (
                    <button
                      onClick={() => handlePinMessage(message)}
                      className="bg-gray-800 hover:bg-gray-700 rounded-full p-1"
                      title={message.isPinned ? "Unpin message" : "Pin message"}
                    >
                      <Pin className={`w-3 h-3 ${
                        message.isPinned ? 'text-yellow-400' : 'text-gray-400 hover:text-yellow-400'
                      }`} />
                    </button>
                  )}
                  <button
                    onClick={() => handleDeleteMessage(message.id)}
                    className="bg-gray-800 hover:bg-gray-700 rounded-full p-1"
                    title="Delete message"
                  >
                    <Trash2 className="w-3 h-3 text-red-400" />
                  </button>
                </div>
              )}
              {/* Reply preview if exists */}
              {message.replyTo && (
                <div className="text-xs bg-black/20 p-2 rounded mb-2 border-l-2 border-blue-500">
                  <div className="text-gray-400">
                    Reply to {message.replyTo.userEmail.split('@')[0]}:
                  </div>
                  <div className="truncate">{message.replyTo.content}</div>
                </div>
              )}
              
              <div className="flex items-start justify-between gap-2">
                <div className="flex-1">
                  <div className="flex items-center gap-1.5 mb-1">
                    <span className="text-xs text-gray-400">
                      {message.userEmail.split('@')[0]}
                    </span>
                    {message.userRole && (
                      <div className="flex items-center gap-1">
                        {getRoleIcon(message.userRole)}
                        <span className={`text-[10px] ${getRoleColor(message.userRole)}`}>
                          {message.userRole}
                        </span>
                      </div>
                    )}
                  </div>
                  <p className="text-sm text-white text-left">
                    {message.content}
                  </p>
                  <button 
                    onClick={() => setReplyingTo(message)}
                    className="text-xs text-gray-400 hover:text-blue-400 mt-1"
                  >
                    Reply
                  </button>
                </div>
                <div className="flex flex-col text-[10px] text-gray-200 items-end shrink-0">
                  <span title="Server time" className="flex items-center gap-1">
                    <Globe className="w-3 h-3" />
                    {message.timestamp.toLocaleTimeString([], { 
                      hour: '2-digit', 
                      minute: '2-digit',
                      timeZone: 'UTC'
                    })}
                  </span>
                  <span title="Your local time" className="flex items-center gap-1 opacity-75">
                    <Clock className="w-3 h-3" />
                    {message.timestamp.toLocaleTimeString([], { 
                      hour: '2-digit', 
                      minute: '2-digit'
                    })}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
        <div ref={messagesEndRef} />
        
        {!shouldAutoScroll && messages.length > 0 && (
          <button
            onClick={() => {
              setShouldAutoScroll(true);
              scrollToBottom();
            }}
            className="fixed bottom-24 right-8 bg-purple-500 hover:bg-purple-600 text-white rounded-full p-2 shadow-lg transition-all duration-200 animate-bounce"
            aria-label="Scroll to latest messages"
          >
            <svg
              className="w-5 h-5"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
            </svg>
          </button>
        )}
      </div>

      {/* Message Input */}
      <div className="space-y-2">
        {replyingTo && (
          <div className="flex items-center gap-2 bg-[#24283b] p-2 rounded">
            <span className="text-sm text-gray-400 truncate">
              Replying to {replyingTo.userEmail.split('@')[0]}
            </span>
            <button 
              onClick={() => setReplyingTo(null)}
              className="text-xs text-gray-400 hover:text-red-400 shrink-0"
            >
              Cancel
            </button>
          </div>
        )}
        <form onSubmit={handleSubmit} className="flex flex-col sm:flex-row gap-2 bg-[#24283b] p-2 rounded-lg relative">
          <input
            type="text"
            value={newMessage}
            onChange={handleInputChange}
            placeholder={isUserMuted(user?.uid || '') ? "You are muted" : "Type your message..."}
            className="flex-1 bg-transparent text-white px-4 py-2 focus:outline-none placeholder-gray-500 min-w-0"
            maxLength={200}
            disabled={isUserMuted(user?.uid || '')}
          />
          <div className="flex items-center gap-2 justify-end sm:justify-start">
            {isAdmin && <CommandsPopover />}
            <button
              type="button"
              onClick={() => setIsEmojiPickerOpen(!isEmojiPickerOpen)}
              className="text-gray-400 hover:text-gray-300 transition-colors"
            >
              <Smile className="h-5 w-5" />
            </button>
            {isEmojiPickerOpen && (
              <div className="absolute bottom-14 right-0 sm:right-16">
                <EmojiPicker onEmojiSelect={handleEmojiSelect} />
              </div>
            )}
            <Button 
              type="submit" 
              disabled={!newMessage.trim()}
              className="bg-[#7c3aed] hover:bg-[#9461ff] px-4 sm:px-6 py-2 rounded-lg transition-all duration-200 disabled:opacity-50 disabled:hover:bg-[#7c3aed]"
            >
              <Send className="h-4 w-4 text-white" />
            </Button>
          </div>
        </form>
      </div>

      <div className="h-6 text-sm text-gray-400">
        {Object.entries(typingUsers)
          .filter(([uid, isTyping]) => isTyping && uid !== user?.uid)
          .map(([uid]) => (
            <div key={uid} className="flex items-center gap-2 animate-pulse">
              <div className="w-2 h-2 bg-purple-400 rounded-full" />
              <span>{messages.find(m => m.userId === uid)?.userEmail?.split('@')[0]} is typing...</span>
            </div>
          ))}
      </div>
    </div>
  );
}