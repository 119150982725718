import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import App from './App';
import './index.css';

// Create the root element for the React application
const rootElement = document.getElementById('root')!;

// Render the application
ReactDOM.createRoot(rootElement).render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
    <Toaster position="top-right" /> {/* Toaster should be outside of Router */}
  </React.StrictMode>
);