import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, onSnapshot, Timestamp, DocumentData, updateDoc, doc, where, deleteDoc } from 'firebase/firestore';
import { db } from '@/lib/firebase';
import { Button } from '@/components/ui/Button';
import { 
  TicketIcon, 
  Bell,
  MessageSquare,
  Send,
  Clock,
  Filter,
  Search,
  Trash2,
  Archive,
} from 'lucide-react';
import { toast } from 'react-hot-toast';

interface Ticket extends DocumentData {
  id: string;
  title: string;
  description: string;
  category: string;
  userId: string;
  userEmail: string;
  createdAt: Timestamp;
  status: 'open' | 'in-progress' | 'resolved';
  replies: Reply[];
  hasNewReply: boolean;
  archived?: boolean;
}

interface Reply {
  id: string;
  content: string;
  userId: string;
  userEmail: string;
  isStaff: boolean;
  createdAt: Timestamp;
}

export function TicketSupportManagement() {
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const [selectedTicket, setSelectedTicket] = useState<Ticket | null>(null);
  const [replyContent, setReplyContent] = useState('');
  const [statusFilter, setStatusFilter] = useState<string>('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [hasNewTickets, setHasNewTickets] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showArchived, setShowArchived] = useState(false);

  useEffect(() => {
    const ticketsRef = collection(db, 'tickets');
    let q;

    if (showArchived) {
      q = query(
        ticketsRef,
        where('archived', '==', true),
        orderBy('createdAt', 'desc')
      );
    } else {
      q = query(
        ticketsRef,
        orderBy('createdAt', 'desc')
      );
    }

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const ticketsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        archived: doc.data().archived || false
      })) as Ticket[];
      
      const filteredTickets = showArchived 
        ? ticketsData.filter(ticket => ticket.archived)
        : ticketsData.filter(ticket => !ticket.archived);
      
      const newTickets = filteredTickets.some(ticket => 
        ticket.status === 'open' && !ticket.replies.some(reply => reply.isStaff)
      );
      
      setHasNewTickets(newTickets);
      setTickets(filteredTickets);
    });

    return () => unsubscribe();
  }, [showArchived]);

  const handleReply = async (ticket: Ticket) => {
    if (!replyContent.trim()) {
      toast.error('Reply cannot be empty');
      return;
    }

    setIsSubmitting(true);

    try {
      const ticketRef = doc(db, 'tickets', ticket.id);
      const newReply = {
        id: Math.random().toString(36).substr(2, 9),
        content: replyContent.trim(),
        userId: 'staff',
        userEmail: 'support@example.com',
        isStaff: true,
        createdAt: Timestamp.now()
      };

      await updateDoc(ticketRef, {
        replies: [...(ticket.replies || []), newReply],
        status: 'in-progress',
        hasNewReply: true
      });

      setReplyContent('');
      toast.success('Reply sent successfully');
    } catch (error) {
      console.error('Error sending reply:', error);
      toast.error('Failed to send reply');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleStatusChange = async (ticketId: string, newStatus: 'open' | 'in-progress' | 'resolved') => {
    try {
      await updateDoc(doc(db, 'tickets', ticketId), {
        status: newStatus
      });
      toast.success('Ticket status updated');
    } catch (error) {
      console.error('Error updating ticket status:', error);
      toast.error('Failed to update ticket status');
    }
  };

  const handleArchive = async (ticketId: string) => {
    try {
      await updateDoc(doc(db, 'tickets', ticketId), {
        archived: true
      });
      toast.success('Ticket archived successfully');
    } catch (error) {
      console.error('Error archiving ticket:', error);
      toast.error('Failed to archive ticket');
    }
  };

  const handleDelete = async (ticketId: string) => {
    if (window.confirm('Are you sure you want to delete this ticket? This action cannot be undone.')) {
      try {
        await deleteDoc(doc(db, 'tickets', ticketId));
        toast.success('Ticket deleted successfully');
      } catch (error) {
        console.error('Error deleting ticket:', error);
        toast.error('Failed to delete ticket');
      }
    }
  };

  const filteredTickets = tickets.filter(ticket => {
    const matchesStatus = statusFilter === 'all' || ticket.status === statusFilter;
    const matchesSearch = 
      ticket.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
      ticket.description.toLowerCase().includes(searchQuery.toLowerCase()) ||
      ticket.userEmail.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesStatus && matchesSearch;
  });

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-center gap-4">
          <h1 className="text-2xl font-bold text-white flex items-center gap-2">
            <TicketIcon className="h-6 w-6 text-purple-400" />
            {showArchived ? 'Archived Tickets' : 'Support Tickets'}
          </h1>
          {hasNewTickets && !showArchived && (
            <div className="relative">
              <Bell className="h-6 w-6 text-yellow-400 animate-ring" />
              <span className="absolute -top-1 -right-1 h-3 w-3 bg-red-500 rounded-full" />
            </div>
          )}
        </div>
      </div>

      <div className="flex gap-4 mb-6">
        <div className="flex-1">
          <div className="relative">
            <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
            <input
              type="text"
              placeholder="Search tickets..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="w-full pl-10 pr-4 py-2 bg-gray-800 rounded-lg text-white border border-gray-700 focus:border-purple-500 focus:ring-1 focus:ring-purple-500"
            />
          </div>
        </div>
        <div className="flex items-center gap-2">
          <Filter className="h-5 w-5 text-gray-400" />
          <select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
            className="px-3 py-2 bg-gray-800 rounded-lg text-white border border-gray-700 focus:border-purple-500 focus:ring-1 focus:ring-purple-500"
          >
            <option value="all">All Status</option>
            <option value="open">Open</option>
            <option value="in-progress">In Progress</option>
            <option value="resolved">Resolved</option>
          </select>
          <Button
            variant="outline"
            size="sm"
            onClick={() => setShowArchived(!showArchived)}
            className={`ml-2 ${showArchived ? 'bg-purple-600' : ''}`}
          >
            <Archive className="h-4 w-4 mr-2" />
            {showArchived ? 'Show Active' : 'Show Archived'}
          </Button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredTickets.map((ticket) => (
          <div key={ticket.id} className="bg-gray-800 rounded-xl p-4 border border-gray-700">
            <div className="flex justify-between items-start mb-2">
              <h3 className="font-medium text-white">{ticket.title}</h3>
              <div className="flex items-center gap-2">
                {!showArchived && (
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => handleArchive(ticket.id)}
                    className="text-gray-400 hover:text-gray-300"
                  >
                    <Archive className="h-4 w-4" />
                  </Button>
                )}
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={() => handleDelete(ticket.id)}
                  className="text-red-400 hover:text-red-300"
                >
                  <Trash2 className="h-4 w-4" />
                </Button>
                <select
                  value={ticket.status}
                  onChange={(e) => handleStatusChange(ticket.id, e.target.value as 'open' | 'in-progress' | 'resolved')}
                  className="text-xs px-2 py-1 rounded bg-gray-700 text-white border border-gray-600"
                >
                  <option value="open">Open</option>
                  <option value="in-progress">In Progress</option>
                  <option value="resolved">Resolved</option>
                </select>
              </div>
            </div>

            <p className="text-sm text-gray-300 mb-2">{ticket.description}</p>

            <div className="flex items-center gap-4 text-xs text-gray-400 mb-4">
              <span>{ticket.userEmail}</span>
              <span className="flex items-center gap-1">
                <Clock className="h-3 w-3" />
                {ticket.createdAt.toDate().toLocaleDateString()}
              </span>
            </div>

            {ticket.replies.length > 0 && (
              <div className="mb-4 space-y-2">
                <h4 className="text-sm font-medium text-gray-300 flex items-center gap-2">
                  <MessageSquare className="h-4 w-4" />
                  Replies
                </h4>
                {ticket.replies.map((reply) => (
                  <div key={reply.id} className="bg-gray-700/50 p-2 rounded">
                    <div className="flex items-center gap-2 mb-1">
                      <span className="text-sm font-medium text-gray-300">
                        {reply.isStaff ? 'Support Staff' : 'User'}
                      </span>
                      <span className="text-xs text-gray-400">
                        {reply.createdAt.toDate().toLocaleString()}
                      </span>
                    </div>
                    <p className="text-sm text-gray-300">{reply.content}</p>
                  </div>
                ))}
              </div>
            )}

            {ticket.status !== 'resolved' && (
              <div className="flex gap-2">
                <input
                  type="text"
                  value={replyContent}
                  onChange={(e) => setReplyContent(e.target.value)}
                  placeholder="Write a reply..."
                  className="flex-1 px-3 py-2 bg-gray-700 rounded-lg text-white border border-gray-600 focus:border-purple-500 focus:ring-1 focus:ring-purple-500 text-sm"
                />
                <Button
                  onClick={() => handleReply(ticket)}
                  className="flex items-center gap-2 bg-purple-600 hover:bg-purple-700"
                  disabled={isSubmitting}
                  size="sm"
                >
                  <Send className="h-4 w-4" />
                  Reply
                </Button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}