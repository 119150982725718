import React, { useState } from 'react';
import { getFirestore, collection, doc, setDoc } from 'firebase/firestore';
import { Button } from '@/components/ui/Button';
import { toast } from 'react-toastify';

const firestore = getFirestore(); // Initialize Firestore instance

export function CreateNotification() {
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [type, setType] = useState('news'); // Default type
  const [loading, setLoading] = useState(false); // Loading state
  const [feedback, setFeedback] = useState(''); // Feedback message
  const [errorFields, setErrorFields] = useState({ title: false, message: false }); // Error tracking

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    let hasError = false;

    // Validation
    if (title.length < 5) {
      setErrorFields((prev) => ({ ...prev, title: true }));
      hasError = true;
    } else {
      setErrorFields((prev) => ({ ...prev, title: false }));
    }

    if (message.length < 10) {
      setErrorFields((prev) => ({ ...prev, message: true }));
      hasError = true;
    } else {
      setErrorFields((prev) => ({ ...prev, message: false }));
    }

    if (hasError) {
      setFeedback('Please correct the highlighted fields.');
      return;
    }

    setFeedback(''); // Clear previous feedback
    setLoading(true); // Set loading state
    try {
      const notification = {
        title,
        message,
        type,
        read: false,
        createdAt: new Date().toISOString(), // Ensure createdAt is in ISO format
      };

      const notificationRef = doc(collection(firestore, 'notifications')); // Create a new document reference
      await setDoc(notificationRef, notification); // Use setDoc to add the notification
      toast.success('Notification sent successfully!'); // Success feedback

      // Clear the form
      setTitle('');
      setMessage('');
      setType('news');
    } catch (error) {
      console.error('Error sending notification:', error);
      toast.error('Failed to send notification.'); // Error feedback
    } finally {
      setLoading(false); // Reset loading state
    }
  };

  return (
    <div className="bg-gray-900 p-6 rounded-lg shadow-lg">
      <div className="p-4 bg-gray-800 rounded-lg">
        <h2 className="text-2xl font-bold mb-4">Create a New Notification</h2>
        <p className="text-sm text-gray-300 mb-4">Fill in the details below to send a notification.</p>
        {feedback && <p className={`feedback ${feedback.includes('successfully') ? 'success' : 'error'}`}>{feedback}</p>} {/* Feedback message */}
        
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Title</label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className={`w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg ${errorFields.title ? 'border-red-500' : ''}`}
              required
              placeholder="Enter Notification Title"
            />
            {errorFields.title && <p className="error-message text-red-500">Title must be at least 5 characters.</p>}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Message</label>
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className={`w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg ${errorFields.message ? 'border-red-500' : ''}`}
              required
              placeholder="Enter Notification Message"
            />
            {errorFields.message && <p className="error-message text-red-500">Message must be at least 10 characters.</p>}
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium mb-1">Type</label>
            <select
              value={type}
              onChange={(e) => setType(e.target.value)}
              className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg"
            >
              <option value="news">News</option>
              <option value="update">Update</option>
              <option value="announcement">Announcement</option>
              <option value="task">Task</option>
              <option value="payment">Payment</option>
              <option value="referral">Referral</option>
            </select>
          </div>
          <Button type="submit" className="mt-2" disabled={loading}>
            {loading ? 'Sending...' : 'Send Notification'}
          </Button>
        </form>
      </div>
    </div>
  );
}