import { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, serverTimestamp, getDoc, doc, query, limit } from 'firebase/firestore';
import { db } from '@/lib/firebase';
import { toast } from 'react-hot-toast';
import { Plus, Edit2, Trash2, MessageSquare, Users, Eye, X, ArrowLeft, MoreVertical } from 'lucide-react';
import { Button } from '@/components/ui/Button';

const TAGS = ['Uncategorized', 'General', 'Help', 'Discussion', 'Feedback', 'Question', 'Other'];

interface TopicData {
  id: string;
  title: string;
  description: string;
  tag: string;
  createdAt: any;
  status: 'active' | 'inactive';
  createdBy: string;
  userEmail: string;
  messageCount: number;
  participantCount: number;
  viewCount: number;
  likes: number;
  likedBy: string[];
  replies: TopicReply[];
}

interface TopicReply {
  id: string;
  content: string;
  createdAt: any;
  createdBy: string;
  userAvatar?: string;
  userName: string;
}

interface TopicDetails extends TopicData {
  replies: TopicReply[];
}

export function TopicsManagement() {
  const [topics, setTopics] = useState<TopicData[]>([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [filter, setFilter] = useState<'all' | 'active' | 'inactive'>('all');
  const [newTopic, setNewTopic] = useState<Omit<TopicData, 'id' | 'createdAt'>>({
    title: '',
    description: '',
    tag: 'Uncategorized',
    status: 'active',
    createdBy: '',
    userEmail: '',
    messageCount: 0,
    participantCount: 0,
    viewCount: 0,
    likes: 0,
    likedBy: [],
    replies: []
  });
  const [selectedTopic, setSelectedTopic] = useState<TopicDetails | null>(null);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);

  useEffect(() => {
    fetchTopics();
  }, []);

  async function fetchTopics() {
    try {
      const topicsQuery = query(collection(db, 'topics'), limit(10));
      const topicsSnapshot = await getDocs(topicsQuery);
      const topicsData = topicsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data() as Record<string, any>
      })) as TopicData[];
      setTopics(topicsData);
    } catch (error) {
      console.error('Failed to fetch topics:', error);
      toast.error('Failed to load topics');
    } finally {
      setLoading(false);
    }
  }

  const handleCreateTopic = async (e: React.FormEvent) => {
    e.preventDefault();
    
    try {
      if (!newTopic.title || !newTopic.description) {
        toast.error('Please fill in all required fields');
        return;
      }

      const topicRef = await addDoc(collection(db, 'topics'), {
        ...newTopic,
        createdAt: serverTimestamp(),
      });

      setTopics([...topics, { id: topicRef.id, ...newTopic, createdAt: new Date().toISOString() }]);
      setShowModal(false);
      setNewTopic({
        title: '',
        description: '',
        tag: 'Uncategorized',
        status: 'active',
        createdBy: '',
        userEmail: '',
        messageCount: 0,
        participantCount: 0,
        viewCount: 0,
        likes: 0,
        likedBy: [],
        replies: []
      });
      toast.success('Topic created successfully!');
    } catch (error) {
      console.error('Error creating topic:', error);
      toast.error('Failed to create topic');
    }
  };

  async function fetchTopicDetails(topicId: string) {
    try {
      const topicDoc = await getDoc(doc(db, 'topics', topicId));
      const repliesQuery = query(collection(db, 'topics', topicId, 'replies'), limit(5));
      const repliesSnapshot = await getDocs(repliesQuery);
      
      const replies = repliesSnapshot.docs.map(doc => {
        const data = doc.data() as TopicReply;
        return {
          ...data,
          id: doc.id,
        };
      }) as TopicReply[];

      setSelectedTopic({
        ...(topicDoc.data() as TopicData),
        id: topicDoc.id,
        replies
      });
      setIsDetailsModalOpen(true);
    } catch (error) {
      console.error('Failed to fetch topic details:', error);
      toast.error('Failed to load topic details');
    }
  }

  const maskEmail = (email: string) => {
    if (!email) return '';
    const [username, domain] = email.split('@');
    return `*****@${domain}`;
  };

  if (loading) {
    return (
      <div className="space-y-4">
        <div className="h-8 bg-gray-800 rounded w-1/4 animate-pulse"></div>
        <div className="h-64 bg-gray-800 rounded animate-pulse"></div>
      </div>
    );
  }

  const filteredTopics = topics.filter(topic =>
    filter === 'all' ? true : topic.status === filter
  );

  return (
    <div className="space-y-6 p-4 md:p-6 lg:p-8">
      {/* Header */}
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between space-y-4 md:space-y-0">
        <div>
          <h2 className="text-2xl font-bold">Topic Management</h2>
          <p className="text-gray-400">Create and manage community topics</p>
        </div>
        <Button onClick={() => setShowModal(true)}>
          <Plus className="h-4 w-4 mr-2" />
          Add New Topic
        </Button>
      </div>

      {/* Filters */}
      <div className="flex items-center space-x-4 mb-6">
        <div className="flex space-x-2 overflow-x-auto">
          {(['all', 'active', 'inactive'] as const).map((status) => (
            <Button
              key={status}
              variant={filter === status ? 'default' : 'ghost'}
              onClick={() => setFilter(status)}
            >
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </Button>
          ))}
        </div>
      </div>

      {/* Topics Grid */}
      <div className="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {filteredTopics.map((topic) => (
          <div
            key={topic.id}
            onClick={() => fetchTopicDetails(topic.id)}
            className="bg-gray-800/50 rounded-lg p-6 border border-gray-700/50 flex flex-col justify-between cursor-pointer hover:bg-gray-800/70 transition-colors duration-200"
          >
            {/* Topic Header */}
            <div>
              <div className="flex items-center justify-between mb-2">
                <h3 className="text-lg font-semibold">{topic.title}</h3>
                <span className={`px-2 py-1 rounded-full text-xs ${
                  topic.status === 'active' 
                    ? 'bg-green-500/20 text-green-400' 
                    : 'bg-red-500/20 text-red-400'
                }`}>
                  {topic.status}
                </span>
              </div>

              {/* Category Badge */}
              <div className="mb-4">
                <span className={`px-2 py-1 rounded-full text-xs ${
                  topic.tag && topic.tag !== 'Uncategorized'
                    ? 'bg-purple-500/20 text-purple-400' 
                    : 'bg-gray-500/20 text-gray-400'
                }`}>
                  {topic.tag || 'Uncategorized'}
                </span>
              </div>

              <p className="text-gray-400 mb-4">{topic.description}</p>
            </div>

            {/* Topic Stats */}
            <div className="space-y-2">
              <div className="flex items-center justify-between text-sm text-gray-400">
                <div className="flex items-center space-x-4">
                  <span className="flex items-center" title="Messages">
                    <MessageSquare className="h-4 w-4 mr-1" />
                    {topic.messageCount?.toLocaleString() || '0'}
                  </span>
                  <span className="flex items-center" title="Participants">
                    <Users className="h-4 w-4 mr-1" />
                    {topic.participantCount?.toLocaleString() || '0'}
                  </span>
                </div>
                <span className="flex items-center" title="Views">
                  <Eye className="h-4 w-4 mr-1" />
                  {topic.viewCount?.toLocaleString() || '0'}
                </span>
              </div>
              
              {/* Creation Info */}
              <div className="text-xs text-gray-500">
                Created by {topic.userEmail ? maskEmail(topic.userEmail) : 'Anonymous'} on {' '}
                {topic.createdAt?.toDate?.() 
                  ? new Date(topic.createdAt.toDate()).toLocaleDateString('en-US', {
                      year: 'numeric',
                      month: 'short',
                      day: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit'
                    })
                  : 'Date unavailable'}
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Create Topic Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50 p-4">
          <div className="bg-gray-900 p-6 rounded-xl max-w-md w-full">
            <h3 className="text-xl font-bold mb-4">Create New Topic</h3>
            <form onSubmit={handleCreateTopic} className="space-y-4">
              <div>
                <label className="block text-sm font-medium mb-1">Title</label>
                <input
                  type="text"
                  value={newTopic.title}
                  onChange={(e) => setNewTopic({ ...newTopic, title: e.target.value })}
                  className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Description</label>
                <textarea
                  value={newTopic.description}
                  onChange={(e) => setNewTopic({ ...newTopic, description: e.target.value })}
                  className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 h-24 resize-none"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium mb-1">Category</label>
                <select
                  value={newTopic.tag}
                  onChange={(e) => setNewTopic({ ...newTopic, tag: e.target.value })}
                  className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500"
                  required
                >
                  {TAGS.map((tag) => (
                    <option key={tag} value={tag}>{tag}</option>
                  ))}
                </select>
              </div>

              <div className="flex justify-end space-x-3">
                <Button
                  variant="ghost"
                  onClick={() => setShowModal(false)}
                  type="button"
                >
                  Cancel
                </Button>
                <Button type="submit">
                  Create Topic
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}

      {isDetailsModalOpen && selectedTopic && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-gray-900 rounded-xl w-full max-w-4xl h-[90vh] flex flex-col relative">
            {/* Modal Header */}
            <div className="p-4 border-b border-gray-800 flex items-center justify-between">
              <button
                onClick={() => setIsDetailsModalOpen(false)}
                className="text-gray-400 hover:text-white transition-colors"
              >
                <X className="h-5 w-5" />
              </button>
              <h3 className="text-xl font-bold text-center flex-1">Topic Details</h3>
              <div className="w-5" /> {/* Spacer for alignment */}
            </div>

            {/* Modal Content */}
            <div className="flex-1 overflow-y-auto p-6 space-y-6">
              {/* Topic Header */}
              <div className="space-y-4">
                <div className="flex items-center justify-between">
                  <h2 className="text-2xl font-bold">{selectedTopic.title}</h2>
                  <span className={`px-2 py-1 rounded-full text-xs ${
                    selectedTopic.status === 'active' 
                      ? 'bg-green-500/20 text-green-400' 
                      : 'bg-red-500/20 text-red-400'
                  }`}>
                    {selectedTopic.status}
                  </span>
                </div>

                <div className="flex items-center space-x-2">
                  <span className={`px-2 py-1 rounded-full text-xs ${
                    selectedTopic.tag && selectedTopic.tag !== 'Uncategorized'
                      ? 'bg-purple-500/20 text-purple-400' 
                      : 'bg-gray-500/20 text-gray-400'
                  }`}>
                    {selectedTopic.tag 
                      ? selectedTopic.tag.charAt(0).toUpperCase() + selectedTopic.tag.slice(1) 
                      : 'Uncategorized'}
                  </span>
                  <span className="text-sm text-gray-400">
                    Created {selectedTopic.createdAt?.toDate?.() 
                      ? new Date(selectedTopic.createdAt.toDate()).toLocaleDateString('en-US', {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit'
                        })
                      : 'Date unavailable'}
                  </span>
                </div>

                <p className="text-gray-300">{selectedTopic.description}</p>

                {/* Topic Stats */}
                <div className="flex items-center space-x-6 text-sm text-gray-400">
                  <span className="flex items-center">
                    <MessageSquare className="h-4 w-4 mr-1" />
                    {selectedTopic.messageCount?.toLocaleString() || '0'} replies
                  </span>
                  <span className="flex items-center">
                    <Users className="h-4 w-4 mr-1" />
                    {selectedTopic.participantCount?.toLocaleString() || '0'} participants
                  </span>
                  <span className="flex items-center">
                    <Eye className="h-4 w-4 mr-1" />
                    {selectedTopic.viewCount?.toLocaleString() || '0'} views
                  </span>
                </div>
              </div>

              {/* Replies Section */}
              <div className="space-y-4">
                <h3 className="text-lg font-semibold">Replies</h3>
                {selectedTopic.replies.length > 0 ? (
                  <div className="space-y-4">
                    {selectedTopic.replies.map((reply) => (
                      <div key={reply.id} className="bg-gray-800/50 rounded-lg p-4 space-y-2">
                        <div className="flex items-center justify-between">
                          <div className="flex items-center space-x-2">
                            {reply.userAvatar ? (
                              <img 
                                src={reply.userAvatar} 
                                alt={reply.userName}
                                className="w-8 h-8 rounded-full"
                              />
                            ) : (
                              <div className="w-8 h-8 rounded-full bg-gray-700 flex items-center justify-center">
                                {reply.userName.charAt(0).toUpperCase()}
                              </div>
                            )}
                            <span className="font-medium">{reply.userName}</span>
                          </div>
                          <span className="text-xs text-gray-400">
                            {reply.createdAt?.toDate?.() 
                              ? new Date(reply.createdAt.toDate()).toLocaleDateString('en-US', {
                                  year: 'numeric',
                                  month: 'short',
                                  day: 'numeric',
                                  hour: '2-digit',
                                  minute: '2-digit'
                                })
                              : 'Date unavailable'}
                          </span>
                        </div>
                        <p className="text-gray-300">{reply.content}</p>
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className="text-gray-400 text-center py-4">No replies yet</p>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}