import { useState, useEffect } from 'react';
import { collection, getDocs, onSnapshot } from 'firebase/firestore';
import { db } from '@/lib/firebase';
import { Users, Award, Target, DollarSign, Bell, LifeBuoy, Zap, Flame, ArrowUp, MessageSquare } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

// Define a type for user data
type UserData = {
  id: string;
  createdAt: Date;
  email: string;
  isAdmin?: boolean;
  tags?: string[];
};

// Define a type for user logs
type UserLog = {
  id: string;
  userId: string;
  userEmail?: string;
  action: string;
  description: string;
  timestamp: { seconds: number; nanoseconds: number };
  metadata?: any;
};

interface StatCardProps {
  icon: React.ElementType;
  title: string;
  value: string | number;
  description: string;
  hasIncreased: boolean;
}

const LogCard = ({ log }: { log: UserLog }) => (
  <div className="bg-gray-800 p-4 rounded-lg shadow-md">
    <div className="flex items-center space-x-2">
      <Users className="h-6 w-6 text-blue-400" />
      <div>
        <h3 className="text-lg font-semibold text-white">{log.userEmail || log.userId}</h3>
        <p className="text-sm text-gray-400 font-medium">{log.action}</p>
        <p className="text-sm text-gray-400">{log.description}</p>
        <p className="text-xs text-gray-500">{new Date(log.timestamp.seconds * 1000).toLocaleString()}</p>
        {log.metadata && (
          <pre className="text-xs text-gray-500 mt-2 overflow-x-auto">
            {JSON.stringify(log.metadata, null, 2)}
          </pre>
        )}
      </div>
    </div>
  </div>
);

export function AdminOverview() {
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState({
    totalUsers: 0,
    activeUsers: 0,
    totalTasks: 0,
    totalRewards: 0,
    openTickets: 0,
  });
  const [totalCompletedTasks, setTotalCompletedTasks] = useState(0);
  const [totalUsersBalance, setTotalUsersBalance] = useState(0);
  const [totalUserSpins, setTotalUserSpins] = useState(0);
  const [totalUserStreaks, setTotalUserStreaks] = useState(0);
  const [showUpdateIndicator, setShowUpdateIndicator] = useState(false);
  const [previousStats, setPreviousStats] = useState({
    totalCompletedTasks: 0,
    totalUsersBalance: 0,
    totalUserSpins: 0,
    totalUserStreaks: 0,
    totalShoutMessages: 0,
    totalUsers: 0,
  });
  const [totalShoutMessages, setTotalShoutMessages] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [logs, setLogs] = useState<UserLog[]>([]);

  useEffect(() => {
    fetchOverviewData();
    const intervalId = setInterval(() => {
      fetchOverviewData();
    }, 10 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const usersRef = collection(db, 'users');
    
    const unsubscribe = onSnapshot(usersRef, (snapshot) => {
      const userCount = snapshot.size;
      setTotalUsers(userCount);
      
      if (userCount > previousStats.totalUsers) {
        setPreviousStats(prev => ({
          ...prev,
          totalUsers: userCount
        }));
      }
    });

    return () => unsubscribe();
  }, [previousStats.totalUsers]);

  useEffect(() => {
    const usersRef = collection(db, 'users');
    
    const unsubscribe = onSnapshot(usersRef, (snapshot) => {
      const totalBalance = snapshot.docs.reduce((total, doc) => {
        const userData = doc.data();
        const userBalance = parseFloat(userData.balance) || 0;
        return total + userBalance;
      }, 0);
      
      setTotalUsersBalance(totalBalance);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const usersRef = collection(db, 'users');
    
    const unsubscribe = onSnapshot(usersRef, (snapshot) => {
      const totalSpins = snapshot.docs.reduce((total, doc) => {
        const userData = doc.data();
        return total + (userData.totalSpins || 0);
      }, 0);
      
      setTotalUserSpins(totalSpins);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const usersRef = collection(db, 'users');
    
    const unsubscribe = onSnapshot(usersRef, (snapshot) => {
      const totalStreaks = snapshot.docs.reduce((total, doc) => {
        const userData = doc.data();
        return total + (userData.streak || 0);
      }, 0);
      
      setTotalUserStreaks(totalStreaks);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const shoutboxRef = collection(db, 'shoutbox');
    
    const unsubscribe = onSnapshot(shoutboxRef, (snapshot) => {
      const totalMessages = snapshot.size;
      setTotalShoutMessages(totalMessages);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchLogs = async () => {
      const logsRef = collection(db, 'userLogs');
      const logsSnapshot = await getDocs(logsRef);
      const logsData = logsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as UserLog[];
      setLogs(logsData);
    };

    const unsubscribe = onSnapshot(collection(db, 'userLogs'), (snapshot) => {
      const logsData = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })) as UserLog[];
      setLogs(logsData);
    });

    fetchLogs();
    return () => unsubscribe();
  }, []);

  async function fetchOverviewData() {
    try {
      const usersSnapshot = await getDocs(collection(db, 'users'));
      const tasksSnapshot = await getDocs(collection(db, 'tasks'));
      const withdrawalsSnapshot = await getDocs(collection(db, 'withdrawals'));
      const ticketsSnapshot = await getDocs(collection(db, 'tickets'));

      const totalUsers = usersSnapshot.size;
      const activeUsers = usersSnapshot.docs.filter(doc => doc.data().isActive === true).length;
      const totalTasks = tasksSnapshot.size;
      const totalRewards = withdrawalsSnapshot.docs.reduce((acc, doc) => acc + (doc.data().amount || 0), 0);
      const openTickets = ticketsSnapshot.docs.filter(doc => doc.data().status === 'open').length;

      const completedTasksCount = usersSnapshot.docs.reduce((total, doc) => {
        const userData = doc.data();
        return total + (userData.tasksCompleted || 0);
      }, 0);

      setTotalCompletedTasks(completedTasksCount);

      const totalBalance = usersSnapshot.docs.reduce((total, doc) => {
        const userData = doc.data();
        const userBalance = parseFloat(userData.balance) || 0;
        return total + userBalance;
      }, 0);

      setTotalUsersBalance(totalBalance);

      setStats({
        totalUsers,
        activeUsers,
        totalTasks,
        totalRewards,
        openTickets,
      });

      const totalSpins = usersSnapshot.docs.reduce((total, doc) => {
        const userData = doc.data();
        return total + (userData.totalSpins || 0);
      }, 0);

      setTotalUserSpins(totalSpins);

      const totalStreaks = usersSnapshot.docs.reduce((total, doc) => {
        const userData = doc.data();
        return total + (userData.streak || 0);
      }, 0);

      setTotalUserStreaks(totalStreaks);

      // Update previousStats for completed tasks
      if (completedTasksCount > previousStats.totalCompletedTasks) {
        setPreviousStats(prev => ({
          ...prev,
          totalCompletedTasks: completedTasksCount
        }));
      }
    } catch (error) {
      console.error('Failed to fetch overview data:', error);
    } finally {
      setLoading(false);
    }
  }

  const StatCard = ({ icon: Icon, title, value, description, hasIncreased }: StatCardProps) => (
    <div className="bg-gray-800 p-6 rounded-lg shadow-md relative">
      <div className="flex items-center space-x-4">
        <div className="bg-gray-700 p-3 rounded-full">
          <Icon className="h-6 w-6 text-blue-400" />
        </div>
        <div>
          <p className="text-sm text-gray-400">{title}</p>
          <div className="flex items-center">
            <p className="text-2xl font-semibold text-white">{value}</p>
            <AnimatePresence>
              {hasIncreased && showUpdateIndicator && (
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0 }}
                  className="ml-2"
                  key="update-indicator"
                >
                  <ArrowUp className="h-4 w-4 text-green-400" />
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <p className="text-xs text-gray-500">{description}</p>
        </div>
      </div>
    </div>
  );

  if (loading) {
    return <div className="text-center text-lg text-white">Loading...</div>;
  }

  return (
    <div className="space-y-8 p-6 bg-gray-900 rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold text-white">Admin Overview</h2>
      <p className="text-gray-400">Get insights into user activity, tasks, and rewards.</p>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <StatCard
          icon={Target}
          title="Total Tasks Completed"
          value={totalCompletedTasks}
          description="All completed tasks by users"
          hasIncreased={totalCompletedTasks > previousStats.totalCompletedTasks}
        />

        <StatCard
          icon={DollarSign}
          title="Total Users Balance"
          value={`$${totalUsersBalance.toFixed(8)}`}
          description="Combined balance of all users"
          hasIncreased={totalUsersBalance > previousStats.totalUsersBalance}
        />

        <StatCard
          icon={Zap}
          title="Total Spins"
          value={totalUserSpins}
          description="All spins by users"
          hasIncreased={totalUserSpins > previousStats.totalUserSpins}
        />

        <StatCard
          icon={Flame}
          title="Total Streaks"
          value={totalUserStreaks}
          description="Combined streaks of all users"
          hasIncreased={totalUserStreaks > previousStats.totalUserStreaks}
        />

        <StatCard
          icon={MessageSquare}
          title="Total Shout Messages"
          value={totalShoutMessages}
          description="Messages in the shoutbox"
          hasIncreased={totalShoutMessages > previousStats.totalShoutMessages}
        />

        <StatCard
          icon={Users}
          title="Total Users"
          value={totalUsers}
          description="Total users in the system"
          hasIncreased={totalUsers > previousStats.totalUsers}
        />

        <StatCard
          icon={Bell}
          title="Recent User Logs"
          value={logs.length}
          description="Total user activity logs"
          hasIncreased={false}
        />

        <div className="bg-gray-800 p-6 rounded-lg shadow-md">
          <div className="flex items-center space-x-4">
            <div className="bg-gray-700 p-3 rounded-full">
              <Award className="h-6 w-6 text-blue-400" />
            </div>
            <div>
              <p className="text-sm text-gray-400">Active Users</p>
              <p className="text-2xl font-semibold text-white">{stats.activeUsers}</p>
              <p className="text-xs text-gray-500">Users active in the last 24 hours.</p>
            </div>
          </div>
        </div>

        <div className="bg-gray-800 p-6 rounded-lg shadow-md">
          <div className="flex items-center space-x-4">
            <div className="bg-gray-700 p-3 rounded-full">
              <Target className="h-6 w-6 text-blue-400" />
            </div>
            <div>
              <p className="text-sm text-gray-400">Total Tasks</p>
              <p className="text-2xl font-semibold text-white">{stats.totalTasks}</p>
              <p className="text-xs text-gray-500">Total tasks created in the system.</p>
            </div>
          </div>
        </div>

        <div className="bg-gray-800 p-6 rounded-lg shadow-md">
          <div className="flex items-center space-x-4">
            <div className="bg-gray-700 p-3 rounded-full">
              <DollarSign className="h-6 w-6 text-blue-400" />
            </div>
            <div>
              <p className="text-sm text-gray-400">Total Rewards</p>
              <p className="text-2xl font-semibold text-white">${stats.totalRewards}</p>
              <p className="text-xs text-gray-500">Total rewards distributed to users.</p>
            </div>
          </div>
        </div>

        <div className="bg-gray-800 p-6 rounded-lg shadow-md">
          <div className="flex items-center space-x-4">
            <div className="bg-gray-700 p-3 rounded-full">
              <LifeBuoy className="h-6 w-6 text-blue-400" />
            </div>
            <div>
              <p className="text-sm text-gray-400">Open Tickets</p>
              <p className="text-2xl font-semibold text-white">{stats.openTickets}</p>
              <p className="text-xs text-gray-500">Active support tickets.</p>
            </div>
          </div>
        </div>
      </div>

      {/* User Logs Section */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {logs.map(log => (
          <LogCard key={log.id} log={log} />
        ))}
      </div>
    </div>
  );
}