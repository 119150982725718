import { Routes, Route } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './lib/firebase';
import { ProtectedRoute } from './components/auth/ProtectedRoute';
import { AdminRoute } from './components/auth/AdminRoute';
import { LandingPage } from './pages/LandingPage';
import { Dashboard } from './pages/Dashboard';
import { AdminDashboard } from './pages/admin/AdminDashboard';
import { Login } from './pages/auth/Login';
import { Register } from './pages/auth/Register';
import { LoadingScreen } from './components/shared/LoadingScreen';
import { VerificationPending } from '@/pages/auth/VerificationPending';
import { VerifyEmail } from '@/pages/auth/VerifyEmail';

export default function App() {
  const [user, loading] = useAuthState(auth);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      
      <Route element={<ProtectedRoute user={user} />}>
        <Route path="/dashboard" element={<Dashboard />} />
      </Route>

      <Route element={<AdminRoute user={user} />}>
        <Route path="/admin/*" element={<AdminDashboard />} />
      </Route>

      <Route path="/verification-pending" element={<VerificationPending />} />
      <Route path="/verify-email" element={<VerifyEmail />} />
    </Routes>
  );
}