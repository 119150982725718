import { ArrowRight } from 'lucide-react';
import { Button } from '@/components/ui/Button';

interface NewsSectionProps {
  news: any[]; // Array of news articles
  onReadMore: (news: any) => void; // Function to handle read more action
}

export function NewsSection({ news, onReadMore }: NewsSectionProps) {
  // Limit to a maximum of 3 news articles
  const limitedNews = news.slice(0, 3);

  return (
    <div className="space-y-4">
      {limitedNews.length > 0 ? (
        limitedNews.map((item) => {
          const formattedDate = item.date ? new Date(item.date).toLocaleDateString() : 'Unknown Date';

          return (
            <div
              key={item.id}
              className="bg-gray-800/50 p-4 rounded-lg border border-gray-700/50 hover:border-purple-500/50 transition-colors"
            >
              <div className="flex items-center justify-between mb-2">
                <span className="text-xs text-purple-400 font-medium">
                  {item.tag || "General"}
                </span>
                <span className="text-xs text-gray-400">
                  {formattedDate}
                </span>
              </div>
              <h4 className="font-medium mb-2">{item.title}</h4>
              <p className="text-sm text-gray-300 mb-2">By: {item.author || "Unknown Author"}</p>
              
              {/* Flex container for Read More and Views */}
              <div className="flex justify-between items-center mt-4">
                <Button 
                  variant="ghost" 
                  size="sm" 
                  className="text-sm"
                  onClick={() => onReadMore(item)} // Call the updated onReadMore function
                >
                  Read More
                  <ArrowRight className="h-4 w-4 ml-2" />
                </Button>
                <p className="text-sm text-gray-400">Views: {item.views || 0}</p> {/* Display view count */}
              </div>
            </div>
          );
        })
      ) : (
        <p className="text-gray-400">No news articles available.</p>
      )}
    </div>
  );
}