import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Ticket, Clipboard } from 'lucide-react';
import { Button } from '@/components/ui/Button';
import { db } from '@/lib/firebase'; // Import Firestore
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import Confetti from 'react-confetti'; // Import a confetti library
import { toast } from 'react-hot-toast'; // Import toast for notifications

interface RewardsPopupProps {
  isOpen: boolean;
  onClose: () => void;
  userId: string; // Add userId to track who is entering the raffle
  ticketPrice: number; // Add ticketPrice to props
}

interface Raffle {
  id: string;
  title: string;
  description: string; // Add description to the Raffle interface
  ticketCost: number; // Change to represent dollar amount
  entries: number; // Number of entries loaded from Firestore
  soldTickets: number;
  endDate: string; // End date loaded from Firestore
  createdAt: string; // Add createdAt to the Raffle interface
  status: string; // Add status to the Raffle interface
  ticketPrice: number; // Add ticketPrice to the Raffle interface
}

export function RewardsPopup({ isOpen, onClose, userId, ticketPrice }: RewardsPopupProps) {
  const [activeTab, setActiveTab] = useState<'raffle' | 'prizes'>('raffle');
  const [raffles, setRaffles] = useState<Raffle[]>([]); // State to store raffles
  const [showEntryPopup, setShowEntryPopup] = useState(false); // State for entry confirmation popup
  const [showCongratsPopup, setShowCongratsPopup] = useState(false);
  const [currentRaffle, setCurrentRaffle] = useState<Raffle | null>(null); // State to store the current raffle details
  const [transactionLink, setTransactionLink] = useState<string>('');

  const solanaAddress = "3FUX7BHaY43LT1dJE22bPjHKXJhZRnX1wXjstHhUGo9K";

  useEffect(() => {
    const fetchRaffles = async () => {
      try {
        const rafflesCollection = collection(db, 'raffles');
        const raffleSnapshot = await getDocs(rafflesCollection);
        const raffleList = raffleSnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() } as Raffle)) // Cast to Raffle type
          .filter(raffle => raffle.status === 'active'); // Only show active raffles
        setRaffles(raffleList); // Set the fetched raffles to state
      } catch (error) {
        console.error('Error fetching raffles:', error);
        toast.error('Failed to load raffles. Please try again later.');
      }
    };

    fetchRaffles();
  }, []); // Empty dependency array to run once on mount

  const handleEnterRaffle = async (raffleId: string) => {
    console.log('Entering raffle with ID:', raffleId);
    const raffle = raffles.find(r => r.id === raffleId);
    
    if (!raffle) {
      toast.error('Raffle not found.');
      return;
    }

    // Check if user has already entered
    const userHasEntered = await checkUserEntry(userId, raffleId);
    if (userHasEntered) {
      toast.error('You have already entered this raffle.');
      return;
    }

    // Check payment status
    const paymentConfirmed = await checkPaymentStatus(userId, raffleId);

    if (!paymentConfirmed) {
      toast.error('Payment not confirmed. Please complete your payment to enter the raffle.');
      return;
    }

    setCurrentRaffle(raffle);
    setShowEntryPopup(true);
  };

  const checkUserEntry = async (userId: string, raffleId: string): Promise<boolean> => {
    // Implement logic to check if the user has already entered the raffle
    // This could involve querying a collection that tracks user entries
    // Placeholder return for demonstration
    return false; // Change this to actual check logic
  };

  const checkPaymentStatus = async (userId: string, raffleId: string): Promise<boolean> => {
    // Implement your logic to check if the payment has been made
    // Placeholder return for demonstration
    return true; // Change this to actual payment check logic
  };

  const handleTransactionSubmit = () => {
    if (transactionLink) {
      toast.success('Transaction link submitted successfully!');
      setShowEntryPopup(false);
      setShowCongratsPopup(true);
    } else {
      toast.error('Please provide a valid transaction link.');
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black"
            onClick={onClose}
          />
          <motion.div
            initial={{ opacity: 0, scale: 0.95, y: 20 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.95, y: 20 }}
            className="fixed inset-0 flex items-center justify-center p-4 pointer-events-none"
          >
            <div className="bg-gray-900 p-6 rounded-xl shadow-xl max-w-md w-full pointer-events-auto border border-gray-800">
              <div className="flex items-center justify-between mb-6">
                <div>
                  <h3 className="text-xl font-bold">Raffles</h3>
                </div>
                <Button variant="ghost" size="sm" onClick={onClose}>
                  <X className="h-5 w-5" />
                </Button>
              </div>

              <div className="flex space-x-2 mb-6">
                <Button
                  variant={activeTab === 'raffle' ? 'default' : 'ghost'}
                  onClick={() => setActiveTab('raffle')}
                >
                  Active Raffles
                </Button>
                <Button
                  variant={activeTab === 'prizes' ? 'default' : 'ghost'}
                  onClick={() => setActiveTab('prizes')}
                >
                  Prize Collection
                </Button>
              </div>

              {activeTab === 'raffle' ? (
                <div className="space-y-4">
                  {raffles.map((raffle) => (
                    <div key={raffle.id} className="bg-gray-800/50 p-4 rounded-lg shadow-md flex flex-col sm:flex-row items-start">
                      <Ticket className="h-8 w-8 text-purple-400 mr-4 mb-2 sm:mb-0" />
                      <div className="flex-1">
                        <div className="flex items-center justify-between mb-2">
                          <h4 className="font-semibold text-lg">{raffle.title}</h4>
                          <span className="text-sm text-purple-400">
                            ${raffle.ticketPrice}
                          </span>
                        </div>
                        <p className="text-sm text-gray-400 mb-2">{raffle.description}</p>
                        <div className="flex items-center justify-between text-sm text-gray-400">
                          <span>({raffle.entries}) entries</span>
                          <span>Ends {raffle.endDate ? new Date(raffle.endDate).toLocaleDateString() : 'N/A'}</span>
                        </div>
                        <div className="text-sm text-gray-400 mb-2 flex items-center">
                          <span className="mr-2">Deposit to:</span>
                          <span className="text-white font-mono truncate w-48">{solanaAddress}</span>
                          <Button variant="ghost" size="sm" onClick={() => navigator.clipboard.writeText(solanaAddress)}>
                            <Clipboard className="h-4 w-4 text-white" />
                          </Button>
                        </div>
                        <Button className="w-full mt-4 bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600 text-sm" onClick={() => handleEnterRaffle(raffle.id)}>
                          Enter Raffle
                        </Button>
                      </div>
                    </div>
                  ))}
                  <div className="bg-gray-800/50 p-4 rounded-lg shadow-md mt-4">
                    <p className="text-sm text-gray-400">
                      <strong>Note:</strong> When you join the raffle, all payments will be manually checked by our staff team. Misleading or joining the raffle without payment will result in a permanent ban from the website.
                    </p>
                  </div>
                </div>
              ) : (
                <div className="grid grid-cols-2 gap-4">
                  {/* Prize collection logic here */}
                </div>
              )}
            </div>
          </motion.div>

          {/* Transaction Link Popup */}
          <AnimatePresence>
            {showEntryPopup && currentRaffle && (
              <motion.div
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
                className="fixed inset-0 flex items-center justify-center p-4 pointer-events-none"
              >
                <div className="bg-gray-900 p-6 rounded-xl shadow-xl max-w-md w-full pointer-events-auto border border-gray-800">
                  <h3 className="text-xl font-bold mb-4 text-white">Enter Raffle</h3>
                  <p className="text-gray-300 mb-2">Please provide your Solscan transaction link:</p>
                  <input
                    type="text"
                    placeholder="Enter Solscan transaction link"
                    value={transactionLink}
                    onChange={(e) => setTransactionLink(e.target.value)}
                    className="w-full p-2 mt-4 bg-gray-800 text-white rounded"
                  />
                  <Button className="w-full mt-2 bg-gradient-to-r from-green-500 to-blue-500 hover:from-green-600 hover:to-blue-600" onClick={handleTransactionSubmit}>
                    Submit Transaction Link
                  </Button>
                  <Button variant="ghost" onClick={() => setShowEntryPopup(false)}>
                    Close
                  </Button>
                </div>
              </motion.div>
            )}
          </AnimatePresence>

          {/* Congratulations Popup */}
          <AnimatePresence>
            {showCongratsPopup && currentRaffle && (
              <motion.div
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
                className="fixed inset-0 flex items-center justify-center p-4 pointer-events-none"
              >
                <div className="bg-gray-900 p-6 rounded-xl shadow-xl max-w-md w-full pointer-events-auto border border-gray-800">
                  <Confetti />
                  <h3 className="text-xl font-bold mb-4 text-white">Congratulations!</h3>
                  <p className="text-gray-300 mb-2">You have successfully joined the raffle:</p>
                  <h4 className="font-semibold text-white">{currentRaffle.title}</h4>
                  <p className="text-sm text-gray-400">Total Entries: {currentRaffle.entries + 1}</p>
                  <p className="text-sm text-gray-400">Date Joined: {new Date().toLocaleDateString()}</p>
                  <p className="text-sm text-gray-400">Ends: {new Date(currentRaffle.endDate).toLocaleDateString()}</p>
                  <p className="text-sm text-gray-400">Created At: {currentRaffle.createdAt ? new Date(currentRaffle.createdAt).toLocaleDateString() : 'N/A'}</p>
                  <Button variant="ghost" onClick={() => setShowCongratsPopup(false)}>
                    Close
                  </Button>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </>
      )}
    </AnimatePresence>
  );
}