import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, ArrowRight, Clock, ChevronRight, TrendingUp, History, AlertCircle, DollarSign } from 'lucide-react';
import { Button } from '@/components/ui/Button';
import { toast } from 'react-hot-toast';
import { addDoc, collection, onSnapshot, doc, updateDoc, getDocs } from 'firebase/firestore';
import { auth, db } from '@/lib/firebase';

// Define the Transaction interface
interface Transaction {
  id: string; // Added id for the transaction
  amount: number; // or string, depending on your data type
  createdAt: string;
  status: 'pending' | 'approved' | 'rejected'; // Added status field
  transactionLink?: string; // Add transaction link field
  userId: string; // Add this line
}

// Define the WalletPopoverProps interface
interface WalletPopoverProps {
  balance: number; // Added balance prop
  streak: number;  // Added streak prop
}

export const WalletPopover: React.FC<WalletPopoverProps> = ({ balance, streak }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showWithdraw, setShowWithdraw] = useState(false);
  const [solanaAddress, setSolanaAddress] = useState('');
  const [withdrawAmount, setWithdrawAmount] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [transactionHistory, setTransactionHistory] = useState<Transaction[]>([]);
  const [showTransactionHistory, setShowTransactionHistory] = useState(false);
  const [userStreak, setUserStreak] = useState(streak); // Initialize with prop
  const [currentBalance, setCurrentBalance] = useState(balance); // Track current balance
  const [solToUsdRate, setSolToUsdRate] = useState(0); // State for SOL to USD rate
  const [averageIncome, setAverageIncome] = useState(0); // State for average income
  const [weeklyIncome, setWeeklyIncome] = useState(0); // State for weekly income
  const [transactionLink, setTransactionLink] = useState(''); // State for transaction link

  const canWithdraw = userStreak >= 7;

  const fetchSolToUsdRate = async () => {
    try {
      const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=solana&vs_currencies=usd');
      const data = await response.json();
      setSolToUsdRate(data.solana.usd); // Set the SOL to USD rate
    } catch (error) {
      console.error('Error fetching SOL to USD rate:', error);
      toast.error('Failed to fetch exchange rate');
    }
  };

  const fetchTransactionHistory = async () => {
    const userId = auth.currentUser?.uid;
    if (!userId) return;

    const historySnapshot = await getDocs(collection(db, 'withdrawals'));
    const historyData = historySnapshot.docs
        .map(doc => ({
            id: doc.id,
            ...doc.data(),
        })) as Transaction[];

    // Filter to only include transactions for the current user
    const userHistory = historyData.filter(transaction => transaction.userId === userId);

    // Sort transactions by createdAt in descending order
    const sortedHistory = userHistory.sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
    setTransactionHistory(sortedHistory);

    // Calculate weekly income and average income
    calculateIncome(sortedHistory);
  };

  const calculateIncome = (transactions: Transaction[]) => {
    const now = new Date();
    const weekAgo = new Date(now);
    weekAgo.setDate(now.getDate() - 7);

    const weeklyTransactions = transactions.filter(transaction => new Date(transaction.createdAt) >= weekAgo);
    const totalWeeklyIncome = weeklyTransactions.reduce((acc, transaction) => acc + transaction.amount, 0);
    setWeeklyIncome(totalWeeklyIncome);

    const totalIncome = transactions.reduce((acc, transaction) => acc + transaction.amount, 0);
    const averageIncome = transactions.length > 0 ? totalIncome / transactions.length : 0;
    setAverageIncome(averageIncome);
  };

  const subscribeToUserData = () => {
    const userId = auth.currentUser?.uid;
    if (!userId) {
      console.error('User is not authenticated');
      return;
    }

    const userDocRef = doc(db, 'users', userId);
    return onSnapshot(userDocRef, (doc) => {
      if (doc.exists()) {
        const userData = doc.data();
        setUserStreak(userData.streak || 0); // Update streak from Firestore
        setCurrentBalance(userData.balance || 0); // Update balance from Firestore
      } else {
        console.error('User document does not exist');
      }
    });
  };

  const handleWithdraw = async () => {
    if (!solanaAddress || !withdrawAmount) {
      toast.error('Please fill in all fields');
      return;
    }

    if (!canWithdraw) {
      toast.error('You need a 7-day streak to withdraw');
      return;
    }

    const amount = parseFloat(withdrawAmount);
    if (amount > currentBalance) {
      toast.error('Insufficient balance');
      return;
    }

    if (amount < 0.001) {
      toast.error('Minimum withdrawal amount is 0.001 SOL');
      return;
    }

    setIsSubmitting(true);
    try {
      // Add withdrawal request to Firestore
      await addDoc(collection(db, 'withdrawals'), {
        userId: auth.currentUser?.uid,
        userEmail: auth.currentUser?.email,
        amount,
        solanaAddress,
        status: 'pending',
        createdAt: new Date().toISOString(),
      });

      // Update balance in Firestore
      const userId = auth.currentUser?.uid;
      if (!userId) {
        console.error('User is not authenticated');
        return; // Exit if user is not authenticated
      }
      const userDocRef = doc(db, 'users', userId);
      await updateDoc(userDocRef, {
        balance: currentBalance - amount, // Update the balance
      });

      // Update local balance state
      setCurrentBalance((prev) => prev - amount);
      toast.success('Withdrawal request submitted successfully');
      setShowWithdraw(false);
      setSolanaAddress('');
      setWithdrawAmount('');
      fetchTransactionHistory(); // Refresh transaction history after withdrawal
    } catch (error) {
      console.error('Error submitting withdrawal:', error);
      toast.error('Failed to submit withdrawal request');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleApproveWithdrawal = async (withdrawalId: string) => {
    if (!transactionLink) {
      toast.error('Transaction link is required for approval.');
      return;
    }

    try {
      const withdrawalDoc = doc(db, 'withdrawals', withdrawalId);
      await updateDoc(withdrawalDoc, {
        status: 'approved',
        transactionLink, // Store the transaction link
      });

      toast.success('Withdrawal approved successfully');
      setTransactionLink(''); // Clear the transaction link
      fetchTransactionHistory(); // Refresh transaction history
    } catch (error) {
      console.error('Error approving withdrawal:', error);
      toast.error('Failed to approve withdrawal');
    }
  };

  useEffect(() => {
    fetchSolToUsdRate(); // Fetch the SOL to USD rate on component mount
    const unsubscribe = subscribeToUserData(); // Subscribe to user data changes
    fetchTransactionHistory();

    return () => {
      if (unsubscribe) {
        unsubscribe(); // Clean up the subscription on unmount
      }
    };
  }, []);

  return (
    <div className="relative">
      <Button
        variant="ghost"
        size="sm"
        onClick={() => setIsOpen(!isOpen)}
        className="relative flex items-center"
      >
        <DollarSign className="h-5 w-5 mr-2" /> {/* Dollar icon only */}
      </Button>

      <AnimatePresence>
        {isOpen && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/20 z-40"
              onClick={() => setIsOpen(false)}
            />
            <motion.div
              initial={{ opacity: 0, scale: 0.95, y: 10 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.95, y: 10 }}
              className="absolute right-0 mt-2 w-96 bg-gradient-to-br from-gray-900 to-gray-800 rounded-lg shadow-xl overflow-hidden z-50 border border-gray-700"
            >
              <div className="p-6">
                <div className="flex items-center justify-between mb-6">
                  <div>
                    <h3 className="text-xl font-bold">Wallet</h3>
                    <p className="text-sm text-gray-400">Available Balance</p>
                  </div>
                  <Button variant="ghost" size="sm" onClick={() => setIsOpen(false)}>
                    <X className="h-5 w-5" />
                  </Button>
                </div>

                <div className="bg-gradient-to-br from-purple-500/10 to-pink-500/10 p-6 rounded-lg border border-purple-500/20 mb-6">
                  <p className="text-3xl font-bold mb-4">
                    {currentBalance.toFixed(3)} SOL {/* Show full SOL amount */}
                  </p>
                  <div className="flex items-center text-sm text-gray-400">
                    <TrendingUp className="h-4 w-4 mr-2 text-green-400" />
                    <span>+${(weeklyIncome * solToUsdRate).toFixed(2)} this week</span> {/* Show weekly income in USD */}
                  </div>
                </div>

                {/* Average Income Section */}
                <div className="bg-gray-800/50 p-4 rounded-lg mb-4">
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-sm font-medium">Average Income</span>
                    <span className="text-sm text-gray-400">${averageIncome.toFixed(2)}</span>
                  </div>
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-sm font-medium">Weekly Income</span>
                    <span className="text-sm text-gray-400">${weeklyIncome.toFixed(2)}</span>
                  </div>
                </div>

                {/* Streak Progress */}
                <div className="bg-gray-800/50 p-4 rounded-lg mb-4">
                  <div className="flex items-center justify-between mb-2">
                    <span className="text-sm font-medium">Streak Progress</span>
                    <span className="text-sm text-gray-400">{userStreak}/7 days</span>
                  </div>
                  <div className="h-2 bg-gray-700 rounded-full overflow-hidden">
                    <div 
                      className="h-full bg-gradient-to-r from-purple-500 to-pink-500 transition-all duration-300"
                      style={{ width: `${(userStreak / 7) * 100}%` }}
                    />
                  </div>
                  {!canWithdraw && (
                    <div className="flex items-center mt-2 text-sm text-yellow-400">
                      <AlertCircle className="h-4 w-4 mr-1" />
                      <span>{7 - userStreak} more days needed to withdraw</span>
                    </div>
                  )}
                </div>

                <div className="space-y-4">
                  <button
                    onClick={() => setShowWithdraw(true)}
                    disabled={!canWithdraw}
                    className="w-full flex items-center justify-between p-4 bg-gray-800/50 rounded-lg hover:bg-gray-700/50 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <div className="flex items-center">
                      <div className="bg-purple-500/20 p-2 rounded-lg mr-3">
                        <ArrowRight className="h-5 w-5 text-purple-400" />
                      </div>
                      <div className="text-left">
                        <p className="font-medium">Withdraw Funds</p>
                        <p className="text-sm text-gray-400">Transfer to Solana wallet</p>
                      </div>
                    </div>
                    <ChevronRight className="h-5 w-5 text-gray-400" />
                  </button>

                  <button className="w-full flex items-center justify-between p-4 bg-gray-800/50 rounded-lg hover:bg-gray-700/50 transition-colors" onClick={() => setShowTransactionHistory(true)}>
                    <div className="flex items-center">
                      <div className="bg-purple-500/20 p-2 rounded-lg mr-3">
                        <History className="h-5 w-5 text-purple-400" />
                      </div>
                      <div className="text-left">
                        <p className="font-medium">Transaction History</p>
                        <p className="text-sm text-gray-400">View all transactions</p>
                      </div>
                    </div>
                    <ChevronRight className="h-5 w-5 text-gray-400" />
                  </button>
                </div>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showWithdraw && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              className="bg-gradient-to-br from-gray-900 to-gray-800 p-6 rounded-xl max-w-md w-full mx-4 border border-gray-700"
            >
              <div className="flex items-center justify-between mb-6">
                <h3 className="text-xl font-bold">Withdraw Funds</h3>
                <Button variant="ghost" size="sm" onClick={() => setShowWithdraw(false)}>
                  <X className="h-5 w-5" />
                </Button>
              </div>

              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium mb-1">
                    Solana Address
                  </label>
                  <input
                    type="text"
                    value={solanaAddress}
                    onChange={(e) => setSolanaAddress(e.target.value)}
                    className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg focus:border-purple-500 transition-colors"
                    placeholder="Enter your Solana address"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium mb-1">
                    Amount (SOL)
                  </label>
                  <input
                    type="number"
                    value={withdrawAmount}
                    onChange={(e) => setWithdrawAmount(e.target.value)}
                    className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg focus:border-purple-500 transition-colors"
                    placeholder="Enter amount"
                    min="0.001"
                    max={currentBalance}
                    step="0.001"
                  />
                </div>

                <div className="bg-gray-800/50 p-4 rounded-lg space-y-2 text-sm text-gray-400">
                  <p className="flex items-center">
                    <Clock className="h-4 w-4 mr-2" />
                    Processing time: Less than 24 hours
                  </p>
                  <p>• Minimum withdrawal: 0.001 SOL</p>
                  <p>• Requires 7-day task completion streak</p>
                </div>

                <div className="flex space-x-3">
                  <Button
                    onClick={handleWithdraw}
                    disabled={isSubmitting || !canWithdraw}
                    className="flex-1"
                  >
                    {isSubmitting ? 'Processing...' : 'Withdraw'}
                  </Button>
                  <Button
                    variant="ghost"
                    onClick={() => setShowWithdraw(false)}
                    className="flex-1"
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showTransactionHistory && (
          <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              className="bg-gradient-to-br from-gray-900 to-gray-800 p-6 rounded-xl max-w-md w-full mx-4 border border-gray-700"
            >
              <div className="flex items-center justify-between mb-6">
                <h3 className="text-xl font-bold">Transaction History</h3>
                <Button variant="ghost" size="sm" onClick={() => setShowTransactionHistory(false)}>
                  <X className="h-5 w-5" />
                </Button>
              </div>

              <div className="space-y-4">
                {transactionHistory.map((transaction) => (
                  <div key={transaction.id} className="p-4 bg-gray-800 rounded-lg mb-2 flex items-center justify-between">
                    <div className="flex items-center">
                      <DollarSign className="h-5 w-5 text-purple-400 mr-2" />
                      <div>
                        <p className="font-medium">{transaction.amount} SOL</p>
                        <p className="text-sm text-gray-400">{transaction.createdAt}</p>
                        {transaction.transactionLink && (
                          <a href={transaction.transactionLink} target="_blank" rel="noopener noreferrer" className="text-blue-400 text-sm">
                            View Transaction
                          </a>
                        )}
                      </div>
                    </div>
                    <span className={`text-sm ${transaction.status === 'pending' ? 'text-yellow-400' : transaction.status === 'approved' ? 'text-green-400' : 'text-red-400'}`}>
                      {transaction.status.charAt(0).toUpperCase() + transaction.status.slice(1)} {/* Capitalize the first letter */}
                    </span>
                  </div>
                ))}
              </div>
            </motion.div>
          </div>
        )}
      </AnimatePresence>
    </div>
  );
};