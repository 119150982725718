import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'

interface EmojiPickerProps {
  onEmojiSelect: (emoji: string) => void;
}

export const EmojiPicker: React.FC<EmojiPickerProps> = ({ onEmojiSelect }) => {
  return (
    <div className="absolute bottom-full mb-2 shadow-lg rounded-lg overflow-hidden z-50">
      <Picker 
        data={data} 
        onEmojiSelect={(emoji: any) => onEmojiSelect(emoji.native)}
        theme="dark"
        previewPosition="none"
        skinTonePosition="none"
        searchPosition="none"
        maxFrequentRows={2}
        perLine={8}
        emojiSize={20}
        emojiButtonSize={28}
        navPosition="none"
      />
    </div>
  );
};