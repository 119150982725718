import React, { useState, useEffect } from 'react';
import { collection, addDoc, query, orderBy, onSnapshot, Timestamp, DocumentData, where, updateDoc, doc } from 'firebase/firestore';
import { db } from '@/lib/firebase';
import { Button } from '@/components/ui/Button';
import { 
  X, 
  TicketIcon, 
  Tag, 
  MessageCircle,
  PenSquare,
  Send,
  Clock,
  AlertCircle,
  MessageSquare,
} from 'lucide-react';
import { toast } from 'react-hot-toast';

interface TicketSupportProps {
  isOpen: boolean;
  onClose: () => void;
  user: any;
}

interface Ticket extends DocumentData {
  id: string;
  title: string;
  description: string;
  category: string;
  userId: string;
  userEmail: string;
  createdAt: Timestamp;
  status: 'open' | 'in-progress' | 'resolved';
  replies: Reply[];
  hasNewReply: boolean;
}

interface Reply {
  id: string;
  content: string;
  userId: string;
  userEmail: string;
  isStaff: boolean;
  createdAt: Timestamp;
}

const CATEGORIES = ['Technical Issue', 'Account Problem', 'Billing', 'Feature Request', 'Other'];

export function TicketSupport({ isOpen, onClose, user }: TicketSupportProps) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('Technical Issue');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!title.trim() || !description.trim()) {
      toast.error('Please fill in all fields');
      return;
    }

    setIsSubmitting(true);

    try {
      const ticketData = {
        title: title.trim(),
        description: description.trim(),
        category,
        userId: user.uid,
        userEmail: user.email,
        createdAt: Timestamp.now(),
        status: 'open',
        replies: [],
        hasNewReply: false
      };

      await addDoc(collection(db, 'tickets'), ticketData);

      setTitle('');
      setDescription('');
      setCategory('Technical Issue');
      toast.success('Ticket created successfully!');
    } catch (error) {
      console.error('Error creating ticket:', error);
      toast.error('Failed to create ticket');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/80 flex items-center justify-center z-50 backdrop-blur-sm">
      <div className="bg-gray-900 rounded-xl p-6 w-full max-w-xl max-h-[80vh] overflow-y-auto border border-gray-800">
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center gap-2">
            <TicketIcon className="h-6 w-6 text-purple-400" />
            <h2 className="text-xl font-bold text-white">Support Tickets</h2>
          </div>
          <Button 
            variant="ghost" 
            size="sm" 
            onClick={onClose}
            className="hover:bg-gray-800 rounded-full p-2"
          >
            <X className="h-5 w-5 text-gray-400 hover:text-white transition-colors" />
          </Button>
        </div>

        <form onSubmit={handleSubmit} className="mb-6 space-y-4 bg-gray-800/50 p-4 rounded-xl border border-gray-700/50">
          <div className="flex items-center gap-2 mb-2">
            <PenSquare className="h-5 w-5 text-purple-400" />
            <h3 className="text-lg font-semibold text-white">Create New Ticket</h3>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-200 mb-1">
              Title <span className="text-red-400">*</span>
            </label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full px-3 py-2 bg-gray-700 rounded-lg text-white border border-gray-600 focus:border-purple-500 focus:ring-1 focus:ring-purple-500"
              placeholder="Brief description of your issue"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-200 mb-1">
              Description <span className="text-red-400">*</span>
            </label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full px-3 py-2 bg-gray-700 rounded-lg text-white border border-gray-600 focus:border-purple-500 focus:ring-1 focus:ring-purple-500"
              rows={3}
              placeholder="Provide detailed information about your issue..."
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-200 mb-1">
              Category
            </label>
            <div className="flex items-center gap-2">
              <Tag className="h-4 w-4 text-purple-400" />
              <select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
                className="w-full px-3 py-2 bg-gray-700 rounded-lg text-white border border-gray-600 focus:border-purple-500 focus:ring-1 focus:ring-purple-500"
              >
                {CATEGORIES.map((cat) => (
                  <option key={cat} value={cat}>{cat}</option>
                ))}
              </select>
            </div>
          </div>

          <Button 
            type="submit" 
            className="w-full flex items-center justify-center gap-2 bg-purple-600 hover:bg-purple-700"
            disabled={isSubmitting}
          >
            <Send className="h-4 w-4" />
            {isSubmitting ? 'Creating...' : 'Create Ticket'}
          </Button>
        </form>
      </div>
    </div>
  );
}