import React, { useEffect, useState } from 'react';
import { doc, onSnapshot, getDoc, setDoc } from 'firebase/firestore';
import { db } from '@/lib/firebase';
import { Users, TrendingUp, DollarSign, X } from 'lucide-react';
import { toast } from 'react-hot-toast';

interface ReferralStats {
  totalReferrals: number;
  activeReferrals: number;
  earnings: number;
  referralsList?: {
    userId: string;
    email: string;
    joinedAt: Date;
  }[];
}

export const ReferralDashboard: React.FC<{ userId: string; onClose: () => void }> = ({ userId, onClose }) => {
  const [referralStats, setReferralStats] = useState<ReferralStats>({
    totalReferrals: 0,
    activeReferrals: 0,
    earnings: 0,
  });

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, 'users', userId), (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        setReferralStats({
          totalReferrals: data.totalReferrals || 0,
          activeReferrals: data.activeReferrals || 0,
          earnings: data.earnings || 0,
          referralsList: data.referralsList || [],
        });
      } else {
        toast.error('User data not found.');
      }
    });

    return () => unsubscribe();
  }, [userId]);

  const handleShare = () => {
    const tweet = `Join me on Dailysolly.eu! 🌟 Please use my referral code: ${userId} when you register and earn rewards in Solana! 🚀 #Solana #Crypto`;
    const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(tweet)}`;
    window.open(url, '_blank');
  };

  const updateReferrerStats = async (referrerId: string, newUserData: { userId: string, email: string }) => {
    try {
      const referrerRef = doc(db, 'users', referrerId);
      const referrerDoc = await getDoc(referrerRef);

      if (!referrerDoc.exists()) {
        throw new Error('Invalid referral code');
      }

      await setDoc(referrerRef, {
        totalReferrals: (referrerDoc.data().totalReferrals || 0) + 1,
        activeReferrals: (referrerDoc.data().activeReferrals || 0) + 1,
        earnings: (referrerDoc.data().earnings || 0) + 0.10,
        referralsList: [
          ...(referrerDoc.data().referralsList || []),
          {
            userId: newUserData.userId,
            email: newUserData.email,
            joinedAt: new Date()
          }
        ]
      }, { merge: true });

      return true;
    } catch (error) {
      console.error('Error updating referrer stats:', error);
      return false;
    }
  };

  return (
    <div className="bg-gray-900 p-6 rounded-lg shadow-lg max-w-lg w-full relative">
      <button onClick={onClose} className="absolute top-2 right-2 text-gray-400 hover:text-white">
        <X className="h-6 w-6" />
      </button>
      <h2 className="text-2xl font-bold text-white mb-4">Your Referral Program</h2>
      <div className="mb-4">
        <label className="block text-sm text-gray-400 mb-2">Your Referral Code</label>
        <div className="flex items-center bg-gray-800 border border-gray-700 rounded-md px-3 py-2">
          <input
            type="text"
            value={userId}
            readOnly
            className="w-full bg-transparent text-white focus:outline-none"
          />
          <button onClick={handleShare} className="ml-2 text-purple-400 hover:text-purple-300">Share</button>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-4">
        <div className="bg-gray-800 p-4 rounded-lg flex flex-col items-center">
          <Users className="h-6 w-6 text-purple-400 mb-2" />
          <h3 className="text-lg font-semibold text-white">{referralStats.totalReferrals}</h3>
          <p className="text-sm text-gray-400">Total Referrals</p>
        </div>
        <div className="bg-gray-800 p-4 rounded-lg flex flex-col items-center">
          <TrendingUp className="h-6 w-6 text-purple-400 mb-2" />
          <h3 className="text-lg font-semibold text-white">{referralStats.activeReferrals}</h3>
          <p className="text-sm text-gray-400">Active Users</p>
        </div>
        <div className="bg-gray-800 p-4 rounded-lg flex flex-col items-center">
          <DollarSign className="h-6 w-6 text-purple-400 mb-2" />
          <h3 className="text-lg font-semibold text-white">${referralStats.earnings.toFixed(2)}</h3>
          <p className="text-sm text-gray-400">Total Earnings</p>
        </div>
      </div>
      <div className="bg-gray-800 p-4 rounded-lg mb-4">
        <h4 className="text-lg font-semibold text-white mb-2">How it Works</h4>
        <ul className="text-gray-400 text-sm space-y-1">
          <li className="flex items-start">
            <span className="text-purple-400 mr-2">•</span>
            <span>Earn 10% of your referrals' earnings</span>
          </li>
          <li className="flex items-start">
            <span className="text-purple-400 mr-2">•</span>
            <span>Get bonus points for active referrals</span>
          </li>
          <li className="flex items-start">
            <span className="text-purple-400 mr-2">•</span>
            <span>Special rewards for milestone referrals</span>
          </li>
          <li className="flex items-start">
            <span className="text-purple-400 mr-2">•</span>
            <span>Earn $0.00042 for each new member you bring</span>
          </li>
        </ul>
      </div>
      {referralStats.referralsList && referralStats.referralsList.length > 0 && (
        <div className="bg-gray-800 p-4 rounded-lg">
          <h4 className="text-lg font-semibold text-white mb-2">Recent Referrals</h4>
          <div className="space-y-2">
            {referralStats.referralsList.map((referral, index) => (
              <div key={referral.userId} className="flex justify-between items-center text-sm">
                <span className="text-gray-400">
                  {referral.email.charAt(0)}*****{referral.email.split('@')[0].slice(-2)}@{referral.email.split('@')[1]}
                </span>
                <span className="text-gray-500">
                  {new Date(referral.joinedAt).toLocaleDateString()}
                </span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};