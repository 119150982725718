import { useState, useEffect } from 'react';
import { collection, query, orderBy, getDocs, doc, updateDoc, onSnapshot, deleteDoc } from 'firebase/firestore';
import { auth, db } from '@/lib/firebase';
import { Button } from '@/components/ui/Button';
import { toast } from 'react-hot-toast';
import { Notification } from '@/components/dashboard/NotificationsPopover'; // Import Notification type

interface WithdrawalRequest {
  id: string;
  userId: string;
  userEmail: string;
  amount: number;
  solanaAddress: string;
  status: 'pending' | 'approved' | 'rejected';
  createdAt: string;
  processedAt?: string;
  processedBy?: string | null; // Allow processedBy to be null
  transactionLink?: string; // Add transaction link field
}

export function WithdrawalManagement() {
  const [withdrawals, setWithdrawals] = useState<WithdrawalRequest[]>([]);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState<'all' | 'pending' | 'approved' | 'rejected'>('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Number of items per page
  const [notifications, setNotifications] = useState<Notification[]>([]); // State for notifications
  const [showTransactionLinkModal, setShowTransactionLinkModal] = useState(false);
  const [currentWithdrawalId, setCurrentWithdrawalId] = useState<string | null>(null);
  const [transactionLink, setTransactionLink] = useState('');

  useEffect(() => {
    const unsubscribe = fetchWithdrawals();
    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  const fetchWithdrawals = () => {
    setLoading(true);
    const q = query(collection(db, 'withdrawals'), orderBy('createdAt', 'desc'));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const data = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as WithdrawalRequest[];
      setWithdrawals(data);
      setLoading(false);
    }, (error) => {
      console.error('Error fetching withdrawals:', error);
      toast.error('Failed to load withdrawals');
      setLoading(false);
    });

    return unsubscribe; // Return unsubscribe function for cleanup
  };

  const handleUpdateStatus = async (withdrawalId: string, newStatus: 'approved' | 'rejected') => {
    if (newStatus === 'approved') {
      setCurrentWithdrawalId(withdrawalId);
      setShowTransactionLinkModal(true);
      return;
    }

    // Handle rejection logic...
    try {
      const withdrawalDoc = doc(db, 'withdrawals', withdrawalId);
      await updateDoc(withdrawalDoc, {
        status: 'rejected',
        processedAt: new Date().toISOString(),
        processedBy: auth.currentUser?.email || null, // Set to null if email is undefined
      });

      toast.success('Withdrawal rejected successfully.');
    } catch (error) {
      console.error('Error updating withdrawal:', error);
      toast.error('Failed to update withdrawal');
    }
  };

  const handleApproveWithdrawal = async () => {
    if (!transactionLink || !currentWithdrawalId) {
      toast.error('Transaction link is required for approval.');
      return;
    }

    try {
      const withdrawalDoc = doc(db, 'withdrawals', currentWithdrawalId);
      await updateDoc(withdrawalDoc, {
        status: 'approved',
        processedAt: new Date().toISOString(),
        processedBy: auth.currentUser?.email || null, // Set to null if email is undefined
        transactionLink, // Store the transaction link
      });

      // Send notification to user
      const notification: Notification = {
        id: currentWithdrawalId,
        title: 'Withdrawal Approved',
        message: `Your withdrawal has been successfully approved. Transaction Link: ${transactionLink}`,
        type: 'system',
        read: false,
        createdAt: new Date().toISOString(),
      };
      setNotifications(prev => [...prev, notification]);

      toast.success('Withdrawal approved successfully.');
      setShowTransactionLinkModal(false);
      setTransactionLink('');
      setCurrentWithdrawalId(null);
    } catch (error) {
      console.error('Error updating withdrawal:', error);
      toast.error('Failed to update withdrawal');
    }
  };

  const handleDeleteWithdrawal = async (withdrawalId: string) => {
    try {
      const withdrawalDoc = doc(db, 'withdrawals', withdrawalId);
      await deleteDoc(withdrawalDoc);
      toast.success('Withdrawal deleted successfully.');
    } catch (error) {
      console.error('Error deleting withdrawal:', error);
      toast.error('Failed to delete withdrawal');
    }
  };

  const filteredWithdrawals = withdrawals.filter(w => 
    filter === 'all' ? true : w.status === filter
  ).filter(w => 
    w.userEmail.toLowerCase().includes(searchTerm.toLowerCase()) || 
    w.solanaAddress.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Pagination logic
  const indexOfLastWithdrawal = currentPage * itemsPerPage;
  const indexOfFirstWithdrawal = indexOfLastWithdrawal - itemsPerPage;
  const currentWithdrawals = filteredWithdrawals.slice(indexOfFirstWithdrawal, indexOfLastWithdrawal);
  const totalPages = Math.ceil(filteredWithdrawals.length / itemsPerPage);

  if (loading) {
    return (
      <div className="p-8">
        <div className="animate-pulse space-y-4">
          <div className="h-8 bg-gray-800 rounded w-1/4"></div>
          <div className="h-64 bg-gray-800 rounded"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h2 className="text-2xl font-bold">Withdrawal Management</h2>
        <div className="flex space-x-2">
          {(['all', 'pending', 'approved', 'rejected'] as const).map((status) => (
            <Button
              key={status}
              variant={filter === status ? 'default' : 'ghost'}
              onClick={() => setFilter(status)}
            >
              {status.charAt(0).toUpperCase() + status.slice(1)}
            </Button>
          ))} 
        </div>
      </div>

      <div className="flex items-center mb-4">
        <input
          type="text"
          placeholder="Search by user email or Solana address"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="px-4 py-2 border border-gray-700 rounded-lg bg-gray-800 text-gray-200" // Updated styling
        />
      </div>

      <div className="bg-gray-800 rounded-lg overflow-hidden">
        <table className="w-full">
          <thead>
            <tr className="bg-gray-900">
              <th className="px-6 py-3 text-left">User</th>
              <th className="px-6 py-3 text-left">Amount</th>
              <th className="px-6 py-3 text-left">Solana Address</th>
              <th className="px-6 py-3 text-left">Status</th>
              <th className="px-6 py-3 text-left">Date</th>
              <th className="px-6 py-3 text-left">Transaction Link</th>
              <th className="px-6 py-3 text-right">Actions</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-700">
            {currentWithdrawals.map((withdrawal) => (
              <tr key={withdrawal.id} className="hover:bg-gray-700/50">
                <td className="px-6 py-4">
                  <div>
                    <div className="font-medium">{withdrawal.userEmail}</div>
                    <div className="text-sm text-gray-400">{withdrawal.userId}</div>
                  </div>
                </td>
                <td className="px-6 py-4">
                  <span className="font-mono">{withdrawal.amount} SOL</span>
                </td>
                <td className="px-6 py-4">
                  <span className="font-mono text-sm">{withdrawal.solanaAddress}</span>
                </td>
                <td className="px-6 py-4">
                  <span className={`px-2 py-1 rounded-full text-xs font-medium ${
                    withdrawal.status === 'approved' 
                      ? 'bg-green-500/20 text-green-400'
                      : withdrawal.status === 'rejected'
                      ? 'bg-red-500/20 text-red-400'
                      : 'bg-yellow-500/20 text-yellow-400'
                  }`}>
                    {withdrawal.status}
                  </span>
                </td>
                <td className="px-6 py-4">
                  <div>
                    <div>{new Date(withdrawal.createdAt).toLocaleDateString()}</div>
                    {withdrawal.processedAt && (
                      <div className="text-sm text-gray-400">
                        Processed: {new Date(withdrawal.processedAt).toLocaleDateString()}
                      </div>
                    )}
                  </div>
                </td>
                <td className="px-6 py-4">
                  {withdrawal.transactionLink ? (
                    <a href={withdrawal.transactionLink} target="_blank" rel="noopener noreferrer" className="text-blue-400">
                      View Link
                    </a>
                  ) : (
                    <span className="text-gray-400">N/A</span>
                  )}
                </td>
                <td className="px-6 py-4 text-right">
                  <div className="flex justify-end space-x-2">
                    {withdrawal.status === 'pending' && (
                      <>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => handleUpdateStatus(withdrawal.id, 'approved')}
                          className="text-green-400 hover:text-green-300"
                        >
                          Approve
                        </Button>
                        <Button
                          variant="ghost"
                          size="sm"
                          onClick={() => handleUpdateStatus(withdrawal.id, 'rejected')}
                          className="text-red-400 hover:text-red-300"
                        >
                          Reject
                        </Button>
                      </>
                    )}
                    <Button
                      variant="ghost"
                      size="sm"
                      onClick={() => handleDeleteWithdrawal(withdrawal.id)}
                      className="text-red-400 hover:text-red-300"
                    >
                      Delete
                    </Button>
                  </div>
                </td>
              </tr>
            ))}

            {currentWithdrawals.length === 0 && (
              <tr>
                <td colSpan={7} className="px-6 py-8 text-center text-gray-400">
                  No withdrawal requests found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Transaction Link Modal */}
      {showTransactionLinkModal && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
          <div className="bg-gray-800 p-6 rounded-lg max-w-md w-full">
            <h3 className="text-lg font-semibold mb-4">Enter Transaction Link</h3>
            <input
              type="text"
              value={transactionLink}
              onChange={(e) => setTransactionLink(e.target.value)}
              className="w-full px-4 py-2 bg-gray-700 border border-gray-600 rounded-lg"
              placeholder="Transaction Link"
            />
            <div className="flex justify-end mt-4">
              <Button onClick={handleApproveWithdrawal} className="mr-2">Submit</Button>
              <Button onClick={() => setShowTransactionLinkModal(false)}>Cancel</Button>
            </div>
          </div>
        </div>
      )}

      {/* Pagination Controls */}
      <div className="flex justify-between items-center mt-4">
        <Button
          variant="ghost"
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
        >
          Previous
        </Button>
        <span>Page {currentPage} of {totalPages}</span>
        <Button
          variant="ghost"
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
        >
          Next
        </Button>
      </div>
    </div>
  );
}