import React, { useState, useEffect } from 'react';
import {
  collection,
  addDoc,
  deleteDoc,
  updateDoc,
  doc,
  onSnapshot,
} from 'firebase/firestore';
import { db } from '@/lib/firebase';
import { Button } from '@/components/ui/Button';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';

interface RedeemItem {
  id: string;
  name: string;
  description: string;
  pointsRequired: number;
  redemptions: number;
  redemptionLimit: number;
  giftCode: string; // Gift code to reveal upon redemption
}

export const WebShopManagement: React.FC = () => {
  const [items, setItems] = useState<RedeemItem[]>([]);
  const [newItem, setNewItem] = useState<Omit<RedeemItem, 'id'>>({
    name: '',
    description: '',
    pointsRequired: 0,
    redemptions: 0,
    redemptionLimit: 100,
    giftCode: '',
  });
  const [editItem, setEditItem] = useState<RedeemItem | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribeRedeemShop = onSnapshot(collection(db, 'redeemShop'), snapshot => {
      const fetchedItems = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      })) as RedeemItem[];
      setItems(fetchedItems);
    });

    setLoading(false);

    return () => unsubscribeRedeemShop(); // Cleanup listener
  }, []);

  const handleAddItem = async () => {
    try {
      await addDoc(collection(db, 'redeemShop'), {
        ...newItem,
      });
      toast.success('Item added successfully!');
      setNewItem({
        name: '',
        description: '',
        pointsRequired: 0,
        redemptions: 0,
        redemptionLimit: 100,
        giftCode: '',
      });
    } catch (error) {
      console.error('Error adding item:', error);
      toast.error('Failed to add item.');
    }
  };

  const handleUpdateItem = async () => {
    if (!editItem) return;

    try {
      const itemRef = doc(db, 'redeemShop', editItem.id);
      await updateDoc(itemRef, {
        name: editItem.name,
        description: editItem.description,
        pointsRequired: editItem.pointsRequired,
        redemptionLimit: editItem.redemptionLimit,
        giftCode: editItem.giftCode,
      });
      toast.success('Item updated successfully!');
      setEditItem(null);
    } catch (error) {
      console.error('Error updating item:', error);
      toast.error('Failed to update item.');
    }
  };

  const handleDeleteItem = async (id: string) => {
    try {
      await deleteDoc(doc(db, 'redeemShop', id));
      toast.success('Item deleted successfully!');
    } catch (error) {
      console.error('Error deleting item:', error);
      toast.error('Failed to delete item.');
    }
  };

  return (
    <div className="p-6 bg-gray-900 text-gray-200 rounded-lg">
      <h1 className="text-2xl font-bold mb-6 text-center">Web Shop Management</h1>

      {/* Add New Item Form */}
      <div className="mb-10">
        <h2 className="text-lg font-semibold mb-4">Add New Item</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4">
          <input
            type="text"
            placeholder="Name"
            value={newItem.name}
            onChange={e => setNewItem({ ...newItem, name: e.target.value })}
            className="px-4 py-2 rounded bg-gray-800 text-gray-200 border border-gray-700"
          />
          <input
            type="text"
            placeholder="Description"
            value={newItem.description}
            onChange={e => setNewItem({ ...newItem, description: e.target.value })}
            className="px-4 py-2 rounded bg-gray-800 text-gray-200 border border-gray-700"
          />
          <input
            type="number"
            placeholder="Points Required"
            value={newItem.pointsRequired}
            onChange={e => setNewItem({ ...newItem, pointsRequired: Number(e.target.value) })}
            className="px-4 py-2 rounded bg-gray-800 text-gray-200 border border-gray-700"
          />
          <input
            type="number"
            placeholder="Redemption Limit"
            value={newItem.redemptionLimit}
            onChange={e => setNewItem({ ...newItem, redemptionLimit: Number(e.target.value) })}
            className="px-4 py-2 rounded bg-gray-800 text-gray-200 border border-gray-700"
          />
          <input
            type="text"
            placeholder="Gift Code (optional)"
            value={newItem.giftCode}
            onChange={e => setNewItem({ ...newItem, giftCode: e.target.value })}
            className="px-4 py-2 rounded bg-gray-800 text-gray-200 border border-gray-700"
          />
        </div>
        <div className="mt-4">
          <Button onClick={handleAddItem}>Add Item</Button>
        </div>
      </div>

      {/* Item List */}
      <h2 className="text-lg font-semibold mb-4">Manage Items</h2>
      {loading ? (
        <p>Loading items...</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {items.length > 0 ? (
            items.map(item => (
              <motion.div
                key={item.id}
                className="bg-gray-800 p-4 rounded-lg shadow-lg"
                whileHover={{ scale: 1.02 }}
              >
                <h3 className="text-lg font-semibold text-white mb-2">{item.name}</h3>
                <p className="text-gray-400 mb-4">{item.description}</p>
                <p className="text-gray-300 mb-2 font-semibold">
                  Points Required: {item.pointsRequired}
                </p>
                <p className="text-gray-400 mb-2">
                  <span className="font-semibold text-gray-300">Redemptions:</span>{' '}
                  {item.redemptions}/{item.redemptionLimit}
                </p>
                <div className="flex space-x-4">
                  <Button onClick={() => setEditItem(item)}>Edit</Button>
                  <Button
                    onClick={() => handleDeleteItem(item.id)}
                    className="bg-red-600 hover:bg-red-500"
                  >
                    Delete
                  </Button>
                </div>
              </motion.div>
            ))
          ) : (
            <p className="col-span-3 text-gray-400">No items available.</p>
          )}
        </div>
      )}

      {/* Edit Item Form */}
      {editItem && (
        <div className="mt-10">
          <h2 className="text-xl font-bold text-white mb-4">Edit Item</h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 bg-gray-800 p-4 rounded-lg">
            <div>
              <label className="text-gray-300 mb-2 block">Item Name</label>
              <input
                type="text"
                placeholder="Name"
                value={editItem.name}
                onChange={e => setEditItem({ ...editItem, name: e.target.value })}
                className="px-4 py-2 rounded bg-gray-700 text-gray-200 border border-gray-600 w-full"
              />
            </div>
            <div>
              <label className="text-gray-300 mb-2 block">Description</label>
              <textarea
                placeholder="Description"
                value={editItem.description}
                onChange={e => setEditItem({ ...editItem, description: e.target.value })}
                className="px-4 py-2 rounded bg-gray-700 text-gray-200 border border-gray-600 w-full"
                rows={3}
              />
            </div>
            <div>
              <label className="text-gray-300 mb-2 block">Points Required</label>
              <input
                type="number"
                placeholder="Points Required"
                value={editItem.pointsRequired}
                onChange={e => setEditItem({ ...editItem, pointsRequired: Number(e.target.value) })}
                className="px-4 py-2 rounded bg-gray-700 text-gray-200 border border-gray-600 w-full"
              />
            </div>
            <div>
              <label className="text-gray-300 mb-2 block">Redemption Limit</label>
              <input
                type="number"
                placeholder="Redemption Limit"
                value={editItem.redemptionLimit}
                onChange={e => setEditItem({ ...editItem, redemptionLimit: Number(e.target.value) })}
                className="px-4 py-2 rounded bg-gray-700 text-gray-200 border border-gray-600 w-full"
              />
            </div>
            <div className="sm:col-span-2 lg:col-span-1">
              <label className="text-gray-300 mb-2 block">Gift Code</label>
              <input
                type="text"
                placeholder="Gift Code"
                value={editItem.giftCode}
                onChange={e => setEditItem({ ...editItem, giftCode: e.target.value })}
                className="px-4 py-2 rounded bg-gray-700 text-gray-200 border border-gray-600 w-full"
              />
            </div>
          </div>
          <div className="mt-6 flex space-x-4">
            <Button onClick={handleUpdateItem}>Save Changes</Button>
            <Button
              onClick={() => setEditItem(null)}
              className="bg-gray-700 hover:bg-gray-600"
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
