import { motion, AnimatePresence } from 'framer-motion';
import { X } from 'lucide-react';
import { Button } from '@/components/ui/Button';

interface NewsModalProps {
  isOpen: boolean;
  onClose: () => void;
  news: {
    title: string;
    content: string;
    date: string;
    category: string;
    views: number;
  };
}

export function NewsModal({ isOpen, onClose, news }: NewsModalProps) {
  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black"
            onClick={onClose}
          />
          <motion.div
            initial={{ opacity: 0, scale: 0.95, y: 20 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.95, y: 20 }}
            className="fixed inset-0 flex items-center justify-center p-4 pointer-events-none"
          >
            <div className="bg-gray-900 p-6 rounded-xl shadow-xl max-w-2xl w-full pointer-events-auto border border-gray-800">
              <div className="flex items-center justify-between mb-6">
                <div>
                  <span className="text-xs text-purple-400 font-medium">
                    {news.category}
                  </span>
                  <h3 className="text-xl font-bold mt-1">{news.title}</h3>
                  <p className="text-sm text-gray-400">
                    {new Date(news.date).toLocaleDateString()}
                  </p>
                  <div className="text-sm text-gray-400 mt-2">
                    {news.views || 0} views
                  </div>
                </div>
                <Button variant="ghost" size="sm" onClick={onClose}>
                  <X className="h-5 w-5" />
                </Button>
              </div>

              <div className="prose prose-invert max-w-none">
                {news.content}
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}