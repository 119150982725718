// src/components/dashboard/TaskPopup.tsx

import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, CheckCircle, Clock, AlertCircle, Info } from 'lucide-react';
import { Button } from '@/components/ui/Button';
import {
  doc,
  updateDoc,
  getDoc,
  serverTimestamp,
  onSnapshot,
  collection,
  query,
  increment, // Importing increment function
} from 'firebase/firestore';
import { auth, db } from '@/lib/firebase';
import { toast } from 'react-hot-toast';

interface Task {
  id: string;
  title: string;
  description: string;
  reward: number; // Changed from 'balance' to 'reward'
  type: 'daily' | 'weekly' | 'special';
  maxStreak: number;
  answer: string; // Added to match usage in component
  day: number; // Added to match usage in component
  completionCount: number; // Added to match usage in component
  status: 'active' | 'inactive'; // Added to match usage in component
  points: number; // Added points property
}

interface TaskPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onComplete: (taskId: string) => void;
  nextTask: Task | null; // New prop for the next task
}

export function TaskPopup({ isOpen, onClose, onComplete, nextTask }: TaskPopupProps) {
  const [timeUntilNextTask, setTimeUntilNextTask] = useState<string>('');
  const [lastCompletionTime, setLastCompletionTime] = useState<Date | null>(null);
  const [loading, setLoading] = useState(false);
  const [userStreak, setUserStreak] = useState(0);
  const [tasks, setTasks] = useState<Task[]>([]); // State to hold tasks from Firestore

  // State for nested answer modal
  const [selectedTask, setSelectedTask] = useState<Task | null>(null); // Task selected for answering
  const [userAnswer, setUserAnswer] = useState<string>(''); // User's answer
  const [isAnswerModalOpen, setIsAnswerModalOpen] = useState<boolean>(false); // Track if answer modal is open

  // Fetch user data from Firestore
  const fetchUserData = async () => {
    const user = auth.currentUser;
    if (!user) return;

    const userRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userRef);
    
    if (userDoc.exists()) {
      const userData = userDoc.data();
      setUserStreak(userData.streak || 0);
      
      if (userData.lastTaskCompletion) {
        const lastCompletion = userData.lastTaskCompletion.toDate();
        setLastCompletionTime(lastCompletion);
      }
    }
  };

  // Subscribe to real-time updates of user data
  const subscribeToUserData = () => {
    const user = auth.currentUser;
    if (!user) return;

    const userRef = doc(db, 'users', user.uid);
    return onSnapshot(userRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const userData = docSnapshot.data();
        setUserStreak(userData.streak || 0);
        if (userData.lastTaskCompletion) {
          setLastCompletionTime(userData.lastTaskCompletion.toDate());
        }
      }
    });
  };

  // Fetch tasks from Firestore
  const fetchTasks = () => {
    const tasksRef = collection(db, 'tasks');
    const currentDay = new Date().getDay() || 7; // Convert Sunday (0) to 7
    const q = query(tasksRef);
    
    return onSnapshot(q, (querySnapshot) => {
      const fetchedTasks: Task[] = [];
      querySnapshot.forEach((doc) => {
        const taskData = { ...doc.data(), id: doc.id } as Task;
        if (taskData.day === currentDay && taskData.status === 'active') {
          fetchedTasks.push(taskData);
        }
      });
      setTasks(fetchedTasks);
    });
  };

  // Helper function to get day name
  const getDayName = (day: number): string => {
    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    return days[day - 1];
  };

  useEffect(() => {
    fetchUserData();
    const unsubscribeUserData = subscribeToUserData();
    const unsubscribeTasks = fetchTasks(); // Fetch tasks on component mount
    return () => {
      if (unsubscribeUserData) {
        unsubscribeUserData();
      }
      if (unsubscribeTasks) {
        unsubscribeTasks();
      }
    };
  }, []);

  // Countdown timer for next task availability
  useEffect(() => {
    const timer = setInterval(() => {
      if (lastCompletionTime) {
        const now = new Date();
        const nextAvailable = new Date(lastCompletionTime);
        nextAvailable.setTime(nextAvailable.getTime() + (24 * 60 * 60 * 1000)); // 24-hour cooldown
        
        const diff = nextAvailable.getTime() - now.getTime();
        
        if (diff <= 0) {
          setTimeUntilNextTask('Available now!');
        } else {
          const hours = Math.floor(diff / (1000 * 60 * 60));
          const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
          const seconds = Math.floor((diff % (1000 * 60)) / 1000);
          setTimeUntilNextTask(
            `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`
          );
        }
      } else {
        setTimeUntilNextTask('Available now!');
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [lastCompletionTime]);

  // Determine if the task can be completed based on cooldown
  const canCompleteTask = () => {
    if (!lastCompletionTime) return true;
    
    const now = new Date();
    const nextAvailable = new Date(lastCompletionTime);
    nextAvailable.setTime(nextAvailable.getTime() + (24 * 60 * 60 * 1000)); // 24-hour cooldown
    
    return now >= nextAvailable;
  };

  // Handle "Do Task" button click
  const handleDoTask = (task: Task) => {
    setSelectedTask(task);
    setIsAnswerModalOpen(true);
  };

  // Handle answer input change
  const handleAnswerChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUserAnswer(e.target.value);
  };

  // Handle task completion
  const handleCompleteTask = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!selectedTask) {
      toast.error('No task selected');
      return;
    }

    if (!canCompleteTask()) {
      toast.error('You can only complete this task once every 24 hours');
      return;
    }

    const user = auth.currentUser;
    if (!user) {
      toast.error('You must be logged in to complete tasks');
      return;
    }

    // Validate the user's answer
    if (userAnswer.trim().toLowerCase() !== selectedTask.answer.trim().toLowerCase()) {
      toast.error('Incorrect answer. Please try again.');
      return;
    }

    setLoading(true);
    try {
      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);
      const userData = userDoc.data();

      let newStreak = 1;
      if (userData?.lastTaskCompletion) {
        const lastCompletion = userData.lastTaskCompletion.toDate();
        const now = new Date();
        const yesterday = new Date(now);
        yesterday.setDate(now.getDate() - 1);
        
        if (lastCompletion.toDateString() === yesterday.toDateString()) {
          newStreak = Math.min((userData.streak || 0) + 1, 7); // Assuming max streak is 7
        }
      }

      // Update user's balance, streak, tasksCompleted, points, and lastTaskCompletion
      await updateDoc(userRef, {
        balance: (userData?.balance || 0) + selectedTask.reward,
        streak: newStreak,
        tasksCompleted: (userData?.tasksCompleted || 0) + 1,
        points: (userData?.points || 0) + selectedTask.points,
        lastTaskCompletion: serverTimestamp(),
      });

      // Atomically increment task's completion count using Firestore's increment
      const taskRef = doc(db, 'tasks', selectedTask.id);
      await updateDoc(taskRef, {
        completionCount: increment(1),
      });

      setLastCompletionTime(new Date());
      setUserStreak(newStreak);
      onComplete(selectedTask.id); // Notify parent component about task completion
      toast.success(`Task completed! You earned ${selectedTask.reward} SOL and ${selectedTask.points} points. Streak: ${newStreak}/7`);
      
      // Close the answer modal
      setIsAnswerModalOpen(false);
      setSelectedTask(null);
      setUserAnswer('');
    } catch (error) {
      console.error('Error completing task:', error);
      toast.error('Failed to complete task');
    } finally {
      setLoading(false);
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          {/* Overlay */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black"
            onClick={onClose}
          />

          {/* Main Popup */}
          <motion.div
            initial={{ opacity: 0, scale: 0.95, y: 20 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.95, y: 20 }}
            className="fixed inset-0 flex items-center justify-center p-4 pointer-events-none"
          >
            <div className="bg-gray-900 p-6 rounded-xl shadow-xl max-w-md w-full pointer-events-auto border border-gray-800">
              {/* Header */}
              <div className="flex items-center justify-between mb-6">
                <h3 className="text-xl font-bold">Tasks Created</h3>
                <Button variant="ghost" size="sm" onClick={onClose} aria-label="Close Popup">
                  <X className="h-5 w-5" />
                </Button>
              </div>

              {/* Content */}
              <div className="space-y-6">
                {/* Display Current Day Task */}
                <div className="bg-gray-800/50 p-3 sm:p-4 rounded-lg">
                  <h4 className="font-semibold mb-2 text-sm sm:text-base">Today's Task ({getDayName(new Date().getDay() || 7)})</h4>
                  {tasks.length > 0 ? (
                    <div className="mb-4 p-3 bg-gray-700/50 rounded-lg hover:bg-gray-700/70 transition-all">
                      <div className="flex justify-between items-center">
                        <div className="flex-grow">
                          <h5 className="font-semibold text-sm sm:text-base mb-2">{tasks[0].title}</h5>
                          <p className="text-gray-400 text-xs sm:text-sm mb-3">{tasks[0].description}</p>
                        </div>
                        <span className="text-purple-400 bg-purple-400/10 px-2 py-1 rounded-full text-xs whitespace-nowrap">
                          +{tasks[0].points} points
                        </span>
                      </div>
                      <div className="flex flex-wrap items-center gap-2">
                        <span className="text-purple-400 bg-purple-400/10 px-3 py-1.5 rounded-full text-xs sm:text-sm whitespace-nowrap">
                          +{tasks[0].reward} SOL
                        </span>
                        <span className="text-blue-400 bg-blue-400/10 px-3 py-1.5 rounded-full text-xs sm:text-sm whitespace-nowrap">
                          {getDayName(tasks[0].day)}
                        </span>
                        {canCompleteTask() ? (
                          <Button
                            onClick={() => handleDoTask(tasks[0])}
                            className="ml-auto text-xs sm:text-sm h-[30px] bg-gradient-to-r from-purple-600 to-blue-600 hover:from-purple-700 hover:to-blue-700 transition-all duration-200"
                          >
                            <div className="flex items-center gap-1.5">
                              <CheckCircle className="h-3.5 w-3.5" />
                              <span>Complete Task</span>
                            </div>
                          </Button>
                        ) : (
                          <Button 
                            disabled 
                            className="ml-auto text-xs sm:text-sm h-[30px] opacity-70"
                          >
                            <div className="flex items-center gap-1.5">
                              <Clock className="h-3.5 w-3.5" />
                              <span>Wait for Reset</span>
                            </div>
                          </Button>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div className="text-center p-6 bg-gray-800/30 rounded-lg">
                      <AlertCircle className="h-8 w-8 text-yellow-500 mx-auto mb-2" />
                      <p className="text-gray-400 text-sm">
                        No task available for today ({getDayName(new Date().getDay() || 7)}).
                        {tasks.length === 0 && <br />}
                        {tasks.length === 0 && "Please check back tomorrow or contact an administrator."}
                      </p>
                    </div>
                  )}
                </div>

                {/* Streak Progress with improved visuals */}
                <div className="p-3 sm:p-4 bg-gray-800/30 rounded-lg">
                  <h4 className="font-semibold mb-3 text-sm sm:text-base flex items-center gap-2">
                    <span>Streak Progress</span>
                    <span className="text-purple-400 bg-purple-400/10 px-2 py-0.5 rounded-full text-xs">
                      {userStreak}/7 days
                    </span>
                  </h4>
                  <div className="grid grid-cols-7 gap-1 sm:gap-2">
                    {Array.from({ length: 7 }).map((_, index) => (
                      <div
                        key={index}
                        className={`h-2 sm:h-3 rounded-full ${
                          index < userStreak
                            ? 'bg-gradient-to-r from-purple-600 to-blue-600 shadow-lg shadow-purple-500/20'
                            : 'bg-gray-700'
                        } transition-all duration-300`}
                      />
                    ))}
                  </div>
                  <p className="text-xs sm:text-sm text-gray-400 mt-3">
                    {userStreak < 7 
                      ? `${7 - userStreak} more days needed for bonus rewards!`
                      : 'Maximum streak achieved! Keep it up!'}
                  </p>
                </div>

                {/* Cooldown Timer with improved visuals */}
                <div className="bg-yellow-500/10 p-4 rounded-lg">
                  <div className="flex items-center gap-3">
                    <div className="bg-yellow-500/20 p-2 rounded-lg">
                      <Clock className="h-5 w-5 text-yellow-400" />
                    </div>
                    <div>
                      <p className="font-medium text-sm sm:text-base text-yellow-400">Task Cooldown</p>
                      <p className="text-xs sm:text-sm text-yellow-400/70">
                        Next task available in {timeUntilNextTask}
                      </p>
                    </div>
                  </div>
                </div>

                {/* New Section: Suggestion to Use Google */}
                <div className="bg-blue-500/10 p-4 rounded-lg mt-4"> {/* Added margin-top for spacing */}
                  <div className="flex items-center gap-3">
                    <div className="bg-blue-500/20 p-2 rounded-lg">
                      <Info className="h-5 w-5 text-blue-400" /> {/* Use an appropriate icon */}
                    </div>
                    <div>
                      <p className="font-medium text-sm sm:text-base text-blue-400">Don't find the answer? Try Google, it's your best friend!</p>
                    </div>
                  </div>
                </div>

                {/* Next Task Section with improved visuals */}
                {nextTask && (
                  <div className="bg-gray-800/50 p-4 rounded-lg">
                    <h4 className="font-semibold mb-3 text-sm sm:text-base flex items-center gap-2">
                      <span>Next Task</span>
                      <span className="text-blue-400 bg-blue-400/10 px-2 py-0.5 rounded-full text-xs">
                        Coming Soon
                      </span>
                    </h4>
                    <div className="space-y-2">
                      <h5 className="font-medium text-sm sm:text-base">{nextTask.title}</h5>
                      <p className="text-gray-400 text-xs sm:text-sm">{nextTask.description}</p>
                      <span className="inline-block text-purple-400 bg-purple-400/10 px-2 py-1 rounded-full text-xs">
                        Reward: +{nextTask.reward} SOL
                      </span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </motion.div>

          {/* Nested Answer Modal */}
          <AnimatePresence>
            {isAnswerModalOpen && selectedTask && (
              <>
                {/* Overlay */}
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 0.5 }}
                  exit={{ opacity: 0 }}
                  className="fixed inset-0 bg-black"
                  onClick={() => setIsAnswerModalOpen(false)}
                />

                {/* Answer Modal */}
                <motion.div
                  initial={{ opacity: 0, scale: 0.95, y: 20 }}
                  animate={{ opacity: 1, scale: 1, y: 0 }}
                  exit={{ opacity: 0, scale: 0.95, y: 20 }}
                  className="fixed inset-0 flex items-center justify-center p-4 pointer-events-none"
                >
                  <div className="bg-gray-900 p-6 rounded-xl shadow-xl max-w-md w-full pointer-events-auto border border-gray-800">
                    {/* Header */}
                    <div className="flex items-center justify-between mb-6">
                      <h3 className="text-xl font-bold">Answer the Task</h3>
                      <Button variant="ghost" size="sm" onClick={() => setIsAnswerModalOpen(false)} aria-label="Close Answer Modal">
                        <X className="h-5 w-5" />
                      </Button>
                    </div>

                    {/* Content */}
                    <div className="space-y-6">
                      {/* Display Selected Task */}
                      <div className="bg-gray-800/50 p-4 rounded-lg">
                        <h4 className="font-semibold mb-2 text-base sm:text-lg">{`Day ${selectedTask.day}: ${selectedTask.title}`}</h4>
                        <p className="text-gray-400 text-sm">{selectedTask.description}</p>
                        <p className="text-green-500">Reward: {selectedTask.reward} SOL</p>
                        <p className="text-gray-400 text-sm">+{selectedTask.points} points</p>
                        <p className="text-gray-300 mt-2">
                          {selectedTask.type === 'daily'
                            ? 'Answer the daily question to earn rewards!'
                            : selectedTask.type === 'weekly'
                            ? 'Answer the weekly challenge to earn rewards!'
                            : 'Complete this special task to earn rewards!'}
                        </p>
                        <form onSubmit={handleCompleteTask} className="mt-4">
                          <input
                            type="text"
                            value={userAnswer}
                            onChange={handleAnswerChange}
                            placeholder="Enter your answer here"
                            required
                            className="w-full p-2 rounded border border-gray-600 bg-gray-700 text-white"
                          />
                          <Button
                            type="submit"
                            disabled={loading || userAnswer.trim() === ''}
                            className="mt-2 w-full"
                          >
                            {loading ? 'Processing...' : 'Done Tasks'}
                          </Button>
                        </form>
                      </div>
                    </div>
                  </div>
                </motion.div>
              </>
            )}
          </AnimatePresence>
        </>
      )}
    </AnimatePresence>
  ); // Correctly closed with a parenthesis
}
