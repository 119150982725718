import { useState, useEffect } from 'react';
import { collection, addDoc, deleteDoc, doc, updateDoc, getDocs, onSnapshot, increment } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '@/lib/firebase';
import { Button } from '@/components/ui/Button';
import { Plus, Trash2, Image, DollarSign, Ticket, Users, Calendar } from 'lucide-react';
import { toast } from 'react-hot-toast';
import { motion, AnimatePresence } from 'framer-motion';

interface Raffle {
  id: string;
  title: string;
  description: string;
  prizeAmount: number;
  imageUrl: string;
  ticketPrice: number;
  maxTickets: number;
  entries: number;
  startDate: string;
  endDate: string;
  status: 'active' | 'ended' | 'draft';
  createdAt: string;
}

export function RaffleManagement() {
  const [raffles, setRaffles] = useState<Raffle[]>([]);
  const [loading, setLoading] = useState(true);
  const [showAddModal, setShowAddModal] = useState(false);
  const [newRaffle, setNewRaffle] = useState({
    title: '',
    description: '',
    prizeAmount: 0,
    ticketPrice: 0,
    maxTickets: 100,
    startDate: '',
    endDate: '',
    status: 'draft' as const, // Set to draft by default
  });
  const [raffleImage, setRaffleImage] = useState<File | null>(null);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'raffles'), (rafflesSnapshot) => {
      const rafflesData = rafflesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        entries: doc.data().entries || 0,
      })) as Raffle[];
      setRaffles(rafflesData);
      setLoading(false);
    });

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  const handleImageUpload = async (file: File) => {
    const storageRef = ref(storage, `raffles/${file.name}`);
    await uploadBytes(storageRef, file);
    return getDownloadURL(storageRef);
  };

  const handleAddRaffle = async () => {
    try {
      let imageUrl = '';
      if (raffleImage) {
        imageUrl = await handleImageUpload(raffleImage);
      }

      const docRef = await addDoc(collection(db, 'raffles'), {
        ...newRaffle,
        imageUrl,
        entries: 0,
        createdAt: new Date().toISOString(),
      });

      setShowAddModal(false);
      setRaffles([...raffles, { ...newRaffle, id: docRef.id, imageUrl, entries: 0, createdAt: new Date().toISOString() }]);
    } catch (error) {
      console.error('Error creating raffle:', error);
      toast.error('Failed to create raffle');
    }
  };

  const handleDeleteRaffle = async (raffleId: string) => {
    if (!window.confirm('Are you sure you want to delete this raffle?')) return;

    try {
      await deleteDoc(doc(db, 'raffles', raffleId));
      setRaffles(raffles.filter(raffle => raffle.id !== raffleId));
      toast.success('Raffle deleted successfully');
    } catch (error) {
      console.error('Error deleting raffle:', error);
      toast.error('Failed to delete raffle');
    }
  };

  const handleUpdateStatus = async (raffleId: string, currentStatus: Raffle['status']) => {
    let newStatus: Raffle['status'];

    // Toggle status between 'active' and 'draft'
    if (currentStatus === 'draft') {
      newStatus = 'active';
    } else if (currentStatus === 'active') {
      newStatus = 'draft';
    } else {
      return; // Do nothing if the status is neither 'draft' nor 'active'
    }

    try {
      // Update the status in Firestore
      await updateDoc(doc(db, 'raffles', raffleId), { status: newStatus });
      // Update the local state
      setRaffles(raffles.map(raffle =>
        raffle.id === raffleId ? { ...raffle, status: newStatus } : raffle
      ));
      toast.success(`Raffle status updated to ${newStatus}`);
    } catch (error) {
      console.error('Error updating raffle status:', error);
      toast.error('Failed to update status');
    }
  };

  const handleAddEntry = async (raffleId: string) => {
    try {
      const raffleRef = doc(db, 'raffles', raffleId);
      await updateDoc(raffleRef, {
        entries: increment(1), // Increment the entries by 1
      });

      // Update local state to reflect the change
      setRaffles(raffles.map(raffle =>
        raffle.id === raffleId ? { ...raffle, entries: raffle.entries + 1 } : raffle
      ));

      toast.success('Entry added successfully!');
    } catch (error) {
      console.error('Error adding entry:', error);
      toast.error('Failed to add entry');
    }
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <div>
          <h2 className="text-2xl font-bold">Raffle Management</h2>
          <p className="text-gray-400">Create and manage prize raffles</p>
          <p className="text-gray-400">Total Raffles: {raffles.length}</p>
        </div>
        <Button onClick={() => setShowAddModal(true)}>
          <Plus className="h-4 w-4 mr-2" />
          Create Raffle
        </Button>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
        {raffles.map((raffle) => (
          <div
            key={raffle.id}
            className="bg-gray-800 rounded-lg p-4 border border-gray-700 shadow-md flex flex-col items-start relative"
          >
            <div className="flex items-center justify-between w-full mb-2">
              <h3 className="text-lg font-semibold">{raffle.title}</h3>
              <span className={`px-2 py-1 rounded-full text-xs ${
                raffle.status === 'active' ? 'bg-green-500/20 text-green-400' :
                'bg-yellow-500/20 text-yellow-400'
              }`}>
                {raffle.status}
              </span>
            </div>
            <p className="text-gray-400 mb-4 text-sm">{raffle.description}</p>
            <div className="grid grid-cols-2 gap-2 text-sm w-full">
              <div className="flex items-center">
                <DollarSign className="h-4 w-4 text-gray-400 mr-1" />
                <p className="text-gray-400">Prize</p>
                <p className="font-semibold ml-2">${raffle.prizeAmount}</p>
              </div>
              <div className="flex items-center">
                <Ticket className="h-4 w-4 text-gray-400 mr-1" />
                <p className="text-gray-400">Price</p>
                <p className="font-semibold ml-2">${raffle.ticketPrice}</p>
              </div>
              <div className="flex items-center">
                <Users className="h-4 w-4 text-gray-400 mr-1" />
                <p className="text-gray-400">Entries</p>
                <p className="font-semibold ml-2">{raffle.entries}/{raffle.maxTickets}</p>
              </div>
              <div className="flex items-center">
                <Calendar className="h-4 w-4 text-gray-400 mr-1" />
                <p className="text-gray-400">Ends</p>
                <p className="font-semibold ml-2">{new Date(raffle.endDate).toLocaleDateString()}</p>
              </div>
            </div>
            <div className="absolute bottom-2 right-2 flex space-x-2">
              <Button
                variant="ghost"
                size="sm"
                onClick={() => handleUpdateStatus(raffle.id, raffle.status)}
              >
                <Plus className="h-4 w-4 text-yellow-400" />
              </Button>
              <Button
                variant="ghost"
                size="sm"
                onClick={() => handleDeleteRaffle(raffle.id)}
              >
                <Trash2 className="h-4 w-4 text-red-400" />
              </Button>
            </div>
          </div>
        ))}
      </div>

      <AnimatePresence>
        {showAddModal && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.5 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black"
              onClick={() => setShowAddModal(false)}
            />
            <motion.div
              initial={{ opacity: 0, scale: 0.95, y: 20 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.95, y: 20 }}
              className="fixed inset-0 flex items-center justify-center p-4 pointer-events-none"
            >
              <div className="bg-gray-900 p-6 rounded-xl shadow-xl max-w-lg w-full pointer-events-auto border border-gray-800"> {/* Increased width */}
                <h3 className="text-xl font-bold mb-6">Create New Raffle</h3>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium mb-1">Title</label>
                    <input
                      type="text"
                      value={newRaffle.title}
                      onChange={(e) => setNewRaffle({ ...newRaffle, title: e.target.value })}
                      className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg"
                    />
                  </div>

                  <div>
                    <label className="block text-sm font-medium mb-1">Description</label>
                    <textarea
                      value={newRaffle.description}
                      onChange={(e) => setNewRaffle({ ...newRaffle, description: e.target.value })}
                      className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg h-24"
                    />
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium mb-1">Prize Amount ($)</label>
                      <input
                        type="number"
                        value={newRaffle.prizeAmount}
                        onChange={(e) => setNewRaffle({ ...newRaffle, prizeAmount: parseFloat(e.target.value) })}
                        className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium mb-1">Ticket Price ($)</label>
                      <input
                        type="number"
                        value={newRaffle.ticketPrice}
                        onChange={(e) => setNewRaffle({ ...newRaffle, ticketPrice: parseFloat(e.target.value) })}
                        className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg"
                      />
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium mb-1">Max Tickets</label>
                    <input
                      type="number"
                      value={newRaffle.maxTickets}
                      onChange={(e) => setNewRaffle({ ...newRaffle, maxTickets: parseInt(e.target.value) })}
                      className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg"
                    />
                  </div>

                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <label className="block text-sm font-medium mb-1">Start Date</label>
                      <input
                        type="date"
                        value={newRaffle.startDate}
                        onChange={(e) => setNewRaffle({ ...newRaffle, startDate: e.target.value })}
                        className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium mb-1">End Date</label>
                      <input
                        type="date"
                        value={newRaffle.endDate}
                        onChange={(e) => setNewRaffle({ ...newRaffle, endDate: e.target.value })}
                        className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg"
                      />
                    </div>
                  </div>

                  <div>
                    <label className="block text-sm font-medium mb-1">Raffle Image</label>
                    <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-700 border-dashed rounded-lg">
                      <div className="space-y-1 text-center">
                        <Image className="mx-auto h-12 w-12 text-gray-400" />
                        <div className="flex text-sm text-gray-400">
                          <label className="relative cursor-pointer rounded-md font-medium text-purple-500 hover:text-purple-400">
                            <span>Upload a file</span>
                            <input
                              type="file"
                              className="sr-only"
                              accept="image/*"
                              onChange={(e) => setRaffleImage(e.target.files?.[0] || null)}
                            />
                          </label>
                        </div>
                        <p className="text-xs text-gray-500">PNG, JPG up to 5MB</p>
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end space-x-3">
                    <Button variant="ghost" onClick={() => setShowAddModal(false)}>
                      Cancel
                    </Button>
                    <Button onClick={handleAddRaffle}>
                      Create Raffle
                    </Button>
                  </div>
                </div>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
}