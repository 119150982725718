import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth, db } from '@/lib/firebase';
import { onAuthStateChanged, deleteUser } from 'firebase/auth';
import { doc, updateDoc, deleteDoc, onSnapshot } from 'firebase/firestore';
import { Trophy, Clock, CheckCircle2 } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';

const VERIFICATION_TIMEOUT = 10 * 60; // 10 minutes in seconds

export function VerificationPending() {
  const navigate = useNavigate();
  const location = useLocation();
  const [timeLeft, setTimeLeft] = useState(VERIFICATION_TIMEOUT);
  const [isVerified, setIsVerified] = useState(false);
  const searchParams = new URLSearchParams(location.search);
  const verified = searchParams.get('verified') === 'true';

  useEffect(() => {
    const user = auth.currentUser;
    if (!user) {
      navigate('/register');
      return;
    }

    // Listen to both Auth and Firestore for verification status
    const unsubscribeFirestore = onSnapshot(doc(db, 'users', user.uid), (doc) => {
      if (doc.exists() && doc.data().emailVerified) {
        setIsVerified(true);
        if (timer) clearInterval(timer);
        setTimeout(() => {
          navigate('/login');
        }, 10000);
      }
    });

    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      if (user?.emailVerified) {
        setIsVerified(true);
        if (timer) clearInterval(timer);
        const userRef = doc(db, 'users', user.uid);
        await updateDoc(userRef, {
          isActive: true,
          emailVerified: true,
          verifiedAt: new Date().toISOString()
        });
        setTimeout(() => {
          navigate('/login');
        }, 10000);
      }
    });

    // Timer for unverified users
    const timer = isVerified ? null : setInterval(() => {
      setTimeLeft((prev) => {
        if (prev <= 1) {
          if (user) {
            deleteDoc(doc(db, 'users', user.uid));
            deleteUser(user);
            navigate('/register');
          }
          if (timer) clearInterval(timer);
        }
        return prev - 1;
      });
    }, 1000);

    return () => {
      if (timer) clearInterval(timer);
      unsubscribeAuth();
      unsubscribeFirestore();
    };
  }, [navigate, verified]);

  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  return (
    <div className="min-h-screen bg-gradient-to-r from-gray-800 to-gray-900 flex items-center justify-center p-4">
      <motion.div 
        className="w-full max-w-md space-y-8 bg-gray-800 p-8 rounded-lg shadow-lg text-center"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        <AnimatePresence mode="wait">
          {isVerified ? (
            <motion.div
              key="verified"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              className="flex flex-col items-center space-y-4"
            >
              <div className="relative">
                <Trophy className="h-12 w-12 text-purple-400" />
                <CheckCircle2 className="h-6 w-6 text-emerald-400 absolute -right-2 -top-2" />
              </div>
              <h2 className="text-2xl font-bold text-white">Email Verified!</h2>
              <p className="text-gray-300">
                Your email has been successfully verified. Redirecting to login...
              </p>
            </motion.div>
          ) : (
            <motion.div
              key="pending"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              className="flex flex-col items-center space-y-4"
            >
              <Trophy className="h-12 w-12 text-white" />
              <h2 className="text-2xl font-bold text-white">Verify your email</h2>
              <div className="flex items-center justify-center space-x-2 text-purple-400">
                <Clock className="h-5 w-5" />
                <span className="font-mono text-xl">
                  {String(minutes).padStart(2, '0')}:{String(seconds).padStart(2, '0')}
                </span>
              </div>
              <p className="text-gray-300">
                We've sent you a verification email. Please check your inbox and click the verification link to activate your account.
              </p>
              <p className="text-sm text-red-400">
                Your account will be deleted if not verified within {minutes} minutes.
              </p>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
}
