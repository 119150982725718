import { useState, useEffect, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Trophy, ShieldCheck, Info, Share2, Users, Clock, Crown, Medal, HelpCircle, Calendar, Coins, Star, Gift, Wallet, AlertCircle } from 'lucide-react'; // Added Clock, Crown, Medal icons
import { Button } from '@/components/ui/Button';
import confetti from 'canvas-confetti';
import { doc, updateDoc, increment, setDoc, collection, query, where, orderBy, limit, getDocs, Timestamp, writeBatch, getDoc, serverTimestamp, addDoc } from 'firebase/firestore';
import { auth, db } from '@/lib/firebase';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip } from 'react-tooltip';
import { toast } from 'react-hot-toast';

interface SpinWheelPopupProps {
  isOpen: boolean;
  onClose: () => void;
  onSpin: (prize: number) => void;
  lastSpinDate: string | null;
}

interface PrizeInfo {
  day: string;
  amount: number;
  color: string;
  icon: React.ReactNode;
  description: string;
  chance: string;
  highlight?: boolean;
}

// Define consistent color scheme for the entire wheel
const WHEEL_COLORS = {
  purple: '#7C3AED',    // Jackpot
  green: '#96E072',     // High tier
  orange: '#FF6B6B',    // Special
  blue: '#45B7D1',      // Medium tier
  cyan: '#4ECDC4',      // Low tier
  indigo: '#4464AD',    // Common tier
};

// Fixed prize structure for all 7 days
const DAILY_PRIZES = {
  0: [ // Sunday
    { amount: 0.0005, probability: 5, color: WHEEL_COLORS.purple, label: '0.0005 SOL', tooltip: 'Sunday Special', icon: <Crown className="w-5 h-5 text-white" /> },
    { amount: 0.0004, probability: 10, color: WHEEL_COLORS.green, label: '0.0004 SOL', tooltip: 'Super Prize', icon: <Star className="w-5 h-5 text-white" /> },
    { amount: 0.0003, probability: 15, color: WHEEL_COLORS.orange, label: '0.0003 SOL', tooltip: 'Big Prize', icon: <Trophy className="w-5 h-5 text-white" /> },
    { amount: 0.0002, probability: 20, color: WHEEL_COLORS.blue, label: '0.0002 SOL', tooltip: 'Medium Prize', icon: <Medal className="w-5 h-5 text-white" /> },
    { amount: 0.0001, probability: 25, color: WHEEL_COLORS.cyan, label: '0.0001 SOL', tooltip: 'Small Prize', icon: <Gift className="w-5 h-5 text-white" /> },
    { amount: 0.00005, probability: 25, color: WHEEL_COLORS.indigo, label: '0.00005 SOL', tooltip: 'Common Prize', icon: <Coins className="w-5 h-5 text-white" /> },
  ],
  1: [ // Monday - Highest rewards day
    { amount: 0.0015, probability: 5, color: WHEEL_COLORS.purple, label: '0.0015 SOL', tooltip: 'Weekly Special!', icon: <Crown className="w-5 h-5 text-white" /> },
    { amount: 0.0010, probability: 10, color: WHEEL_COLORS.green, label: '0.0010 SOL', tooltip: 'Super Prize', icon: <Star className="w-5 h-5 text-white" /> },
    { amount: 0.0008, probability: 15, color: WHEEL_COLORS.orange, label: '0.0008 SOL', tooltip: 'Mega Prize', icon: <Trophy className="w-5 h-5 text-white" /> },
    { amount: 0.0005, probability: 20, color: WHEEL_COLORS.blue, label: '0.0005 SOL', tooltip: 'Big Prize', icon: <Medal className="w-5 h-5 text-white" /> },
    { amount: 0.0003, probability: 25, color: WHEEL_COLORS.cyan, label: '0.0003 SOL', tooltip: 'Medium Prize', icon: <Gift className="w-5 h-5 text-white" /> },
    { amount: 0.0001, probability: 25, color: WHEEL_COLORS.indigo, label: '0.0001 SOL', tooltip: 'Common Prize', icon: <Coins className="w-5 h-5 text-white" /> },
  ],
  2: [ // Tuesday
    { amount: 0.0008, probability: 5, color: WHEEL_COLORS.purple, label: '0.0008 SOL', tooltip: 'Tuesday Special', icon: <Crown className="w-5 h-5 text-white" /> },
    { amount: 0.0006, probability: 10, color: WHEEL_COLORS.green, label: '0.0006 SOL', tooltip: 'Super Prize', icon: <Star className="w-5 h-5 text-white" /> },
    { amount: 0.0004, probability: 15, color: WHEEL_COLORS.orange, label: '0.0004 SOL', tooltip: 'Big Prize', icon: <Trophy className="w-5 h-5 text-white" /> },
    { amount: 0.0003, probability: 20, color: WHEEL_COLORS.blue, label: '0.0003 SOL', tooltip: 'Medium Prize', icon: <Medal className="w-5 h-5 text-white" /> },
    { amount: 0.0002, probability: 25, color: WHEEL_COLORS.cyan, label: '0.0002 SOL', tooltip: 'Small Prize', icon: <Gift className="w-5 h-5 text-white" /> },
    { amount: 0.0001, probability: 25, color: WHEEL_COLORS.indigo, label: '0.0001 SOL', tooltip: 'Common Prize', icon: <Coins className="w-5 h-5 text-white" /> },
  ],
  3: [ // Wednesday
    { amount: 0.0012, probability: 5, color: WHEEL_COLORS.purple, label: '0.0012 SOL', tooltip: 'Midweek Special', icon: <Crown className="w-5 h-5 text-white" /> },
    { amount: 0.0008, probability: 10, color: WHEEL_COLORS.green, label: '0.0008 SOL', tooltip: 'Super Prize', icon: <Star className="w-5 h-5 text-white" /> },
    { amount: 0.0005, probability: 15, color: WHEEL_COLORS.orange, label: '0.0005 SOL', tooltip: 'Big Prize', icon: <Trophy className="w-5 h-5 text-white" /> },
    { amount: 0.0003, probability: 20, color: WHEEL_COLORS.blue, label: '0.0003 SOL', tooltip: 'Medium Prize', icon: <Medal className="w-5 h-5 text-white" /> },
    { amount: 0.0002, probability: 25, color: WHEEL_COLORS.cyan, label: '0.0002 SOL', tooltip: 'Small Prize', icon: <Gift className="w-5 h-5 text-white" /> },
    { amount: 0.0001, probability: 25, color: WHEEL_COLORS.indigo, label: '0.0001 SOL', tooltip: 'Common Prize', icon: <Coins className="w-5 h-5 text-white" /> },
  ],
  4: [ // Thursday
    { amount: 0.0009, probability: 5, color: WHEEL_COLORS.purple, label: '0.0009 SOL', tooltip: 'Thursday Special', icon: <Crown className="w-5 h-5 text-white" /> },
    { amount: 0.0007, probability: 10, color: WHEEL_COLORS.green, label: '0.0007 SOL', tooltip: 'Super Prize', icon: <Star className="w-5 h-5 text-white" /> },
    { amount: 0.0005, probability: 15, color: WHEEL_COLORS.orange, label: '0.0005 SOL', tooltip: 'Big Prize', icon: <Trophy className="w-5 h-5 text-white" /> },
    { amount: 0.0003, probability: 20, color: WHEEL_COLORS.blue, label: '0.0003 SOL', tooltip: 'Medium Prize', icon: <Medal className="w-5 h-5 text-white" /> },
    { amount: 0.0002, probability: 25, color: WHEEL_COLORS.cyan, label: '0.0002 SOL', tooltip: 'Small Prize', icon: <Gift className="w-5 h-5 text-white" /> },
    { amount: 0.0001, probability: 25, color: WHEEL_COLORS.indigo, label: '0.0001 SOL', tooltip: 'Common Prize', icon: <Coins className="w-5 h-5 text-white" /> },
  ],
  5: [ // Friday
    { amount: 0.0011, probability: 5, color: WHEEL_COLORS.purple, label: '0.0011 SOL', tooltip: 'Friday Special', icon: <Crown className="w-5 h-5 text-white" /> },
    { amount: 0.0008, probability: 10, color: WHEEL_COLORS.green, label: '0.0008 SOL', tooltip: 'Super Prize', icon: <Star className="w-5 h-5 text-white" /> },
    { amount: 0.0006, probability: 15, color: WHEEL_COLORS.orange, label: '0.0006 SOL', tooltip: 'Big Prize', icon: <Trophy className="w-5 h-5 text-white" /> },
    { amount: 0.0004, probability: 20, color: WHEEL_COLORS.blue, label: '0.0004 SOL', tooltip: 'Medium Prize', icon: <Medal className="w-5 h-5 text-white" /> },
    { amount: 0.0002, probability: 25, color: WHEEL_COLORS.cyan, label: '0.0002 SOL', tooltip: 'Small Prize', icon: <Gift className="w-5 h-5 text-white" /> },
    { amount: 0.0001, probability: 25, color: WHEEL_COLORS.indigo, label: '0.0001 SOL', tooltip: 'Common Prize', icon: <Coins className="w-5 h-5 text-white" /> },
  ],
  6: [ // Saturday
    { amount: 0.0013, probability: 5, color: WHEEL_COLORS.purple, label: '0.0013 SOL', tooltip: 'Weekend Special', icon: <Crown className="w-5 h-5 text-white" /> },
    { amount: 0.0009, probability: 10, color: WHEEL_COLORS.green, label: '0.0009 SOL', tooltip: 'Super Prize', icon: <Star className="w-5 h-5 text-white" /> },
    { amount: 0.0006, probability: 15, color: WHEEL_COLORS.orange, label: '0.0006 SOL', tooltip: 'Big Prize', icon: <Trophy className="w-5 h-5 text-white" /> },
    { amount: 0.0004, probability: 20, color: WHEEL_COLORS.blue, label: '0.0004 SOL', tooltip: 'Medium Prize', icon: <Medal className="w-5 h-5 text-white" /> },
    { amount: 0.0002, probability: 25, color: WHEEL_COLORS.cyan, label: '0.0002 SOL', tooltip: 'Small Prize', icon: <Gift className="w-5 h-5 text-white" /> },
    { amount: 0.0001, probability: 25, color: WHEEL_COLORS.indigo, label: '0.0001 SOL', tooltip: 'Common Prize', icon: <Coins className="w-5 h-5 text-white" /> },
  ],
};

// Update PRIZE_INFO with better styling
const PRIZE_INFO: PrizeInfo[] = [
  {
    day: 'Monday',
    amount: 0.0015,
    color: WHEEL_COLORS.purple,
    icon: <Crown className="w-6 h-6" />,
    description: 'Weekly Special - Highest reward of the week!',
    chance: '30% chance',
    highlight: true
  },
  {
    day: 'Friday',
    amount: 0.0004,
    color: WHEEL_COLORS.green,
    icon: <Star className="w-6 h-6" />,
    description: 'Friday Bonus - Start the weekend right',
    chance: '30% chance'
  },
  {
    day: 'Wednesday',
    amount: 0.0004,
    color: WHEEL_COLORS.orange,
    icon: <Gift className="w-6 h-6" />,
    description: 'Midweek Boost - Keep the momentum going',
    chance: '35% chance'
  },
  {
    day: 'Saturday',
    amount: 0.0005,
    color: WHEEL_COLORS.blue,
    icon: <Trophy className="w-6 h-6" />,
    description: 'Weekend Special - Extra weekend rewards',
    chance: '35% chance'
  },
  {
    day: 'Tuesday',
    amount: 0.0003,
    color: WHEEL_COLORS.cyan,
    icon: <Coins className="w-6 h-6" />,
    description: 'Tuesday Treasure - Steady rewards',
    chance: '45% chance'
  },
  {
    day: 'Sunday',
    amount: 0.0005,
    color: WHEEL_COLORS.indigo,
    icon: <Calendar className="w-6 h-6" />,
    description: 'Sunday Funday - Start the week strong',
    chance: '40% chance'
  }
];

// Updated PrizeInfoPopover component with better styling
const PrizeInfoPopover = () => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  // Get current day's prizes and chances
  const getCurrentDayPrizes = () => {
    const currentDay = new Date().getDay();
    return DAILY_PRIZES[currentDay as keyof typeof DAILY_PRIZES];
  };

  return (
    <div className="relative inline-flex">
      <button
        ref={buttonRef}
        className="text-gray-400 hover:text-purple-400 transition-colors p-1.5 rounded-lg hover:bg-gray-800/50"
        onClick={() => setIsOpen(!isOpen)}
        aria-label="Prize Information"
      >
        <Trophy className="w-5 h-5 text-yellow-400" />
      </button>

      {isOpen && (
        <>
          <div 
            className="fixed inset-0 bg-black/50 z-40"
            onClick={() => setIsOpen(false)}
          />
          
          <motion.div
            drag
            dragMomentum={false}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            className="fixed bg-[#1a1b23] rounded-xl shadow-xl z-50 w-[280px] sm:w-[320px] overflow-hidden border border-gray-800/50"
            style={{
              top: buttonRef.current ? buttonRef.current.getBoundingClientRect().bottom + 16 : 0,
              left: buttonRef.current ? 
                buttonRef.current.getBoundingClientRect().left - (280/2) + (buttonRef.current.offsetWidth/2) : 0,
            }}
            onClick={(e) => e.stopPropagation()}
          >
            {/* Header */}
            <div className="flex items-center justify-between p-4 border-b border-gray-800">
              <div className="flex items-center gap-2">
                <Trophy className="w-5 h-5 text-yellow-400" />
                <div>
                  <h4 className="text-white font-bold">Daily Prize Pool</h4>
                  <p className="text-xs text-gray-400">Different rewards every day of the week!</p>
                </div>
              </div>
              <button 
                onClick={() => setIsOpen(false)}
                className="text-gray-500 hover:text-gray-400"
              >
                <X className="w-4 h-4" />
              </button>
            </div>

            {/* Prize List */}
            <div className="p-3 space-y-2">
              {getCurrentDayPrizes().map((prize, index) => (
                <div 
                  key={index}
                  className={`flex items-center gap-3 p-3 rounded-xl transition-all duration-200
                    ${index === 0 ? 'bg-purple-500/10 ring-1 ring-purple-500/20' : 'bg-gray-800/50'}`}
                >
                  <div 
                    className={`w-10 h-10 rounded-xl flex items-center justify-center shrink-0
                      ${index === 0 ? 'bg-purple-500/20' : 'bg-gray-700/50'}`}
                  >
                    {prize.icon}
                  </div>
                  <div className="min-w-0 flex-1">
                    <div className="flex items-center justify-between">
                      <span className="text-white font-medium">
                        {prize.tooltip}
                      </span>
                      <span className="text-gray-400 text-sm">
                        {prize.probability}% chance
                      </span>
                    </div>
                    <div className="flex items-center justify-between mt-1">
                      <span 
                        className={`text-base font-mono font-semibold
                          ${index === 0 ? 'text-purple-400' : 'text-gray-300'}`}
                      >
                        {prize.amount.toFixed(4)} SOL
                      </span>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Footer */}
            <div className="p-3 border-t border-gray-800 bg-gray-900/50">
              <div className="flex items-center gap-2 text-xs text-gray-400">
                <Info className="w-4 h-4" />
                <span>Prizes reset daily at 00:00 UTC</span>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </div>
  );
};

// Removed sound effects URLs and related code

export function SpinWheelPopup({ isOpen, onClose, onSpin, lastSpinDate }: SpinWheelPopupProps) {
  const [isSpinning, setIsSpinning] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [canSpin, setCanSpin] = useState(true);
  const [showWinModal, setShowWinModal] = useState(false);
  const [showFairnessSection, setShowFairnessSection] = useState(false);
  const [winAmount, setWinAmount] = useState(0);
  const [fairnessHash, setFairnessHash] = useState('');
  const wheelRef = useRef<HTMLDivElement>(null);
  const [showSpinHistory, setShowSpinHistory] = useState(false);
  const [spinHistory, setSpinHistory] = useState<any[]>([]);
  const [showLeaderboard, setShowLeaderboard] = useState(false); // Leaderboard state
  // Removed sound refs
  // const spinSound = useRef(new Audio(spinSoundUrl)); // Initialize spin sound
  // const winSound = useRef(new Audio(winSoundUrl));   // Initialize win sound

  useEffect(() => {
    if (lastSpinDate) {
      const lastSpin = new Date(lastSpinDate);
      const now = new Date();
      const canSpinAgain = lastSpin.toDateString() !== now.toDateString();
      setCanSpin(canSpinAgain);
    }
  }, [lastSpinDate]);

  const triggerWinAnimation = async (amount: number, colorName: string) => {
    try {
      const user = auth.currentUser;
      if (!user) {
        console.error('No user logged in');
        return;
      }

      // Trigger confetti animation
      confetti({
        spread: 360,
        ticks: 60,
        zIndex: 9999,
        particleCount: 200,
      });

      // Generate fairness hash
      const hash = generateFairnessHash(amount);
      const now = new Date();

      // Create spin result document
      const spinResultData = {
        userId: user.uid,
        email: user.email,
        amountWon: amount,
        date: Timestamp.fromDate(now),
        fairnessHash: hash,
        colorName: colorName,
        timestamp: Timestamp.fromDate(now), // Additional timestamp for easier querying
      };

      // Use batch write to ensure data consistency
      const batch = writeBatch(db);

      // Add spin history record
      const spinHistoryRef = doc(collection(db, 'spinWheelHistory'));
      batch.set(spinHistoryRef, spinResultData);

      // Update user document
      const userRef = doc(db, 'users', user.uid);
      batch.update(userRef, {
        balance: increment(amount),
        lastSpinDate: now.toISOString(),
        totalSpins: increment(1),
        totalAmountWon: increment(amount),
        lastWinAmount: amount,
        lastSpinTimestamp: Timestamp.fromDate(now),
      });

      // Commit the batch
      await batch.commit();

      // Update local state
      setFairnessHash(hash);
      setWinAmount(amount);
      setShowWinModal(true);
      
      // Refresh spin history if it's being displayed
      if (showSpinHistory) {
        await loadSpinHistory();
      }

      // Notify parent component
      onSpin(amount);

    } catch (error) {
      console.error('Error storing spin result:', error);
      toast.error('Failed to store spin result. Please try again.');
    }
  };

  const generateFairnessHash = (amount: number) => {
    return `hash-${amount}-${new Date().getTime()}`;
  };

  const spinWheel = () => {
    if (isSpinning || !canSpin) return;

    setIsSpinning(true);

    // Get current day's prizes
    const currentDay = new Date().getDay();
    const todaysPrizes = DAILY_PRIZES[currentDay as keyof typeof DAILY_PRIZES];

    const random = Math.random() * 100;
    let cumulative = 0;
    let selectedPrize = todaysPrizes[todaysPrizes.length - 1];

    for (const prize of todaysPrizes) {
      cumulative += prize.probability;
      if (random <= cumulative) {
        selectedPrize = prize;
        break;
      }
    }

    const prizeIndex = todaysPrizes.indexOf(selectedPrize);
    const baseRotation = 360 * 10;
    const segmentAngle = 360 / todaysPrizes.length;
    const prizeRotation = segmentAngle * prizeIndex + segmentAngle / 2;
    const finalRotation = baseRotation + prizeRotation;

    setRotation(finalRotation);

    setTimeout(() => {
      setIsSpinning(false);
      triggerWinAnimation(selectedPrize.amount, selectedPrize.color);
    }, 5000);
  };

  const getTimeUntilNextSpin = () => {
    if (!lastSpinDate) return { hours: 0, minutes: 0 };
    const lastSpin = new Date(lastSpinDate);
    const now = new Date();
    const nextSpinTime = new Date(lastSpin);
    nextSpinTime.setDate(lastSpin.getDate() + 1);
    const timeDiff = nextSpinTime.getTime() - now.getTime();
    if (timeDiff <= 0) return { hours: 0, minutes: 0 };
    const totalMinutes = Math.max(0, Math.floor(timeDiff / 1000 / 60));
    return {
      hours: Math.floor(totalMinutes / 60),
      minutes: totalMinutes % 60,
    };
  };

  const loadSpinHistory = async () => {
    try {
      const user = auth.currentUser;
      if (!user) {
        toast.error('Please login to view spin history');
        return;
      }

      const spinHistoryRef = collection(db, 'spinWheelHistory');
      // Temporary query without orderBy while index is building
      const q = query(
        spinHistoryRef,
        where('userId', '==', user.uid)
      );

      const querySnapshot = await getDocs(q);
      let history = querySnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          id: doc.id,
          amountWon: data.amountWon || 0,
          timestamp: data.timestamp?.toDate() || new Date(),
          email: data.email || '',
          fairnessHash: data.fairnessHash || '',
          userId: data.userId || '',
          colorName: data.colorName || ''
        };
      });

      // Sort in memory
      history = history.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
      
      // Limit to last 20 spins
      history = history.slice(0, 20);
      
      console.log('Loaded spin history:', history); // Debug log
      setSpinHistory(history);

    } catch (error) {
      console.error('Error loading spin history:', error);
      toast.error('Failed to load spin history');
    }
  };

  const loadLeaderboard = async () => { // Added Leaderboard loading
    try {
      const leaderboardRef = collection(db, 'users');
      const q = query(
        leaderboardRef,
        orderBy('balance', 'desc'),
        limit(10)
      );

      const querySnapshot = await getDocs(q);
      const leaders = querySnapshot.docs.map(doc => ({
        id: doc.id,
        email: doc.data().email,
        balance: doc.data().balance || 0,
      }));

      setLeaderboardData(leaders);
      setShowLeaderboard(true);
    } catch (error) {
      console.error('Error loading leaderboard:', error);
    }
  };

  const [leaderboardData, setLeaderboardData] = useState<any[]>([]); // Leaderboard data state

  const { hours, minutes } = getTimeUntilNextSpin();

  const getCurrentPrizes = () => {
    const currentDay = new Date().getDay();
    return DAILY_PRIZES[currentDay as keyof typeof DAILY_PRIZES];
  };

  // Add this useEffect to load spin history when the modal is opened
  useEffect(() => {
    if (showSpinHistory && auth.currentUser) {
      loadSpinHistory();
    }
  }, [showSpinHistory]);

  // Add this helper function to mask email
  const maskEmail = (email: string) => {
    if (!email) return '';
    const [username, domain] = email.split('@');
    if (!username || !domain) return email;
    
    // Keep first character, mask the middle, keep last 2 characters of username
    const maskedUsername = username.charAt(0) + '*****' + username.slice(-2);
    return `${maskedUsername}@${domain}`;
  };

  // Add or update state to track winnings
  const [totalWinnings, setTotalWinnings] = useState(0);

  // Update your onSpin handler to track winnings
  const handleSpin = async (prize: number) => {
    // ... existing spin logic ...
    setTotalWinnings(prize); // Set the won amount
  };

  const handleWithdraw = async () => {
    const user = auth.currentUser;
    if (!user) {
        toast.error('You must be logged in to request a withdrawal');
        return;
    }

    // Fetch user data to check task completion and balance
    const userRef = doc(db, 'users', user.uid);
    const userDoc = await getDoc(userRef);
    const userData = userDoc.data();

    // Check if the user has completed tasks for 7 consecutive days
    if (userData?.streak < 7) {
        toast.error('You need a 7-day streak to withdraw');
        return;
    }

    // Check if the user has a balance of at least $10
    if (userData?.balance < 10) {
        toast.error('You need at least $10.00 in your balance to make a withdrawal');
        return;
    }

    // Check if the user has already requested a withdrawal in the last 7 days
    const now = new Date();
    const lastWithdrawalDate = userData?.lastWithdrawalDate?.toDate();
    if (lastWithdrawalDate) {
        const daysSinceLastWithdrawal = Math.floor((now.getTime() - lastWithdrawalDate.getTime()) / (1000 * 60 * 60 * 24));
        if (daysSinceLastWithdrawal < 7) {
            toast.error('You can only request a withdrawal once every 7 days');
            return;
        }
    }

    // Proceed with withdrawal logic
    // ... existing withdrawal logic ...

    // Update last withdrawal date in Firestore
    await updateDoc(userRef, {
        lastWithdrawalDate: serverTimestamp(),
    });

    // Log the withdrawal attempt
    await logUserAction(user.uid, 'withdrawal', 'User requested a withdrawal');
  };

  // Function to log user actions
  const logUserAction = async (userId: string, action: string, description: string) => {
    const logRef = collection(db, 'userLogs');
    await addDoc(logRef, {
        userId,
        action,
        description,
        timestamp: serverTimestamp(),
    });
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black"
            onClick={onClose}
            aria-label="Close spin wheel popup"
          />
          {/* Popup Container */}
          <motion.div
            initial={{ opacity: 0, scale: 0.95, y: 20 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.95, y: 20 }}
            className="fixed inset-0 flex items-center justify-center p-4 pointer-events-none"
          >
            <div
              className="bg-gray-900 p-8 rounded-xl shadow-xl max-w-md w-full pointer-events-auto border border-gray-800"
              role="dialog"
              aria-modal="true"
              aria-labelledby="spin-wheel-title"
            >
              {/* Header */}
              <div className="flex items-center justify-between mb-6">
                <div className="flex items-center gap-3">
                  <PrizeInfoPopover />
                  <div>
                    <h3 id="spin-wheel-title" className="text-xl font-bold text-white">Daily Spin</h3>
                    <p className="text-sm text-gray-400">Try your luck once per day!</p>
                  </div>
                </div>
                <Button variant="ghost" size="sm" onClick={onClose} aria-label="Close popup">
                  <X className="h-5 w-5" />
                </Button>
              </div>

              {/* Spin Wheel and Fairness Icon */}
              <div className="relative w-64 h-64 mx-auto mb-8">
                {/* Overlay for DAILY SPIN text */}
                <div className="absolute inset-0 flex items-center justify-center z-20">
                  <span className="text-2xl font-bold text-white animate-pulse">DAILY SPIN</span>
                </div>

                {/* Simplified Winner Indicator */}
                <div className="absolute -top-2 left-1/2 transform -translate-x-1/2 z-20">
                  {/* Main Triangle */}
                  <motion.div
                    animate={{ 
                      y: [0, -2, 0]
                    }}
                    transition={{ 
                      repeat: Infinity, 
                      duration: 1.5,
                      ease: "easeInOut"
                    }}
                    className="relative w-4 h-6"
                  >
                    <div className="absolute inset-0 bg-gradient-to-b from-yellow-300 via-yellow-400 to-yellow-500
                      clip-triangle shadow-[0_0_8px_2px_rgba(250,204,21,0.3)]" />
                    
                    {/* Inner Highlight */}
                    <div className="absolute inset-0 bg-gradient-to-b from-white via-transparent to-transparent
                      opacity-50 clip-triangle scale-90" />
                  </motion.div>
                </div>

                {/* Spin Wheel */}
                <motion.div
                  ref={wheelRef}
                  className="w-full h-full rounded-full relative overflow-hidden border-4 border-gray-800"
                  style={{
                    backgroundImage: `conic-gradient(${getCurrentPrizes().map(
                      (prize, index) =>
                        `${prize.color} ${index * (360 / getCurrentPrizes().length)}deg ${
                          ((index + 1) * 360) / getCurrentPrizes().length
                        }deg`
                    ).join(', ')})`,
                  }}
                  animate={{ rotate: rotation }}
                  transition={{ duration: 5, ease: 'easeOut' }}
                >
                  {/* Prize Labels */}
                  {getCurrentPrizes().map((prize, index) => {
                    const angle = (360 / getCurrentPrizes().length) * index + (360 / getCurrentPrizes().length) / 2;
                    const radians = (angle * Math.PI) / 180;
                    const radius = 100;
                    const x = 50 + radius * Math.cos(radians);
                    const y = 50 + radius * Math.sin(radians);
                    return (
                      <div
                        key={index}
                        className="absolute transform -translate-x-1/2 -translate-y-1/2 text-xs text-white"
                        style={{ top: `${y}%`, left: `${x}%` }}
                        data-tooltip-id={`prize-tooltip-${index}`}
                        aria-label={`Prize: ${prize.label}`}
                      >
                        {prize.amount} SOL
                        <Tooltip id={`prize-tooltip-${index}`} place="top" />
                      </div>
                    );
                  })}
                </motion.div>
              </div>

              {/* Buttons */}
              <div className="flex gap-2">
                <Button
                  className="flex-1"
                  onClick={spinWheel}
                  disabled={isSpinning || !canSpin}
                  aria-disabled={isSpinning || !canSpin}
                >
                  {isSpinning ? 'Spinning...' : canSpin ? 'Spin to Win!' : `Come back in: ${hours}h ${minutes}m`}
                </Button>
                <Button
                  variant="outline"
                  onClick={() => {
                    setShowSpinHistory(true);
                    loadSpinHistory();
                  }}
                  aria-label="View spin history"
                >
                  <Clock className="h-5 w-5" />
                </Button>
                <Button
                  variant="outline"
                  onClick={() => {
                    console.log('Leaderboard button clicked');
                    loadLeaderboard();
                  }}
                  aria-label="View leaderboard"
                >
                  <Users className="h-5 w-5" />
                </Button>
              </div>
            </div>
          </motion.div>

          {/* Win Modal */}
          {showWinModal && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 flex items-center justify-center z-50"
            >
              <div className="bg-gray-900 p-6 rounded-lg shadow-lg max-w-xs w-full mx-4 border border-gray-800" role="dialog">
                {/* Trophy Icon */}
                <div className="flex flex-col items-center mb-4">
                  <Trophy className="h-12 w-12 text-yellow-500 mb-2" />
                  <h3 className="text-xl font-bold text-white">Congratulations!</h3>
                  <p className="text-gray-300 mt-1 text-center">
                    You won <span className="font-bold">{winAmount.toFixed(4)} SOL!</span>
                  </p>
                </div>
                
                {/* Buttons Container */}
                <div className="flex gap-2 justify-center mt-4">
                  <button
                    className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md flex items-center justify-center"
                    onClick={() => {
                      const shareText = `🎉 Just won ${winAmount.toFixed(4)} SOL on http://Dailysolly.eu\n\n` +
                        `💫 You really need to try this - it's totally FREE!\n` +
                        `🎯 Daily rewards and instant withdrawals\n\n` +
                        `Try your luck at https://dailysolly.eu\n\n` +
                        `#Solana #Crypto #PassiveIncome #SolanaIncome #Cryptocurrency #Rewards #SOL`;
                      const twitterUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(shareText)}`;
                      window.open(twitterUrl, '_blank');
                    }}
                  >
                    <Share2 className="h-4 w-4 mr-1" /> Share
                  </button>
                  <button
                    className="bg-gray-700 hover:bg-gray-600 text-white px-4 py-2 rounded-md"
                    onClick={() => setShowWinModal(false)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </motion.div>
          )}

          {/* Fairness Section Popup */}
          {showFairnessSection && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 flex items-center justify-center z-50"
            >
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.5 }}
                exit={{ opacity: 0 }}
                className="fixed inset-0 bg-black"
                onClick={() => setShowFairnessSection(false)}
              />
              <motion.div
                initial={{ opacity: 0, scale: 0.95, y: 20 }}
                animate={{ opacity: 1, scale: 1, y: 0 }}
                exit={{ opacity: 0, scale: 0.95, y: 20 }}
                className="relative bg-gray-900 p-8 rounded-xl shadow-xl max-w-md w-full pointer-events-auto border border-gray-800"
                role="dialog"
                aria-modal="true"
                aria-labelledby="fairness-section-title"
              >
                <div className="flex justify-between items-center mb-4">
                  <h3 id="fairness-section-title" className="text-xl font-bold text-white">Fairness Verification</h3>
                  <Button variant="ghost" size="sm" onClick={() => setShowFairnessSection(false)} aria-label="Close fairness verification">
                    <X className="h-5 w-5" />
                  </Button>
                </div>
                <div className="bg-gray-800 p-6 rounded-lg text-center">
                  <ShieldCheck className="h-16 w-16 text-green-400 mx-auto mb-4" />
                  <h3 className="text-xl font-bold text-white">Fairness Verified</h3>
                  <p className="text-gray-300 mt-2">
                    The fairness hash for this spin is:
                  </p>
                  <p className="bg-gray-700 p-2 rounded-lg text-green-400 mt-2 font-mono text-sm">
                    {fairnessHash}
                  </p>
                </div>
              </motion.div>
            </motion.div>
          )}

          {/* Spin History Modal */}
          {showSpinHistory && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 flex items-center justify-center z-50"
            >
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.5 }}
                exit={{ opacity: 0 }}
                className="fixed inset-0 bg-black"
                onClick={() => setShowSpinHistory(false)}
              />
              <motion.div
                initial={{ opacity: 0, scale: 0.95, y: 20 }}
                animate={{ opacity: 1, scale: 1, y: 0 }}
                exit={{ opacity: 0, scale: 0.95, y: 20 }}
                className="relative bg-gray-900 p-6 rounded-xl shadow-xl max-w-md w-full pointer-events-auto border border-gray-800"
              >
                {/* Header */}
                <div className="flex justify-between items-center mb-4">
                  <div className="flex items-center gap-3">
                    <div className="p-2 bg-purple-500/10 rounded-lg">
                      <Clock className="h-5 w-5 text-purple-400" />
                    </div>
                    <div>
                      <h3 className="text-xl font-bold text-white">Spin History</h3>
                      <p className="text-sm text-gray-400">Your recent spins and rewards</p>
                    </div>
                  </div>
                  <Button
                    variant="ghost"
                    size="sm"
                    onClick={() => setShowSpinHistory(false)}
                    className="text-gray-400 hover:text-white"
                  >
                    <X className="h-5 w-5" />
                  </Button>
                </div>

                {/* History List */}
                <div className="space-y-3 max-h-[400px] overflow-y-auto pr-2 -mr-2">
                  {spinHistory && spinHistory.length > 0 ? (
                    spinHistory.map((spin) => (
                      <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        key={spin.id}
                        className="bg-gray-800/50 rounded-lg p-4 border border-gray-700/50 hover:border-purple-500/20 transition-colors"
                      >
                        {/* Amount and Date */}
                        <div className="flex items-center justify-between mb-2">
                          <div className="flex items-center gap-2">
                            <div
                              className="w-3 h-3 rounded-full ring-2 ring-white/10"
                              style={{ backgroundColor: spin.colorName }}
                            />
                            <span className="text-white font-mono font-semibold">
                              +{spin.amountWon.toFixed(4)} SOL
                            </span>
                          </div>
                          <span className="text-sm text-gray-400">
                            {new Date(spin.timestamp).toLocaleString()}
                          </span>
                        </div>

                        {/* Masked Email */}
                        <div className="flex items-center gap-2 text-sm text-gray-400 mb-2">
                          <Users className="h-4 w-4 opacity-50" />
                          <span className="truncate">{maskEmail(spin.email)}</span>
                        </div>
                        
                        {/* Fairness Hash */}
                        <div className="mt-2 text-xs font-mono bg-black/20 rounded-lg p-2 text-gray-400">
                          <div className="flex items-center gap-2">
                            <ShieldCheck className="h-4 w-4 text-green-400" />
                            <span className="truncate" title={spin.fairnessHash}>
                              {spin.fairnessHash}
                            </span>
                          </div>
                        </div>
                      </motion.div>
                    ))
                  ) : (
                    <div className="text-center py-8 text-gray-400">
                      <div className="bg-gray-800/50 rounded-lg p-8 border border-gray-700/50">
                        <Trophy className="h-12 w-12 mx-auto mb-3 text-gray-600" />
                        <p className="font-medium">No spin history found</p>
                        <p className="text-sm mt-1 text-gray-500">
                          Try your luck with the daily spin!
                        </p>
                        <Button
                          variant="outline"
                          size="sm"
                          className="mt-4"
                          onClick={() => {
                            setShowSpinHistory(false);
                            // Add any additional action to trigger the spin
                          }}
                        >
                          Spin Now
                        </Button>
                      </div>
                    </div>
                  )}
                </div>

                {/* Footer - Optional Statistics */}
                {spinHistory.length > 0 && (
                  <div className="mt-4 pt-4 border-t border-gray-800">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="text-center p-2 bg-gray-800/50 rounded-lg">
                        <div className="text-sm text-gray-400">Total Spins</div>
                        <div className="text-lg font-bold text-white">{spinHistory.length}</div>
                      </div>
                      <div className="text-center p-2 bg-gray-800/50 rounded-lg">
                        <div className="text-sm text-gray-400">Total Won</div>
                        <div className="text-lg font-bold text-white">
                          {spinHistory.reduce((acc, spin) => acc + spin.amountWon, 0).toFixed(4)} SOL
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </motion.div>
            </motion.div>
          )}

          {/* Leaderboard Modal */}
          {showLeaderboard && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 flex items-center justify-center z-50"
            >
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.5 }}
                exit={{ opacity: 0 }}
                className="fixed inset-0 bg-black"
                onClick={() => setShowLeaderboard(false)}
              />
              <motion.div
                initial={{ opacity: 0, scale: 0.95, y: 20 }}
                animate={{ opacity: 1, scale: 1, y: 0 }}
                exit={{ opacity: 0, scale: 0.95, y: 20 }}
                className="relative bg-gray-900 p-8 rounded-xl shadow-xl max-w-lg w-full pointer-events-auto border border-gray-800 overflow-auto max-h-screen"
                role="dialog"
                aria-modal="true"
                aria-labelledby="leaderboard-title"
              >
                <div className="flex justify-between items-center mb-4">
                  <div>
                    <h3 id="leaderboard-title" className="text-xl font-bold text-white">Leaderboard</h3>
                    <p className="text-sm text-gray-400">Top winners</p>
                  </div>
                  <Button variant="ghost" size="sm" onClick={() => setShowLeaderboard(false)} aria-label="Close leaderboard">
                    <X className="h-5 w-5" />
                  </Button>
                </div>
                <div className="space-y-2 max-h-96 overflow-y-auto">
                  {leaderboardData.length > 0 ? (
                    leaderboardData.map((user, index) => {
                      let RankIcon;
                      if (index === 0) {
                        RankIcon = Crown;
                      } else if (index === 1 || index === 2) {
                        RankIcon = Medal;
                      } else {
                        RankIcon = Trophy;
                      }

                      // Mask email address
                      const maskEmail = (email: string) => {
                        const [username, domain] = email.split('@');
                        const firstPart = username.slice(0, 3);
                        const lastPart = username.slice(-2);
                        const maskedPart = '*'.repeat(5);
                        return `${firstPart}${maskedPart}${lastPart}@${domain}`;
                      };

                      return (
                        <div key={user.id} className="bg-gray-800 p-4 rounded-lg flex items-center justify-between">
                          <div className="flex items-center gap-2">
                            <RankIcon className="h-5 w-5 text-yellow-400" />
                            <span className="text-white font-bold">{index + 1}. {maskEmail(user.email)}</span>
                          </div>
                          <span className="text-yellow-400 font-mono">{user.balance.toFixed(4)} SOL</span>
                        </div>
                      );
                    })
                  ) : (
                    <div className="text-center text-gray-400 py-4">
                      No leaderboard data found
                    </div>
                  )}
                </div>
              </motion.div>
            </motion.div>
          )}

          <Button
            variant="outline"
            onClick={() => {
              const user = auth.currentUser;
              console.log('Current user:', user?.uid);
              console.log('Current spin history:', spinHistory);
            }}
          >
            Debug Data
          </Button>
        </>
      )}
    </AnimatePresence>
  );
}
