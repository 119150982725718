import { Navigate, Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '@/lib/firebase';
import type { User } from 'firebase/auth';
import { LoadingScreen } from '../shared/LoadingScreen';

interface AdminRouteProps {
  user: User | null | undefined;
}

export function AdminRoute({ user }: AdminRouteProps) {
  const [isAdmin, setIsAdmin] = useState<boolean | null>(null);

  useEffect(() => {
    async function checkAdminStatus() {
      if (!user) {
        setIsAdmin(false);
        return;
      }

      const userDoc = await getDoc(doc(db, 'users', user.uid));
      setIsAdmin(userDoc.data()?.isAdmin ?? false);
    }

    checkAdminStatus();
  }, [user]);

  if (isAdmin === null) {
    return <LoadingScreen />;
  }

  if (!user || !isAdmin) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
}