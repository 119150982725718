import { type ClassValue, clsx } from 'clsx';
import { twMerge } from 'tailwind-merge';
import { doc, getDoc, getDocs, query, collection, limit, where } from 'firebase/firestore';
import { db } from './firebase';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// Firebase helper functions
export async function getUserData(userId: string) {
  const userDoc = await getDoc(doc(db, 'users', userId));
  return userDoc.exists() ? userDoc.data() : null;
}

export function formatDate(date: Date | string) {
  return new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });
}

export function generateTicketNumber() {
  return `TKT-${Math.random().toString(36).substr(2, 9).toUpperCase()}`;
}

export function formatCurrency(amount: number) {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(amount);
}

export async function getUserDataBatch(userIds: string[]) {
  const userRefs = userIds.map(id => doc(db, 'users', id));
  const userSnapshots = await getDocs(query(collection(db, 'users'), where('__name__', 'in', userIds)));
  return userSnapshots.docs.map(doc => doc.data());
}