import { useState, useEffect } from 'react';
import { db } from '@/lib/firebase';
import { collection, addDoc, getDocs, query, orderBy, doc, deleteDoc, updateDoc } from 'firebase/firestore';
import { Button } from '@/components/ui/Button';
import { toast } from 'react-hot-toast';

interface Announcement {
  id: string;
  title: string;
  content: string;
  createdAt: Date;
  archived: boolean; // New property for archiving
}

export function AnnouncementManagement() {
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const [newAnnouncement, setNewAnnouncement] = useState({ title: '', content: '' });
  const [editingId, setEditingId] = useState<string | null>(null);
  const [updatedData, setUpdatedData] = useState<Partial<Announcement>>({ title: '', content: '' });

  useEffect(() => {
    const fetchAnnouncements = async () => {
      const announcementsRef = collection(db, 'announcements');
      const q = query(announcementsRef, orderBy('createdAt', 'desc'));
      const snapshot = await getDocs(q);
      const announcementsData = snapshot.docs.map(doc => ({
        id: doc.id,
        title: doc.data().title,
        content: doc.data().content,
        createdAt: doc.data().createdAt ? new Date(doc.data().createdAt.seconds * 1000) : new Date(),
        archived: doc.data().archived || false, // Default to false if not set
      }));
      setAnnouncements(announcementsData);
    };

    fetchAnnouncements();
  }, []);

  const handleCreateAnnouncement = async () => {
    if (!newAnnouncement.title || !newAnnouncement.content) {
      toast.error('Please fill in all fields.');
      return;
    }

    try {
      const announcementData = {
        title: newAnnouncement.title,
        content: newAnnouncement.content,
        createdAt: new Date(),
        archived: false, // New announcements are not archived by default
      };
      await addDoc(collection(db, 'announcements'), announcementData);
      setAnnouncements(prev => [{ id: '', ...announcementData }, ...prev]); // Add to local state
      setNewAnnouncement({ title: '', content: '' }); // Reset form
      toast.success('Announcement created successfully!');
    } catch (error) {
      console.error('Error creating announcement:', error);
      toast.error('Failed to create announcement. Please try again.');
    }
  };

  const deleteAnnouncement = async (id: string) => {
    await deleteDoc(doc(db, 'announcements', id));
    setAnnouncements(announcements.filter(announcement => announcement.id !== id));
    toast.success('Announcement deleted successfully!');
  };

  const archiveAnnouncement = async (id: string) => {
    await updateDoc(doc(db, 'announcements', id), { archived: true });
    setAnnouncements(announcements.map(announcement => 
      announcement.id === id ? { ...announcement, archived: true } : announcement
    ));
    toast.success('Announcement archived successfully!');
  };

  const handleEditClick = (announcement: Announcement) => {
    setEditingId(announcement.id);
    setUpdatedData({ title: announcement.title, content: announcement.content });
  };

  const handleUpdateAnnouncement = async (id: string) => {
    await updateDoc(doc(db, 'announcements', id), updatedData);
    setAnnouncements(announcements.map(announcement => 
      announcement.id === id ? { ...announcement, ...updatedData } : announcement
    ));
    setEditingId(null); // Reset editing state
    setUpdatedData({ title: '', content: '' }); // Clear input fields
    toast.success('Announcement updated successfully!');
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 p-6 bg-gray-900 rounded-lg shadow-lg">
      {/* Left Column: Create Announcement */}
      <div className="bg-gray-800 p-4 rounded-lg">
        <h2 className="text-2xl font-bold mb-4">Create Announcement</h2>
        <input
          type="text"
          placeholder="Title"
          value={newAnnouncement.title}
          onChange={(e) => setNewAnnouncement({ ...newAnnouncement, title: e.target.value })}
          className="w-full px-4 py-2 mb-2 bg-gray-700 border border-gray-600 rounded-lg"
        />
        <textarea
          placeholder="Content"
          value={newAnnouncement.content}
          onChange={(e) => setNewAnnouncement({ ...newAnnouncement, content: e.target.value })}
          className="w-full px-4 py-2 mb-4 bg-gray-700 border border-gray-600 rounded-lg h-20 resize-none"
        />
        <Button onClick={handleCreateAnnouncement} className="w-full">Create Announcement</Button>
      </div>

      {/* Right Column: Existing Announcements */}
      <div className="bg-gray-800 p-4 rounded-lg">
        <h3 className="text-lg font-semibold mt-6">Existing Announcements</h3>
        <div className="space-y-4 mt-4">
          {announcements.map(announcement => (
            <div key={announcement.id} className="p-4 bg-gray-700 rounded-lg">
              <h4 className="font-medium">{announcement.title}</h4>
              <p className="text-sm text-gray-300">{announcement.content}</p>
              <p className="text-xs text-gray-400">Posted on: {announcement.createdAt.toLocaleString()}</p>
              <p className="text-xs text-gray-400">Status: {announcement.archived ? 'Archived' : 'Active'}</p>
              <div className="flex space-x-2 mt-2">
                <Button onClick={() => deleteAnnouncement(announcement.id)} className="bg-red-500">Delete</Button>
                <Button onClick={() => archiveAnnouncement(announcement.id)} className="bg-yellow-500">Archive</Button>
                <Button onClick={() => handleEditClick(announcement)} className="bg-blue-500">Edit</Button>
              </div>
              {editingId === announcement.id && (
                <div className="mt-2">
                  <input
                    type="text"
                    value={updatedData.title}
                    onChange={(e) => setUpdatedData({ ...updatedData, title: e.target.value })}
                    placeholder="Update Title"
                    className="w-full px-4 py-2 mb-2 bg-gray-700 border border-gray-600 rounded-lg"
                  />
                  <textarea
                    value={updatedData.content}
                    onChange={(e) => setUpdatedData({ ...updatedData, content: e.target.value })}
                    placeholder="Update Content"
                    className="w-full px-4 py-2 mb-2 bg-gray-700 border border-gray-600 rounded-lg h-20 resize-none"
                  />
                  <Button onClick={() => handleUpdateAnnouncement(announcement.id)} className="w-full">Save Changes</Button>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}