import { ResponsiveContainer, AreaChart, Area, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';

interface ActivityChartProps {
  data: any[];
}

export function ActivityChart({ data }: ActivityChartProps) {
  const chartData = [
    { name: 'Mon', points: 30, tasks: 5 },
    { name: 'Tue', points: 45, tasks: 7 },
    { name: 'Wed', points: 25, tasks: 4 },
    { name: 'Thu', points: 60, tasks: 8 },
    { name: 'Fri', points: 35, tasks: 6 },
    { name: 'Sat', points: 40, tasks: 5 },
    { name: 'Sun', points: 50, tasks: 7 },
  ];

  return (
    <div className="h-[200px]">
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart data={chartData}>
          <defs>
            <linearGradient id="colorPoints" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8b5cf6" stopOpacity={0.8}/>
              <stop offset="95%" stopColor="#8b5cf6" stopOpacity={0}/>
            </linearGradient>
            <linearGradient id="colorTasks" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#ec4899" stopOpacity={0.8}/>
              <stop offset="95%" stopColor="#ec4899" stopOpacity={0}/>
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" stroke="#374151" />
          <XAxis dataKey="name" stroke="#9ca3af" />
          <YAxis stroke="#9ca3af" />
          <Tooltip
            contentStyle={{
              backgroundColor: '#1f2937',
              border: '1px solid #374151',
              borderRadius: '0.5rem',
            }}
          />
          <Area
            type="monotone"
            dataKey="points"
            stroke="#8b5cf6"
            fillOpacity={1}
            fill="url(#colorPoints)"
          />
          <Area
            type="monotone"
            dataKey="tasks"
            stroke="#ec4899"
            fillOpacity={1}
            fill="url(#colorTasks)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}