import React, { useState, useEffect } from 'react';
import { collection, addDoc, query, orderBy, onSnapshot, Timestamp, DocumentData, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { db } from '@/lib/firebase';
import { Button } from '@/components/ui/Button';
import { 
  X, 
  MessageCircle, 
  Tag, 
  User, 
  Clock,
  PenSquare,
  Send,
  MessageSquare,
  AlertCircle,
  Trash2,
  Heart,
  Reply,
  Edit,
} from 'lucide-react';
import { toast } from 'react-toastify';
import { RecentDiscussions } from './RecentDiscussions';

interface TopicsProps {
  isOpen: boolean;
  onClose: () => void;
  user: any;
}

interface Topic extends DocumentData {
  id: string;
  title: string;
  description: string;
  tag: string;
  userId: string;
  userEmail: string;
  createdAt: Timestamp;
  likes: number;
  likedBy: string[];
  replies: Reply[];
  status: 'active' | 'closed';
  isNew: boolean;
  hasNewReplies: boolean;
}

interface Reply {
  id: string;
  content: string;
  userId: string;
  userEmail: string;
  createdAt: Timestamp;
}

const TAGS = ['Uncategorized', 'General', 'Help', 'Discussion', 'Feedback', 'Question', 'Other'];

const maskEmail = (email: string) => {
  if (!email) return '';
  const [username, domain] = email.split('@');
  return `*****@${domain}`;
};

export function Topics({ isOpen, onClose, user }: TopicsProps) {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [selectedTag, setSelectedTag] = useState('Uncategorized');
  const [topics, setTopics] = useState<Topic[]>([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [replyContent, setReplyContent] = useState('');
  const [selectedTopic, setSelectedTopic] = useState<Topic | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editingTopic, setEditingTopic] = useState<Topic | null>(null);
  const [isRecentDiscussionsOpen, setIsRecentDiscussionsOpen] = useState(false);

  useEffect(() => {
    if (!isOpen) return;

    const topicsRef = collection(db, 'topics');
    const q = query(topicsRef, orderBy('createdAt', 'desc'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const topicsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Topic[];
      setTopics(topicsData);
    }, (error) => {
      console.error('Error fetching topics:', error);
      toast.error('Failed to load topics');
    });

    return () => unsubscribe();
  }, [isOpen]);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!title.trim() || !description.trim()) {
      toast.error('Please fill in all fields');
      return;
    }

    if (!user?.uid) {
      toast.error('You must be logged in to create a topic');
      return;
    }

    setIsSubmitting(true);

    try {
      const topicData = {
        title: title.trim(),
        description: description.trim(),
        tag: selectedTag === '' ? 'Uncategorized' : selectedTag,
        userId: user.uid,
        userEmail: user.email,
        createdAt: Timestamp.now(),
        likes: 0,
        likedBy: [],
        replies: [],
        status: 'active',
        isNew: true,
        hasNewReplies: false
      };

      const topicsRef = collection(db, 'topics');
      await addDoc(topicsRef, topicData);

      setTitle('');
      setDescription('');
      setSelectedTag('Uncategorized');
      toast.success('Topic created successfully!');
    } catch (error) {
      console.error('Error creating topic:', error);
      toast.error('Failed to create topic. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEdit = async (topic: Topic) => {
    setEditingTopic(topic);
    setTitle(topic.title);
    setDescription(topic.description);
    setSelectedTag(topic.tag);
    setIsEditing(true);
  };

  const handleUpdate = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!editingTopic) return;

    setIsSubmitting(true);
    try {
      const topicRef = doc(db, 'topics', editingTopic.id);
      await updateDoc(topicRef, {
        title: title.trim(),
        description: description.trim(),
        tag: selectedTag,
        updatedAt: Timestamp.now()
      });

      setIsEditing(false);
      setEditingTopic(null);
      setTitle('');
      setDescription('');
      setSelectedTag('Uncategorized');
      toast.success('Topic updated successfully!');
    } catch (error) {
      console.error('Error updating topic:', error);
      toast.error('Failed to update topic');
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async (topicId: string) => {
    if (!window.confirm('Are you sure you want to delete this topic?')) return;

    try {
      await deleteDoc(doc(db, 'topics', topicId));
      toast.success('Topic deleted successfully');
    } catch (error) {
      console.error('Error deleting topic:', error);
      toast.error('Failed to delete topic');
    }
  };

  const handleLike = async (topic: Topic) => {
    if (!user?.uid) {
      toast.error('You must be logged in to like topics');
      return;
    }

    try {
      const topicRef = doc(db, 'topics', topic.id);
      const hasLiked = topic.likedBy?.includes(user.uid);

      if (hasLiked) {
        await updateDoc(topicRef, {
          likes: topic.likes - 1,
          likedBy: topic.likedBy.filter((uid: string) => uid !== user.uid)
        });
      } else {
        await updateDoc(topicRef, {
          likes: topic.likes + 1,
          likedBy: [...(topic.likedBy || []), user.uid]
        });
      }
    } catch (error) {
      console.error('Error updating likes:', error);
      toast.error('Failed to update like');
    }
  };

  const handleReply = async (topic: Topic) => {
    if (!replyContent.trim()) {
      toast.error('Reply cannot be empty');
      return;
    }

    if (!user?.uid) {
      toast.error('You must be logged in to reply');
      return;
    }

    try {
      const topicRef = doc(db, 'topics', topic.id);
      const newReply = {
        id: Math.random().toString(36).substr(2, 9),
        content: replyContent.trim(),
        userId: user.uid,
        userEmail: user.email,
        createdAt: Timestamp.now()
      };

      await updateDoc(topicRef, {
        replies: [...(topic.replies || []), newReply],
        hasNewReplies: true
      });

      setReplyContent('');
      setSelectedTopic(null);
      toast.success('Reply added successfully');
    } catch (error) {
      console.error('Error adding reply:', error);
      toast.error('Failed to add reply');
    }
  };

  const handleViewTopic = async (topic: Topic) => {
    try {
      const topicRef = doc(db, 'topics', topic.id);
      
      // Clear appropriate notification flags
      if (topic.userId === user?.uid && topic.hasNewReplies) {
        await updateDoc(topicRef, {
          hasNewReplies: false
        });
      } else if (topic.userId !== user?.uid && topic.isNew) {
        await updateDoc(topicRef, {
          isNew: false
        });
      }
      
      setSelectedTopic(topic);
    } catch (error) {
      console.error('Error clearing notifications:', error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/80 flex items-center justify-center z-50 backdrop-blur-sm">
      <div className="bg-gray-900 rounded-xl p-6 w-full max-w-xl max-h-[80vh] overflow-y-auto border border-gray-800">
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center gap-2">
            <MessageCircle className="h-6 w-6 text-purple-400" />
            <h2 className="text-xl font-bold text-white">Community Topics</h2>
          </div>
          <div className="flex items-center gap-2">
            <Button
              variant="outline"
              size="sm"
              onClick={() => setIsRecentDiscussionsOpen(true)}
              className="flex items-center gap-2"
            >
              <MessageSquare className="h-4 w-4" />
              View All
            </Button>
            <Button 
              variant="ghost" 
              size="sm" 
              onClick={onClose}
              className="hover:bg-gray-800 rounded-full p-2"
            >
              <X className="h-5 w-5 text-gray-400 hover:text-white transition-colors" />
            </Button>
          </div>
        </div>

        <form onSubmit={isEditing ? handleUpdate : handleSubmit} className="mb-6 space-y-4 bg-gray-800/50 p-4 rounded-xl border border-gray-700/50">
          <div className="flex items-center gap-2 mb-2">
            <PenSquare className="h-5 w-5 text-purple-400" />
            <h3 className="text-lg font-semibold text-white">
              {isEditing ? 'Edit Topic' : 'Share Your Thoughts'}
            </h3>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-200 mb-1">
              Title <span className="text-red-400">*</span>
            </label>
            <input
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              className="w-full px-3 py-2 bg-gray-700 rounded-lg text-white border border-gray-600 focus:border-purple-500 focus:ring-1 focus:ring-purple-500"
              placeholder="What's on your mind?"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-200 mb-1">
              Description <span className="text-red-400">*</span>
            </label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="w-full px-3 py-2 bg-gray-700 rounded-lg text-white border border-gray-600 focus:border-purple-500 focus:ring-1 focus:ring-purple-500"
              rows={3}
              placeholder="Share more details about your topic..."
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-200 mb-1">
              Category
            </label>
            <div className="flex items-center gap-2">
              <Tag className="h-4 w-4 text-purple-400" />
              <select
                value={selectedTag}
                onChange={(e) => setSelectedTag(e.target.value || 'Uncategorized')}
                className="w-full px-3 py-2 bg-gray-700 rounded-lg text-white border border-gray-600 focus:border-purple-500 focus:ring-1 focus:ring-purple-500"
              >
                {TAGS.map((tag) => (
                  <option key={tag} value={tag}>{tag}</option>
                ))}
              </select>
            </div>
          </div>

          <Button 
            type="submit" 
            className="w-full flex items-center justify-center gap-2 bg-purple-600 hover:bg-purple-700"
            disabled={isSubmitting}
          >
            {isEditing ? <Edit className="h-4 w-4" /> : <Send className="h-4 w-4" />}
            {isSubmitting ? 'Processing...' : isEditing ? 'Update Topic' : 'Create Topic'}
          </Button>
        </form>
      </div>
      <RecentDiscussions 
        isOpen={isRecentDiscussionsOpen} 
        onClose={() => setIsRecentDiscussionsOpen(false)}
        user={user}
      />
    </div>
  );
}