import React, { useEffect, useState, useRef } from 'react';
import { collection, query, orderBy, onSnapshot, Timestamp, DocumentData, doc, updateDoc, deleteDoc, arrayUnion, getDoc, where } from 'firebase/firestore';
import { db } from '@/lib/firebase';
import { 
  MessageSquare, 
  User, 
  Clock,
  Heart,
  MessageCircle,
  X,
  PenSquare,
  Trash2,
  Send,
} from 'lucide-react';
import { toast } from 'react-toastify';
import { Button } from '@/components/ui/Button';

interface Topic extends DocumentData {
  id: string;
  title: string;
  description: string;
  tag: string;
  userId: string;
  userEmail: string;
  createdAt: Timestamp;
  likes: number;
  likedBy: string[];
  replies: Reply[];
  status: 'active' | 'closed';
  userRole: string;
}

interface Reply {
  id: string;
  content: string;
  userId: string;
  userEmail: string;
  createdAt: Timestamp;
  userRole: string;
  isRead?: boolean;
}

interface User {
  uid: string;
  email: string;
  role?: string;
}

interface RecentDiscussionsProps {
  isOpen: boolean;
  onClose: () => void;
  user?: User | null;
}

const getRoleColor = (role: string) => {
  switch (role.toLowerCase()) {
    case 'admin':
      return 'text-red-400';
    case 'moderator':
      return 'text-blue-400';
    default:
      return 'text-gray-400';
  }
};

const maskEmail = (email: string) => {
  if (!email) return '';
  const [username, domain] = email.split('@');
  const maskedUsername = username.charAt(0) + '*'.repeat(username.length - 1);
  return `${maskedUsername}@${domain}`;
};

const DeleteConfirmationDialog = ({ 
  isOpen, 
  onClose, 
  onConfirm 
}: { 
  isOpen: boolean; 
  onClose: () => void; 
  onConfirm: () => void; 
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/80 flex items-center justify-center z-[70] backdrop-blur-sm">
      <div className="bg-gray-900 rounded-xl p-6 w-full max-w-md border border-gray-800 shadow-lg">
        <h3 className="text-xl font-semibold text-white mb-4">Delete Topic</h3>
        <p className="text-gray-300 mb-6">Are you sure you want to delete this topic? This action cannot be undone.</p>
        
        <div className="flex justify-end gap-3">
          <Button
            variant="outline"
            onClick={onClose}
            className="hover:bg-gray-800"
          >
            Cancel
          </Button>
          <Button
            variant="destructive"
            onClick={onConfirm}
            className="bg-red-500 hover:bg-red-600 text-white"
          >
            Delete
          </Button>
        </div>
      </div>
    </div>
  );
};

// Add this function to sort replies by date
const sortRepliesByDate = (replies: Reply[]) => {
  return [...replies].sort((a, b) => 
    b.createdAt.toMillis() - a.createdAt.toMillis()
  );
};

export function RecentDiscussions({ isOpen, onClose, user }: RecentDiscussionsProps) {
  const [topics, setTopics] = useState<Topic[]>([]);
  const [loading, setLoading] = useState(true);
  const [detailedTopic, setDetailedTopic] = useState<Topic | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const topicsPerPage = 9; // 3x3 grid
  const [isEditing, setIsEditing] = useState(false);
  const [editTitle, setEditTitle] = useState('');
  const [editDescription, setEditDescription] = useState('');
  const [editTag, setEditTag] = useState('');
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [topicToDelete, setTopicToDelete] = useState<Topic | null>(null);

  useEffect(() => {
    const topicsRef = collection(db, 'topics');
    const q = query(topicsRef, orderBy('createdAt', 'desc'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const topicsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      })) as Topic[];
      setTopics(topicsData);
      setLoading(false);
    }, (error) => {
      console.error('Error fetching topics:', error);
      toast.error('Failed to load topics');
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const handleLike = async (topic: Topic) => {
    if (!user?.uid) {
      toast.error('You must be logged in to like topics');
      return;
    }

    try {
      const topicRef = doc(db, 'topics', topic.id);
      const hasLiked = topic.likedBy?.includes(user.uid);

      // Optimistic update
      const updatedLikedBy = hasLiked 
        ? topic.likedBy.filter(id => id !== user.uid)
        : [...(topic.likedBy || []), user.uid];

      const updatedLikes = hasLiked ? (topic.likes - 1) : (topic.likes + 1);

      // Immediately update local state
      setTopics(currentTopics => 
        currentTopics.map(t => 
          t.id === topic.id 
            ? { 
                ...t, 
                likes: updatedLikes,
                likedBy: updatedLikedBy 
              }
            : t
        )
      );

      // Update Firestore
      await updateDoc(topicRef, {
        likes: updatedLikes,
        likedBy: updatedLikedBy
      });

    } catch (error) {
      console.error('Error updating likes:', error);
      toast.error('Failed to update like');
      
      // Revert optimistic update on error
      setTopics(currentTopics => 
        currentTopics.map(t => 
          t.id === topic.id 
            ? topic  // Revert to original topic state
            : t
        )
      );
    }
  };

  const DetailedTopicView = ({ topic }: { topic: Topic }) => {
    const [replyContent, setReplyContent] = useState('');
    const [refreshing, setRefreshing] = useState(false);

    const refreshReplies = async () => {
      try {
        setRefreshing(true);
        const topicRef = doc(db, 'topics', topic.id);
        const topicDoc = await getDoc(topicRef);
        
        if (topicDoc.exists()) {
          const updatedTopic = { id: topicDoc.id, ...topicDoc.data() } as Topic;
          setTopics(currentTopics => 
            currentTopics.map(t => t.id === topic.id ? updatedTopic : t)
          );
        }
      } catch (error) {
        console.error('Error refreshing replies:', error);
        toast.error('Failed to refresh replies');
      } finally {
        setRefreshing(false);
      }
    };

    const handleReply = async () => {
      if (!user?.uid) {
        toast.error('Please sign in to reply');
        return;
      }

      const trimmedContent = replyContent.trim();
      if (!trimmedContent) {
        toast.error('Reply cannot be empty');
        return;
      }

      try {
        const newReply: Reply = {
          id: `reply_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`,
          content: trimmedContent,
          userId: user.uid,
          userEmail: user.email || '',
          createdAt: Timestamp.now(),
          userRole: user.role || 'user',
        };

        // Optimistic UI updates
        setDetailedTopic(currentTopic => {
          if (!currentTopic) return null;
          return {
            ...currentTopic,
            replies: [...currentTopic.replies, newReply]
          };
        });

        setTopics(currentTopics => 
          currentTopics.map(t => {
            if (t.id === topic.id) {
              return {
                ...t,
                replies: [...(t.replies || []), newReply]
              };
            }
            return t;
          })
        );

        // Clear input immediately
        setReplyContent('');

        // Update Firestore
        const topicRef = doc(db, 'topics', topic.id);
        await updateDoc(topicRef, {
          replies: arrayUnion(newReply),
          lastReplyAt: Timestamp.now(),
          replyCount: (topic.replies?.length || 0) + 1,
          hasNewReplies: true
        });
      } catch (error) {
        console.error('Error posting reply:', error);
        toast.error('Failed to post reply');
      }
    };

    return (
      <div className="fixed inset-0 bg-black/80 flex items-center justify-center z-[60] backdrop-blur-sm">
        <div className="bg-[#1a1b26] rounded-xl w-full max-w-2xl max-h-[90vh] overflow-hidden flex flex-col">
          {/* Header */}
          <div className="p-4 border-b border-[#24283b] flex justify-between items-center">
            <div className="flex items-center gap-3">
              <div className="bg-purple-500/10 p-2 rounded-lg">
                <MessageSquare className="h-5 w-5 text-purple-400" />
              </div>
              <div>
                <div className="flex items-center gap-2">
                  <h2 className="text-xl font-semibold text-white">{topic.title}</h2>
                  <span className="text-xs px-2 py-0.5 rounded-full bg-purple-500/20 text-purple-300 border border-purple-500/20">
                    {topic.tag}
                  </span>
                </div>
                <p className="text-sm text-gray-400">Discussion Thread</p>
              </div>
            </div>
            <div className="flex items-center gap-2">
              {(topic.userId === user?.uid || user?.role === 'admin') && (
                <Button 
                  variant="ghost" 
                  onClick={() => handleDelete(topic)}
                  className="hover:bg-red-500/10 hover:text-red-400 rounded-full p-2"
                >
                  <Trash2 className="h-5 w-5" />
                </Button>
              )}
              <Button 
                variant="ghost" 
                onClick={() => setDetailedTopic(null)}
                className="hover:bg-[#24283b] rounded-full p-2"
              >
                <X className="h-5 w-5 text-gray-400 hover:text-white" />
              </Button>
            </div>
          </div>

          {/* Content Area - Scrollable */}
          <div className="flex-1 overflow-y-auto p-4 space-y-4">
            {/* Main Post */}
            <div className="bg-[#24283b] rounded-lg p-4">
              <div className="flex items-start gap-3">
                <div className="bg-purple-500/10 p-2 rounded-lg shrink-0">
                  <User className="h-5 w-5 text-purple-400" />
                </div>
                <div className="flex-1">
                  <div className="flex items-center gap-2 mb-2">
                    <span className="font-medium text-gray-200">{maskEmail(topic.userEmail)}</span>
                    {topic.userRole && (
                      <span className={`text-xs px-2 py-0.5 rounded-full border ${getRoleColor(topic.userRole)} border-current`}>
                        {topic.userRole}
                      </span>
                    )}
                  </div>
                  <p className="text-gray-200 mb-3">{topic.description}</p>
                  <div className="flex items-center justify-between text-sm text-gray-400">
                    <div className="flex items-center gap-2">
                      <Clock className="h-4 w-4" />
                      <span>{topic.createdAt.toDate().toLocaleString()}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Stats Bar */}
            <div className="flex items-center gap-4 p-2">
              <div className="flex items-center gap-2 text-gray-400">
                <Heart className={`h-4 w-4 ${topic.likes > 0 ? 'text-pink-400 fill-pink-400' : ''}`} />
                <span className="text-sm">{topic.likes || 0} likes</span>
              </div>
              <div className="flex items-center gap-2 text-gray-400">
                <MessageCircle className="h-4 w-4" />
                <span className="text-sm">{topic.replies?.length || 0} replies</span>
              </div>
            </div>

            {/* Replies Section */}
            <div className="mt-6 flex-1 overflow-y-auto">
              <div className="flex items-center justify-between mb-4 px-4">
                <div className="flex items-center gap-2">
                  <MessageCircle className="h-5 w-5 text-purple-400" />
                  <h3 className="text-lg font-semibold text-white">Replies</h3>
                </div>
                <Button
                  variant="ghost"
                  size="sm"
                  onClick={refreshReplies}
                  className={`p-1.5 rounded-full ${refreshing ? 'animate-spin' : 'hover:bg-purple-500/10'}`}
                  disabled={refreshing}
                >
                  <svg className="h-4 w-4" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 4V9H4.58152M19.9381 11C19.446 7.05369 16.0796 4 12 4C8.64262 4 5.76829 6.06817 4.58152 9M4.58152 9H9M20 20V15H19.4185M19.4185 15C18.2317 17.9318 15.3574 20 12 20C7.92038 20 4.55399 16.9463 4.06189 13M19.4185 15H15" 
                      stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </Button>
              </div>
              
              <div className="space-y-4 px-4">
                {topic.replies?.length === 0 ? (
                  <div className="text-center py-8 bg-[#24283b] rounded-lg">
                    <MessageSquare className="h-8 w-8 text-gray-400 mx-auto mb-2" />
                    <p className="text-gray-400">No replies yet. Be the first to reply!</p>
                  </div>
                ) : (
                  sortRepliesByDate(topic.replies).map((reply: Reply) => (
                    <div 
                      key={reply.id}
                      className="bg-[#24283b] rounded-lg p-4 hover:bg-[#2d3348] transition-colors"
                    >
                      <div className="flex items-start gap-3">
                        <div className="bg-blue-500/10 p-2 rounded-lg shrink-0">
                          <User className="h-4 w-4 text-blue-400" />
                        </div>
                        <div className="flex-1">
                          <div className="flex items-center justify-between mb-2">
                            <div className="flex items-center gap-2">
                              <span className="font-medium text-gray-200">
                                {maskEmail(reply.userEmail)}
                              </span>
                              {reply.userRole && (
                                <span className={`text-xs px-2 py-0.5 rounded-full border ${getRoleColor(reply.userRole)} border-current`}>
                                  {reply.userRole}
                                </span>
                              )}
                            </div>
                            {/* Delete button for own replies */}
                            {(reply.userId === user?.uid || user?.role === 'admin') && (
                              <Button
                                variant="ghost"
                                size="sm"
                                onClick={() => handleDeleteReply(topic.id, reply.id)}
                                className="text-red-400 hover:text-red-300 hover:bg-red-400/10 rounded-full p-1.5"
                              >
                                <Trash2 className="h-4 w-4" />
                              </Button>
                            )}
                          </div>
                          <p className="text-gray-200 mb-3">{reply.content}</p>
                          <div className="flex items-center text-sm text-gray-400">
                            <Clock className="h-4 w-4 mr-1" />
                            <span>{reply.createdAt.toDate().toLocaleString()}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </div>
          </div>

          {/* Reply Input Section - Fixed at Bottom */}
          <div className="border-t border-[#24283b] p-4 bg-[#1a1b26]">
            <div className="flex gap-2">
              <div className="flex-1 relative">
                <input
                  type="text"
                  value={replyContent}
                  onChange={(e) => setReplyContent(e.target.value)}
                  placeholder="Write a reply..."
                  className="w-full bg-[#24283b] text-white px-4 py-3 pr-24 rounded-lg focus:outline-none focus:ring-1 focus:ring-purple-500 placeholder-gray-500"
                  maxLength={200}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault();
                      if (replyContent.trim()) {
                        handleReply();
                      }
                    }
                  }}
                />
                <div className="absolute right-3 top-1/2 -translate-y-1/2">
                  <Button
                    onClick={() => handleReply()}
                    disabled={!replyContent.trim()}
                    className="bg-purple-600 hover:bg-purple-700 text-white px-4 py-1.5 rounded-lg transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed flex items-center gap-2"
                  >
                    <Send className="h-4 w-4" />
                    <span className="hidden sm:inline">Reply</span>
                  </Button>
                </div>
              </div>
            </div>
            <p className="text-xs text-gray-400 mt-2 text-center">
              Press Enter to send • Maximum 200 characters
            </p>
          </div>
        </div>
      </div>
    );
  };

  const handleEdit = (topic: Topic) => {
    setIsEditing(true);
    setEditTitle(topic.title);
    setEditDescription(topic.description);
    setEditTag(topic.tag);
  };

  const handleUpdate = async (topic: Topic) => {
    if (!editTitle.trim() || !editDescription.trim()) {
      toast.error('Please fill in all fields');
      return;
    }

    try {
      toast.loading('Updating topic...', { toastId: 'updateLoading' });
      const topicRef = doc(db, 'topics', topic.id);
      
      await updateDoc(topicRef, {
        title: editTitle.trim(),
        description: editDescription.trim(),
        tag: editTag,
        updatedAt: Timestamp.now()
      });

      setIsEditing(false);
      toast.dismiss('updateLoading');
      toast.success('Topic updated successfully');
    } catch (error) {
      console.error('Error updating topic:', error);
      toast.dismiss('updateLoading');
      toast.error('Failed to update topic');
    }
  };

  const handleDelete = async (topic: Topic) => {
    // Check if user owns the topic or is an admin
    if (topic.userId !== user?.uid && user?.role !== 'admin') {
      toast.error('You can only delete your own topics');
      return;
    }

    setTopicToDelete(topic);
    setIsDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    if (!topicToDelete) return;

    try {
      await deleteDoc(doc(db, 'topics', topicToDelete.id));
      setDetailedTopic(null);
      toast.success('Topic deleted successfully');
    } catch (error) {
      console.error('Error deleting topic:', error);
      toast.error('Failed to delete topic');
    } finally {
      setIsDeleteDialogOpen(false);
      setTopicToDelete(null);
    }
  };

  const handleDeleteReply = async (topicId: string, replyId: string) => {
    if (!user?.uid) return;

    try {
      // Optimistic UI update
      setDetailedTopic(currentTopic => {
        if (!currentTopic) return null;
        const updatedReplies = currentTopic.replies.filter(reply => reply.id !== replyId);
        return {
          ...currentTopic,
          replies: updatedReplies,
          // Reset hasNewReplies if this was the last reply
          hasNewReplies: updatedReplies.length > 0 && updatedReplies.some((reply: Reply) => !reply.isRead)
        };
      });

      // Get the topic document
      const topicRef = doc(db, 'topics', topicId);
      const topicDoc = await getDoc(topicRef);
      
      if (!topicDoc.exists()) return;
      
      const currentReplies = topicDoc.data().replies || [];
      const updatedReplies = currentReplies.filter((reply: Reply) => reply.id !== replyId);
      
      // Update Firestore with new replies array and hasNewReplies status
      await updateDoc(topicRef, {
        replies: updatedReplies,
        hasNewReplies: updatedReplies.length > 0 && updatedReplies.some((reply: Reply) => !reply.isRead),
        replyCount: updatedReplies.length
      });

      toast.success('Reply deleted successfully');
    } catch (error) {
      console.error('Error deleting reply:', error);
      toast.error('Failed to delete reply');
    }
  };

  // Add this function to mark replies as read when viewing a topic
  const markRepliesAsRead = async (topicId: string) => {
    if (!user?.uid) return;

    try {
      const topicRef = doc(db, 'topics', topicId);
      const topicDoc = await getDoc(topicRef);
      
      if (!topicDoc.exists()) return;
      
      const currentReplies = topicDoc.data().replies || [];
      const updatedReplies = currentReplies.map((reply: Reply) => ({
        ...reply,
        isRead: true
      }));

      await updateDoc(topicRef, {
        replies: updatedReplies,
        hasNewReplies: false
      });
    } catch (error) {
      console.error('Error marking replies as read:', error);
    }
  };

  // Use this when opening a topic
  useEffect(() => {
    if (detailedTopic && user?.uid === detailedTopic.userId) {
      markRepliesAsRead(detailedTopic.id);
    }
  }, [detailedTopic, user?.uid]);

  const indexOfLastTopic = currentPage * topicsPerPage;
  const indexOfFirstTopic = indexOfLastTopic - topicsPerPage;
  const currentTopics = topics.slice(indexOfFirstTopic, indexOfLastTopic);
  const totalPages = Math.ceil(topics.length / topicsPerPage);

  const Pagination = () => {
    return (
      <div className="flex justify-center items-center gap-2 mt-6">
        <Button
          variant="outline"
          size="sm"
          onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
          disabled={currentPage === 1}
          className="text-sm"
        >
          Previous
        </Button>
        
        <div className="flex items-center gap-2">
          {Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
            <Button
              key={page}
              variant={currentPage === page ? "default" : "outline"}
              size="sm"
              onClick={() => setCurrentPage(page)}
              className={`w-8 h-8 ${
                currentPage === page 
                  ? 'bg-purple-500 text-white' 
                  : 'text-gray-400 hover:text-white'
              }`}
            >
              {page}
            </Button>
          ))}
        </div>

        <Button
          variant="outline"
          size="sm"
          onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
          disabled={currentPage === totalPages}
          className="text-sm"
        >
          Next
        </Button>
      </div>
    );
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black/80 flex items-center justify-center z-50 backdrop-blur-sm">
      <div className="bg-gray-900 rounded-xl p-6 w-full max-w-6xl max-h-[85vh] overflow-y-auto border border-gray-800">
        {/* Header with close button */}
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center gap-2">
            <MessageCircle className="h-6 w-6 text-purple-400" />
            <h2 className="text-xl font-bold text-white">Recent Discussions</h2>
          </div>
          <Button 
            variant="ghost" 
            size="sm" 
            onClick={onClose}
            className="hover:bg-gray-800 rounded-full p-2"
          >
            <X className="h-5 w-5 text-gray-400 hover:text-white transition-colors" />
          </Button>
        </div>

        {/* Topics Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {currentTopics.map((topic) => (
            <div 
              key={topic.id} 
              className="bg-gray-800/50 rounded-lg p-4 border border-gray-700/50 hover:border-purple-500/50 transition-colors cursor-pointer"
              onClick={() => setDetailedTopic(topic)}
            >
              <div className="flex items-center justify-between mb-3">
                <h3 className="font-medium text-white truncate">{topic.title}</h3>
                <span className="text-xs px-2 py-1 bg-purple-500/20 text-purple-300 rounded-full">
                  {topic.tag}
                </span>
              </div>
              
              <p className="text-sm text-gray-400 line-clamp-2 mb-3">
                {topic.description}
              </p>

              <div className="flex items-center justify-between text-xs text-gray-500 mb-3">
                <div className="flex items-center gap-1">
                  <User className="h-3 w-3" />
                  <span>{maskEmail(topic.userEmail)}</span>
                </div>
                <div className="flex items-center gap-1">
                  <Clock className="h-3 w-3" />
                  <span>{topic.createdAt.toDate().toLocaleDateString()}</span>
                </div>
              </div>

              <div className="flex items-center gap-3 text-xs">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleLike(topic);
                  }}
                  className={`flex items-center gap-1 px-2 py-1 rounded-full transition-all duration-200 ${
                    user?.uid && topic.likedBy?.includes(user.uid)
                      ? 'bg-pink-500/20 text-pink-300'
                      : 'bg-gray-700/50 text-gray-400 hover:bg-gray-600/50'
                  }`}
                >
                  <Heart 
                    className={`h-3 w-3 ${
                      user?.uid && topic.likedBy?.includes(user.uid)
                        ? 'fill-pink-300'
                        : ''
                    }`} 
                  />
                  <span>{topic.likes || 0}</span>
                </button>
                
                <div className="flex items-center gap-1 px-2 py-1 rounded-full bg-gray-700/50 text-gray-400">
                  <MessageSquare className="h-3 w-3" />
                  <span>{topic.replies?.length || 0}</span>
                </div>
              </div>
            </div>
          ))}
        </div>

        {topics.length === 0 ? (
          <div className="text-center py-8">
            <MessageSquare className="h-8 w-8 text-gray-400 mx-auto mb-2" />
            <p className="text-gray-400">No discussions yet.</p>
          </div>
        ) : (
          <Pagination />
        )}

        {detailedTopic && <DetailedTopicView topic={detailedTopic} />}

        <DeleteConfirmationDialog 
          isOpen={isDeleteDialogOpen}
          onClose={() => {
            setIsDeleteDialogOpen(false);
            setTopicToDelete(null);
          }}
          onConfirm={confirmDelete}
        />
      </div>
    </div>
  );
}