import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { auth, db } from '@/lib/firebase';
import { applyActionCode } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';

export function VerifyEmail() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const oobCode = searchParams.get('oobCode');

  useEffect(() => {
    const verifyEmail = async () => {
      if (!oobCode) {
        navigate('/register');
        return;
      }

      try {
        await applyActionCode(auth, oobCode);
        
        // Update user status in Firestore
        const user = auth.currentUser;
        if (user) {
          const userRef = doc(db, 'users', user.uid);
          await updateDoc(userRef, {
            isActive: true,
            emailVerified: true
          });
        }

        // Redirect to verification pending with success state
        navigate('/verification-pending?verified=true');
      } catch (error) {
        console.error('Error verifying email:', error);
        navigate('/verification-pending?error=true');
      }
    };

    verifyEmail();
  }, [oobCode, navigate]);

  return null; // This component doesn't render anything
} 