import { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Save, Shield, Eye, EyeOff, MoreHorizontal } from 'lucide-react';
import { Button } from '@/components/ui/Button';
import { updatePassword, updateEmail } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { auth, db } from '@/lib/firebase';
import { toast } from 'react-hot-toast';
import { UserProfile } from '@/components/dashboard/UserProfile';

interface AccountSettingsProps {
  isOpen: boolean;
  onClose: () => void;
  userData: any;
  user: any;
}

export function AccountSettings({ isOpen, onClose, userData, user }: AccountSettingsProps) {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState(userData?.email || '');
  const [nickname, setNickname] = useState(userData?.nickname || '');
  const [description, setDescription] = useState(userData?.description || '');
  const [saving, setSaving] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [showPasswordOptions, setShowPasswordOptions] = useState(false);

  const handleSave = async () => {
    if (newPassword && newPassword !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }

    setSaving(true);
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) throw new Error('No user logged in');

      // Update password if provided
      if (newPassword) {
        await updatePassword(currentUser, newPassword);
      }

      // Update email if changed
      if (email !== currentUser.email) {
        await updateEmail(currentUser, email);
      }

      // Update profile data
      await updateDoc(doc(db, 'users', currentUser.uid), {
        nickname,
        description,
        updatedAt: new Date().toISOString(),
      });

      toast.success('Account settings updated successfully');
      onClose();
    } catch (error) {
      console.error('Error updating account:', error);
      toast.error('Failed to update account settings');
    } finally {
      setSaving(false);
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black"
            onClick={onClose}
          />
          <motion.div
            initial={{ opacity: 0, scale: 0.95, y: 20 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.95, y: 20 }}
            className="fixed inset-0 flex items-center justify-center p-4 pointer-events-none"
          >
            <div className="bg-gray-900 p-6 rounded-xl shadow-xl max-w-md w-full pointer-events-auto border border-gray-800">
              <div className="flex items-center justify-between mb-6">
                <div className="flex items-center space-x-3">
                  <h3 className="text-xl font-bold">Account Settings</h3>
                  {userData?.role && (
                    <div className="bg-purple-500/20 p-1.5 rounded-lg">
                      <Shield className="h-4 w-4 text-purple-500" />
                    </div>
                  )}
                </div>
                <Button variant="ghost" size="sm" onClick={onClose}>
                  <X className="h-5 w-5" />
                </Button>
              </div>

              {/* User Profile Component */}
              <UserProfile user={user} userData={userData} />

              <div className="space-y-4 mt-4">
                <div>
                  <label className="block text-sm font-medium mb-1">Email</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium mb-1">Nickname</label>
                  <input
                    type="text"
                    value={nickname}
                    onChange={(e) => setNickname(e.target.value)}
                    className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium mb-1">Description</label>
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg h-24 resize-none"
                  />
                </div>

                <div className="border-t border-gray-800 pt-4">
                  <div className="flex justify-between items-center mb-4">
                    <h4 className="font-medium">Change Password</h4>
                    <button
                      className="text-gray-500 hover:text-gray-300"
                      onClick={() => setShowPasswordOptions(!showPasswordOptions)}
                    >
                      <MoreHorizontal className="h-5 w-5" />
                    </button>
                  </div>
                  {showPasswordOptions && (
                    <div className="space-y-4">
                      <div>
                        <label className="block text-sm font-medium mb-1">
                          Current Password
                        </label>
                        <div className="relative">
                          <input
                            type={passwordVisible ? 'text' : 'password'}
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                            className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg"
                          />
                          <button
                            type="button"
                            className="absolute right-2 top-2"
                            onClick={() => setPasswordVisible(!passwordVisible)}
                          >
                            {passwordVisible ? <EyeOff className="h-4 w-4" /> : <Eye className="h-4 w-4" />}
                          </button>
                        </div>
                      </div>

                      <div>
                        <label className="block text-sm font-medium mb-1">
                          New Password
                        </label>
                        <div className="relative">
                          <input
                            type={passwordVisible ? 'text' : 'password'}
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg"
                          />
                          <button
                            type="button"
                            className="absolute right-2 top-2"
                            onClick={() => setPasswordVisible(!passwordVisible)}
                          >
                            {passwordVisible ? <EyeOff className="h-4 w-4" /> : <Eye className="h-4 w-4" />}
                          </button>
                        </div>
                      </div>

                      <div>
                        <label className="block text-sm font-medium mb-1">
                          Confirm New Password
                        </label>
                        <input
                          type={passwordVisible ? 'text' : 'password'}
                          value={confirmPassword}
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg"
                        />
                      </div>
                    </div>
                  )}
                </div>

                <Button
                  onClick={handleSave}
                  className="w-full"
                  disabled={saving}
                >
                  <Save className="h-4 w-4 mr-2" />
                  {saving ? 'Saving...' : 'Save Changes'}
                </Button>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}
