// src/components/dashboard/UserProfile.tsx

import React, { useState, ChangeEvent } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { User, Calendar, Mail, Star, Upload, AlertCircle } from 'lucide-react'; // Import necessary icons
import { Button } from '@/components/ui/Button';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '@/lib/firebase'; // Ensure Firestore is imported
import { toast } from 'react-hot-toast';

interface UserProfileProps {
  user: any;
  userData: any;
}

export function UserProfile({ user, userData }: UserProfileProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [error, setError] = useState<string | null>(null);

  // Handle Image Selection and Upload
  const handleImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) return;

    // Validate file type (optional)
    if (!file.type.startsWith('image/')) {
      toast.error('Please select a valid image file.');
      return;
    }

    // Validate file size (optional, e.g., max 5MB)
    const maxSizeInBytes = 5 * 1024 * 1024; // 5MB
    if (file.size > maxSizeInBytes) {
      toast.error('Image size should be less than 5MB.');
      return;
    }

    try {
      setUploading(true);
      setError(null);

      // Prepare form data for Cloudinary
      const formData = new FormData();
      formData.append('file', file);
      formData.append(
        'upload_preset',
        import.meta.env.VITE_CLOUDINARY_UPLOAD_PRESET
      );

      // Upload image to Cloudinary
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/${import.meta.env.VITE_CLOUDINARY_CLOUD_NAME}/image/upload`,
        {
          method: 'POST',
          body: formData,
        }
      );

      const data = await response.json();

      if (response.ok) {
        const imageUrl = data.secure_url;

        // Update Firestore with the new avatar URL
        const userDocRef = doc(db, 'users', user.uid);
        await updateDoc(userDocRef, {
          avatarUrl: imageUrl,
        });

        toast.success('Profile picture updated successfully!');
      } else {
        console.error('Cloudinary Upload Error:', data);
        setError(data.error.message || 'Failed to upload image.');
        toast.error(data.error.message || 'Failed to upload image.');
      }
    } catch (err) {
      console.error('Upload Error:', err);
      setError('An unexpected error occurred.');
      toast.error('An unexpected error occurred.');
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="bg-gray-800/50 rounded-lg p-4 border border-gray-700/50 relative">
      {/* Streak Icon */}
      {userData?.streak >= 7 && (
        <div className="absolute top-2 right-2">
          <Star className="h-5 w-5 text-yellow-400" />
          <div className="absolute left-1/2 transform -translate-x-1/2 -translate-y-full bg-gray-800 text-white text-xs rounded p-1 opacity-0 group-hover:opacity-100 transition-opacity">
            7-Day Streak Achieved!
          </div>
        </div>
      )}

      <div className="flex items-center space-x-4">
        {/* Profile Image */}
        <div className="relative">
          {userData?.avatarUrl ? (
            <img
              src={userData.avatarUrl}
              alt="Profile"
              className="w-12 h-12 rounded-full object-cover"
            />
          ) : (
            <div className="w-12 h-12 rounded-full bg-purple-500/20 flex items-center justify-center">
              <User className="h-6 w-6 text-purple-500" />
            </div>
          )}
          {/* Online Status Indicator */}
          <div className="absolute -bottom-1 -right-1 h-4 w-4 bg-green-500 rounded-full border-2 border-gray-800" />
        </div>

        {/* User Information */}
        <div className="flex-1">
          <h3 className="font-semibold">
            {userData?.nickname || user?.email?.split('@')[0]}
          </h3>
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className="text-sm text-gray-400 hover:text-white transition-colors"
          >
            {isExpanded ? 'Hide Profile' : 'View Profile'}
          </button>
        </div>

        {/* Upload Button */}
        <label htmlFor="avatar-upload" className="cursor-pointer">
          <Upload className="h-5 w-5 text-gray-400 hover:text-white transition-colors" />
        </label>
        <input
          type="file"
          id="avatar-upload"
          accept="image/*"
          className="hidden"
          onChange={handleImageChange}
        />
      </div>

      {/* Upload Progress and Error Messages */}
      <AnimatePresence>
        {uploading && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="mt-2"
          >
            <div className="w-full bg-gray-700 rounded-full h-2">
              <div
                className="bg-purple-500 h-2 rounded-full"
                style={{ width: `${uploadProgress}%` }}
              ></div>
            </div>
            <p className="text-sm text-gray-400 mt-1">Uploading...</p>
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {error && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="mt-2 flex items-center text-red-500 text-sm"
          >
            <AlertCircle className="h-4 w-4 mr-1" />
            <span>{error}</span>
          </motion.div>
        )}
      </AnimatePresence>

      {/* Expanded Profile Information */}
      <AnimatePresence>
        {isExpanded && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            className="overflow-hidden"
          >
            <div className="pt-4 mt-4 border-t border-gray-700/50 space-y-3">
              {/* Email */}
              <div className="flex items-center text-sm text-gray-400">
                <Mail className="h-4 w-4 mr-2" />
                {user?.email}
              </div>

              {/* Membership Date */}
              <div className="flex items-center text-sm text-gray-400">
                <Calendar className="h-4 w-4 mr-2" />
                Member since{' '}
                {userData?.createdAt
                  ? new Date(userData.createdAt).toLocaleDateString()
                  : 'N/A'}
              </div>

              {/* Description */}
              {userData?.description && (
                <p className="text-sm text-gray-400">{userData.description}</p>
              )}

              {/* Tags and Badges */}
              <div className="flex flex-wrap gap-2">
                {userData?.tags?.map((tag: string) => (
                  <span
                    key={tag}
                    className="px-2 py-1 text-xs rounded-full bg-purple-500/20 text-purple-400"
                  >
                    {tag}
                  </span>
                ))}
                {/* Badge for 7-day streak */}
                {userData?.streak >= 7 && (
                  <div className="relative group">
                    <Star className="h-5 w-5 text-yellow-400" />
                    <div className="absolute left-1/2 transform -translate-x-1/2 -translate-y-full bg-gray-800 text-white text-xs rounded p-1 opacity-0 group-hover:opacity-100 transition-opacity">
                      7-Day Streak Achieved!
                    </div>
                  </div>
                )}
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
