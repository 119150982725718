// src/pages/auth/Login.tsx
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { signInWithEmailAndPassword, sendPasswordResetEmail } from 'firebase/auth';
import { toast } from 'react-hot-toast';
import { Trophy, Mail } from 'lucide-react';
import { auth, db } from '@/lib/firebase';
import { Button } from '@/components/ui/Button';
import { motion, AnimatePresence } from 'framer-motion';
import { doc, updateDoc } from 'firebase/firestore';

const loginSchema = z.object({
  email: z.string().email('Invalid email address'),
  password: z.string().min(6, 'Password must be at least 6 characters'),
});

type LoginFormData = z.infer<typeof loginSchema>;

export function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const [isResetOpen, setIsResetOpen] = useState(false);
  const [resetEmail, setResetEmail] = useState('');
  const navigate = useNavigate();
  const { register, handleSubmit, formState: { errors } } = useForm<LoginFormData>({
    resolver: zodResolver(loginSchema),
  });

  const onSubmit = async (data: LoginFormData) => {
    try {
      setIsLoading(true);
      const userCredential = await signInWithEmailAndPassword(auth, data.email, data.password);
      const user = userCredential.user;

      // Update isActive field in Firestore
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, { isActive: true });

      navigate('/dashboard');
    } catch (error) {
      toast.error('Invalid email or password');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePasswordReset = async (email: string) => {
    try {
      await sendPasswordResetEmail(auth, email);
      toast.success('Password reset email sent! Check your inbox.');
      setIsResetOpen(false);
      setResetEmail('');
    } catch (error) {
      toast.error('Failed to send reset email. Please check your email address.');
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-gray-800 to-gray-900 p-4">
      <motion.div 
        className="w-full max-w-md space-y-8 bg-gray-800 p-8 rounded-lg shadow-lg"
        initial={{ opacity: 0, scale: 0.95 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
      >
        <div className="text-center">
          <Link to="/" className="inline-flex items-center space-x-2">
            <Trophy className="h-8 w-8 text-white" />
            <span className="text-2xl font-bold text-white">DailySolly</span>
          </Link>
          <h2 className="mt-6 text-3xl font-bold text-white">Welcome back</h2>
          <p className="mt-2 text-gray-300">Sign in to your account</p>
        </div>

        <form onSubmit={handleSubmit(onSubmit)} className="mt-8 space-y-6">
          <div className="space-y-4">
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-white">
                Email address
              </label>
              <input
                {...register('email')}
                type="email"
                className="mt-1 block w-full rounded-md border border-gray-600 bg-gray-700 px-3 py-2 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-300"
                placeholder="you@example.com"
              />
              {errors.email && (
                <p className="mt-1 text-sm text-red-400">{errors.email.message}</p>
              )}
            </div>

            <div>
              <label htmlFor="password" className="block text-sm font-medium text-white">
                Password
              </label>
              <input
                {...register('password')}
                type="password"
                className="mt-1 block w-full rounded-md border border-gray-600 bg-gray-700 px-3 py-2 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-300"
              />
              {errors.password && (
                <p className="mt-1 text-sm text-red-400">{errors.password.message}</p>
              )}
              <div className="flex justify-end mt-2">
                <button
                  type="button"
                  onClick={() => setIsResetOpen(true)}
                  className="text-sm text-purple-400 hover:text-purple-300"
                >
                  Forgot password?
                </button>
              </div>
            </div>
          </div>

          <Button type="submit" className="w-full bg-gradient-to-r from-purple-600 to-pink-600" disabled={isLoading}>
            {isLoading ? 'Signing in...' : 'Sign in'}
          </Button>

          <p className="text-center text-sm text-gray-300">
            By signing in, you agree to our{' '}
            <button onClick={() => setIsTermsOpen(true)} className="text-white underline">
              Terms of Service
            </button>
          </p>
        </form>
      </motion.div>

      {/* Terms of Service Modal */}
      <AnimatePresence>
        {isTermsOpen && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.5 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black"
              onClick={() => setIsTermsOpen(false)}
            />
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              className="fixed inset-0 flex items-center justify-center p-4 pointer-events-none"
            >
              <div className="bg-gray-800 rounded-lg shadow-lg max-w-md w-full pointer-events-auto p-6">
                <h3 className="text-lg font-bold text-white mb-4">Terms of Service</h3>
                <p className="text-gray-300 mb-4">
                  Welcome to DailySolly! By using our services, you agree to the following terms:
                </p>
                <ul className="list-disc list-inside text-gray-300 mb-4">
                  <li>You must be at least 18 years old to use our services.</li>
                  <li>All user data will be handled according to our privacy policy.</li>
                  <li>We reserve the right to modify or terminate our services at any time.</li>
                  <li>Users are responsible for maintaining the confidentiality of their account information.</li>
                  <li>Any fraudulent activity will result in account suspension.</li>
                </ul>
                <Button onClick={() => setIsTermsOpen(false)} className="mt-4 w-full">
                  Close
                </Button>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>

      {/* Password Reset Modal */}
      <AnimatePresence>
        {isResetOpen && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.5 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black"
              onClick={() => setIsResetOpen(false)}
            />
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              className="fixed inset-0 flex items-center justify-center p-4 pointer-events-none"
            >
              <div className="bg-gray-800 rounded-lg shadow-lg max-w-md w-full pointer-events-auto p-6">
                <div className="text-center mb-6">
                  <Mail className="h-12 w-12 text-purple-400 mx-auto mb-4" />
                  <h3 className="text-xl font-bold text-white">Reset Password</h3>
                  <p className="text-gray-300 mt-2">
                    Enter your email address and we'll send you instructions to reset your password.
                  </p>
                </div>

                <input
                  type="email"
                  value={resetEmail}
                  onChange={(e) => setResetEmail(e.target.value)}
                  placeholder="Enter your email"
                  className="w-full rounded-md border border-gray-600 bg-gray-700 px-3 py-2 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-purple-300 mb-4"
                />

                <div className="flex space-x-3">
                  <Button
                    onClick={() => handlePasswordReset(resetEmail)}
                    className="flex-1 bg-gradient-to-r from-purple-600 to-pink-600"
                  >
                    Send Reset Link
                  </Button>
                  <Button
                    onClick={() => setIsResetOpen(false)}
                    className="flex-1 bg-gray-700 hover:bg-gray-600"
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
}