import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { motion } from 'framer-motion';
import { Trophy, LogIn, UserPlus, Send, MessageCircle, Star } from 'lucide-react';
import { auth } from '@/lib/firebase';
import { Button } from '@/components/ui/Button';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { initializeUserData } from '@/lib/firebase';
import { toast } from 'react-hot-toast';

export function LandingPage() {
  const [user] = useAuthState(auth);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/dashboard');
    } catch (error: any) {
      toast.error(error.message || 'Failed to login');
    } finally {
      setLoading(false);
    }
  };

  const handleRegister = async (e: React.FormEvent) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }
    setLoading(true);
    try {
      const { user } = await createUserWithEmailAndPassword(auth, email, password);
      await initializeUserData(user.uid, email);
      navigate('/dashboard');
    } catch (error: any) {
      toast.error(error.message || 'Failed to register');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-purple-900/20 to-gray-900 flex flex-col relative">
      {/* Header */}
      <header className="container mx-auto px-4 py-6">
        <Link to="/" className="flex items-center space-x-2">
          <Trophy className="h-8 w-8 text-purple-500" />
          <span className="text-xl font-bold bg-gradient-to-r from-purple-400 to-pink-400 bg-clip-text text-transparent">
            DailySolly
          </span>
        </Link>
      </header>

      {/* Main Content */}
      <main className="flex-1 container mx-auto px-4 flex items-center justify-center">
        <div className="max-w-4xl w-full text-center space-y-8">
          <motion.h1 
            className="text-6xl sm:text-7xl font-bold bg-gradient-to-r from-purple-400 via-pink-400 to-purple-400 bg-clip-text text-transparent"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            DailySolly
          </motion.h1>
          
          <motion.p 
            className="text-xl text-gray-400 max-w-2xl mx-auto"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
          >
            Complete daily tasks, maintain streaks, and earn Solana rewards. Join our community of achievers today.
          </motion.p>

          <motion.div
            className="flex items-center justify-center space-x-4"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            {user ? (
              <Link to="/dashboard">
                <Button size="lg">Go to Dashboard</Button>
              </Link>
            ) : (
              <>
                <Link to="/login">
                  <Button variant="outline" size="lg">
                    <LogIn className="mr-2 h-5 w-5" />
                    Login
                  </Button>
                </Link>
                <Link to="/register">
                  <Button size="lg">
                    <UserPlus className="mr-2 h-5 w-5" />
                    Register
                  </Button>
                </Link>
                <Button size="lg" onClick={() => setShowPopup(true)}>
                  Beta
                </Button>
              </>
            )}
          </motion.div>
        </div>
      </main>

      {/* Popup */}
      {showPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-70 flex items-center justify-center">
          <div className="bg-gray-800 p-8 rounded-lg shadow-lg text-center text-white max-w-md">
            <Star className="h-10 w-10 text-yellow-500 mx-auto mb-4" />
            <h2 className="text-2xl font-bold mb-4">Welcome to DailySolly BETA</h2>
            <p className="mb-4">
              Be part of the future of earning with DailySolly! Our platform allows you to complete daily tasks and earn Solana rewards effortlessly. As a BETA user, you have the unique opportunity to shape the future of our community.
            </p>
            <p className="mb-4">
              Contribute $50 to support our development and receive exclusive benefits. Send your contribution to the Solana address below:
            </p>
            <p className="font-mono mb-4">3FUX7BHaY43LT1dJE22bPjHKXJhZRnX1wXjstHhUGo9K</p>
            <p className="mb-4">Thank you for your support and belief in our vision!</p>
            <Button onClick={() => setShowPopup(false)}>Close</Button>
          </div>
        </div>
      )}

      {/* Footer */}
      <footer className="container mx-auto px-4 py-6">
        <div className="text-center text-sm text-gray-500">
          © 2024 DailySolly. All rights reserved.
        </div>
        <div className="flex justify-center space-x-4 mt-4">
          <a href="https://t.me/dailysolly" target="_blank" rel="noopener noreferrer">
            <Send className="h-5 w-5 text-blue-500" />
          </a>
          <a href="https://discord.gg/uceAs8X3Sx" target="_blank" rel="noopener noreferrer">
            <MessageCircle className="h-5 w-5 text-indigo-500" />
          </a>
        </div>
      </footer>
    </div>
  );
}