import { useEffect, useState } from 'react';
import {
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
  writeBatch,
  Timestamp,
  increment,
  query,
  where,
  getDoc,
} from 'firebase/firestore';
import { db, auth } from '@/lib/firebase';
import {
  CheckCircle,
  RefreshCcw,
  Trash2,
  ArrowDown,
  Search,
} from 'lucide-react';
import { toast } from 'react-hot-toast';
import Papa from 'papaparse';

interface SpinHistory {
  id: string;
  email: string;
  amountWon: number;
  date: string;
}

interface UserProfile {
  email: string;
  totalAmountWon: number;
  lastSpinDate: string;
}

export function SpinWheel() {
  const [spinHistory, setSpinHistory] = useState<SpinHistory[]>([]);
  const [userProfiles, setUserProfiles] = useState<UserProfile[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [log, setLog] = useState<string[]>([]);

  // Pagination States
  const [spinPage, setSpinPage] = useState(1);
  const [userPage, setUserPage] = useState(1);
  const spinsPerPage = 10;
  const usersPerPage = 10;

  // Search States
  const [spinSearch, setSpinSearch] = useState('');
  const [userSearch, setUserSearch] = useState('');

  // Aggregate Statistics
  const [totalSpins, setTotalSpins] = useState(0);
  const [totalAmountWon, setTotalAmountWon] = useState(0);
  const [averageAmountWon, setAverageAmountWon] = useState(0);

  const fetchSpinHistory = async () => {
    try {
      const spinRef = collection(db, 'spinWheelHistory');
      const snapshot = await getDocs(spinRef);
      
      const data: SpinHistory[] = snapshot.docs.map((doc) => {
        const docData = doc.data();
        const amountWon = parseFloat(docData.amountWon) || 0;
        console.log('Spin amount:', amountWon);
        return {
          id: doc.id,
          email: docData.email || '',
          amountWon: amountWon,
          date: docData.date?.toDate()?.toLocaleString() || new Date().toLocaleString(),
        };
      });

      console.log('All spins:', data);
      
      const winningSpins = data.filter(spin => spin.amountWon > 0);
      console.log('Winning spins:', winningSpins);
      
      const total = winningSpins.reduce((acc, spin) => acc + spin.amountWon, 0);
      console.log('Total amount won:', total);
      
      setTotalAmountWon(total);
      const average = winningSpins.length > 0 ? total / winningSpins.length : 0;
      console.log('Calculated average:', average);
      
      setSpinHistory(data);
      setTotalSpins(data.length);
      setAverageAmountWon(average);
    } catch (error) {
      console.error('Error fetching spin history:', error);
      setError('Failed to fetch spin history');
      toast.error('Failed to fetch spin history');
    }
  };

  const fetchUserProfiles = async () => {
    try {
      const usersRef = collection(db, 'users');
      const spinRef = collection(db, 'spinWheelHistory');
      
      const [usersSnapshot, spinHistorySnapshot] = await Promise.all([
        getDocs(usersRef),
        getDocs(spinRef)
      ]);
      
      const userAggregates = new Map();
      
      spinHistorySnapshot.docs.forEach(doc => {
        const data = doc.data();
        if (!data.email) return;
        
        const email = data.email;
        const amount = data.amountWon || 0;
        const date = data.date?.toDate() || new Date();
        
        if (!userAggregates.has(email)) {
          userAggregates.set(email, {
            totalAmountWon: 0,
            lastSpinDate: null
          });
        }
        
        const userAggregate = userAggregates.get(email);
        userAggregate.totalAmountWon += amount;
        
        if (!userAggregate.lastSpinDate || date > userAggregate.lastSpinDate) {
          userAggregate.lastSpinDate = date;
        }
      });
      
      const userData: UserProfile[] = usersSnapshot.docs
        .filter(doc => doc.data().email) // Filter out docs without email
        .map(doc => {
          const data = doc.data();
          const aggregates = userAggregates.get(data.email) || {
            totalAmountWon: 0,
            lastSpinDate: null
          };
          
          return {
            email: data.email,
            totalAmountWon: aggregates.totalAmountWon,
            lastSpinDate: aggregates.lastSpinDate 
              ? aggregates.lastSpinDate.toLocaleString()
              : 'Never'
          };
        });

      setUserProfiles(userData);
    } catch (error) {
      console.error('Error fetching user profiles:', error);
      setError('Failed to fetch user profiles');
      toast.error('Failed to fetch user profiles');
    }
  };

  useEffect(() => {
    const initializeData = async () => {
      setLoading(true);
      setError(null);
      try {
        await Promise.all([
          fetchSpinHistory(),
          fetchUserProfiles()
        ]);
      } catch (error) {
        console.error('Error initializing data:', error);
        setError('Failed to initialize data');
      } finally {
        setLoading(false);
      }
    };

    initializeData();
  }, []);

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-900">
        <div className="text-red-500 text-lg">{error}</div>
      </div>
    );
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-900">
        <div className="text-white text-lg">Loading spin wheel data...</div>
      </div>
    );
  }

  // Pagination Calculations
  const indexOfLastSpin = spinPage * spinsPerPage;
  const indexOfFirstSpin = indexOfLastSpin - spinsPerPage;
  const currentSpins = spinHistory
    .filter(
      (spin) =>
        spin.email.toLowerCase().includes(spinSearch.toLowerCase()) ||
        spin.amountWon.toString().includes(spinSearch) ||
        spin.date.toLowerCase().includes(spinSearch.toLowerCase())
    )
    .slice(indexOfFirstSpin, indexOfLastSpin);

  const indexOfLastUser = userPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = userProfiles
    .filter(
      (user) =>
        user.email.toLowerCase().includes(userSearch.toLowerCase()) ||
        user.totalAmountWon.toString().includes(userSearch) ||
        user.lastSpinDate.toLowerCase().includes(userSearch.toLowerCase())
    )
    .slice(indexOfFirstUser, indexOfLastUser);

  const totalSpinPages = Math.ceil(
    spinHistory.filter(
      (spin) =>
        spin.email.toLowerCase().includes(spinSearch.toLowerCase()) ||
        spin.amountWon.toString().includes(spinSearch) ||
        spin.date.toLowerCase().includes(spinSearch.toLowerCase())
    ).length / spinsPerPage
  );

  const totalUserPages = Math.ceil(
    userProfiles.filter(
      (user) =>
        user.email.toLowerCase().includes(userSearch.toLowerCase()) ||
        user.totalAmountWon.toString().includes(userSearch) ||
        user.lastSpinDate.toLowerCase().includes(userSearch.toLowerCase())
    ).length / usersPerPage
  );

  return (
    <div className="p-4 md:p-8 bg-gray-900 min-h-screen text-white">
      {/* Header */}
      <div className="flex flex-col md:flex-row items-center justify-between mb-6">
        <div className="flex items-center space-x-2">
          <CheckCircle className="h-8 w-8 text-purple-500" />
          <h3 className="text-2xl font-bold">Spin Wheel Dashboard</h3>
        </div>
        <div className="flex space-x-4 mt-4 md:mt-0">
          <button
            onClick={() => {
              fetchSpinHistory(); // Ensure data refreshes on button click
              fetchUserProfiles(); // Refresh user profiles as well
            }}
            className="flex items-center bg-gray-800 hover:bg-gray-700 text-white px-4 py-2 rounded transition"
          >
            <RefreshCcw className="mr-2" />
            Refresh History
          </button>
          <button
            onClick={() => {
              // Implement delete all spin results functionality
            }}
            className="flex items-center bg-gray-800 hover:bg-gray-700 text-white px-4 py-2 rounded transition"
          >
            <Trash2 className="mr-2" />
            Delete All Results
          </button>
          <button
            onClick={() => {
              // Implement export CSV functionality
            }}
            className="flex items-center bg-gray-800 hover:bg-gray-700 text-white px-4 py-2 rounded transition"
          >
            <ArrowDown className="mr-2" />
            Export CSV
          </button>
        </div>
      </div>

      {/* Aggregate Statistics */}
      <section className="mb-8">
        <h4 className="text-xl font-semibold mb-4">Aggregate Statistics</h4>
        <div className="flex flex-col sm:flex-row gap-4">
          <div className="bg-gray-800 p-6 rounded-lg shadow-md flex-1">
            <p className="text-sm text-gray-400">Total Spins</p>
            <p className="text-2xl font-bold text-purple-400">{totalSpins}</p>
          </div>
          <div className="bg-gray-800 p-6 rounded-lg shadow-md flex-1">
            <p className="text-sm text-gray-400">Total Amount Won</p>
            <p className="text-2xl font-bold text-purple-400">{totalAmountWon} SOL</p>
          </div>
          <div className="bg-gray-800 p-6 rounded-lg shadow-md flex-1">
            <p className="text-sm text-gray-400">Average Amount Won</p>
            <p className="text-2xl font-bold text-purple-400">
              {averageAmountWon.toFixed(2)} SOL
            </p>
          </div>
        </div>
      </section>

      {/* User Profiles Section */}
      <section className="mb-8">
        <div className="flex items-center justify-between mb-4">
          <h4 className="text-xl font-semibold">User Profiles</h4>
          <button
            onClick={() => {
              // Implement export CSV functionality
            }}
            className="flex items-center bg-gray-800 hover:bg-gray-700 text-white px-4 py-2 rounded transition"
          >
            <ArrowDown className="mr-2" />
            Export CSV
          </button>
        </div>
        {/* Search Bar */}
        <div className="mb-4 flex items-center bg-gray-800 rounded-lg">
          <Search className="h-5 w-5 text-gray-400 ml-3" />
          <input
            type="text"
            placeholder="Search User Profiles..."
            value={userSearch}
            onChange={(e) => setUserSearch(e.target.value)}
            className="w-full bg-transparent p-2 rounded-r-lg focus:outline-none text-white"
          />
        </div>
        {/* User Profiles Table */}
        {userProfiles.length > 0 ? (
          <>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-gray-800 rounded-lg">
                <thead>
                  <tr>
                    <th className="py-2 px-4 border-b border-gray-700">Email</th>
                    <th className="py-2 px-4 border-b border-gray-700">Total Amount Won (SOL)</th>
                    <th className="py-2 px-4 border-b border-gray-700">Last Spin Date</th>
                  </tr>
                </thead>
                <tbody>
                  {currentUsers.map((user, index) => (
                    <tr key={index} className="hover:bg-gray-700">
                      <td className="py-2 px-4 border-b border-gray-700">{user.email}</td>
                      <td className="py-2 px-4 border-b border-gray-700 text-purple-400">
                        {user.totalAmountWon} SOL
                      </td>
                      <td className="py-2 px-4 border-b border-gray-700">{user.lastSpinDate}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* Pagination Controls */}
            <div className="flex justify-center mt-4 space-x-2">
              {Array.from({ length: totalUserPages }, (_, i) => i + 1).map((page) => (
                <button
                  key={page}
                  onClick={() => setUserPage(page)}
                  className={`px-3 py-1 rounded ${
                    userPage === page
                      ? 'bg-purple-500 text-white'
                      : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                  } transition`}
                >
                  {page}
                </button>
              ))}
            </div>
          </>
        ) : (
          <p className="text-gray-400">No user profiles available.</p>
        )}
      </section>

      {/* Spin History Section */}
      <section className="mb-8">
        <div className="flex items-center justify-between mb-4">
          <h4 className="text-xl font-semibold">Spin Results</h4>
          <div className="flex space-x-2">
            <button
              onClick={() => {
                // Implement export CSV functionality
              }}
              className="flex items-center bg-gray-800 hover:bg-gray-700 text-white px-4 py-2 rounded transition"
            >
              <ArrowDown className="mr-2" />
              Export CSV
            </button>
            <button
              onClick={() => {
                // Implement delete all spin results functionality
              }}
              className="flex items-center bg-gray-800 hover:bg-gray-700 text-white px-4 py-2 rounded transition"
            >
              <Trash2 className="mr-2" />
              Delete All
            </button>
          </div>
        </div>
        {/* Search Bar */}
        <div className="mb-4 flex items-center bg-gray-800 rounded-lg">
          <Search className="h-5 w-5 text-gray-400 ml-3" />
          <input
            type="text"
            placeholder="Search Spin Results..."
            value={spinSearch}
            onChange={(e) => setSpinSearch(e.target.value)}
            className="w-full bg-transparent p-2 rounded-r-lg focus:outline-none text-white"
          />
        </div>
        {/* Spin History Table */}
        {spinHistory.length > 0 ? (
          <>
            <div className="overflow-x-auto">
              <table className="min-w-full bg-gray-800 rounded-lg">
                <thead>
                  <tr>
                    <th className="py-2 px-4 border-b border-gray-700">Email</th>
                    <th className="py-2 px-4 border-b border-gray-700">Amount Won (SOL)</th>
                    <th className="py-2 px-4 border-b border-gray-700">Date</th>
                    <th className="py-2 px-4 border-b border-gray-700">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentSpins.map((spin) => (
                    <tr key={spin.id} className="hover:bg-gray-700">
                      <td className="py-2 px-4 border-b border-gray-700">{spin.email}</td>
                      <td className="py-2 px-4 border-b border-gray-700 text-purple-400">
                        {spin.amountWon} SOL
                      </td>
                      <td className="py-2 px-4 border-b border-gray-700">{spin.date}</td>
                      <td className="py-2 px-4 border-b border-gray-700">
                        <button
                          onClick={() => {
                            // Implement delete spin result functionality
                          }}
                          className="flex items-center bg-red-500 hover:bg-red-600 text-white px-3 py-1 rounded transition"
                        >
                          <Trash2 className="h-4 w-4 mr-1" />
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* Pagination Controls */}
            <div className="flex justify-center mt-4 space-x-2">
              {Array.from({ length: totalSpinPages }, (_, i) => i + 1).map((page) => (
                <button
                  key={page}
                  onClick={() => setSpinPage(page)}
                  className={`px-3 py-1 rounded ${
                    spinPage === page
                      ? 'bg-purple-500 text-white'
                      : 'bg-gray-700 text-gray-300 hover:bg-gray-600'
                  } transition`}
                >
                  {page}
                </button>
              ))}
            </div>
          </>
        ) : (
          <p className="text-gray-400">No spin results available.</p>
        )}
      </section>

      {/* Action Log Section */}
      <section>
        <h4 className="text-xl font-semibold mb-4">Action Log</h4>
        {log.length > 0 ? (
          <ul className="list-disc list-inside text-gray-400 space-y-2 max-h-40 overflow-y-auto">
            {log.map((entry, index) => (
              <li key={index}>{entry}</li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-400">No actions logged yet.</p>
        )}
      </section>
    </div>
  );
}
