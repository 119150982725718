import React, { useState, useEffect } from 'react';
import {
  collection,
  query,
  where,
  addDoc,
  doc,
  updateDoc,
  onSnapshot,
} from 'firebase/firestore';
import { db } from '@/lib/firebase';
import { Button } from '@/components/ui/Button';
import { toast } from 'react-toastify';
import { motion } from 'framer-motion';
import { X } from 'lucide-react';

interface RedeemItem {
  id: string;
  name: string;
  description: string;
  pointsRequired: number;
  redemptions: number;
  giftCode?: string; // Optional predefined gift code
  imageUrl?: string;
}

interface RedemptionRecord {
  id: string;
  itemId: string;
  redeemedAt: Date;
  userId: string;
}

interface RedeemShopModalProps {
  isOpen: boolean;
  onClose: () => void;
  userId: string;
  userPoints: number;
}

export const RedeemShopModal: React.FC<RedeemShopModalProps> = ({
  isOpen,
  onClose,
  userId,
  userPoints,
}) => {
  const [items, setItems] = useState<RedeemItem[]>([]);
  const [loading, setLoading] = useState(true);
  const [redeemingItemId, setRedeemingItemId] = useState<string | null>(null);
  const [giftCardCode, setGiftCardCode] = useState('');
  const [redemptionHistory, setRedemptionHistory] = useState<RedemptionRecord[]>([]);
  const [showGiftCardPopup, setShowGiftCardPopup] = useState(false);
  const [showInsufficientPointsPopup, setShowInsufficientPointsPopup] = useState(false);

  useEffect(() => {
    if (!isOpen) return;

    const unsubscribeRedeemShop = onSnapshot(collection(db, 'redeemShop'), snapshot => {
      const fetchedItems = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      })) as RedeemItem[];
      setItems(fetchedItems);
    });

    const unsubscribeUserRedemptions = onSnapshot(
      query(collection(db, 'userRedemptions'), where('userId', '==', userId)),
      snapshot => {
        const fetchedHistory = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        })) as RedemptionRecord[];
        setRedemptionHistory(fetchedHistory);
      }
    );

    setLoading(false);

    return () => {
      unsubscribeRedeemShop();
      unsubscribeUserRedemptions();
    };
  }, [isOpen, userId]);

  const handleRedeem = async (item: RedeemItem) => {
    if (userPoints < item.pointsRequired) {
      setShowInsufficientPointsPopup(true);
      return;
    }

    if (redemptionHistory.some(record => record.itemId === item.id)) {
      toast.error('You have already redeemed this item.');
      return;
    }

    setRedeemingItemId(item.id);

    try {
      const giftCode = item.giftCode || `GIFT-${Math.random().toString(36).substr(2, 9).toUpperCase()}`;

      // Deduct points from the user
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        points: userPoints - item.pointsRequired,
      });

      // Add redemption to userRedemptions collection
      await addDoc(collection(db, 'userRedemptions'), {
        userId,
        itemId: item.id,
        redeemedAt: new Date(),
        giftCode,
      });

      // Update redemptions count in redeemShop collection
      const itemRef = doc(db, 'redeemShop', item.id);
      await updateDoc(itemRef, {
        redemptions: item.redemptions + 1,
      });

      setGiftCardCode(giftCode);
      setShowGiftCardPopup(true);
      toast.success(`Successfully redeemed ${item.name}!`);
    } catch (error) {
      console.error('Error redeeming item:', error);
      toast.error('Failed to redeem the item.');
    } finally {
      setRedeemingItemId(null);
    }
  };

  if (!isOpen) return null;

  return (
    <motion.div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <motion.div
        className="bg-gray-900 p-6 rounded-lg shadow-lg max-w-5xl w-full relative overflow-y-auto max-h-full border border-purple-600"
        initial={{ scale: 0.8 }}
        animate={{ scale: 1 }}
        exit={{ scale: 0.8 }}
      >
        {/* Improved Close Button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 p-2 rounded-full bg-gray-800 hover:bg-gray-700 text-gray-400 hover:text-white"
        >
          <X className="h-6 w-6" />
        </button>

        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-bold text-white">Gift Shop</h2>
          <p className="text-sm text-gray-400">Exchange your points for exciting rewards!</p>
        </div>

        {loading ? (
          <p className="text-center text-gray-400">Loading...</p>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
            {items.map(item => (
              <motion.div
                key={item.id}
                className={`bg-gray-800 p-4 rounded-lg ${
                  redemptionHistory.some(record => record.itemId === item.id)
                    ? 'opacity-50'
                    : 'hover:bg-gray-700'
                }`}
                whileHover={{ scale: 1.02 }}
              >
                <h3 className="text-lg font-bold text-white">{item.name}</h3>
                <p className="text-sm text-gray-400">{item.description}</p>
                <p className="text-gray-300 mt-2">Redemptions: {item.redemptions}</p>
                <p className="text-gray-300">Points: {item.pointsRequired}</p>
                <Button
                  onClick={() => handleRedeem(item)}
                  disabled={
                    redeemingItemId === item.id ||
                    redemptionHistory.some(record => record.itemId === item.id)
                  }
                  className="mt-4"
                >
                  {redeemingItemId === item.id ? 'Redeeming...' : 'Redeem'}
                </Button>
              </motion.div>
            ))}
          </div>
        )}

        {showGiftCardPopup && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
            <div className="bg-gray-900 p-6 rounded-lg text-center">
              <h3 className="text-lg font-semibold text-white">Your Gift Card Code</h3>
              <p className="text-gray-400 mt-2">Copy this code to redeem:</p>
              <div className="bg-gray-800 p-4 mt-4 rounded text-white text-lg font-bold">
                {giftCardCode}
              </div>
              <Button onClick={() => setShowGiftCardPopup(false)} className="mt-4">
                Close
              </Button>
            </div>
          </div>
        )}

        {showInsufficientPointsPopup && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
            <div className="bg-gray-900 p-6 rounded-lg text-center">
              <h3 className="text-lg font-semibold text-white">Insufficient Points</h3>
              <p className="text-gray-400 mt-2">You do not have enough points to redeem this item.</p>
              <Button onClick={() => setShowInsufficientPointsPopup(false)} className="mt-4">
                Close
              </Button>
            </div>
          </div>
        )}
      </motion.div>
    </motion.div>
  );
};
