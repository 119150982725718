import { useEffect, useState } from 'react';
import { collection, getDocs, addDoc, deleteDoc, doc, setDoc } from 'firebase/firestore';
import { db } from '@/lib/firebase';
import { Button } from '@/components/ui/Button';
import { toast } from 'react-hot-toast';

interface Referral {
  id: string;
  user: string;
  referredBy: string;
  createdAt: Date;
}

interface UserDetails {
  user: string;
  referredBy: string;
}

export function ReferralManagement() {
  const [referrals, setReferrals] = useState<Referral[]>([]);
  const [loading, setLoading] = useState(true);
  const [newReferral, setNewReferral] = useState({ user: '', referredBy: '' });
  const [isCreating, setIsCreating] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserDetails | null>(null);

  useEffect(() => {
    const fetchReferrals = async () => {
      setLoading(true);
      try {
        const referralsCollection = collection(db, 'referrals');
        const referralsSnapshot = await getDocs(referralsCollection);
        const referralsData = referralsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt ? new Date(doc.data().createdAt.seconds * 1000) : new Date(),
        })) as Referral[];
        setReferrals(referralsData);
      } catch (error) {
        console.error('Error fetching referrals:', error);
        toast.error('Failed to fetch referrals.');
      } finally {
        setLoading(false);
      }
    };

    fetchReferrals();
  }, []);

  const handleAddReferral = async () => {
    if (!newReferral.user || !newReferral.referredBy) {
      toast.error('Please fill in all fields.');
      return;
    }

    setIsCreating(true);
    try {
      const referralData = {
        user: newReferral.user,
        referredBy: newReferral.referredBy,
        createdAt: new Date(),
      };
      const docRef = await addDoc(collection(db, 'referrals'), referralData);
      setReferrals(prev => [...prev, { id: docRef.id, ...referralData }]);

      // Update referralStats for the new user
      const referralStatsRef = doc(db, 'referralStats', newReferral.user);
      await setDoc(referralStatsRef, {
        totalReferrals: 0,
        activeReferrals: 0,
        earnings: 0.00000,
      }, { merge: true });

      setNewReferral({ user: '', referredBy: '' });
      toast.success('Referral added successfully!');
    } catch (error) {
      console.error('Error adding referral:', error);
      toast.error('Failed to add referral.');
    } finally {
      setIsCreating(false);
    }
  };

  const handleDeleteReferral = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this referral?')) {
      try {
        await deleteDoc(doc(db, 'referrals', id));
        setReferrals(referrals.filter(referral => referral.id !== id));
        toast.success('Referral deleted successfully!');
      } catch (error) {
        console.error('Error deleting referral:', error);
        toast.error('Failed to delete referral.');
      }
    }
  };

  const handleUserDetails = (user: string, referredBy: string) => {
    setSelectedUser({ user, referredBy });
  };

  const closeUserDetails = () => {
    setSelectedUser(null);
  };

  if (loading) {
    return <div>Loading referrals...</div>;
  }

  return (
    <div className="space-y-6 p-6">
      <h2 className="text-2xl font-bold text-white">Referrals</h2>
      <p className="text-gray-400">Manage your referral programs here.</p>
      <div className="mt-4 grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="bg-gray-800 p-4 rounded-lg">
          <h3 className="text-lg font-semibold text-white">Add New Referral</h3>
          <div className="flex flex-col space-y-2">
            <input
              type="text"
              placeholder="User"
              value={newReferral.user}
              onChange={(e) => setNewReferral({ ...newReferral, user: e.target.value })}
              className="p-2 border border-gray-600 rounded bg-gray-700 text-white"
            />
            <input
              type="text"
              placeholder="Referred By"
              value={newReferral.referredBy}
              onChange={(e) => setNewReferral({ ...newReferral, referredBy: e.target.value })}
              className="p-2 border border-gray-600 rounded bg-gray-700 text-white"
            />
            <Button onClick={handleAddReferral} className="bg-purple-500" disabled={isCreating}>
              {isCreating ? 'Adding...' : 'Add Referral'}
            </Button>
          </div>
        </div>
        <div className="bg-gray-800 p-4 rounded-lg">
          <h3 className="text-lg font-semibold text-white">Referral List</h3>
          <ul className="space-y-2">
            {referrals.map(referral => (
              <li key={referral.id} className="flex justify-between p-2 border-b border-gray-700">
                <div>
                  <p className="font-medium text-white">{referral.user}</p>
                  <p className="text-sm text-gray-400 cursor-pointer" onClick={() => handleUserDetails(referral.user, referral.referredBy)}>
                    Referred by: {referral.referredBy}
                  </p>
                  <p className="text-xs text-gray-500">Created at: {referral.createdAt.toLocaleString()}</p>
                </div>
                <Button onClick={() => handleDeleteReferral(referral.id)} className="text-red-500">Delete</Button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      {/* User Details Modal */}
      {selectedUser && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-gray-800 p-6 rounded-lg">
            <h3 className="text-lg font-semibold text-white">User Details</h3>
            <p className="text-white">User: {selectedUser.user}</p>
            <p className="text-white">Referred By: {selectedUser.referredBy}</p>
            <Button onClick={closeUserDetails} className="mt-4 bg-purple-500">Close</Button>
          </div>
        </div>
      )}
    </div>
  );
}