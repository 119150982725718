import { Routes, Route, Navigate } from 'react-router-dom';
import { Suspense } from 'react';
import { AdminLayout } from '@/components/admin/AdminLayout';
import { AdminOverview } from '@/components/admin/AdminOverview';
import { UserManagement } from '@/components/admin/UserManagement';
import { TaskManagement } from '@/components/admin/TaskManagement';
import { WithdrawalManagement } from '@/components/admin/WithdrawalManagement';
import { ReferralManagement } from '@/components/admin/ReferralManagement';
import { Analytics } from '@/components/admin/Analytics';
import { RaffleManagement } from '@/components/admin/RaffleManagement';
import { SpinWheel } from '@/components/admin/SpinWheel';
import { AnnouncementManagement } from '@/components/admin/AnnouncementManagement';
import { CreateNewsArticle } from '@/components/admin/CreateNewsArticle';
import { CreateNotification } from '@/components/admin/CreateNotification';
import { WebShopManagement } from '@/components/admin/WebShopManagement'; // Import the new component
import { TopicsManagement } from '@/components/admin/TopicsManagement';
import { TicketSupportManagement } from '@/components/admin/TicketSupportManagement'; // Import the new component
import { Droplet } from 'lucide-react'; // Faucet icon
import FaucetManager from '@/components/admin/FaucetManager'; // Import the FaucetManager// import { Faucet } from 'lucide-react'; // Remove this line if not used
// Use default import instead of named import
import { useState, useEffect, useCallback } from 'react';
import { collection, query, onSnapshot, getDocs, limit } from 'firebase/firestore';
import { db } from '@/lib/firebase';

interface Task {
  id: string;
  title: string;
  description: string;
  reward: number;
  type: 'football' | 'bible' | 'quiz';
  difficulty: 'easy' | 'medium' | 'hard';
  requirements: string[];
  createdAt: string;
  status: 'active' | 'inactive';
  completionCount: number;
  day: number;
  question: string;
  answer: string;
}

interface Ticket {
  id: string;
  status: 'open' | 'in-progress' | 'resolved';
  replies: Reply[];
  hasNewReply: boolean;
}

interface Reply {
  isStaff: boolean;
}

const FaucetPopup: React.FC<{ isOpen: boolean; onClose: () => void }> = ({ isOpen, onClose }) => {
  const [solanaAddress, setSolanaAddress] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleClaim = async () => {
    setIsLoading(true);
    setMessage('');
    try {
      const response = await fetch('/api/faucet/claim', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, solanaAddress }),
      });

      const data = await response.json();
      if (response.ok) {
        setMessage(`Successfully claimed ${data.amount} SOL`);
        // Optional: reset form or add additional logic
        setSolanaAddress('');
        setEmail('');
      } else {
        setMessage(data.message || 'Failed to claim faucet');
      }
    } catch (error) {
      console.error('Error claiming faucet:', error);
      setMessage('Network error. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
      <div className="bg-gray-900 p-6 rounded-lg shadow-lg max-w-md w-full">
        <h3 className="text-xl font-bold mb-4 text-white">Solana Faucet</h3>
        <input
          type="text"
          placeholder="Your Solana Address"
          value={solanaAddress}
          onChange={(e) => setSolanaAddress(e.target.value)}
          className="w-full p-2 mb-4 border border-gray-600 rounded bg-gray-800 text-white"
        />
        <input
          type="email"
          placeholder="Your Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full p-2 mb-4 border border-gray-600 rounded bg-gray-800 text-white"
        />
        <button 
          onClick={handleClaim} 
          disabled={isLoading}
          className={`w-full p-2 rounded ${
            isLoading 
              ? 'bg-gray-600 cursor-not-allowed' 
              : 'bg-blue-500 hover:bg-blue-600 text-white'
          }`}
        >
          {isLoading ? 'Processing...' : 'Claim 0.00045 SOL'}
        </button>
        {message && (
          <p className={`mt-4 text-sm ${
            message.includes('Successfully') 
              ? 'text-green-400' 
              : 'text-red-400'
          }`}>
            {message}
          </p>
        )}
        <div className="flex justify-end mt-4">
          <button 
            onClick={onClose} 
            className="text-gray-400 hover:text-white"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const UserLogs = () => {
    const [logs, setLogs] = useState<any[]>([]);

    useEffect(() => {
        const fetchLogs = async () => {
            const logsRef = collection(db, 'userLogs');
            const logsSnapshot = await getDocs(logsRef);
            const logsData = logsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setLogs(logsData);
        };

        fetchLogs();
    }, []);

    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold">User Action Logs</h2>
            <table className="min-w-full bg-gray-800 rounded-lg">
                <thead>
                    <tr>
                        <th className="py-2 px-4 border-b border-gray-700">User ID</th>
                        <th className="py-2 px-4 border-b border-gray-700">Action</th>
                        <th className="py-2 px-4 border-b border-gray-700">Description</th>
                        <th className="py-2 px-4 border-b border-gray-700">Timestamp</th>
                    </tr>
                </thead>
                <tbody>
                    {logs.map(log => (
                        <tr key={log.id} className="hover:bg-gray-700">
                            <td className="py-2 px-4 border-b border-gray-700">{log.userId}</td>
                            <td className="py-2 px-4 border-b border-gray-700">{log.action}</td>
                            <td className="py-2 px-4 border-b border-gray-700">{log.description}</td>
                            <td className="py-2 px-4 border-b border-gray-700">{new Date(log.timestamp.seconds * 1000).toLocaleString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export function AdminDashboard() {
  const [hasNewTickets, setHasNewTickets] = useState(false);
  const [hasNewReplies, setHasNewReplies] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showFaucetPopup, setShowFaucetPopup] = useState(false);

  useEffect(() => {
    const ticketsRef = collection(db, 'tickets');
    const q = query(ticketsRef);

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const ticketsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        replies: doc.data().replies || [], // Ensure replies always exists
      })) as Ticket[];
      
      const newTickets = ticketsData.some(ticket => 
        ticket.status === 'open' && ticket.replies.length === 0
      );
      
      const newReplies = ticketsData.some(ticket => 
        ticket.hasNewReply && 
        ticket.replies.length > 0 && 
        !ticket.replies[ticket.replies.length - 1].isStaff
      );

      setHasNewTickets(newTickets);
      setHasNewReplies(newReplies);
    }, (error) => {
      console.error('Error in ticket subscription:', error);
      setError('Failed to load tickets');
    });

    return () => unsubscribe();
  }, []);

  const LoadingFallback = () => (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-purple-900/20 to-gray-900 flex items-center justify-center">
      <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-purple-500"></div>
    </div>
  );

  if (error) {
    return (
      <div className="min-h-screen bg-gradient-to-br from-gray-900 via-purple-900/20 to-gray-900 flex items-center justify-center">
        <div className="text-red-500 bg-red-500/10 px-4 py-2 rounded-lg">
          Error: {error}
        </div>
      </div>
    );
  }

  const handleTaskCreated = (task: Omit<Task, 'requirements' | 'question'>) => {
    console.log('Nieuwe taak aangemaakt:', task);
    // Voeg hier logica toe om de taaklijst bij te werken of een notificatie te tonen
    // Bijvoorbeeld:
    // toast.success(`Taak "${task.title}" succesvol aangemaakt!`);
  };

  return (
    <AdminLayout notifications={{ tickets: hasNewTickets, replies: hasNewReplies }}>
      <Suspense fallback={<LoadingFallback />}>
        <Routes>
          <Route index element={<AdminOverview />} />
          <Route path="users" element={<UserManagement initialUsers={[]} />} />
          <Route path="tasks" element={<TaskManagement onTaskCreated={handleTaskCreated} />} />
          <Route path="withdrawals" element={<WithdrawalManagement />} />
          <Route path="referrals" element={<ReferralManagement />} />
          <Route path="analytics" element={<Analytics />} />
          <Route path="raffles" element={<RaffleManagement />} />
          <Route path="spinwheel" element={<SpinWheel />} />
          <Route path="announcements" element={<AnnouncementManagement />} />
          <Route path="news" element={<CreateNewsArticle />} />
          <Route path="notifications" element={<CreateNotification />} />
          <Route path="webshop" element={<WebShopManagement />} />
          <Route path="topics" element={<TopicsManagement />} />
          <Route path="tickets" element={<TicketSupportManagement />} />
          <Route path="faucet-manager" element={<FaucetManager />} />
          <Route path="user-logs" element={<UserLogs />} />

          <Route path="*" element={<Navigate to="/admin" replace />} />
        </Routes>
      </Suspense>
      <FaucetPopup isOpen={showFaucetPopup} onClose={() => setShowFaucetPopup(false)} />
    </AdminLayout>
  );
}