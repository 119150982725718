import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Bell, CheckCircle, Clock, MessageSquare, Gift, X, Info, Trash2, Check, Newspaper, ClipboardCheck, Megaphone, DollarSign, UserPlus } from 'lucide-react';
import { Button } from '@/components/ui/Button';
import { formatDistanceToNow, format } from 'date-fns';
import { collection, onSnapshot, doc, updateDoc, writeBatch } from 'firebase/firestore';
import { db } from '@/lib/firebase';
import { auth } from '@/lib/firebase';

export interface Notification {
  id: string;
  title: string;
  message: string;
  type: 'reward' | 'system' | 'social' | 'achievement' | 'news' | 'update' | 'task' | 'announcement' | 'payment' | 'referral';
  read: boolean;
  createdAt: string;
}

interface NotificationsPopoverProps {
  notifications: any[];
}

export const NotificationsPopover: React.FC<NotificationsPopoverProps> = ({ notifications }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isRinging, setIsRinging] = useState(false);
  const [loading, setLoading] = useState(true);
  const [notificationsList, setNotifications] = useState<Notification[]>([]);
  const [hiddenNotifications, setHiddenNotifications] = useState<Set<string>>(new Set());
  const [showAll, setShowAll] = useState(false);
  const [newNotifications, setNewNotifications] = useState<Set<string>>(new Set());

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'notifications'), (snapshot) => {
      const now = new Date();
      const fetchedNotifications = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      })) as Notification[];

      const visibleNotifications = fetchedNotifications.filter(notification => {
        const createdAt = new Date(notification.createdAt);
        return (now.getTime() - createdAt.getTime()) > 10000;
      });

      const unreadNotifications = new Set(
        visibleNotifications
          .filter(notification => !notification.read)
          .map(notification => notification.id)
      );
      setNewNotifications(unreadNotifications);

      setNotifications(visibleNotifications);
      setLoading(false);
    }, (error) => {
      console.error("Error fetching notifications: ", error);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (newNotifications.size > 0) {
      setIsRinging(true);
      const timer = setTimeout(() => {
        setIsRinging(false);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [newNotifications]);

  useEffect(() => {
    // Load hidden notifications from localStorage
    const storedHidden = localStorage.getItem('hiddenNotifications');
    if (storedHidden) {
      setHiddenNotifications(new Set(JSON.parse(storedHidden)));
    }
  }, []);

  const markAsRead = async (userId: string, notificationId: string) => {
    const notificationRef = doc(db, 'users', userId, 'notifications', notificationId);
    await updateDoc(notificationRef, { read: true });

    setNotifications(prevNotifications =>
      prevNotifications.map(notification =>
        notification.id === notificationId ? { ...notification, read: true } : notification
      )
    );
  };

  const hideNotification = (notificationId: string) => {
    setHiddenNotifications(prev => {
      const updated = new Set(prev).add(notificationId);
      localStorage.setItem('hiddenNotifications', JSON.stringify(Array.from(updated)));
      return updated;
    });
  };

  const markAllAsRead = async (userId: string) => {
    try {
      const batch = writeBatch(db);
      notificationsList.forEach(notification => {
        if (!notification.read) {
          const notificationRef = doc(db, 'notifications', notification.id);
          batch.update(notificationRef, { read: true });
        }
      });
      await batch.commit();

      // Clear all new notifications
      setNewNotifications(new Set());

      // Update local notifications state
      setNotifications(prevNotifications =>
        prevNotifications.map(notification => ({ ...notification, read: true }))
      );

      // Stop the bell animation if it's ringing
      setIsRinging(false);
    } catch (error) {
      console.error("Error marking all notifications as read:", error);
    }
  };

  // Define the getNotificationIcon function
  const getNotificationIcon = (type: string) => {
    switch (type) {
      case 'reward':
        return <Gift className="h-5 w-5 text-yellow-500" />;
      case 'system':
        return <Info className="h-5 w-5 text-blue-500" />;
      case 'social':
        return <UserPlus className="h-5 w-5 text-green-500" />;
      case 'achievement':
        return <CheckCircle className="h-5 w-5 text-purple-500" />;
      case 'news':
        return <Newspaper className="h-5 w-5 text-red-500" />;
      case 'update':
        return <Megaphone className="h-5 w-5 text-orange-500" />;
      case 'task':
        return <ClipboardCheck className="h-5 w-5 text-teal-500" />;
      case 'announcement':
        return <Megaphone className="h-5 w-5 text-pink-500" />;
      case 'payment':
        return <DollarSign className="h-5 w-5 text-indigo-500" />;
      case 'referral':
        return <UserPlus className="h-5 w-5 text-lime-500" />;
      default:
        return <Bell className="h-5 w-5 text-gray-500" />;
    }
  };

  return (
    <div className="relative">
      <Button
        variant="ghost"
        size="sm"
        onClick={() => setIsOpen(!isOpen)}
        className="relative"
      >
        <motion.div
          animate={isRinging ? {
            rotate: [0, 15, -15, 15, -15, 0],
            transition: { duration: 0.5, repeat: 2 }
          } : {}}
        >
          <Bell className="h-5 w-5" />
        </motion.div>
        {newNotifications.size > 0 && (
          <span className="absolute -top-1 -right-1 h-4 w-4 text-xs bg-gradient-to-r from-purple-500 to-pink-500 rounded-full flex items-center justify-center">
            {newNotifications.size}
          </span>
        )}
      </Button>

      <AnimatePresence>
        {isOpen && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/20 z-40"
              onClick={() => setIsOpen(false)}
            />
            <motion.div
              initial={{ opacity: 0, scale: 0.95, y: 10 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.95, y: 10 }}
              className="absolute right-0 mt-2 w-96 bg-gradient-to-br from-gray-900 to-gray-800 rounded-lg shadow-xl overflow-hidden z-50 border border-gray-700"
            >
              <div className="p-4">
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-lg font-semibold">Notifications</h3>
                  <div className="flex items-center space-x-2">
                    <Button 
                      variant="ghost" 
                      size="sm" 
                      onClick={() => auth.currentUser && markAllAsRead(auth.currentUser.uid)}
                    >
                      Mark all read
                    </Button>
                    <Button variant="ghost" size="sm" onClick={() => setIsOpen(false)}>
                      <X className="h-4 w-4" />
                    </Button>
                  </div>
                </div>

                {loading ? (
                  <div className="text-center py-8">
                    <p className="text-gray-400">Loading notifications...</p>
                  </div>
                ) : (
                  notificationsList.length > 0 ? (
                    notificationsList
                      .filter(notification => !hiddenNotifications.has(notification.id))
                      .map((notification) => (
                        <div
                          key={notification.id}
                          className={`p-3 mb-2 rounded-lg transition-colors cursor-pointer ${
                            notification.read
                              ? 'bg-gray-800/30'
                              : 'bg-gradient-to-r from-purple-500/10 to-pink-500/10 border border-purple-500/20'
                          }`}
                          onClick={() => {
                            if (!notification.read) {
                              markAsRead('userIdForCurrentUser', notification.id);
                            }
                          }}
                        >
                          <div className="flex items-start space-x-3">
                            <div className="bg-gray-800 p-2 rounded-lg relative">
                              {getNotificationIcon(notification.type)}
                              {notification.read && (
                                <Check className="h-3 w-3 text-green-500 absolute -top-1 -right-1" />
                              )}
                            </div>
                            <div className="flex-1">
                              <div className="flex items-center justify-between">
                                <p className="font-medium">{notification.title}</p>
                                <span className="text-xs text-gray-400">
                                  {formatDistanceToNow(new Date(notification.createdAt), { addSuffix: true })}
                                </span>
                              </div>
                              <p className="text-sm text-gray-400 mt-1">
                                {notification.message}
                              </p>
                              <span className="text-xs text-gray-500 italic">{notification.type}</span>
                              <span className="text-xs text-gray-500 block">
                                {format(new Date(notification.createdAt), 'PPpp')}
                              </span>
                            </div>
                            <button onClick={(e) => {
                              e.stopPropagation();
                              hideNotification(notification.id);
                            }}>
                              <Trash2 className="h-5 w-5 text-red-500" />
                            </button>
                          </div>
                        </div>
                      ))
                  ) : (
                    <div className="text-center py-8">
                      <Bell className="h-12 w-12 text-gray-400 mx-auto mb-3" />
                      <p className="text-gray-400">No notifications yet</p>
                    </div>
                  )
                )}

                <Button
                  variant="ghost"
                  size="sm"
                  className="w-full mt-4 text-purple-400"
                  onClick={() => setShowAll(true)}
                >
                  View All Notifications
                </Button>
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {showAll && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black/50 z-50"
              onClick={() => setShowAll(false)}
            />
            <motion.div
              initial={{ opacity: 0, scale: 0.95, y: 10 }}
              animate={{ opacity: 1, scale: 1, y: 0 }}
              exit={{ opacity: 0, scale: 0.95, y: 10 }}
              className="fixed inset-0 m-auto w-full max-w-2xl h-full max-h-96 bg-gradient-to-br from-gray-900 to-gray-800 rounded-lg shadow-xl overflow-y-auto z-50 border border-gray-700"
            >
              <div className="p-6">
                <div className="flex items-center justify-between mb-4">
                  <h3 className="text-lg font-semibold">All Notifications</h3>
                  <Button variant="ghost" size="sm" onClick={() => setShowAll(false)}>
                    <X className="h-4 w-4" />
                  </Button>
                </div>

                {notificationsList.map((notification) => (
                  <div
                    key={notification.id}
                    className="p-3 mb-2 rounded-lg bg-gray-800/30"
                  >
                    <div className="flex items-start space-x-3">
                      <div className="bg-gray-800 p-2 rounded-lg">
                        {getNotificationIcon(notification.type)}
                      </div>
                      <div className="flex-1">
                        <div className="flex items-center justify-between">
                          <p className="font-medium">{notification.title}</p>
                          <span className="text-xs text-gray-400">
                            {formatDistanceToNow(new Date(notification.createdAt), { addSuffix: true })}
                          </span>
                        </div>
                        <p className="text-sm text-gray-400 mt-1">
                          {notification.message}
                        </p>
                        <span className="text-xs text-gray-500 italic">{notification.type}</span>
                        <span className="text-xs text-gray-500 block">
                          {format(new Date(notification.createdAt), 'PPpp')}
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
}