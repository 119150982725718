import { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import {
  LayoutDashboard,
  Users,
  CheckSquare,
  DollarSign,
  LifeBuoy,
  MessageSquare,
  Users2,
  Gift,
  BarChart3,
  ChevronRight,
  LogOut,
  ArrowLeft,
  Droplet,
} from 'lucide-react';
import { Button } from '@/components/ui/Button';
import { auth, db } from '@/lib/firebase';
import { collection, query, orderBy, getDocs, where, onSnapshot } from 'firebase/firestore';

const menuItems = [
  { path: '/admin', icon: LayoutDashboard, label: 'Overview' },
  { path: '/admin/users', icon: Users, label: 'Users' },
  { path: '/admin/tasks', icon: CheckSquare, label: 'Tasks' },
  { path: '/admin/withdrawals', icon: DollarSign, label: 'Withdrawals' },
  { path: '/admin/tickets', icon: LifeBuoy, label: 'Support Tickets' },
  { path: '/admin/referrals', icon: Users2, label: 'Referrals' },
  { path: '/admin/raffles', icon: Gift, label: 'Raffles' },
  { path: '/admin/analytics', icon: BarChart3, label: 'Analytics' },
  { path: '/admin/spinwheel', icon: Gift, label: 'Spin Wheel Stats' },
  { path: '/admin/topics', icon: MessageSquare, label: 'Topics' },
  { path: '/admin/announcements', icon: MessageSquare, label: 'Announcements' },
  { path: '/admin/news', icon: MessageSquare, label: 'Manage News' },
  { path: '/admin/notifications', icon: MessageSquare, label: 'Create Notification' },
  { path: '/admin/webshop', icon: DollarSign, label: 'WebShop' },
  { path: '/admin/faucet-manager', icon: Droplet, label: 'Faucet' },
];

interface AdminLayoutProps {
  children: React.ReactNode;
  notifications: {
    tickets: boolean;
    replies: boolean;
  };
}

export function AdminLayout({ children, notifications }: AdminLayoutProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [newTicketCount, setNewTicketCount] = useState(0);
  const [loadingTickets, setLoadingTickets] = useState(true); // Loading state for tickets
  const [ticketNotifications, setTicketNotifications] = useState({
    open: 0,
    urgent: 0,
    total: 0
  });

  useEffect(() => {
    const fetchNewTicketCount = async () => {
      setLoadingTickets(true); // Set loading state
      try {
        const ticketsRef = collection(db, 'tickets');
        const q = query(ticketsRef, orderBy('createdAt', 'desc'));
        const snapshot = await getDocs(q);
        const ticketsData = snapshot.docs.map(doc => doc.data());

        // Count new tickets (you can adjust the logic based on your criteria)
        const newTickets = ticketsData.filter((ticket: any) => ticket.status === 'open');
        setNewTicketCount(newTickets.length);
      } catch (error) {
        console.error('Error fetching new ticket count:', error);
      } finally {
        setLoadingTickets(false); // Reset loading state
      }
    };

    fetchNewTicketCount();
    const interval = setInterval(fetchNewTicketCount, 5000); // Check every 5 seconds
    return () => clearInterval(interval); // Cleanup on unmount
  }, []);

  useEffect(() => {
    const ticketsRef = collection(db, 'tickets');
    const q = query(ticketsRef, where('status', '==', 'open'));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const tickets = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      setTicketNotifications({
        open: tickets.length,
        urgent: tickets.filter((ticket: any) => ticket.priority === 'urgent').length,
        total: tickets.length
      });
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await auth.signOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const handleTicketClick = () => {
    setNewTicketCount(0); // Reset the notification count when clicking on Support Tickets
    navigate('/admin/tickets'); // Navigate to the tickets page
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-900 via-purple-900/20 to-gray-900 flex">
      {/* Sidebar */}
      <motion.div
        animate={{ width: isCollapsed ? '80px' : '256px' }}
        className="bg-gray-900/50 border-r border-gray-800/50 backdrop-blur-sm overflow-hidden"
        transition={{ duration: 0.3 }}
      >
        <div className="p-4">
          <div className="flex items-center justify-between mb-8">
            {!isCollapsed && (
              <Link
                to="/admin"
                className="text-xl font-bold bg-gradient-to-r from-purple-400 to-pink-400 bg-clip-text text-transparent"
              >
                Admin Panel
              </Link>
            )}
            <Button
              variant="ghost"
              size="sm"
              onClick={() => setIsCollapsed(!isCollapsed)}
              aria-label={isCollapsed ? 'Expand menu' : 'Collapse menu'}
            >
              <ChevronRight
                className={`h-5 w-5 transition-transform ${isCollapsed ? 'rotate-180' : ''}`}
              />
            </Button>
          </div>

          <nav className="space-y-2">
            {menuItems.map((item) => {
              const isActive = location.pathname === item.path;
              const hasNotification = item.path === '/admin/tickets' && ticketNotifications.total > 0;

              return (
                <Link
                  key={item.path}
                  to={item.path}
                  onClick={item.path === '/admin/tickets' ? handleTicketClick : undefined}
                  className={`flex items-center justify-between px-4 py-2.5 rounded-lg transition-all duration-200
                    ${isActive ? 'bg-gradient-to-r from-purple-500/20 to-pink-500/20 text-white border border-purple-500/30' : 'text-gray-400 hover:bg-gray-800/50'}
                  `}
                >
                  <div className="flex items-center">
                    <item.icon className={`h-5 w-5 ${isActive ? 'text-purple-400' : ''}`} />
                    {!isCollapsed && <span className="ml-3">{item.label}</span>}
                  </div>
                  {hasNotification && !isCollapsed && (
                    <div className="flex items-center gap-2">
                      <span className="px-2 py-0.5 text-xs bg-red-500/20 text-red-400 rounded-full">
                        {ticketNotifications.total}
                      </span>
                      {ticketNotifications.urgent > 0 && (
                        <span className="px-2 py-0.5 text-xs bg-yellow-500/20 text-yellow-400 rounded-full">
                          !
                        </span>
                      )}
                    </div>
                  )}
                </Link>
              );
            })}
          </nav>
        </div>

        <div className="absolute bottom-0 w-full p-4">
          <div className="flex items-center justify-between">
            <Button
              variant="ghost"
              size="sm"
              onClick={() => navigate('/dashboard')}
              className="text-gray-400 hover:text-white"
              aria-label="Back to App"
            >
              <ArrowLeft className="h-5 w-5" />
              {!isCollapsed && <span className="ml-2">Back to App</span>}
            </Button>
            <Button
              variant="ghost"
              size="sm"
              onClick={handleLogout}
              className="text-red-400 hover:text-red-300"
              aria-label="Logout"
            >
              <LogOut className="h-5 w-5" />
            </Button>
          </div>
        </div>
      </motion.div>

      {/* Main Content */}
      <main className="flex-1 p-8 overflow-y-auto">{children}</main>
    </div>
  );
}