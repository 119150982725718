import { useState, useEffect, Fragment } from 'react';
import {
  collection,
  query,
  getDocs,
  updateDoc,
  doc,
  where,
  deleteDoc,
  limit,
} from 'firebase/firestore';
import { db } from '@/lib/firebase';
import {
  Ban,
  Unlock,
  UserCheck,
  UserPlus,
  UserMinus,
  Edit2,
  Trash,
  Info,
  MoreVertical,
  Shield,
  ShieldCheck,
  UserCog,
  AlertCircle,
  Tag,
  Check,
  RefreshCcw,
} from 'lucide-react';
import { toast, Toaster } from 'react-hot-toast';

// Modal Components from Headless UI
import { Dialog, Transition, Portal } from '@headlessui/react';

// Tooltip Components from Radix UI
import * as Tooltip from '@radix-ui/react-tooltip';

interface User {
  id: string;
  email: string;
  displayName: string;
  photoURL?: string;
  isAdmin?: boolean;
  isBanned?: boolean;
  tags?: string[];
  createdAt: string;
  lastLogin?: string;
  points?: number;
  banReason?: string;
  tasks?: any[];
  lastTaskCompletion?: string | null;
}

interface UserManagementProps {
  initialUsers: User[];
}

export function UserManagement({ initialUsers }: UserManagementProps) {
  const [users, setUsers] = useState<User[]>(initialUsers);
  const [totalUsers, setTotalUsers] = useState<number>(initialUsers.length);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedRole, setSelectedRole] = useState<string>('all');

  // Modal States
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isBanModalOpen, setIsBanModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [editDisplayName, setEditDisplayName] = useState('');
  const [editPhotoURL, setEditPhotoURL] = useState('');
  const [banReason, setBanReason] = useState('');

  // Update totalUsers whenever users change
  useEffect(() => {
    setTotalUsers(users.length);
  }, [users]);

  // Fetch Users
  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const usersRef = collection(db, 'users');
      const querySnapshot = await getDocs(usersRef);

      const usersData = querySnapshot.docs
        .map((doc) => ({
          id: doc.id,
          email: doc.data().email || '',
          displayName: doc.data().displayName || '',
          createdAt: doc.data().createdAt || '',
          ...doc.data(),
          emailVerified: doc.data().emailVerified || false
        })) as User[];

      // Sort users by createdAt date (newest first)
      const sortedUsers = usersData.sort((a, b) => {
        return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
      });

      setUsers(sortedUsers);
      setTotalUsers(querySnapshot.size); // Use total document count
      setLoading(false);
    } catch (err) {
      setError('Failed to fetch users');
      setLoading(false);
      console.error('Error fetching users:', err);
    }
  };

  // Handle Toggle Ban
  const handleToggleBan = async (userId: string, currentBanStatus: boolean, reason: string) => {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        isBanned: !currentBanStatus,
        banReason: !currentBanStatus ? reason : '',
      });

      setUsers(
        users.map((user) =>
          user.id === userId
            ? { ...user, isBanned: !currentBanStatus, banReason: !currentBanStatus ? reason : '' }
            : user
        )
      );

      toast.success(`User ${!currentBanStatus ? 'banned' : 'unbanned'} successfully`);
    } catch (err) {
      console.error('Error updating user ban status:', err);
      setError('Failed to update user ban status');
      toast.error('Failed to update user ban status');
    }
  };

  // Handle Role Change
  const handleRoleChange = async (userId: string, newRole: string) => {
    try {
      const userRef = doc(db, 'users', userId);
      const tags =
        newRole === 'user'
          ? []
          : newRole === 'admin'
          ? ['admin']
          : [newRole];

      await updateDoc(userRef, {
        tags,
        isAdmin: newRole === 'admin',
      });

      setUsers(
        users.map((user) =>
          user.id === userId
            ? {
                ...user,
                tags,
                isAdmin: newRole === 'admin',
              }
            : user
        )
      );

      toast.success(`User role updated to ${newRole}`);
    } catch (err) {
      console.error('Error updating user role:', err);
      setError('Failed to update user role');
      toast.error('Failed to update user role');
    }
  };

  // Handle Delete User
  const handleDeleteUser = async (userId: string) => {
    try {
      const userRef = doc(db, 'users', userId);
      await deleteDoc(userRef);

      // Update local state to remove the deleted user
      setUsers(users.filter((user) => user.id !== userId));

      toast.success('User deleted successfully');
    } catch (err) {
      console.error('Error deleting user:', err); // Log the error for debugging
      setError('Failed to delete user');
      toast.error('Failed to delete user');
    }
  };

  // Open Edit Modal
  const openEditModal = (user: User) => {
    setCurrentUser(user);
    setEditDisplayName(user.displayName || '');
    setEditPhotoURL(user.photoURL || '');
    setIsEditModalOpen(true);
  };

  // Close Edit Modal
  const closeEditModal = () => {
    setIsEditModalOpen(false);
    setCurrentUser(null);
  };

  // Submit Edit
  const submitEdit = async () => {
    if (!currentUser) return;

    try {
      const userRef = doc(db, 'users', currentUser.id);
      await updateDoc(userRef, {
        displayName: editDisplayName,
        photoURL: editPhotoURL,
      });

      setUsers(
        users.map((user) =>
          user.id === currentUser.id
            ? { ...user, displayName: editDisplayName, photoURL: editPhotoURL }
            : user
        )
      );

      toast.success('User profile updated successfully');
      closeEditModal();
    } catch (err) {
      console.error('Error updating user profile:', err);
      setError('Failed to update user profile');
      toast.error('Failed to update user profile');
    }
  };

  // Open Delete Modal
  const openDeleteModal = (user: User) => {
    setCurrentUser(user);
    setIsDeleteModalOpen(true);
  };

  // Close Delete Modal
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setCurrentUser(null);
  };

  // Open Ban Modal
  const openBanModal = (user: User) => {
    setCurrentUser(user);
    setBanReason('');
    setIsBanModalOpen(true);
  };

  // Close Ban Modal
  const closeBanModal = () => {
    setIsBanModalOpen(false);
    setCurrentUser(null);
    setBanReason('');
  };

  const filteredUsers = users.filter((user) => {
    const matchesSearch =
      user.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.displayName?.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesRole =
      selectedRole === 'all' ||
      (selectedRole === 'admin' && user.isAdmin) ||
      (selectedRole !== 'admin' && user.tags?.includes(selectedRole));
    return matchesSearch && matchesRole;
  });

  // Add this helper function at the top of your component
  const getInitials = (user: User) => {
    if (user.displayName) {
      return user.displayName
        .split(' ')
        .map(n => n[0])
        .join('')
        .toUpperCase()
        .slice(0, 2);
    }
    return user.email
      .split('@')[0]
      .slice(0, 2)
      .toUpperCase();
  };

  // Update the useEffect for handling outside clicks
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const dropdowns = document.querySelectorAll('[aria-label="Manage role"] + div');
      dropdowns.forEach((dropdown) => {
        if (!dropdown.contains(event.target as Node) && 
            !(event.target as Element).closest('[aria-label="Manage role"]')) {
          dropdown.classList.add('hidden');
        }
      });
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  // Add this function with your other handlers
  const handleTagToggle = async (userId: string, tag: string) => {
    try {
      const userRef = doc(db, 'users', userId);
      const user = users.find(u => u.id === userId);
      const currentTags = user?.tags || [];
      
      const newTags = currentTags.includes(tag)
        ? currentTags.filter(t => t !== tag)
        : [...currentTags, tag];

      await updateDoc(userRef, {
        tags: newTags,
      });

      setUsers(
        users.map((user) =>
          user.id === userId
            ? { ...user, tags: newTags }
            : user
        )
      );

      toast.success(`User tag ${currentTags.includes(tag) ? 'removed' : 'added'} successfully`);
    } catch (err) {
      console.error('Error updating user tags:', err);
      setError('Failed to update user tags');
      toast.error('Failed to update user tags');
    }
  };

  // Add these state handlers for managing popups
  const [activePopup, setActivePopup] = useState<{
    type: 'edit' | 'role' | 'tag' | 'ban' | 'delete';
    userId: string;
    position: { x: number; y: number };
  } | null>(null);

  // Update the calculatePopupPosition function
  const calculatePopupPosition = (buttonElement: HTMLElement) => {
    const rect = buttonElement.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;
    
    // Calculate base position (above the button by default)
    let y = rect.top + window.scrollY - 10;
    let x = rect.left + window.scrollX + (rect.width / 2);

    // If there's not enough space above, show below
    if (rect.top < 220) { // Adjust based on your popup height
      y = rect.bottom + window.scrollY + 10;
    }

    // Ensure popup doesn't go off-screen horizontally
    const popupWidth = 256; // Adjust based on your popup width
    const minX = popupWidth / 2;
    const maxX = viewportWidth - (popupWidth / 2);
    x = Math.max(minX, Math.min(x, maxX));

    return { x, y };
  };

  const handleResetTasks = async (userId: string) => {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        tasks: [], // Reset tasks to an empty array or your desired default state
        lastTaskCompletion: null // Reset last task completion to allow immediate task completion
      });

      // Update local state to reflect the reset
      setUsers(
        users.map((user) =>
          user.id === userId
            ? { ...user, tasks: [], lastTaskCompletion: null } // Update only the tasks and last completion
            : user
        )
      );

      toast.success(`Tasks reset for user ${userId} successfully`);
    } catch (err) {
      console.error('Error resetting user tasks:', err);
      setError('Failed to reset user tasks');
      toast.error('Failed to reset user tasks');
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gradient-to-r from-gray-900 to-gray-800">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-purple-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gradient-to-r from-gray-900 to-gray-800">
        <div className="text-red-500 bg-red-100 p-4 rounded-lg">
          {error}
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gradient-to-r from-gray-900 to-gray-800 p-6">
      <Toaster />
      
      {/* Stats Section */}
      <div className="mb-6 bg-gray-800/50 backdrop-blur-sm rounded-xl p-4">
        <div className="flex items-center justify-between">
          <h2 className="text-xl font-bold text-white">User Management</h2>
          <div className="flex items-center gap-2">
            <span className="text-gray-400">Total Users:</span>
            <span className="bg-purple-500/20 text-purple-400 px-3 py-1 rounded-lg font-medium">
              {totalUsers}
            </span>
          </div>
        </div>
      </div>

      {/* Search and Filter */}
      <div className="mb-6 flex flex-col sm:flex-row gap-4">
        <input
          type="text"
          placeholder="Search users..."
          className="px-4 py-2 border border-gray-700 rounded-lg bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-500"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <select
          className="px-4 py-2 border border-gray-700 rounded-lg bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-500"
          value={selectedRole}
          onChange={(e) => setSelectedRole(e.target.value)}
        >
          <option value="all">All Roles</option>
          <option value="admin">Admin</option>
          <option value="moderator">Moderator</option>
          <option value="support">Support</option>
          <option value="user">User</option>
        </select>
      </div>

      {/* Users Grid */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {filteredUsers.map((user) => (
          <div
            key={user.id}
            className="relative bg-gray-800/50 backdrop-blur-sm rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 overflow-visible w-full max-w-[320px] h-[180px] mx-auto"
          >
            {/* Header */}
            <div className="h-24 bg-gradient-to-r from-purple-600 to-blue-600 rounded-t-lg"></div>
            
            {/* Profile Content */}
            <div className="px-4 py-2 -mt-12">
              <div className="flex items-start gap-3">
                {/* Avatar */}
                <div className="relative">
                  <div className="w-16 h-16 rounded-xl bg-gray-700 border-4 border-gray-800 shadow-lg flex items-center justify-center">
                    <UserCheck className="w-8 h-8 text-gray-400" />
                  </div>
                </div>

                {/* User Info */}
                <div className="flex-1 min-w-0">
                  <h2 className="text-base font-bold text-white truncate">
                    {user.displayName || user.email.split('@')[0]}
                  </h2>
                  <p className="text-gray-400 text-xs truncate">{user.email}</p>
                  <p className="absolute bottom-2 left-4 text-gray-500 text-[10px]">
                    Registered on: {user.createdAt && typeof user.createdAt === 'object' && 'seconds' in user.createdAt ? 
                      new Date((user.createdAt as { seconds: number }).seconds * 1000).toLocaleDateString() : 'N/A'}
                  </p>
                  
                  {/* Role and Tags Container */}
                  <div className="mt-2 flex flex-wrap items-center gap-1">
                    {/* Primary Role Badge */}
                    <span className={`inline-flex items-center px-1.5 py-0.5 rounded-full text-[11px] font-medium ${
                      user.isAdmin ? 'bg-red-500/20 text-red-400 border border-red-500/20' :
                      user.tags?.includes('moderator') ? 'bg-blue-500/20 text-blue-400 border border-blue-500/20' :
                      user.tags?.includes('support') ? 'bg-green-500/20 text-green-400 border border-green-500/20' :
                      'bg-gray-500/20 text-gray-400 border border-gray-500/20'
                    }`}>
                      {user.isAdmin ? 'Admin' :
                       user.tags?.includes('moderator') ? 'Moderator' :
                       user.tags?.includes('support') ? 'Support' :
                       'User'}
                    </span>

                    {/* Additional Tags */}
                    {user.tags?.filter(tag => !['moderator', 'support'].includes(tag)).map((tag) => (
                      <span
                        key={tag}
                        className="inline-flex items-center px-1.5 py-0.5 rounded-full text-[11px] font-medium 
                          bg-purple-500/10 text-purple-400 border border-purple-500/20"
                      >
                        {tag.split('_')
                          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                          .join(' ')}
                      </span>
                    ))}
                    
                    {/* Ban Badge */}
                    {user.isBanned && (
                      <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium 
                        bg-yellow-500/10 text-yellow-400 border border-yellow-500/20">
                        Banned
                      </span>
                    )}
                  </div>
                </div>
              </div>

              {/* Actions */}
              <div className="absolute bottom-3 right-4 flex items-center gap-1">
                <Tooltip.Provider delayDuration={100}>
                  {/* Edit Button */}
                  <Tooltip.Root>
                    <Tooltip.Trigger asChild>
                      <button
                        className="p-1.5 hover:bg-gray-700/50 rounded-lg transition-colors focus:outline-none focus:ring-2 focus:ring-purple-500"
                        onClick={(e) => {
                          e.stopPropagation();
                          setCurrentUser(user);
                          setEditDisplayName(user.displayName || '');
                          setEditPhotoURL(user.photoURL || '');
                          setActivePopup({
                            type: 'edit',
                            userId: user.id,
                            position: { x: 0, y: 0 }
                          });
                        }}
                      >
                        <Edit2 className="w-4 h-4 text-blue-400" />
                      </button>
                    </Tooltip.Trigger>
                    <Tooltip.Portal>
                      <Tooltip.Content
                        className="px-2 py-1 text-xs font-medium text-white bg-gray-900 rounded-md shadow-lg animate-in fade-in-0 zoom-in-95"
                        sideOffset={5}
                      >
                        Edit Profile
                        <Tooltip.Arrow className="fill-gray-900" />
                      </Tooltip.Content>
                    </Tooltip.Portal>
                  </Tooltip.Root>

                  {/* Role Button */}
                  <Tooltip.Root>
                    <Tooltip.Trigger asChild>
                      <button
                        className="p-1.5 hover:bg-gray-700/50 rounded-lg transition-colors focus:outline-none focus:ring-2 focus:ring-purple-500"
                        onClick={(e) => {
                          e.stopPropagation();
                          setCurrentUser(user);
                          setActivePopup({
                            type: 'role',
                            userId: user.id,
                            position: { x: 0, y: 0 }
                          });
                        }}
                      >
                        <UserCog className="w-4 h-4 text-emerald-400" />
                      </button>
                    </Tooltip.Trigger>
                    <Tooltip.Portal>
                      <Tooltip.Content
                        className="px-2 py-1 text-xs font-medium text-white bg-gray-900 rounded-md shadow-lg animate-in fade-in-0 zoom-in-95"
                        sideOffset={5}
                      >
                        Manage Role
                        <Tooltip.Arrow className="fill-gray-900" />
                      </Tooltip.Content>
                    </Tooltip.Portal>
                  </Tooltip.Root>

                  {/* Tag Button */}
                  <Tooltip.Root>
                    <Tooltip.Trigger asChild>
                      <button
                        className="p-1.5 hover:bg-gray-700/50 rounded-lg transition-colors focus:outline-none focus:ring-2 focus:ring-purple-500"
                        onClick={(e) => {
                          e.stopPropagation();
                          setCurrentUser(user);
                          setActivePopup({
                            type: 'tag',
                            userId: user.id,
                            position: { x: 0, y: 0 }
                          });
                        }}
                      >
                        <Tag className="w-4 h-4 text-purple-400" />
                      </button>
                    </Tooltip.Trigger>
                    <Tooltip.Portal>
                      <Tooltip.Content
                        className="px-2 py-1 text-xs font-medium text-white bg-gray-900 rounded-md shadow-lg animate-in fade-in-0 zoom-in-95"
                        sideOffset={5}
                      >
                        Manage Tags
                        <Tooltip.Arrow className="fill-gray-900" />
                      </Tooltip.Content>
                    </Tooltip.Portal>
                  </Tooltip.Root>

                  {/* Ban Button */}
                  <Tooltip.Root>
                    <Tooltip.Trigger asChild>
                      <button
                        className="p-1.5 hover:bg-gray-700/50 rounded-lg transition-colors focus:outline-none focus:ring-2 focus:ring-purple-500"
                        onClick={(e) => {
                          e.stopPropagation();
                          setCurrentUser(user);
                          setBanReason('');
                          setActivePopup({
                            type: 'ban',
                            userId: user.id,
                            position: { x: 0, y: 0 }
                          });
                        }}
                      >
                        {user.isBanned ? (
                          <Unlock className="w-4 h-4 text-yellow-400" />
                        ) : (
                          <Ban className="w-4 h-4 text-yellow-400" />
                        )}
                      </button>
                    </Tooltip.Trigger>
                    <Tooltip.Portal>
                      <Tooltip.Content
                        className="px-2 py-1 text-xs font-medium text-white bg-gray-900 rounded-md shadow-lg animate-in fade-in-0 zoom-in-95"
                        sideOffset={5}
                      >
                        {user.isBanned ? 'Unban User' : 'Ban User'}
                        <Tooltip.Arrow className="fill-gray-900" />
                      </Tooltip.Content>
                    </Tooltip.Portal>
                  </Tooltip.Root>

                  {/* Delete Button */}
                  <Tooltip.Root>
                    <Tooltip.Trigger asChild>
                      <button
                        className="p-1.5 hover:bg-gray-700/50 rounded-lg transition-colors focus:outline-none focus:ring-2 focus:ring-purple-500"
                        onClick={(e) => {
                          e.stopPropagation();
                          setCurrentUser(user);
                          setActivePopup({
                            type: 'delete',
                            userId: user.id,
                            position: { x: 0, y: 0 }
                          });
                        }}
                      >
                        <Trash className="w-4 h-4 text-red-400" />
                      </button>
                    </Tooltip.Trigger>
                    <Tooltip.Portal>
                      <Tooltip.Content
                        className="px-2 py-1 text-xs font-medium text-white bg-gray-900 rounded-md shadow-lg animate-in fade-in-0 zoom-in-95"
                        sideOffset={5}
                      >
                        Delete User
                        <Tooltip.Arrow className="fill-gray-900" />
                      </Tooltip.Content>
                    </Tooltip.Portal>
                  </Tooltip.Root>

                  {/* Reset Tasks Button */}
                  <Tooltip.Root>
                    <Tooltip.Trigger asChild>
                      <button
                        className="p-1.5 hover:bg-gray-700/50 rounded-lg transition-colors focus:outline-none focus:ring-2 focus:ring-purple-500"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleResetTasks(user.id);
                        }}
                        aria-label="Reset Tasks"
                      >
                        <RefreshCcw className="w-4 h-4 text-blue-400" />
                      </button>
                    </Tooltip.Trigger>
                    <Tooltip.Portal>
                      <Tooltip.Content
                        className="px-2 py-1 text-xs font-medium text-white bg-gray-900 rounded-md shadow-lg animate-in fade-in-0 zoom-in-95"
                        sideOffset={5}
                      >
                        Reset Tasks
                        <Tooltip.Arrow className="fill-gray-900" />
                      </Tooltip.Content>
                    </Tooltip.Portal>
                  </Tooltip.Root>
                </Tooltip.Provider>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Add this at the end of your component, before the closing return tag */}
      {activePopup && (
        <Portal>
          <div 
            className="fixed inset-0 z-50 overflow-hidden bg-gray-900/50 backdrop-blur-sm flex items-center justify-center"
            onClick={() => setActivePopup(null)}
          >
            <div
              className="relative w-full max-w-md mx-4 animate-in fade-in zoom-in-95 duration-200"
              onClick={(e) => e.stopPropagation()}
            >
              {/* Edit Popup */}
              {activePopup.type === 'edit' && (
                <div className="bg-gray-800 rounded-lg shadow-xl border border-gray-700">
                  <div className="px-4 py-3 border-b border-gray-700">
                    <h3 className="text-lg font-medium text-white">Edit Profile</h3>
                  </div>
                  <div className="p-4 space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-300 mb-1">
                        Display Name
                      </label>
                      <input
                        type="text"
                        value={editDisplayName}
                        onChange={(e) => setEditDisplayName(e.target.value)}
                        className="w-full px-3 py-2 text-sm border border-gray-600 rounded-lg bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-500"
                        placeholder="Enter name"
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-gray-300 mb-1">
                        Photo URL
                      </label>
                      <input
                        type="text"
                        value={editPhotoURL}
                        onChange={(e) => setEditPhotoURL(e.target.value)}
                        className="w-full px-3 py-2 text-sm border border-gray-600 rounded-lg bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-500"
                        placeholder="Enter photo URL"
                      />
                    </div>
                  </div>
                  <div className="px-4 py-3 border-t border-gray-700 flex justify-end gap-2">
                    <button
                      onClick={() => setActivePopup(null)}
                      className="px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 rounded-lg transition-colors"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => {
                        submitEdit();
                        setActivePopup(null);
                      }}
                      className="px-4 py-2 text-sm bg-purple-500 text-white hover:bg-purple-600 rounded-lg transition-colors"
                    >
                      Save Changes
                    </button>
                  </div>
                </div>
              )}

              {/* Role Popup */}
              {activePopup.type === 'role' && (
                <div className="bg-gray-800 rounded-lg shadow-xl border border-gray-700">
                  <div className="px-4 py-3 border-b border-gray-700">
                    <h3 className="text-lg font-medium text-white">Manage Role</h3>
                  </div>
                  <div className="py-2">
                    {['admin', 'moderator', 'support', 'user'].map((role) => (
                      <button
                        key={role}
                        onClick={() => {
                          handleRoleChange(activePopup.userId, role);
                          setActivePopup(null);
                        }}
                        className="w-full px-4 py-2 text-left text-sm text-gray-300 hover:bg-gray-700 flex items-center gap-2 transition-colors"
                      >
                        <div className="w-4 h-4">
                          {users.find(u => u.id === activePopup.userId)?.isAdmin && role === 'admin' && (
                            <Check className="w-4 h-4 text-emerald-400" />
                          )}
                        </div>
                        {role.charAt(0).toUpperCase() + role.slice(1)}
                      </button>
                    ))}
                  </div>
                </div>
              )}

              {/* Tag Popup */}
              {activePopup.type === 'tag' && (
                <div className="bg-gray-800 rounded-lg shadow-xl border border-gray-700">
                  <div className="px-4 py-3 border-b border-gray-700">
                    <h3 className="text-lg font-medium text-white">Manage Tags</h3>
                  </div>
                  <div className="py-2">
                    {[
                      'admin',
                      'moderator',
                      'support',
                      'ticket support',
                      'advertiser',
                      'sponsor',
                      'vip',
                      'early access',
                      'contributor'
                    ].map((tag) => (
                      <button
                        key={tag}
                        onClick={() => {
                          handleTagToggle(activePopup.userId, tag);
                          setActivePopup(null);
                        }}
                        className="w-full px-4 py-2 text-left text-sm text-gray-300 hover:bg-gray-700 flex items-center gap-2 transition-colors"
                      >
                        <div className="w-4 h-4">
                          {users.find(u => u.id === activePopup.userId)?.tags?.includes(tag) && (
                            <Check className="w-4 h-4 text-emerald-400" />
                          )}
                        </div>
                        {tag.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
                      </button>
                    ))}
                  </div>
                </div>
              )}

              {/* Ban Popup */}
              {activePopup.type === 'ban' && (
                <div className="bg-gray-800 rounded-lg shadow-xl border border-gray-700">
                  <div className="px-4 py-3 border-b border-gray-700">
                    <h3 className="text-lg font-medium text-white">
                      {users.find(u => u.id === activePopup.userId)?.isBanned ? 'Unban User' : 'Ban User'}
                    </h3>
                  </div>
                  {!users.find(u => u.id === activePopup.userId)?.isBanned && (
                    <div className="p-4">
                      <label className="block text-sm font-medium text-gray-300 mb-1">
                        Reason for Ban
                      </label>
                      <textarea
                        value={banReason}
                        onChange={(e) => setBanReason(e.target.value)}
                        className="w-full px-3 py-2 text-sm border border-gray-600 rounded-lg bg-gray-700 text-white focus:outline-none focus:ring-2 focus:ring-purple-500"
                        placeholder="Enter reason for ban..."
                        rows={3}
                      />
                    </div>
                  )}
                  <div className="px-4 py-3 border-t border-gray-700 flex justify-end gap-2">
                    <button
                      onClick={() => setActivePopup(null)}
                      className="px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 rounded-lg transition-colors"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => {
                        const user = users.find(u => u.id === activePopup.userId);
                        if (user) {
                          handleToggleBan(activePopup.userId, user.isBanned || false, banReason);
                        }
                        setActivePopup(null);
                      }}
                      className="px-4 py-2 text-sm bg-yellow-500 text-white hover:bg-yellow-600 rounded-lg transition-colors"
                    >
                      {users.find(u => u.id === activePopup.userId)?.isBanned ? 'Unban' : 'Ban User'}
                    </button>
                  </div>
                </div>
              )}

              {/* Delete Popup */}
              {activePopup.type === 'delete' && (
                <div className="bg-gray-800 rounded-lg shadow-xl border border-gray-700">
                  <div className="px-4 py-3 border-b border-gray-700">
                    <h3 className="text-lg font-medium text-white">Delete User</h3>
                  </div>
                  <div className="p-4">
                    <AlertCircle className="w-12 h-12 text-red-500 mx-auto mb-4" />
                    <p className="text-sm text-gray-300 text-center">
                      Are you sure you want to delete this user?<br />
                      This action cannot be undone.
                    </p>
                  </div>
                  <div className="px-4 py-3 border-t border-gray-700 flex justify-end gap-2">
                    <button
                      onClick={() => setActivePopup(null)}
                      className="px-4 py-2 text-sm text-gray-300 hover:bg-gray-700 rounded-lg transition-colors"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={() => {
                        if (activePopup.userId) {
                          handleDeleteUser(activePopup.userId); // Call delete function
                        }
                        setActivePopup(null);
                      }}
                      className="px-4 py-2 text-sm bg-red-500 text-white hover:bg-red-600 rounded-lg transition-colors"
                    >
                      Delete User
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Portal>
      )}
    </div>
  );
}
