import { motion, AnimatePresence } from 'framer-motion';
import { X, Trophy, Target, Users, Gift } from 'lucide-react';
import { Button } from '@/components/ui/Button';
import { ActivityChart } from './ActivityChart';

interface StatsData {
  currentStreak: number;
  bestStreak: number;
  totalPoints: number;
  weeklyPoints: number;
  totalTasks: number;
  completionRate: number;
  totalReferrals: number;
  activeReferrals: number;
}

interface StatsModalProps {
  isOpen: boolean;
  onClose: () => void;
  type: 'streak' | 'points' | 'tasks' | 'referrals';
  data: StatsData;
}

export function StatsModal({ isOpen, onClose, type, data }: StatsModalProps) {
  const getTitle = () => {
    const titles = {
      streak: {
        title: 'Streak Details',
        icon: Trophy,
        color: 'text-yellow-500',
      },
      points: {
        title: 'Points History',
        icon: Gift,
        color: 'text-purple-500',
      },
      tasks: {
        title: 'Task Completion',
        icon: Target,
        color: 'text-blue-500',
      },
      referrals: {
        title: 'Referral Analytics',
        icon: Users,
        color: 'text-green-500',
      },
    };

    return titles[type] || titles.streak; // Default to streak if type is invalid
  };

  const { title, icon: Icon, color } = getTitle();

  // Ensure all numeric values are valid numbers or default to 0
  const safeData = {
    currentStreak: Number(data?.currentStreak) || 0,
    bestStreak: Number(data?.bestStreak) || 0,
    totalPoints: Number(data?.totalPoints) || 0,
    weeklyPoints: Number(data?.weeklyPoints) || 0,
    totalTasks: Number(data?.totalTasks) || 0,
    completionRate: Number(data?.completionRate) || 0,
    totalReferrals: Number(data?.totalReferrals) || 0,
    activeReferrals: Number(data?.activeReferrals) || 0,
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.5 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black"
            onClick={onClose}
          />
          <motion.div
            initial={{ opacity: 0, scale: 0.95, y: 20 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.95, y: 20 }}
            className="fixed inset-0 flex items-center justify-center p-4 pointer-events-none"
          >
            <div className="bg-gray-900 p-6 rounded-xl shadow-xl max-w-2xl w-full pointer-events-auto border border-gray-800">
              <div className="flex items-center justify-between mb-6">
                <div className="flex items-center space-x-3">
                  <div className={`p-2 rounded-lg bg-gray-800 ${color}`}>
                    <Icon className="h-6 w-6" />
                  </div>
                  <h3 className="text-xl font-bold">{title}</h3>
                </div>
                <Button variant="ghost" size="sm" onClick={onClose}>
                  <X className="h-5 w-5" />
                </Button>
              </div>

              <div className="space-y-6">
                <ActivityChart data={[]} />

                <div className="grid grid-cols-2 gap-4">
                  {type === 'streak' && (
                    <>
                      <div className="bg-gray-800 p-4 rounded-lg">
                        <p className="text-sm text-gray-400">Current Streak</p>
                        <p className="text-2xl font-bold">{safeData.currentStreak} days</p>
                      </div>
                      <div className="bg-gray-800 p-4 rounded-lg">
                        <p className="text-sm text-gray-400">Best Streak</p>
                        <p className="text-2xl font-bold">{safeData.bestStreak} days</p>
                      </div>
                    </>
                  )}

                  {type === 'points' && (
                    <>
                      <div className="bg-gray-800 p-4 rounded-lg">
                        <p className="text-sm text-gray-400">Total Points</p>
                        <p className="text-2xl font-bold">{safeData.totalPoints}</p>
                      </div>
                      <div className="bg-gray-800 p-4 rounded-lg">
                        <p className="text-sm text-gray-400">Points This Week</p>
                        <p className="text-2xl font-bold">{safeData.weeklyPoints}</p>
                      </div>
                    </>
                  )}

                  {type === 'tasks' && (
                    <>
                      <div className="bg-gray-800 p-4 rounded-lg">
                        <p className="text-sm text-gray-400">Total Completed</p>
                        <p className="text-2xl font-bold">{safeData.totalTasks}</p>
                      </div>
                      <div className="bg-gray-800 p-4 rounded-lg">
                        <p className="text-sm text-gray-400">Completion Rate</p>
                        <p className="text-2xl font-bold">{safeData.completionRate}%</p>
                      </div>
                    </>
                  )}

                  {type === 'referrals' && (
                    <>
                      <div className="bg-gray-800 p-4 rounded-lg">
                        <p className="text-sm text-gray-400">Total Referrals</p>
                        <p className="text-2xl font-bold">{safeData.totalReferrals}</p>
                      </div>
                      <div className="bg-gray-800 p-4 rounded-lg">
                        <p className="text-sm text-gray-400">Active Referrals</p>
                        <p className="text-2xl font-bold">{safeData.activeReferrals}</p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}